import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Edit2, Save, User, Mail, Phone, AlertCircle, CheckCircle } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from '@/hooks/use-toast';
import { useTranslation } from 'react-i18next';

interface ProfileEditorProps {
  onEditComplete?: () => void;
  className?: string;
}

const ProfileEditor: React.FC<ProfileEditorProps> = ({
  onEditComplete,
  className
}) => {
  const { user, profile, updateProfile } = useAuth();
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(profile?.first_name || '');
  const [lastName, setLastName] = useState(profile?.last_name || '');
  const [phone, setPhone] = useState(profile?.phone || '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
    setFirstName(profile?.first_name || '');
    setLastName(profile?.last_name || '');
    setPhone(profile?.phone || '');
    setError(null);
    setIsSuccess(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!profile) return;

    setIsLoading(true);
    setError(null);

    try {
      // Validate first name (required)
      if (!firstName.trim()) {
        throw new Error(t('profile.editor.first_name_required'));
      }

      await updateProfile({
        ...profile,
        first_name: firstName,
        last_name: lastName || null,
        phone: phone || null
      });

      setIsSuccess(true);
      setIsEditing(false);

      toast({
        title: t('profile.editor.toast.success_title'),
        description: t('profile.editor.toast.success_description'),
      });

      if (onEditComplete) {
        onEditComplete();
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      setError(error instanceof Error ? error.message : t('profile.editor.update_failed'));

      toast({
        title: t('profile.editor.toast.error_title'),
        description: error instanceof Error ? error.message : t('profile.editor.toast.error_description'),
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!user || !profile) {
    return null;
  }

  return (
    <Card className={className}>
      <CardContent className="p-5">
        {isSuccess && !isEditing && (
          <Alert className="mb-4 bg-green-50 border-green-200">
            <CheckCircle className="h-4 w-4 text-green-600" />
            <AlertDescription className="text-green-700">
              {t('profile.editor.success_message')}
            </AlertDescription>
          </Alert>
        )}

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {isEditing ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="firstName">{t('profile.editor.first_name')}</Label>
              <div className="relative">
                <User className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="pl-10"
                  placeholder={t('profile.editor.first_name_placeholder')}
                  autoFocus
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="lastName">{t('profile.editor.last_name')}</Label>
              <div className="relative">
                <User className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="pl-10"
                  placeholder={t('profile.editor.last_name_placeholder')}
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="phone">{t('profile.editor.phone')}</Label>
              <div className="relative">
                <Phone className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="pl-10"
                  placeholder={t('profile.editor.phone_placeholder')}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2 pt-2">
              <Button variant="outline" type="button" onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                    {t('profile.editor.saving')}
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    {t('profile.editor.save_changes')}
                  </>
                )}
              </Button>
            </div>
          </form>
        ) : (
          <div className="space-y-4">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold">{t('profile.editor.personal_info')}</h3>
              <Button
                onClick={handleEdit}
                variant="outline"
                size="sm"
                className="h-8 px-2"
              >
                <Edit2 className="h-3.5 w-3.5 mr-1" />
                {t('common.edit')}
              </Button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-muted-foreground mb-1">{t('profile.editor.name')}</p>
                <p className="flex items-center gap-2">
                  <User className="h-4 w-4 text-primary" />
                  <span>{profile.first_name} {profile.last_name}</span>
                </p>
              </div>

              <div>
                <p className="text-sm font-medium text-muted-foreground mb-1">{t('profile.editor.email')}</p>
                <p className="flex items-center gap-2">
                  <Mail className="h-4 w-4 text-primary" />
                  <span>{user.email}</span>
                </p>
              </div>

              <div>
                <p className="text-sm font-medium text-muted-foreground mb-1">{t('profile.editor.phone')}</p>
                <p className="flex items-center gap-2">
                  <Phone className="h-4 w-4 text-primary" />
                  <span>{profile.phone || t('profile.editor.not_provided')}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ProfileEditor;