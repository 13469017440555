import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui/dialog';
import { ArrowLeft, Trash, User } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import { useIsMobile } from '@/hooks/use-mobile';
import { useProperty, usePropertyRents, useUser } from '@/lib/query-hooks/get-hooks';
import { Property, Rent } from '@/types';
import { parseISO, format } from 'date-fns';
import { getPropertyAddress, encryptData, decryptData } from '@/lib/helpers';
import { useUpdateProperty } from '@/lib/query-hooks/mutation/useUpdateProperty';
import { useDeleteProperty } from '@/lib/query-hooks/mutation/useDeleteProperty';
import { ToastAction } from '@/components/ui/toast';
import { UpdatePropertyHookInput } from '@/types/mutation/mutation-input-types';
import { useUpdateRent } from '@/lib/query-hooks/mutation/useUpdateRent';
import { RentCancelDialog } from '@/components/bookings/RentCancelDialog';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useAuth } from '@/contexts/AuthContext';
import { useAllBills, CombinedBill } from '@/lib/query-hooks/useAllBills';
import { useTranslation } from 'react-i18next';

// Import our custom card components
import PropertyInformationCard from '@/components/property-details/PropertyInformationCard';
import RentInformationCard from '@/components/property-details/RentInformationCard';
import PropertyKeysCard from '@/components/property-details/PropertyKeysCard';
import UtilityBillsCard from '@/components/property-details/UtilityBillsCard';
import PropertyChecklistsCard from '@/components/checklists/PropertyChecklistsCard';
import { formatDate } from '@/utils/i18n-utils';

const PropertyDetails: React.FC = () => {
  // Extract params and navigation first
  const { id: propId } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  const userId = user?.id || '';
  const propertyId = propId || '';

  // State declarations
  const [showDoorCode, setShowDoorCode] = useState<boolean>(false);
  const [showWifiPassword, setShowWifiPassword] = useState<boolean>(false);
  const [isEditingKeys, setIsEditingKeys] = useState<boolean>(false);
  const [doorCode, setDoorCode] = useState<string>('');
  const [wifiNetwork, setWifiNetwork] = useState<string>('');
  const [wifiPassword, setWifiPassword] = useState<string>('');
  const [editProperty, setEditProperty] = useState<Property | null>(null);
  const [isDelDialogOpen, setIsDelDialogOpen] = useState<boolean>(false);
  const [rentToCancel, setRentToCancel] = useState<Rent | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [originalKeysData, setOriginalKeysData] = useState({
    doorCode: '',
    wifiNetwork: '',
    wifiPassword: ''
  });

  const {
    data: userData,
    isLoading: isLoadingUserData
  } = useUserData(userId);

  // Data fetching hooks - always called, not conditionally
  const property = useProperty(propertyId, userId);
  const propertyRents = usePropertyRents(propertyId, userId);

  // Process the rent data
  const currentRent = useMemo(() => {
    return propertyRents.find(rent => rent.status === "active" && rent.isAccepted);
  }, [propertyRents]);

  // Get tenant ID safely first, then call useUser with it
  const tenantId = useMemo(() => currentRent?.tenant_id || null, [currentRent]);
  // Call useUser hook unconditionally with a fallback empty string if no tenant
  const tenant = useUser(tenantId || '');

  // Fetch bills for this property
  const {
    bills: propertyBills,
    isLoading: isLoadingBills
  } = useAllBills({
    userId: userId,
    role: userData?.users?.[userId]?.role || 'lessor',
    propertyId: propertyId,
    isCurrentPeriod: true
  });

  // Process bills for display
  const billsByType = useMemo(() => {
    const result: Record<string, CombinedBill[]> = {};

    propertyBills.forEach(bill => {
      const type = bill.type === 'utility' ? bill.utilityType || 'other' : bill.type;
      if (!result[type]) {
        result[type] = [];
      }
      result[type].push(bill);
    });

    return result;
  }, [propertyBills]);

  // Update Property Hook
  const {
    updatePropertyAsync,
    isLoading: updatePropertyInProgress
  } = useUpdateProperty({
    userId: userId,
    onSuccess: () => {
      toast({
        title: t('toast.property_updated'),
        description: t('toast.property_updated_desc'),
      });
      setIsEditingKeys(false);
      setEditProperty(null);
    },
    onError: (error) => {
      toast({
        title: t('toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>,
      });
    },
  });

  // Delete Property Hook
  const {
    deletePropertyAsync
  } = useDeleteProperty({
    userId: userId,
    onSuccess: () => {
      toast({
        title: t('toast.property_deleted'),
        description: t('toast.property_deleted_desc'),
      });
      navigate('/properties');
    },
    onError: (error) => {
      toast({
        title: t('toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>,
      });
    },
  });

  // Update Rent Hook
  const {
    updateRentAsync
  } = useUpdateRent({
    userId: userId,
    onSuccess: () => {
      toast({
        title: t('toast.rent_updated'),
        description: t('toast.rent_updated_desc'),
      });
    },
    onError: (error) => {
      toast({
        title: t('toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>,
      });
    },
  });

  // Initialize state from property data
  useEffect(() => {
    if (property) {
      setWifiNetwork(property.wi_fi_network || '');

      // Decrypt data only if it exists
      if (property.door_code) {
        setDoorCode(decryptData(property.door_code));
      }

      if (property.wi_fi_key) {
        setWifiPassword(decryptData(property.wi_fi_key));
      }

      // Store original values for comparison
      setOriginalKeysData({
        doorCode: property.door_code ? decryptData(property.door_code) : '',
        wifiNetwork: property.wi_fi_network || '',
        wifiPassword: property.wi_fi_key ? decryptData(property.wi_fi_key) : ''
      });
    }
  }, [property]);

  // Format date helper function
  const formatDateWithLang = (dateString: string) => {
    try {
      if (!dateString) return t('common.no_date');

      const date = parseISO(dateString);
      if (isNaN(date.getTime())) {
        return t('common.invalid_date');
      }

      return formatDate(dateString, i18n.language, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', dateString, error);
      return t('common.invalid_date');
    }
  };

  // Check if there are changes to the keys
  const hasKeysChanges = () => {
    return (
      doorCode !== originalKeysData.doorCode ||
      wifiNetwork !== originalKeysData.wifiNetwork ||
      wifiPassword !== originalKeysData.wifiPassword
    );
  };

  // Check if there are changes to the property details
  const hasPropertyChanges = () => {
    if (!editProperty || !property) return false;

    return (
      editProperty.title !== property.title ||
      editProperty.city !== property.city ||
      editProperty.street !== property.street ||
      editProperty.building !== property.building ||
      editProperty.apartment !== property.apartment ||
      editProperty.price !== property.price ||
      editProperty.bedrooms !== property.bedrooms ||
      editProperty.bathrooms !== property.bathrooms ||
      editProperty.area !== property.area ||
      editProperty.unit_measure !== property.unit_measure ||
      editProperty.unit_term !== property.unit_term ||
      editProperty.isStudio !== property.isStudio ||
      editProperty.bills_pay_by_lessor !== property.bills_pay_by_lessor ||
      selectedImage !== null
    );
  };

  const handleDeleteProperty = (propertyId: string) => {
    deletePropertyAsync(propertyId);
  };

  const handleUpdateKeys = async () => {
    if (isEditingKeys && hasKeysChanges()) {
      // Encrypt sensitive data before saving
      const encryptedDoorCodeValue = doorCode ? encryptData(doorCode) : '';
      const encryptedWifiPasswordValue = wifiPassword ? encryptData(wifiPassword) : '';

      try {
        const updateData: UpdatePropertyHookInput = {
          id: propertyId!,
          lessor_id: userId,
          door_code: encryptedDoorCodeValue,
          wi_fi_network: wifiNetwork,
          wi_fi_key: encryptedWifiPasswordValue,
          updated_at: new Date().toISOString()
        };

        await updatePropertyAsync(updateData);

        // Update original values with the plaintext versions for local comparison
        setOriginalKeysData({
          doorCode,
          wifiNetwork,
          wifiPassword
        });

        setIsEditingKeys(false);
      } catch (error) {
        console.error('Failed to update keys:', error);
      }
    } else {
      // Toggle editing mode
      setIsEditingKeys(!isEditingKeys);
    }
  };

  const handlePropertyDetailsUpdate = async () => {
    if (editProperty && hasPropertyChanges()) {
      try {
        const updateData: UpdatePropertyHookInput = {
          id: propertyId!,
          lessor_id: userId,
          title: editProperty.title,
          city: editProperty.city,
          street: editProperty.street,
          building: editProperty.building,
          apartment: editProperty.apartment,
          price: editProperty.price,
          bedrooms: editProperty.bedrooms,
          bathrooms: editProperty.bathrooms,
          area: editProperty.area,
          unit_measure: editProperty.unit_measure,
          unit_term: editProperty.unit_term,
          bills_pay_by_lessor: editProperty.bills_pay_by_lessor,
          updated_at: new Date().toISOString(),
          image_file: selectedImage
        };

        await updatePropertyAsync(updateData);

        // Reset image selection
        setSelectedImage(null);
        setImagePreview(null);
      } catch (error) {
        console.error('Failed to update property details:', error);
      }
    }

    // Reset editing state
    setEditProperty(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedImage(file);

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    if (editProperty) {
      setEditProperty({
        ...editProperty,
        image_url: null
      });
      setSelectedImage(null);
      setImagePreview(null);
    }
  };

  const handleStartEditingProperty = () => {
    if (property) {
      setEditProperty({ ...property });
      setImagePreview(property.image_url || null);
    }
  };

  const handleCancelEditingProperty = () => {
    setEditProperty(null);
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handlePropertyChange = (field: keyof Property, value: any) => {
    if (editProperty) {
      setEditProperty({
        ...editProperty,
        [field]: value
      });
    }
  };

  // Handle cancel Rent confirmation
  const handleConfirmRentCancel = async () => {
    if (!rentToCancel) return;

    const now = new Date();
    const updatedRent: Rent = {
      ...rentToCancel,
      status: 'canceled',
      updated_at: now.toISOString()
    };

    const newRent = await updateRentAsync(updatedRent);
    if (newRent && property) {
      await updatePropertyAsync({
        ...property,
        status: 'vacant',
        updated_at: now.toISOString()
      });
    }
    setRentToCancel(null);
  };

  // Handle cancel rent button click
  const handleOpenCancelDialog = (rent: Rent) => {
    setRentToCancel(rent);
  };

  if (!property) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <h2 className="text-2xl font-bold">{t('properties.property_not_found')}</h2>
            <p className="text-muted-foreground mt-2">{t('properties.property_not_exist')}</p>
            <Button className="mt-4" onClick={() => navigate('/properties')}>
              {t('navigation.back_to_properties')}
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-6 pt-2 pb-8">
        <Button
          variant="ghost"
          onClick={() => navigate('/properties')}
          className="mb-4"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('navigation.back_to_properties')}
        </Button>

        <div className="flex flex-row justify-between sm:flex-row sm:items-start sm:justify-between gap-4">
          <div>
            <h1 className="text-2xl md:text-3xl font-bold tracking-tight">
              {property.title || getPropertyAddress(property)}
            </h1>
            <p className="text-muted-foreground mt-1">
              {getPropertyAddress(property)}
            </p>
          </div>
          <div className='flex space-x-2'>
            {
              isMobile &&
              <Button
                className="w-full text-xs"
                size='sm'
                onClick={() => navigate(`/properties/${propertyId}/invite`)}
              >
                <User className="l:mr-2 h-3 w-3 md:h-4 md:w-4" /> {t('tenant.invite_tenant')}
              </Button>
            }
            <Dialog open={isDelDialogOpen} onOpenChange={setIsDelDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="destructive" size="sm" className="text-xs md:text-sm">
                  <Trash className="l:mr-2 h-3 w-3 md:h-4 md:w-4" /> {!isMobile && t('common.delete')}
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogTitle>{t('properties.delete_property')}</DialogTitle>
                <DialogDescription>
                  {t('properties.delete_confirmation')}
                </DialogDescription>
                <DialogFooter className="mt-4">
                  <Button variant="outline" onClick={() => setIsDelDialogOpen(false)}>
                    {t('common.cancel')}
                  </Button>
                  <Button variant="destructive" onClick={() => handleDeleteProperty(property.id)}>
                    {t('common.delete')}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2 space-y-6">
            {/* Property Information Card */}
            <PropertyInformationCard
              property={property}
              editProperty={editProperty}
              currentRent={currentRent}
              setEditProperty={setEditProperty}
              handlePropertyChange={handlePropertyChange}
              handlePropertyDetailsUpdate={handlePropertyDetailsUpdate}
              handleCancelEditingProperty={handleCancelEditingProperty}
              handleStartEditingProperty={handleStartEditingProperty}
              selectedImage={selectedImage}
              imagePreview={imagePreview}
              handleImageChange={handleImageChange}
              handleRemoveImage={handleRemoveImage}
            />

            {/* Utility Bills Card */}
            <UtilityBillsCard
              billsByType={billsByType}
              formatDateFn={formatDateWithLang}
            />
          </div>

          <div className="space-y-6 col-span-1">
            {/* Rent Information Card */}
            <RentInformationCard
              propertyId={propertyId}
              currentRent={currentRent}
              tenant={tenant}
              handleOpenCancelDialog={handleOpenCancelDialog}
              formatDateFn={formatDateWithLang}
            />

            {/* Checklists Card */}
            <PropertyChecklistsCard
              isMobile={isMobile}
              propertyId={propertyId}
            />

            {/* Property Keys Card */}
            <PropertyKeysCard
              doorCode={doorCode}
              wifiNetwork={wifiNetwork}
              wifiPassword={wifiPassword}
              isEditingKeys={isEditingKeys}
              hasKeysChanges={hasKeysChanges}
              setDoorCode={setDoorCode}
              setWifiNetwork={setWifiNetwork}
              setWifiPassword={setWifiPassword}
              setIsEditingKeys={setIsEditingKeys}
              handleUpdateKeys={handleUpdateKeys}
            />
          </div>
        </div>
      </div>

      {/* Cancel Confirmation Dialog */}
      <RentCancelDialog
        onOpenChange={() => setRentToCancel(null)}
        rentToCancel={rentToCancel}
        handleConfirmCancel={handleConfirmRentCancel}
      />
    </MainLayout>
  );
};

export default PropertyDetails;