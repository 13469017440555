import React from 'react';
import { Button } from '@/components/ui/button';
import { BillItem } from '../shared/BillItem';
import { StatusBadge } from '../shared/StatusBadge';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/i18n-utils';

interface LessorPropertyBillsGroupProps {
    propertyId: string;
    propertyAddress: string;
    bills: CombinedBill[];
    unpaidBillsCount: number;
    unpaidAmount: number;
    isPayingBill: boolean;
    onPayBill: (billId: string) => void;
    onPayAllBills: () => void;
}

export const LessorPropertyBillsGroup: React.FC<LessorPropertyBillsGroupProps> = ({
    propertyId,
    propertyAddress,
    bills,
    unpaidBillsCount,
    unpaidAmount,
    isPayingBill,
    onPayBill,
    onPayAllBills
}) => {
    const { t, i18n } = useTranslation();
    const hasUnpaidBills = unpaidBillsCount > 0;

    return (
        <div className="border rounded-lg overflow-hidden">
            <div className="bg-muted/20 px-4 py-3 flex flex-col sm:flex-row gap-2 sm:items-center sm:justify-between">
                <div className="flex flex-wrap items-center gap-2">
                    <h3 className="font-medium pr-2">{propertyAddress}</h3>
                    {hasUnpaidBills ? (
                        <StatusBadge variant="unpaid" count={unpaidBillsCount} />
                    ) : (
                        <StatusBadge variant="paid" />
                    )}
                </div>

                {hasUnpaidBills && (
                    <Button
                        size="sm"
                        onClick={onPayAllBills}
                        disabled={isPayingBill}
                        className="self-start sm:self-auto"
                    >
                        <span className="whitespace-nowrap">
                            {t('bills.pay_all_amount', {
                                amount: formatCurrency(unpaidAmount, i18n.language),
                                defaultValue: 'Pay All ({{amount}})'
                            })}
                        </span>
                    </Button>
                )}
            </div>

            <div className="p-4 space-y-2">
                {bills.map(bill => (
                    <BillItem
                        key={bill.id}
                        bill={bill}
                        onPayBill={onPayBill}
                        isPayingBill={isPayingBill}
                        showOverdueStatus={true}
                    />
                ))}
            </div>
        </div>
    );
};