import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Property } from '@/types';
import { getPropertyAddress } from '@/lib/helpers';
import { useTranslation } from 'react-i18next';

interface PropertyFilterProps {
  properties: Record<string, Property>;
  selectedPropertyId: string | null;
  onSelectProperty: (propertyId: string | null) => void;
}

const PropertyFilter: React.FC<PropertyFilterProps> = ({
  properties,
  selectedPropertyId,
  onSelectProperty
}) => {
  const { t } = useTranslation();

  // Create an array of properties from the record
  const propertyArray = Object.values(properties || {});

  const handleChange = (value: string) => {
    // If "all" is selected, set selectedPropertyId to null
    if (value === "all") {
      onSelectProperty(null);
    } else {
      onSelectProperty(value);
    }
  };

  return (
    <div className="w-full sm:w-auto">
      <Select
        value={selectedPropertyId || "all"}
        onValueChange={handleChange}
      >
        <SelectTrigger className="w-full sm:w-[240px] truncate">
          <SelectValue placeholder="Filter by property" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">{t('bills.all_properties')}</SelectItem>
          {propertyArray.map((property) => (
            <SelectItem key={property.id} value={property.id}>
              {property.title ? property.title : getPropertyAddress(property)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default PropertyFilter;