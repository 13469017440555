import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { DollarSign, User, Calendar } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Property, Rent, UserProfile } from '@/types';
import { getPropertyAddress } from '@/lib/helpers';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface PropertyCardProps {
    property: Property;
    rents: Rent[];
    tenant?: UserProfile | null;
}

const PropertyCard: React.FC<PropertyCardProps> = ({ property, rents, tenant }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // Helper to get status badge styling
    const getStatusBadgeClass = (status: string) => {
        switch (status) {
            case "occupied":
                return "capitalize bg-green-100 text-green-800 hover:bg-green-100 border-green-200";
            case "vacant":
                return "capitalize bg-orange-50 text-orange-800 hover:bg-orange-50 border-orange-200";
            default:
                return "capitalize bg-gray-100 text-gray-800 hover:bg-gray-100 border-gray-200";
        }
    };

    // Find active rent to get end date
    const activeRent = rents.find(rent => rent.status === "active");

    // Format available from date
    const availableFrom = activeRent?.date_end
        ? formatDate(activeRent.date_end, i18n.language)
        : t('properties.today');

    return (
        <Card className="overflow-hidden flex flex-col justify-stretch items-stretch">
            <div className='flex max-h-40 overflow-hidden items-end justify-center'>
                <img
                    src={property.image_url || "/img/property_placeholder.jpg"}
                    alt={property.title || getPropertyAddress(property) || t('properties.property_image')}
                    className="max-w-full object-cover object-bottom"
                />
            </div>
            <CardContent className="p-4 flex-grow">
                <div className="flex justify-between items-start mb-2">
                    <h3 className="font-medium text-lg line-clamp-1">
                        {property.title || getPropertyAddress(property)}
                    </h3>
                    <Badge
                        variant={property.status === "occupied" ? "default" : "outline"}
                        className={getStatusBadgeClass(property.status)}
                    >
                        {t(`properties.status.${property.status}`)}
                    </Badge>
                </div>
                <p className="text-muted-foreground text-sm line-clamp-1">
                    {getPropertyAddress(property)}
                </p>

                <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                    <div className="flex items-center gap-1">
                        <DollarSign className="h-4 w-4 text-muted-foreground" />
                        <span>
                            {formatCurrency(property.price, i18n.language, property.currency)}/{t(`properties.terms.${property.unit_term}`)}
                        </span>
                    </div>

                    {property.status === "inactive" ? (
                        <div className="flex items-center gap-1 capitalize">
                            <User className="h-4 w-4 text-muted-foreground" />
                            <span>{t(`properties.status.${property.status}`)}</span>
                        </div>
                    ) : property.status === "occupied" || tenant ? (
                        <div className="flex items-center gap-1">
                            <User className="h-4 w-4 text-muted-foreground" />
                            <span>{tenant?.email || t('tenant.no_tenant')}</span>
                        </div>
                    ) : (
                        <div className="flex items-center gap-1">
                            <User className="h-4 w-4 text-muted-foreground" />
                            <span>{t('tenant.no_tenant')}</span>
                        </div>
                    )}

                    <div className="flex items-center gap-1 col-span-2">
                        <Calendar className="h-4 w-4 text-muted-foreground" />
                        <span>
                            {t('properties.available_from')} {availableFrom}
                        </span>
                    </div>
                </div>
            </CardContent>
            <CardFooter className="px-4 py-3 bg-muted/50 w-full flex justify-end">
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => navigate(`/properties/${property.id}`)}
                >
                    {t('properties.view_details')}
                </Button>

            </CardFooter>
        </Card>
    );
};

export default PropertyCard;