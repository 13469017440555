import { Property } from "@/types";
import { DeletePropertyResponse } from "@/types/mutation/mutation-responses-types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { deleteProperty } from "@/lib/actions/delete-property";
import { getFileNameFromUrl, deletePropertyImage } from "@/lib/actions/property-image-upload";

// React Query Mutation Hook
interface PropertyDeleteOptions {
    onSuccess?: (deletedProperty: Property) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
    deletedProperty: Property | undefined;
}

export function useDeleteProperty(options: PropertyDeleteOptions) {
    console.log('Delete Property Request Hook triggered');
    const queryClient = useQueryClient();

    if (!options) {
        throw new Error('options is required');
    }

    const mutation = useMutation<Property, Error, string, MutationContext>({
        mutationFn: async (propertyId) => {
            // Get the property data to use in optimistic update reversal if needed
            const queryKey = ['userData', options.userId];
            const cachedData = queryClient.getQueryData<NormalizedData>(queryKey);
            const propertyToDelete = cachedData?.properties[propertyId];

            // Delete property image if exists
            if (propertyToDelete?.image_url) {
                const fileName = getFileNameFromUrl(propertyToDelete.image_url);
                if (fileName) {
                    await deletePropertyImage(propertyId, fileName);
                }
            }

            // Delete the property from the database
            return deleteProperty(propertyId);
        },

        // Add optimistic update
        onMutate: async (propertyId) => {
            const queryKey = ['userData', options.userId];
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);
            const deletedProperty = previousData?.properties[propertyId];

            if (previousData && deletedProperty) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                    // Get the property to be deleted (for relationships)
                    const propertyToDelete = newData.properties[propertyId];

                    if (propertyToDelete) {
                        const lessorId = propertyToDelete.lessor_id;

                        // Remove from properties
                        delete newData.properties[propertyId];

                        // Update relationship maps

                        // Remove from propertyRents
                        if (newData.propertyRents[propertyId]) {
                            const rentIds = [...newData.propertyRents[propertyId]];
                            delete newData.propertyRents[propertyId];

                            // Remove related rent data
                            rentIds.forEach(rentId => {
                                const rent = newData.rents[rentId];
                                if (rent) {
                                    // Remove related rentBills
                                    if (newData.rentBillsByRent[rentId]) {
                                        const billIds = [...newData.rentBillsByRent[rentId]];
                                        billIds.forEach(billId => {
                                            delete newData.rentBills[billId];
                                        });
                                        delete newData.rentBillsByRent[rentId];
                                    }

                                    // Update userRents relationships
                                    if (rent.lessor_id && newData.userRents.asLessor[rent.lessor_id]) {
                                        newData.userRents.asLessor[rent.lessor_id] =
                                            newData.userRents.asLessor[rent.lessor_id].filter(id => id !== rentId);
                                    }

                                    if (rent.tenant_id && newData.userRents.asTenant[rent.tenant_id]) {
                                        newData.userRents.asTenant[rent.tenant_id] =
                                            newData.userRents.asTenant[rent.tenant_id].filter(id => id !== rentId);
                                    }

                                    // Remove the rent itself
                                    delete newData.rents[rentId];
                                }
                            });
                        }

                        // Remove from userProperties
                        if (newData.userProperties.asLessor[lessorId]) {
                            newData.userProperties.asLessor[lessorId] =
                                newData.userProperties.asLessor[lessorId].filter(id => id !== propertyId);
                        }

                        // Remove from checklistsByProperty
                        if (newData.checklistsByProperty[propertyId]) {
                            // For each checklist, we should also remove related inventory items
                            const checklistIds = [...newData.checklistsByProperty[propertyId]];
                            checklistIds.forEach(checklistId => {
                                // Remove inventory items for this checklist
                                if (newData.inventoryItemsByChecklist[checklistId]) {
                                    const inventoryItemIds = [...newData.inventoryItemsByChecklist[checklistId]];
                                    inventoryItemIds.forEach(itemId => {
                                        delete newData.inventoryItems[itemId];
                                    });
                                    delete newData.inventoryItemsByChecklist[checklistId];
                                }

                                // Remove the checklist itself
                                delete newData.checklists[checklistId];
                            });
                            delete newData.checklistsByProperty[propertyId];
                        }

                        // Remove from repairsByProperty
                        if (newData.repairsByProperty[propertyId]) {
                            const repairIds = [...newData.repairsByProperty[propertyId]];
                            repairIds.forEach(repairId => {
                                const repair = newData.repairs[repairId];
                                if (repair && repair.tenant_id) {
                                    // Clean up tenant relationship for these repairs
                                    if (newData.repairsByTenant[repair.tenant_id]) {
                                        newData.repairsByTenant[repair.tenant_id] =
                                            newData.repairsByTenant[repair.tenant_id].filter(id => id !== repairId);
                                    }
                                }

                                // Delete the repair
                                delete newData.repairs[repairId];
                            });
                            delete newData.repairsByProperty[propertyId];
                        }

                        // Remove property from userProperties.asTenant relationships
                        // Find any tenants who are linked to this property via rents
                        Object.keys(newData.userProperties.asTenant).forEach(tenantId => {
                            if (newData.userProperties.asTenant[tenantId].includes(propertyId)) {
                                newData.userProperties.asTenant[tenantId] =
                                    newData.userProperties.asTenant[tenantId].filter(id => id !== propertyId);
                            }
                        });
                    }

                    return newData;
                });
            }

            return { previousData, deletedProperty };
        },

        onError: (error, _propertyId, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }
            console.error('Error deleting Property', error);
            options?.onError?.(error);
        },

        onSuccess: (response, _propertyId, context) => {

            if (response && options?.onSuccess) {
                options.onSuccess(response);
            }

            // Invalidate queries to refetch fresh data
            //queryClient.invalidateQueries({ queryKey: ['userData', options.userId] });
        },
        retry: false
    });

    return {
        deleteProperty: mutation.mutate,
        deletePropertyAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}