import React from 'react';
import { Property, RentStatus } from '@/types';
import { Button } from '@/components/ui/button';
import { Filter, X } from 'lucide-react';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerClose
} from '@/components/ui/drawer';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { getPropertyAddress } from '@/lib/helpers';
import { useTranslation } from 'react-i18next';

interface BookingsFilterDrawerProps {
  selectedStatus: RentStatus | 'all';
  selectedAcceptance: 'all' | 'accepted' | 'not-accepted';
  selectedProperty: string;
  userProperties: Property[];
  onStatusChange: (value: RentStatus | 'all') => void;
  onAcceptanceChange: (value: 'all' | 'accepted' | 'not-accepted') => void;
  onPropertyChange: (value: string) => void;
  onResetFilters: () => void;
}

export const BookingsFilterDrawer: React.FC<BookingsFilterDrawerProps> = ({
  selectedStatus,
  selectedAcceptance,
  selectedProperty,
  userProperties,
  onStatusChange,
  onAcceptanceChange,
  onPropertyChange,
  onResetFilters
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  // Handle applying filters
  const handleApplyFilters = () => {
    setOpen(false);
  };

  // Handle reset
  const handleReset = () => {
    onResetFilters();
  };

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        className="md:hidden"
        onClick={() => setOpen(true)}
      >
        <Filter className="h-4 w-4 mr-2" />
        {t('bookings.filters')}
      </Button>

      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerContent>
          <DrawerHeader className="border-b">
            <DrawerTitle>{t('bookings.filter_drawer_title')}</DrawerTitle>
            <DrawerDescription>
              {t('bookings.filter_drawer_description')}
            </DrawerDescription>
          </DrawerHeader>

          <div className="p-4 space-y-6">
            <div className="space-y-2">
              <label className="text-sm font-medium">{t('bookings.status')}</label>
              <Select value={selectedStatus} onValueChange={(value) => onStatusChange(value as RentStatus | 'all')}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={t('bookings.status')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">{t('bookings.all_statuses')}</SelectItem>
                  <SelectItem value="active">{t('bookings.status_badge.active')}</SelectItem>
                  <SelectItem value="pending">{t('bookings.status_badge.pending')}</SelectItem>
                  <SelectItem value="canceled">{t('bookings.status_badge.canceled')}</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">{t('bookings.acceptance')}</label>
              <Select
                value={selectedAcceptance}
                onValueChange={(value) => onAcceptanceChange(value as 'all' | 'accepted' | 'not-accepted')}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={t('bookings.acceptance')} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">{t('bookings.all')}</SelectItem>
                  <SelectItem value="accepted">{t('bookings.accepted')}</SelectItem>
                  <SelectItem value="not-accepted">{t('bookings.not_accepted')}</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {userProperties.length > 1 && (
              <div className="space-y-2">
                <label className="text-sm font-medium">{t('maintenance.property')}</label>
                <Select value={selectedProperty} onValueChange={onPropertyChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={t('maintenance.property')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">{t('bookings.all_properties')}</SelectItem>
                    {userProperties.map(property => (
                      <SelectItem key={property.id} value={property.id}>
                        {property.title || getPropertyAddress(property)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>

          <DrawerFooter className="border-t">
            <Button
              variant="default"
              className="w-full"
              onClick={handleApplyFilters}
            >
              {t('bookings.apply_filters')}
            </Button>
            <Button
              variant="outline"
              className="w-full"
              onClick={handleReset}
            >
              {t('bookings.reset_filters')}
            </Button>
            <DrawerClose asChild>
              <Button
                variant="ghost"
                className="w-full"
              >
                <X className="h-4 w-4 mr-2" />
                {t('common.close')}
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};