import { Checklist, InventoryItem, InventoryItemStatus } from "@/types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { createChecklist } from "@/lib/actions/create-checklist";
import { batchCreateInventoryItems } from "@/lib/actions/batch-create-inventory-items";
import { v4 as uuidv4 } from 'uuid';

// Input types
export interface CreateChecklistItemInput {
  name: string;
  note?: string;
}

export interface CreateChecklistWithItemsInput {
  property_id: string;
  title: string;
  description?: string;
  items: CreateChecklistItemInput[];
}

// Result type
export interface ChecklistWithItems {
  checklist: Checklist;
  items: InventoryItem[];
}

// React Query Mutation Hook options
interface ChecklistCreateOptions {
  onSuccess?: (data: ChecklistWithItems) => void;
  onError?: (error: Error) => void;
  userId: string;
}

// Define type for mutation context
interface MutationContext {
  previousData: NormalizedData | undefined;
  tempId: string;
  tempItemIds: string[];
}

export function useCreateChecklist(options: ChecklistCreateOptions) {
  console.log('Create Checklist Hook triggered');
  const queryClient = useQueryClient();

  if (!options) {
    throw new Error('options is required');
  }
  if (!options.userId) {
    throw new Error('user ID is required');
  }

  const mutation = useMutation<ChecklistWithItems, Error, CreateChecklistWithItemsInput, MutationContext>({
    mutationFn: async (input) => {
      // Step 1: Create the checklist
      const checklist = await createChecklist({
        property_id: input.property_id,
        title: input.title,
        description: input.description
      });

      // Step 2: Create the inventory items
      const itemInputs = input.items.map(item => ({
        checklist_id: checklist.id,
        name: item.name,
        note: item.note || '',
        status: 'pending' as InventoryItemStatus
      }));

      // Only attempt to create items if there are any
      let items: InventoryItem[] = [];
      if (itemInputs.length > 0) {
        items = await batchCreateInventoryItems(itemInputs);
      }

      // Return both the checklist and its items
      return {
        checklist,
        items
      };
    },

    // Add optimistic update
    onMutate: async (input) => {
      if (!input) {
        throw new Error('input data is required');
      }

      const queryKey = ['userData', options.userId];
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousData = queryClient.getQueryData<NormalizedData>(queryKey);

      // Generate temporary IDs for optimistic update
      const tempId = uuidv4();
      const tempItemIds = input.items.map(() => uuidv4());
      const now = new Date().toISOString();

      if (previousData) {
        // Optimistically update the cache
        queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
          if (!old) return old;

          const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

          // Create temporary checklist object
          const tempChecklist: Checklist = {
            id: tempId,
            property_id: input.property_id,
            title: input.title,
            description: input.description || '',
            last_run_date: '',
            created_at: now,
            updated_at: now
          };

          // Add checklist to checklists collection
          newData.checklists[tempId] = tempChecklist;

          // Update relationship maps
          if (!newData.checklistsByProperty[input.property_id]) {
            newData.checklistsByProperty[input.property_id] = [];
          }
          newData.checklistsByProperty[input.property_id].push(tempId);

          // Create temporary inventory items
          input.items.forEach((itemInput, index) => {
            const itemId = tempItemIds[index];
            const tempItem: InventoryItem = {
              id: itemId,
              checklist_id: tempId,
              name: itemInput.name,
              note: itemInput.note || '',
              status: 'pending', // Default status
              created_at: now,
              updated_at: now
            };

            // Add inventory item to inventory items collection
            newData.inventoryItems[itemId] = tempItem;

            // Update relationship maps
            if (!newData.inventoryItemsByChecklist[tempId]) {
              newData.inventoryItemsByChecklist[tempId] = [];
            }
            newData.inventoryItemsByChecklist[tempId].push(itemId);
          });

          return newData;
        });
      }

      return { previousData, tempId, tempItemIds };
    },

    onError: (error, _newData, context) => {
      // Roll back to the previous value if there's an error
      const queryKey = ['userData', options.userId];
      if (context?.previousData) {
        queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
      }
      console.error('Error creating Checklist', error);
      options?.onError?.(error);
    },

    onSuccess: (data, _variables, context) => {
      const queryKey = ['userData', options.userId];

      // Update the cache with the real data from the server
      queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
        if (!old || !context?.tempId) return old;

        const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

        // Remove temporary objects
        delete newData.checklists[context.tempId];

        context.tempItemIds.forEach(itemId => {
          delete newData.inventoryItems[itemId];
        });

        if (newData.inventoryItemsByChecklist[context.tempId]) {
          delete newData.inventoryItemsByChecklist[context.tempId];
        }

        // Remove the temp checklist from the property relationship
        if (newData.checklistsByProperty[data.checklist.property_id]) {
          newData.checklistsByProperty[data.checklist.property_id] =
            newData.checklistsByProperty[data.checklist.property_id]
              .filter(id => id !== context.tempId);
        }

        // Add the real checklist
        newData.checklists[data.checklist.id] = data.checklist;

        // Update relationships
        if (!newData.checklistsByProperty[data.checklist.property_id]) {
          newData.checklistsByProperty[data.checklist.property_id] = [];
        }
        newData.checklistsByProperty[data.checklist.property_id].push(data.checklist.id);

        // Add real inventory items
        data.items.forEach(item => {
          newData.inventoryItems[item.id] = item;

          // Update relationship maps
          if (!newData.inventoryItemsByChecklist[data.checklist.id]) {
            newData.inventoryItemsByChecklist[data.checklist.id] = [];
          }
          newData.inventoryItemsByChecklist[data.checklist.id].push(item.id);
        });

        return newData;
      });

      options?.onSuccess?.(data);
    },
    retry: false
  });

  return {
    createChecklist: mutation.mutate,
    createChecklistAsync: mutation.mutateAsync,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
    isSuccess: mutation.isSuccess,
    data: mutation.data,
  };
}