import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ArrowLeft, ClipboardList, AlertTriangle, Check, Clock, Edit, Play } from 'lucide-react';
import ChecklistItem from '@/components/checklists/ChecklistItem';
import { useAuth } from '@/contexts/AuthContext';
import { useProperty, usePropertyChecklists } from '@/lib/query-hooks/get-hooks';
import { Skeleton } from '@/components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

const ChecklistDetail: React.FC = () => {
  const { id, checklistId } = useParams<{ id: string, checklistId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  const userId = user?.id || '';
  const propertyId = id || '';

  // Get property data
  const property = useProperty(propertyId, userId);

  // Get checklist data
  const {
    checklists,
    inventoryItems,
    isLoading,
    isError
  } = usePropertyChecklists(propertyId, userId);

  // Find the specific checklist
  const checklist = checklistId
    ? checklists.find(c => c.id === checklistId)
    : undefined;

  // Get items for this checklist
  const checklistItems = checklist ? inventoryItems[checklist.id] || [] : [];

  if (isLoading) {
    return (
      <MainLayout>
        <div className="space-y-6 pt-2 pb-8">
          <Skeleton className="h-4 w-32 mb-4" />
          <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
            <div>
              <Skeleton className="h-8 w-64 mb-2" />
              <Skeleton className="h-4 w-48" />
            </div>
            <div className="flex gap-2">
              <Skeleton className="h-10 w-24" />
              <Skeleton className="h-10 w-32" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <Skeleton className="h-64 w-full" />
            <div className="md:col-span-3">
              <Skeleton className="h-24 w-full mb-4" />
              <Skeleton className="h-6 w-48 mb-3" />
              <div className="space-y-2">
                <Skeleton className="h-20 w-full" />
                <Skeleton className="h-20 w-full" />
                <Skeleton className="h-20 w-full" />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

  if (!property || !checklist) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <h2 className="text-2xl font-bold">{t('common.not_found')}</h2>
            <p className="text-muted-foreground mt-2">{t('checklists.not_found_description')}</p>
            <Button className="mt-4" onClick={() => navigate('/properties')}>
              {t('navigation.back_to_properties')}
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }

  // Calculate completion statistics
  const totalItems = checklistItems.length;
  const completedItems = checklistItems.filter(item => item.status === 'completed').length;
  const issueItems = checklistItems.filter(item => item.status === 'issue').length;
  const pendingItems = checklistItems.filter(item => item.status === 'pending').length;

  const completionPercentage = totalItems > 0
    ? Math.round((completedItems / totalItems) * 100)
    : 0;

  return (
    <MainLayout>
      <div className="space-y-6 pt-2 pb-8">
        <Button
          variant="ghost"
          onClick={() => navigate(`/properties/${propertyId}/checklists`)}
          className="-ml-2 px-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('checklists.back_to_checklists')}
        </Button>

        <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
          <div>
            <div className="flex items-center">
              <ClipboardList className="h-6 w-6 mr-2 text-primary" />
              <h1 className="text-3xl font-bold tracking-tight">{checklist.title}</h1>
            </div>
            <p className="text-muted-foreground mt-1">
              {property.street}, {property.apartment}, {property.city}
            </p>
          </div>

          <div className="flex flex-wrap gap-2">
            <Button
              variant="outline"
              onClick={() => navigate(`/properties/${propertyId}/checklists/${checklistId}/edit`)}
            >
              <Edit className="mr-2 h-4 w-4" /> {t('common.edit')}
            </Button>
            <Button onClick={() => navigate(`/properties/${propertyId}/checklists/${checklistId}/run`)}>
              <Play className="mr-2 h-4 w-4" /> {t('checklists.run_checklist')}
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <Card className="md:col-span-1">
            <CardHeader>
              <CardTitle>{t('checklists.statistics')}</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <div className="flex items-center justify-between text-sm mb-1">
                    <span>{t('maintenance.completion')}</span>
                    <span className="font-medium">{completionPercentage}%</span>
                  </div>
                  <div className="flex h-2 w-full overflow-hidden rounded-full bg-secondary">
                    <div
                      className="bg-green-500 h-full"
                      style={{ width: `${totalItems > 0 ? (completedItems / totalItems) * 100 : 0}%` }}
                    />
                    <div
                      className="bg-red-500 h-full"
                      style={{ width: `${totalItems > 0 ? (issueItems / totalItems) * 100 : 0}%` }}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <span className="flex items-center text-sm">
                      <Check className="h-4 w-4 mr-1 text-green-600" />
                      {t('checklists.completed')}
                    </span>
                    <span className="font-medium">{completedItems}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="flex items-center text-sm">
                      <AlertTriangle className="h-4 w-4 mr-1 text-red-600" />
                      {t('checklists.issues')}
                    </span>
                    <span className="font-medium">{issueItems}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="flex items-center text-sm">
                      <Clock className="h-4 w-4 mr-1 text-gray-600" />
                      {t('checklists.pending')}
                    </span>
                    <span className="font-medium">{pendingItems}</span>
                  </div>
                </div>

                <div className="border-t pt-4 mt-4">
                  <div className="text-sm text-muted-foreground">
                    <p>{t('checklists.created_on', {
                      date: formatDate(checklist.created_at, i18n.language, 'dd MMM yyyy')
                    })}</p>
                    {checklist.last_run_date && (
                      <p>{t('checklists.last_run_on', {
                        date: formatDate(checklist.last_run_date, i18n.language, 'dd MMM yyyy')
                      })}</p>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <div className="md:col-span-3 space-y-4">
            {checklist.description && (
              <Card>
                <CardContent className="p-4">
                  <p className="text-muted-foreground">{checklist.description}</p>
                </CardContent>
              </Card>
            )}

            <div>
              <h2 className="text-xl font-semibold mb-3">{t('checklists.checklist_items')}</h2>

              {checklistItems.length === 0 ? (
                <Card>
                  <CardContent className="p-6 text-center">
                    <p className="text-muted-foreground">{t('checklists.no_items_in_checklist')}</p>
                  </CardContent>
                </Card>
              ) : (
                <div className="space-y-2">
                  {checklistItems.map(item => (
                    <ChecklistItem
                      key={item.id}
                      item={item}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ChecklistDetail;