"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Checklist } from "@/types";
import { UpdateChecklistResponse } from "@/types/mutation/checklist-response-types";
import { UPDATE_CHECKLIST } from "@/queries-graphql/mutation/update-checklist-mutation";
import { UpdateChecklistInput } from "@/types/mutation/checklist-input-types";

export async function updateChecklist(input: UpdateChecklistInput): Promise<Checklist> {
  const client = graphqlClient;
  try {
    const variables = { ...input };

    const data = await client.request<UpdateChecklistResponse>(
      UPDATE_CHECKLIST,
      variables
    );

    const checklist = data.updatechecklistsCollection.records[0];

    if (!checklist) {
      throw new Error("Failed to update checklist");
    }

    return checklist;
  } catch (error) {
    console.error("Error updating checklist:", error);
    throw error;
  }
}
