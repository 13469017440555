import { UserProfile, UserRole } from '@/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUserProfile } from '@/lib/actions/create-user-profile';
import { CreateUserProfileInput } from '@/types/mutation/mutation-input-types';

// React Query Mutation Hook
interface UserCreateProfileOptions {
    onSuccess?: (userProfile: UserProfile) => void;
    onError?: (error: Error) => void;
}


export function useCreateUserProfile(options: UserCreateProfileOptions) {
    console.log('Create User Profile Hook triggered');
    const queryClient = useQueryClient();
    if (!options) {
        throw new Error('options is required');
    }

    const mutation = useMutation<UserProfile, Error, CreateUserProfileInput>({
        mutationFn: async (data) => {
            // Use the enhanced createUserProfile function with retry logic
            return createUserProfile(data, 5, 1000);
        },

        onError: (error, _newData) => {
            console.error('Error creating User Profile', error);
            options?.onError?.(error);
        },

        onSuccess: (newUser) => {
            options?.onSuccess?.(newUser);
        },
        // Set up proper retry logic
        retry: 3,
        retryDelay: 1000
    });

    return {
        createUserProfile: mutation.mutate,
        createUserProfileAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}