"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Property } from "@/types";
import { DeletePropertyResponse } from "@/types/mutation/mutation-responses-types";
import { DELETE_PROPERTY } from "@/queries-graphql/mutation/delete-property-mutation";

// Function to delete Property
export const deleteProperty = async (
    propertyId: string
): Promise<Property> => {
    const client = graphqlClient;
    try {
        // Execute the GraphQL mutation
        const response = await client.request<DeletePropertyResponse>(
            DELETE_PROPERTY,
            { id: propertyId }
        );

        return response.deleteFrompropertiesCollection.records[0];
    } catch (error) {
        console.error('Error deleting property:', error);
        throw error;
    }
};
