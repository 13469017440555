import React, { useState, useMemo, useEffect } from 'react';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Archive, ArchiveRestore } from 'lucide-react';
import { parseISO } from 'date-fns';
import { toast } from '@/hooks/use-toast';
import { RentStatus, Rent, Property } from '@/types';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { usePaginatedArchivedRents } from '@/lib/query-hooks/usePaginatedArchivedRents';
import { useUserProperties } from '@/lib/query-hooks/get-hooks';
import { useUpdateRent } from '@/lib/query-hooks/mutation/useUpdateRent';
import { ToastAction } from '@/components/ui/toast';
import { RentCancelDialog } from '@/components/bookings/RentCancelDialog';
import { useUpdateProperty } from '@/lib/query-hooks/mutation/useUpdateProperty';
import { useAuth } from '@/contexts/AuthContext';
import { RentUpdateDialog } from '@/components/bookings/RentUpdateDialog';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { BookingFilters } from '@/components/bookings/BookingFilters';
import { BookingsView } from '@/components/bookings/BookingsView';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

// Pagination constants
const ITEMS_PER_PAGE = 10;

const Bookings: React.FC = () => {
  const { profile, role } = useAuth();
  const { t, i18n } = useTranslation();

  // State variables
  const [searchTerm, setSearchTerm] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<RentStatus | 'all'>('all');
  const [selectedAcceptance, setSelectedAcceptance] = useState<'all' | 'accepted' | 'not-accepted'>('all');
  const [selectedProperty, setSelectedProperty] = useState<string>('all');
  const [editRent, setEditRent] = useState<Rent | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoadingArchived, setIsLoadingArchived] = useState(false);
  const [rentToCancel, setRentToCancel] = useState<Rent | null>(null);

  // Fetch user data - this contains all non-archived rents and related data
  const {
    data: userData,
    isLoading: isLoadingUserData,
    isError: isErrorUserData,
    refetch: refetchUserData
  } = useUserData(profile?.id || '');

  // Fetch archived rents using the pagination hook
  const {
    combinedData: archivedRents,
    fetchNextPage: fetchNextPageArchivedRents,
    hasMore: hasMoreArchivedRents,
    isLoadingMore: isLoadingMoreArchivedRents,
    isLoading: isLoadingArchivedRents,
    error: errorArchivedRents
  } = usePaginatedArchivedRents({
    userId: profile?.id || '',
    role: role || 'lessor',
    pageSize: 20, // Fetch more than we show per page
    enabled: showArchived // Only enable when showing archived rents
  });

  // Update Rent Hook
  const {
    updateRentAsync,
    isLoading: updateRentInProgress
  } = useUpdateRent({
    userId: profile?.id || '',
    onSuccess: () => {
      toast({
        title: t('bookings.rent_updated'),
        description: t('bookings.rent_updated_description')
      });
      refetchUserData();
    },
    onError: (error) => {
      toast({
        title: t('common.error_title', 'Oh! Something went wrong.'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>
      });
    }
  });

  // Update Property Hook
  const {
    updatePropertyAsync
  } = useUpdateProperty({
    userId: profile?.id || '',
    onSuccess: () => {
      toast({
        title: t('properties.toast.property_updated'),
        description: t('properties.toast.property_updated_desc')
      });
      refetchUserData();
    },
    onError: (error) => {
      toast({
        title: t('properties.toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>
      });
    }
  });

  // Get properties for the current user
  const userProperties = useUserProperties(profile?.id || '', role || 'lessor');

  // Get all non-archived rents from userData
  const activeRents = useMemo(() => {
    if (!userData || !profile?.id) return [];

    // If user is lessor, get all their properties' rents
    // If user is tenant, get all rents where they are the tenant
    const rentIds = role === 'lessor'
      ? userData.userRents?.asLessor?.[profile.id] || []
      : userData.userRents?.asTenant?.[profile.id] || [];

    return rentIds
      .map(id => userData.rents[id])
      .filter(rent => !rent.isArchived); // Filter out archived rents
  }, [userData, profile?.id, role]);

  // Handle showing archived rentals
  const handleToggleArchived = async () => {
    if (!showArchived) {
      setIsLoadingArchived(true);
      try {
        await fetchNextPageArchivedRents();
      } finally {
        setIsLoadingArchived(false);
      }
    }
    setShowArchived(!showArchived);
    setCurrentPage(1); // Reset to first page when toggling
  };

  // Filter rents based on search, status, and filters
  const filteredRents = useMemo(() => {
    const rents = showArchived
      ? Object.values(archivedRents.rentsById)
      : activeRents;

    if (!rents || rents.length === 0) return [];

    return rents.filter((rent) => {
      // Filter by status
      if (selectedStatus !== 'all' && rent.status !== selectedStatus) return false;

      // Filter by acceptance
      if (
        selectedAcceptance === 'accepted' && !rent.isAccepted ||
        selectedAcceptance === 'not-accepted' && rent.isAccepted
      ) {
        return false;
      }

      // Filter by property
      if (selectedProperty !== 'all' && rent.property_id !== selectedProperty) return false;

      // Filter by search term (property title or address)
      if (searchTerm.trim() !== '' && userData) {
        const property = userData.properties[rent.property_id];
        if (property) {
          const propertyName = property.title || property.street + ' ' + property.building;
          if (!propertyName.toLowerCase().includes(searchTerm.toLowerCase())) {
            return false;
          }
        }
      }

      return true;
    });
  }, [activeRents, archivedRents.rentsById, showArchived, selectedStatus, selectedAcceptance, selectedProperty, searchTerm, userData]);

  // Calculate total number of pages for pagination
  const totalPages = Math.ceil(filteredRents.length / ITEMS_PER_PAGE);

  // Get current page items
  const paginatedRents = useMemo(() => {
    const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    return filteredRents.slice(startIdx, endIdx);
  }, [filteredRents, currentPage]);

  // Load more archived data when approaching the end of loaded items
  useEffect(() => {
    // If we're showing archived rents and approaching the end of current data
    if (showArchived &&
      currentPage >= Math.ceil(Object.keys(archivedRents.rentsById).length / ITEMS_PER_PAGE) - 1 &&
      hasMoreArchivedRents) {
      fetchNextPageArchivedRents();
    }
  }, [currentPage, archivedRents.rentsById, showArchived, hasMoreArchivedRents, fetchNextPageArchivedRents]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Handle opening the update dialog
  const handleOpenUpdateDialog = (rent: Rent) => {
    setEditRent(rent);
  };

  // Handle closing the update dialog
  const handleCloseUpdateDialog = () => {
    setEditRent(null);
  };

  // Handle cancel rent button click
  const handleOpenCancelDialog = (rent: Rent) => {
    setRentToCancel(rent);
  };

  const handleRentUpdate = (editedRent: Rent) => {
    const now = new Date();
    const updatedRent: Rent = {
      ...editedRent,
      updated_at: now.toISOString()
    };
    updateRentAsync(updatedRent);
  };

  // Handle cancel Rent confirmation
  const handleConfirmCancel = async () => {
    if (!rentToCancel || !userData) return;

    const now = new Date();
    const updatedRent: Rent = {
      ...rentToCancel,
      status: 'canceled',
      updated_at: now.toISOString()
    };

    const newRent = await updateRentAsync(updatedRent);
    if (newRent) {
      const property: Property = userData.properties[newRent.property_id];
      await updatePropertyAsync({
        ...property,
        status: 'vacant',
        updated_at: now.toISOString()
      });
    }
    setRentToCancel(null);
  };

  // Get property details from userId
  const getPropertyDetails = (propertyId: string) => {
    if (!userData) return null;
    return userData.properties[propertyId];
  };

  // Get tenant details from userId
  const getTenantDetails = (tenantId: string) => {
    if (!userData) return null;
    return userData.users[tenantId];
  };

  // Reset filters
  const handleResetFilters = () => {
    setSelectedStatus('all');
    setSelectedAcceptance('all');
    setSelectedProperty('all');
    setSearchTerm('');
  };

  // Loading state
  if (isLoadingUserData && !userData) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <div className="inline-flex h-10 w-10 animate-spin items-center justify-center rounded-full border-2 border-primary border-t-transparent"></div>
            <h2 className="text-xl font-medium mt-4">{t('bookings.loading_rentals')}</h2>
          </div>
        </div>
      </MainLayout>
    );
  }

  // Error state
  if (isErrorUserData) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <h2 className="text-xl font-medium text-destructive">{t('bookings.error_loading', 'Error loading bookings')}</h2>
            <p className="mt-2">{t('bookings.try_again_later', 'Please try again later or contact support.')}</p>
            <Button onClick={() => refetchUserData()} className="mt-4">
              {t('common.retry')}
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{t('bookings.title')}</h1>
            <p className="text-muted-foreground mt-1">
              {showArchived
                ? t('bookings.archived_description', 'View and manage your archived rental agreements')
                : t('bookings.active_description', 'Manage all your active rental agreements')}
            </p>
          </div>
        </div>

        {/* Filters */}
        <div className='w-full flex flex-row justify-between'>
          <BookingFilters
            selectedStatus={selectedStatus}
            selectedAcceptance={selectedAcceptance}
            selectedProperty={selectedProperty}
            userProperties={userProperties}
            onStatusChange={setSelectedStatus}
            onAcceptanceChange={setSelectedAcceptance}
            onPropertyChange={setSelectedProperty}
            onResetFilters={handleResetFilters}
          />

          <Button
            onClick={handleToggleArchived}
            variant="outline"
            className="flex items-center gap-2 self-start"
            disabled={isLoadingArchived}
          >
            {showArchived ? (
              <>
                <ArchiveRestore className="h-4 w-4" />
                {t('bookings.view_active', 'View Active')}
              </>
            ) : (
              <>
                <Archive className="h-4 w-4" />
                {t('bookings.view_archived', 'View Archived')}
              </>
            )}
          </Button>
        </div>

        <Card className="overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-slate-50 to-indigo-50 pb-4">
            <CardTitle>
              {showArchived ? t('bookings.archived_bookings') : t('bookings.title')}
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <BookingsView
              rents={paginatedRents}
              getPropertyDetails={getPropertyDetails}
              getTenantDetails={getTenantDetails}
              showArchived={showArchived}
              onEdit={handleOpenUpdateDialog}
              onCancel={handleOpenCancelDialog}
              isLoading={isLoadingUserData || isLoadingArchived}
              formatDateFn={(dateString) => formatDate(dateString, i18n.language)}
            />
          </CardContent>

          {totalPages > 1 && (
            <CardFooter className="flex justify-center border-t p-4">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                      className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                    />
                  </PaginationItem>

                  {[...Array(totalPages)].map((_, index) => {
                    const page = index + 1;

                    // Show first page, last page, and pages around current page
                    if (
                      page === 1 ||
                      page === totalPages ||
                      (page >= currentPage - 1 && page <= currentPage + 1)
                    ) {
                      return (
                        <PaginationItem key={page}>
                          <PaginationLink
                            isActive={page === currentPage}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    // Show ellipsis for gaps
                    if ((page === 2 && currentPage > 3) || (page === totalPages - 1 && currentPage < totalPages - 2)) {
                      return (
                        <PaginationItem key={`ellipsis-${page}`}>
                          <PaginationEllipsis />
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}

                  <PaginationItem>
                    <PaginationNext
                      onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                      className={currentPage === totalPages ? "pointer-events-none opacity-50" : ""}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </CardFooter>
          )}
        </Card>
      </div>

      {/* Edit Rent Dialog */}
      {editRent && (
        <RentUpdateDialog
          rent={editRent}
          open={!!editRent}
          onClose={handleCloseUpdateDialog}
          onSubmit={(rent: Rent) => handleRentUpdate(rent)}
        />
      )}

      {/* Cancel Confirmation Dialog */}
      <RentCancelDialog
        onOpenChange={() => setRentToCancel(null)}
        rentToCancel={rentToCancel}
        handleConfirmCancel={handleConfirmCancel}
      />
    </MainLayout>
  );
};

export default Bookings;