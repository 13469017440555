import { Checklist, InventoryItem, InventoryItemStatus } from "@/types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateChecklist } from "@/lib/actions/update-checklist";
import { batchCreateInventoryItems } from "@/lib/actions/batch-create-inventory-items";
import { batchDeleteInventoryItemsByIds } from "@/lib/actions/batch-delete-inventory-items";
import { updateInventoryItem } from "@/lib/actions/update-inventory-item";
import { v4 as uuidv4 } from 'uuid';

// Input types
export interface UpdateChecklistItemInput {
  id?: string; // Existing item ID (if updating)
  name: string;
  note?: string;
  status?: InventoryItemStatus;
}

export interface UpdateChecklistWithItemsInput {
  id: string;
  property_id: string; // Needed for relationship management
  title?: string;
  description?: string;
  last_run_date?: string;
  items: UpdateChecklistItemInput[];
  itemsToDelete?: string[]; // IDs of items to delete
}

// Result type
export interface UpdatedChecklistWithItems {
  checklist: Checklist;
  items: InventoryItem[];
  deletedItems: InventoryItem[];
}

// React Query Mutation Hook options
interface ChecklistUpdateOptions {
  onSuccess?: (data: UpdatedChecklistWithItems) => void;
  onError?: (error: Error) => void;
  userId: string;
}

// Define type for mutation context
interface MutationContext {
  previousData: NormalizedData | undefined;
  tempItemIds: string[];
}

export function useUpdateChecklist(options: ChecklistUpdateOptions) {
  console.log('Update Checklist Hook triggered');
  const queryClient = useQueryClient();

  if (!options) {
    throw new Error('options is required');
  }
  if (!options.userId) {
    throw new Error('user ID is required');
  }

  const mutation = useMutation<UpdatedChecklistWithItems, Error, UpdateChecklistWithItemsInput, MutationContext>({
    mutationFn: async (input) => {
      const now = new Date().toISOString();
      const deletedItems: InventoryItem[] = [];
      const updatedItems: InventoryItem[] = [];
      const newItems: InventoryItem[] = [];

      // Step 1: Update the checklist
      const updatedChecklist = await updateChecklist({
        id: input.id,
        title: input.title,
        description: input.description,
        last_run_date: input.last_run_date,
        updated_at: now
      });

      // Step 2: Handle existing items that need to be updated
      const updatePromises = input.items
        .filter(item => item.id) // Only items with IDs (existing items)
        .map(async item => {
          const updatedItem = await updateInventoryItem({
            id: item.id!,
            name: item.name,
            note: item.note,
            status: item.status,
            updated_at: now
          });
          updatedItems.push(updatedItem);
          return updatedItem;
        });

      await Promise.all(updatePromises);

      // Step 3: Create new items
      const newItemInputs = input.items
        .filter(item => !item.id) // Only items without IDs (new items)
        .map(item => ({
          checklist_id: input.id,
          name: item.name,
          note: item.note || '',
          status: item.status || 'pending' as InventoryItemStatus
        }));

      if (newItemInputs.length > 0) {
        try {
          const createdItems = await batchCreateInventoryItems(newItemInputs);
          newItems.push(...createdItems);
        } catch (error) {
          console.error("Error batch creating inventory items:", error);
          throw error; // Let the error be handled by the mutation error handler
        }
      }

      // Step 4: Delete items if needed
      if (input.itemsToDelete && input.itemsToDelete.length > 0) {
        const deletedItemsResult = await batchDeleteInventoryItemsByIds(input.itemsToDelete);
        deletedItems.push(...deletedItemsResult);
      }

      // Return the updated checklist with all items
      return {
        checklist: updatedChecklist,
        items: [...updatedItems, ...newItems],
        deletedItems
      };
    },

    // Add optimistic update
    onMutate: async (input) => {
      if (!input) {
        throw new Error('input data is required');
      }
      if (!input.id) {
        throw new Error('checklist ID is required');
      }

      const queryKey = ['userData', options.userId];
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousData = queryClient.getQueryData<NormalizedData>(queryKey);

      // Generate temporary IDs for new items
      const newItems = input.items.filter(item => !item.id);
      const tempItemIds = newItems.map(() => uuidv4());
      const now = new Date().toISOString();

      if (previousData) {
        // Optimistically update the cache
        queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
          if (!old) return old;

          const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

          // Update the checklist
          if (newData.checklists[input.id]) {
            newData.checklists[input.id] = {
              ...newData.checklists[input.id],
              title: input.title || newData.checklists[input.id].title,
              description: input.description !== undefined
                ? input.description
                : newData.checklists[input.id].description,
              last_run_date: input.last_run_date !== undefined
                ? input.last_run_date
                : newData.checklists[input.id].last_run_date,
              updated_at: now
            };
          }

          // Update existing items
          input.items
            .filter(item => item.id) // Only items with IDs (existing items)
            .forEach(item => {
              if (item.id && newData.inventoryItems[item.id]) {
                newData.inventoryItems[item.id] = {
                  ...newData.inventoryItems[item.id],
                  name: item.name || newData.inventoryItems[item.id].name,
                  note: item.note !== undefined
                    ? item.note
                    : newData.inventoryItems[item.id].note,
                  status: item.status || newData.inventoryItems[item.id].status,
                  updated_at: now
                };
              }
            });

          // Add new items
          newItems.forEach((item, index) => {
            const itemId = tempItemIds[index];
            const tempItem: InventoryItem = {
              id: itemId,
              checklist_id: input.id,
              name: item.name,
              note: item.note || '',
              status: item.status || 'pending',
              created_at: now,
              updated_at: now
            };

            // Add inventory item to inventory items collection
            newData.inventoryItems[itemId] = tempItem;

            // Update relationship maps
            if (!newData.inventoryItemsByChecklist[input.id]) {
              newData.inventoryItemsByChecklist[input.id] = [];
            }
            newData.inventoryItemsByChecklist[input.id].push(itemId);
          });

          // Remove deleted items
          if (input.itemsToDelete && input.itemsToDelete.length > 0) {
            input.itemsToDelete.forEach(itemId => {
              // Remove from inventoryItems
              if (newData.inventoryItems[itemId]) {
                delete newData.inventoryItems[itemId];
              }

              // Remove from relationship maps
              if (newData.inventoryItemsByChecklist[input.id]) {
                newData.inventoryItemsByChecklist[input.id] =
                  newData.inventoryItemsByChecklist[input.id].filter(id => id !== itemId);
              }
            });
          }

          return newData;
        });
      }

      return { previousData, tempItemIds };
    },

    onError: (error, _newData, context) => {
      // Roll back to the previous value if there's an error
      const queryKey = ['userData', options.userId];
      if (context?.previousData) {
        queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
      }
      console.error('Error updating Checklist', error);
      options?.onError?.(error);
    },

    onSuccess: (data, variables, context) => {
      const queryKey = ['userData', options.userId];

      // Update the cache with the real data from the server
      queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
        if (!old) return old;

        const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

        // Update the checklist
        newData.checklists[data.checklist.id] = data.checklist;

        // Remove temporary items
        if (context?.tempItemIds) {
          context.tempItemIds.forEach(itemId => {
            delete newData.inventoryItems[itemId];

            // Remove from relationship maps
            if (newData.inventoryItemsByChecklist[data.checklist.id]) {
              newData.inventoryItemsByChecklist[data.checklist.id] =
                newData.inventoryItemsByChecklist[data.checklist.id].filter(id => id !== itemId);
            }
          });
        }

        // Add/update real items
        data.items.forEach(item => {
          newData.inventoryItems[item.id] = item;

          // Update relationship maps
          if (!newData.inventoryItemsByChecklist[data.checklist.id]) {
            newData.inventoryItemsByChecklist[data.checklist.id] = [];
          }
          if (!newData.inventoryItemsByChecklist[data.checklist.id].includes(item.id)) {
            newData.inventoryItemsByChecklist[data.checklist.id].push(item.id);
          }
        });

        // Make sure deleted items are removed
        if (variables.itemsToDelete) {
          variables.itemsToDelete.forEach(itemId => {
            delete newData.inventoryItems[itemId];

            // Remove from relationship maps
            if (newData.inventoryItemsByChecklist[data.checklist.id]) {
              newData.inventoryItemsByChecklist[data.checklist.id] =
                newData.inventoryItemsByChecklist[data.checklist.id].filter(id => id !== itemId);
            }
          });
        }

        return newData;
      });

      options?.onSuccess?.(data);
    },
    retry: false
  });

  return {
    updateChecklist: mutation.mutate,
    updateChecklistAsync: mutation.mutateAsync,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
    isSuccess: mutation.isSuccess,
    data: mutation.data,
  };
}