import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { useNavigate } from 'react-router-dom';
import { toast } from '@/hooks/use-toast';

interface ResetPasswordOptions {
  redirectTo?: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

interface PasswordResetState {
  email: string;
  password: string;
  confirmPassword: string;
  isLoading: boolean;
  isSubmitted: boolean;
  isValidResetLink: boolean | null;
  isCheckingLink: boolean;
  isSuccess: boolean;
  error: string | null;
}

export function usePasswordReset(options?: ResetPasswordOptions) {
  const navigate = useNavigate();
  const redirectUrl = options?.redirectTo || `${window.location.origin}/auth/reset-password`;
  
  // State management
  const [state, setState] = useState<PasswordResetState>({
    email: '',
    password: '',
    confirmPassword: '',
    isLoading: false,
    isSubmitted: false,
    isValidResetLink: null,
    isCheckingLink: true,
    isSuccess: false,
    error: null,
  });

  // Update individual state fields
  const updateState = (newState: Partial<PasswordResetState>) => {
    setState(prev => ({ ...prev, ...newState }));
  };

  // Parse hash params from URL (for reset page)
  const parseHashParams = (): Record<string, string> => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const result: Record<string, string> = {};
    
    for (const [key, value] of params.entries()) {
      result[key] = value;
    }
    
    return result;
  };

  // Check if current URL is a valid reset link
  useEffect(() => {
    const checkResetToken = async () => {
      try {
        updateState({ isCheckingLink: true, error: null });
        
        // Get the hash part of the URL which contains the access token
        const params = parseHashParams();
        const accessToken = params['access_token'];
        const refreshToken = params['refresh_token'];
        const type = params['type'];

        if (!accessToken || !refreshToken || type !== 'recovery') {
          // Try to get the session normally in case user is already authenticated
          const { data, error } = await supabase.auth.getSession();

          if (error || !data.session) {
            updateState({ 
              isValidResetLink: false, 
              error: 'Invalid or expired password reset link. Please request a new one.' 
            });
            return;
          }
        } else {
          // If we have an access token in the URL, set the session
          const { error } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });

          if (error) {
            throw error;
          }
        }

        updateState({ isValidResetLink: true });
      } catch (err) {
        console.error('Error checking reset token:', err);
        updateState({ 
          isValidResetLink: false, 
          error: 'An error occurred while validating your reset link.' 
        });
      } finally {
        updateState({ isCheckingLink: false });
      }
    };

    // Only run this effect for reset password page
    const url = new URL(window.location.href);
    if (url.pathname.includes('/reset-password')) {
      checkResetToken();
    } else {
      updateState({ isCheckingLink: false });
    }
  }, []);

  // Handle email change
  const setEmail = (email: string) => {
    updateState({ email });
  };

  // Handle password change
  const setPassword = (password: string) => {
    updateState({ password });
  };

  // Handle confirm password change
  const setConfirmPassword = (confirmPassword: string) => {
    updateState({ confirmPassword });
  };

  // Reset form state
  const resetForm = () => {
    updateState({
      email: '',
      password: '',
      confirmPassword: '',
      isLoading: false,
      isSubmitted: false,
      error: null
    });
  };

  // Validate password reset form
  const validateResetForm = (): boolean => {
    if (state.password !== state.confirmPassword) {
      updateState({ error: "Passwords don't match" });
      return false;
    }

    if (state.password.length < 6) {
      updateState({ error: "Password must be at least 6 characters" });
      return false;
    }

    return true;
  };

  // Send password reset email
  const sendPasswordResetEmail = async () => {
    updateState({ isLoading: true, error: null });

    try {
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(state.email, {
        redirectTo: redirectUrl,
      });

      if (resetError) {
        throw resetError;
      }

      updateState({ isSubmitted: true });
      
      toast({
        title: "Reset email sent",
        description: "Check your email for password reset instructions.",
      });
    } catch (error) {
      console.error('Password reset error:', error);
      let errorMessage = 'An error occurred. Please check your email and try again.';

      if (error instanceof Error) {
        // Check for common Supabase errors
        if (error.message.includes('email')) {
          errorMessage = 'No account found with this email address.';
        }
      }

      updateState({ error: errorMessage });
      
      toast({
        title: "Reset failed",
        description: errorMessage,
        variant: "destructive",
      });

      options?.onError?.(error instanceof Error ? error : new Error(errorMessage));
    } finally {
      updateState({ isLoading: false });
    }
  };

  // Reset password with new password
  const resetPassword = async () => {
    if (!validateResetForm()) {
      return;
    }

    updateState({ isLoading: true, error: null });

    try {
      // Attempt to update the user's password
      const { error } = await supabase.auth.updateUser({
        password: state.password
      });

      if (error) {
        throw error;
      }

      // Password reset successful
      updateState({ isSuccess: true });

      // Show success message
      toast({
        title: "Password updated",
        description: "Your password has been successfully reset.",
      });

      // Call success callback
      options?.onSuccess?.();

      // Sign out after successful password reset to ensure a clean state
      setTimeout(() => {
        supabase.auth.signOut();
        // Redirect to the confirmation page instead of using URL parameters
        navigate('/auth/reset-confirmation');
      }, 1500);
      
    } catch (err) {
      console.error('Reset password error:', err);
      let errorMessage = 'An error occurred. Please try again or request a new reset link.';

      if (err instanceof Error) {
        // Handle specific Supabase error messages
        if (err.message.includes('password')) {
          errorMessage = 'Password is too weak. Please choose a stronger password.';
        } else if (err.message.includes('token')) {
          errorMessage = 'Your reset link has expired. Please request a new one.';
        }
      }

      updateState({ error: errorMessage });
      
      toast({
        title: "Password reset failed",
        description: errorMessage,
        variant: "destructive",
      });

      options?.onError?.(err instanceof Error ? err : new Error(errorMessage));
    } finally {
      updateState({ isLoading: false });
    }
  };

  return {
    // State
    email: state.email,
    password: state.password,
    confirmPassword: state.confirmPassword,
    isLoading: state.isLoading,
    isSubmitted: state.isSubmitted,
    isValidResetLink: state.isValidResetLink,
    isCheckingLink: state.isCheckingLink,
    isSuccess: state.isSuccess,
    error: state.error,
    
    // Setters
    setEmail,
    setPassword,
    setConfirmPassword,
    resetForm,
    
    // Actions
    sendPasswordResetEmail,
    resetPassword
  };
}