// fetch-user.ts
"use server"

import { graphqlClient } from "../graphql-client";
import { GET_USER } from "@/queries-graphql/get/get-user";
import { RawUserResponse } from "@/types/raw-responses";
import { UserProfile } from "@/types";

export async function fetchUser(email: string, userId: string | null): Promise<UserProfile | null> {
    try {
        if (!email && !userId) {
            console.log("Either email or userId is required");
            return null;
        }

        const userProfileResponse: RawUserResponse = await graphqlClient.request(GET_USER, { email, userId });

        // Check if we have any edges (results) in the response
        if (userProfileResponse.profilesCollection.edges.length === 0) {
            console.log("No user profile found for this email/userId");
            return null;
        }

        const userProfile = userProfileResponse.profilesCollection.edges[0].node;
        return userProfile;
    }
    catch (err) {
        console.log("User profile fetching error", err);
        return null;
    }
}