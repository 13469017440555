// RegistrationConfirmation.tsx
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Mail, ArrowRight, Home, Loader, AlertCircle, Check } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { Alert, AlertDescription } from '@/components/ui/alert';

const RegistrationConfirmation: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const email = searchParams.get('email') || '';
    const rentId = searchParams.get('rentId');
    const isInvitation = searchParams.get('invitation') === 'true' || !!rentId;
    const autoRedirect = searchParams.get('autoRedirect') === 'true';

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<'waiting' | 'success' | 'error'>('waiting');
    const [error, setError] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(5);

    // Handle auto-redirect when user is already verified
    useEffect(() => {
        if (autoRedirect && user && user.email_confirmed_at) {
            setStatus('success');
            setLoading(true);

            // Start countdown for auto-redirect
            const timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);

                        // Redirect based on flow type
                        if (isInvitation && rentId) {
                            navigate(`/auth/complete-profile?rentId=${rentId}&email=${encodeURIComponent(email)}`);
                        } else {
                            navigate('/auth/login');
                        }
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [user, autoRedirect, navigate, isInvitation, rentId, email]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-background p-4">
            <Card className="w-full max-w-md">
                <CardHeader className="space-y-1">
                    <div className="mx-auto bg-primary/10 p-4 rounded-full mb-4">
                        {loading ? (
                            <Loader className="h-12 w-12 text-primary animate-spin" />
                        ) : status === 'success' ? (
                            <Check className="h-12 w-12 text-green-500" />
                        ) : status === 'error' ? (
                            <AlertCircle className="h-12 w-12 text-red-500" />
                        ) : (
                            <Mail className="h-12 w-12 text-primary" />
                        )}
                    </div>
                    <CardTitle className="text-2xl text-center">
                        {status === 'success' ? 'Email Verified!' : 'Check Your Email'}
                    </CardTitle>
                    <CardDescription className="text-center">
                        {isInvitation ? (
                            <>
                                Thanks for accepting the invitation! We've sent a confirmation email to{' '}
                                <span className="font-medium">{email}</span>.
                            </>
                        ) : (
                            <>
                                Thanks for signing up! We've sent a confirmation email to{' '}
                                <span className="font-medium">{email}</span>.
                            </>
                        )}
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4 text-center">
                    {status === 'error' && error && (
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    {status === 'success' ? (
                        <div className="bg-green-50 p-4 rounded-lg">
                            <h3 className="font-medium mb-2">Email verified successfully!</h3>
                            <p className="text-sm text-muted-foreground">
                                {isInvitation
                                    ? "Redirecting you to complete your profile..."
                                    : "You can now log in to your account."}
                            </p>
                            {autoRedirect && (
                                <p className="mt-2 text-sm font-medium">
                                    Redirecting in {countdown} seconds...
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="bg-muted p-4 rounded-lg">
                            <h3 className="font-medium mb-2">Next steps:</h3>
                            <ol className="text-sm text-muted-foreground text-left list-decimal pl-5 space-y-2">
                                <li>Check your email inbox (and spam folder)</li>
                                <li>Click the confirmation link in the email</li>
                                <li>
                                    {isInvitation
                                        ? "You'll then be able to complete your profile"
                                        : "Once confirmed, you'll be able to sign in to your account"}
                                </li>
                            </ol>
                        </div>
                    )}

                    <p className="text-sm text-muted-foreground">
                        Didn't receive the email? Check your spam folder or{' '}
                        <Link to="/auth/login" className="text-primary hover:underline">
                            try signing in
                        </Link>{' '}
                        to request a new confirmation link.
                    </p>
                </CardContent>
                <CardFooter className="flex flex-col sm:flex-row gap-3">
                    <Button asChild variant="outline" className="w-full">
                        <Link to="/auth/login">
                            <ArrowRight className="mr-2 h-4 w-4" />
                            Go to Login
                        </Link>
                    </Button>
                    <Button asChild variant="secondary" className="w-full">
                        <Link to="/">
                            <Home className="mr-2 h-4 w-4" />
                            Back to Home
                        </Link>
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default RegistrationConfirmation;