"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Rent } from "@/types";
import { CreateRentInput } from "@/types/mutation/mutation-input-types";
import { CreateRentResponse } from "@/types/mutation/mutation-responses-types";
import { CREATE_RENT } from "@/queries-graphql/mutation/new-rent-mutation";

// Function to create a new Rent record
export const createRent = async (
    input: CreateRentInput
): Promise<Rent> => {
    const client = graphqlClient;
    try {
        const variables = { ...input };

        const data = await client.request<CreateRentResponse>(
            CREATE_RENT,
            variables
        );
        const response = data.insertIntorentsCollection.records[0];
        return response;
    } catch (error) {
        console.error('Error creating Rent:', error);
        throw error;
    }
};