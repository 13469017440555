import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Building, BedDouble, Bath, Square, DollarSign, Calendar } from 'lucide-react';
import { Property, Rent } from '@/types';
import { parseISO, format } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import { getPropertyAddress } from '@/lib/helpers';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface PropertyInfoCardProps {
    property: Property;
    acceptedRents: Rent[];
}

export const PropertyInfoCard: React.FC<PropertyInfoCardProps> = ({
    property,
    acceptedRents
}) => {
    const { t, i18n } = useTranslation();

    // Organize rents by date range for display
    const activeRentPeriods = useMemo(() => {
        if (!acceptedRents.length) return [];

        return acceptedRents.map(rent => ({
            id: rent.id,
            startDate: formatDate(rent.date_start, i18n.language),
            endDate: formatDate(rent.date_end, i18n.language),
            tenant: rent.tenant_id || null
        }));
    }, [acceptedRents, i18n.language]);

    return (
        <Card className="h-full">
            <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
                <CardTitle className="flex items-center gap-2">
                    <Building className="h-5 w-5 text-primary" />
                    {t('properties.property_information')}
                </CardTitle>
            </CardHeader>
            <CardContent className="pt-6 space-y-6">
                {/* Property Image */}
                <div className="w-full h-40 overflow-hidden rounded-lg mb-4">
                    <img
                        src={property.image_url || "/img/property_placeholder.jpg"}
                        alt={property.title || getPropertyAddress(property)}
                        className="w-full h-full object-cover"
                    />
                </div>

                {/* Property Details */}
                <div>
                    <h3 className="font-medium text-lg mb-1">{property.title || getPropertyAddress(property)}</h3>
                    <p className="text-muted-foreground text-sm mb-3">{getPropertyAddress(property)}</p>

                    <div className="grid grid-cols-2 gap-y-4 gap-x-2">
                        <div className="flex items-center gap-2">
                            <div className="p-2 bg-blue-50 rounded-full">
                                <BedDouble className="h-4 w-4 text-blue-500" />
                            </div>
                            <div>
                                <p className="text-xs text-muted-foreground">{t('properties.bedrooms')}</p>
                                <p className="font-medium">
                                    {property.isStudio ? t('properties.studio_short') : property.bedrooms}
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center gap-2">
                            <div className="p-2 bg-blue-50 rounded-full">
                                <Bath className="h-4 w-4 text-blue-500" />
                            </div>
                            <div>
                                <p className="text-xs text-muted-foreground">{t('properties.bathrooms')}</p>
                                <p className="font-medium">{property.bathrooms}</p>
                            </div>
                        </div>

                        <div className="flex items-center gap-2">
                            <div className="p-2 bg-blue-50 rounded-full">
                                <Square className="h-4 w-4 text-blue-500" />
                            </div>
                            <div>
                                <p className="text-xs text-muted-foreground">{t(`properties.area_unit.${property.unit_measure}`)}</p>
                                <p className="font-medium">
                                    {property.area} {t(`properties.units.${property.unit_measure}`)}
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center gap-2">
                            <div className="p-2 bg-blue-50 rounded-full">
                                <DollarSign className="h-4 w-4 text-blue-500" />
                            </div>
                            <div>
                                <p className="text-xs text-muted-foreground">{t(`properties.terms.${property.unit_term}_rent`)}</p>
                                <p className="font-medium">
                                    {formatCurrency(property.price, i18n.language, property.currency)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Current Rental Periods */}
                <div>
                    <h3 className="font-medium mb-2 flex items-center gap-2">
                        <Calendar className="h-4 w-4 text-primary" />
                        {t('invitation.current_rental_periods')}
                    </h3>

                    {activeRentPeriods.length > 0 ? (
                        <div className="space-y-2">
                            {activeRentPeriods.map((period) => (
                                <div
                                    key={period.id}
                                    className="p-2 border rounded-md flex justify-between items-center"
                                >
                                    <div className="text-sm">
                                        <p className="text-muted-foreground text-xs">{t('invitation.rental_period')}</p>
                                        <p>{period.startDate} - {period.endDate}</p>
                                    </div>
                                    <Badge variant="outline" className="bg-blue-50">
                                        {t('properties.status.occupied')}
                                    </Badge>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="p-3 bg-green-50 text-green-800 rounded-md text-sm">
                            {t('invitation.property_available')}
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};