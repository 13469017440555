import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import {
  Camera, Edit, Save, X, MapPin, Building, Home,
  BedDouble, Bath, Square, DollarSign, Calendar,
  CheckCircle
} from 'lucide-react';
import { Property } from '@/types';
import { format } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { unitTerms } from '@/consts';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate, getLocalizedUnit } from '@/utils/i18n-utils';

interface PropertyInformationCardProps {
  property: Property;
  editProperty: Property | null;
  currentRent: any;
  setEditProperty: React.Dispatch<React.SetStateAction<Property | null>>;
  handlePropertyChange: (field: keyof Property, value: any) => void;
  handlePropertyDetailsUpdate: () => Promise<void>;
  handleCancelEditingProperty: () => void;
  handleStartEditingProperty: () => void;
  selectedImage: File | null;
  imagePreview: string | null;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveImage: () => void;
}

const PropertyInformationCard: React.FC<PropertyInformationCardProps> = ({
  property,
  editProperty,
  currentRent,
  setEditProperty,
  handlePropertyChange,
  handlePropertyDetailsUpdate,
  handleCancelEditingProperty,
  handleStartEditingProperty,
  selectedImage,
  imagePreview,
  handleImageChange,
  handleRemoveImage
}) => {
  const { t, i18n } = useTranslation();

  // Get status badge color based on property status
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'occupied': return 'bg-blue-100 text-blue-800 hover:bg-blue-200';
      case 'vacant': return 'bg-green-100 text-green-800 hover:bg-green-200';
      case 'inactive': return 'bg-gray-100 text-gray-800 hover:bg-gray-200';
      default: return 'bg-gray-100 text-gray-800 hover:bg-gray-200';
    }
  };

  // Translate property status
  const getTranslatedStatus = (status: string) => {
    return t(`properties.status.${status}`);
  };

  // Format date with i18n
  const formatDateWithI18n = (dateString: string) => {
    return formatDate(dateString, i18n.language);
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50 ">
        <div className='flex w-full flex-row justify-between items-center space-x-4'>
          <div className="flex justify-start gap-2">
            <CardTitle>{t('properties.property_details')}</CardTitle>

            <Badge className={getStatusColor(property.status)}>
              {getTranslatedStatus(property.status)}
            </Badge>
          </div>

          {editProperty ? (
            <div className='flex flex-row gap-2'>
              <Button
                onClick={handlePropertyDetailsUpdate}
                size="sm"
                className="text-xs md:text-sm"
                disabled={!editProperty}
              >
                <Save className="size-4 shrink-0" /> {t('common.save')}
              </Button>
              <Button
                variant="outline"
                onClick={handleCancelEditingProperty}
                size="sm"
                className="text-xs md:text-sm"
              >
                {t('common.cancel')}
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              onClick={handleStartEditingProperty}
              size="sm"
              className="text-xs md:text-sm border-primary/20 text-primary hover:bg-primary/10"
            >
              <Edit className="size-4 shrink-0" /> {t('common.edit')}
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className={cn("pt-4", {
        "p-0": editProperty // Remove padding in edit mode for full-width layout
      })}>
        {editProperty ? (
          // Edit Mode Layout
          <div className="grid grid-cols-1 md:grid-cols-12 gap-0">
            {/* Left Column - Image */}
            <div className="md:col-span-4 p-4 border-r border-b md:border-b-0 bg-muted/10">
              <div className="space-y-4">
                <div className="relative w-full h-48 bg-gray-100 rounded-lg overflow-hidden shadow-sm">
                  {(imagePreview || editProperty.image_url) && (
                    <>
                      <img
                        src={imagePreview || editProperty.image_url || "/img/property_placeholder.jpg"}
                        alt={t('properties.property_image')}
                        className="w-full h-48 object-cover bg-center opacity-100 group-hover:opacity-80 transition-opacity"
                      />
                      <div className="absolute top-2 right-2 z-10">
                        <Button
                          variant="destructive"
                          size="icon"
                          className="h-6 w-6 opacity-80 hover:opacity-100"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemoveImage();
                          }}
                        >
                          <X className="h-3 w-3" />
                        </Button>
                      </div>
                    </>
                  )}
                  <label className="absolute inset-0 flex items-center justify-center cursor-pointer">
                    {!imagePreview && !editProperty.image_url && (
                      <div className="flex flex-col items-center">
                        <Camera className="h-8 w-8 text-gray-500" />
                        <span className="mt-2 text-sm text-gray-500">{t('common.click_to_upload')}</span>
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>

                <div className='flex flex-row w-full justify-start items-center gap-2 bg-gray-50 p-3 rounded-lg'>
                  <p className="text-left text-sm font-medium text-gray-700">{t('properties.bills_included')}</p>
                  <div className='flex h-full items-center justify-center'>
                    <Switch
                      checked={editProperty.bills_pay_by_lessor || false}
                      onCheckedChange={(e) => {
                        handlePropertyChange('bills_pay_by_lessor', e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Form Fields */}
            <div className="md:col-span-8 p-4">
              <div className="space-y-6">
                {/* Location Section */}
                <div>
                  <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                    <MapPin className="h-4 w-4 mr-1 text-primary" /> {t('properties.location')}
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2 sm:col-span-1">
                      <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.city')}</p>
                      <Input
                        value={editProperty.city}
                        onChange={(e) => handlePropertyChange('city', e.target.value)}
                        className="h-9"
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.street')}</p>
                      <Input
                        value={editProperty.street}
                        onChange={(e) => handlePropertyChange('street', e.target.value)}
                        placeholder={t('properties.street')}
                        className="h-9"
                      />
                    </div>
                    <div>
                      <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.building')}</p>
                      <Input
                        value={editProperty.building}
                        onChange={(e) => handlePropertyChange('building', e.target.value)}
                        placeholder={t('properties.building')}
                        className="h-9"
                      />
                    </div>
                    <div>
                      <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.apartment')}</p>
                      <Input
                        value={editProperty.apartment}
                        onChange={(e) => handlePropertyChange('apartment', e.target.value)}
                        placeholder={t('properties.apartment')}
                        className="h-9"
                      />
                    </div>
                  </div>
                </div>

                <Separator />

                {/* Property Details Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  {/* Rental Details */}
                  <div>
                    <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                      <DollarSign className="h-4 w-4 mr-1 text-primary" /> {t('properties.rent_information')}
                    </h3>

                    <div className="space-y-4">
                      <div>
                        <p className="text-xs font-medium text-gray-500 mb-1 capitalize">{t('properties.price')}</p>
                        <div className="flex items-center">
                          <span className="mr-1 text-gray-700">$</span>
                          <Input
                            value={editProperty.price}
                            onChange={(e) => handlePropertyChange('price', Number(e.target.value))}
                            type="number"
                            min={0}
                            className="h-9"
                          />
                        </div>
                      </div>

                      <div>
                        <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.term')}</p>
                        <Select
                          onValueChange={(e) => handlePropertyChange('unit_term', e)}
                          defaultValue={editProperty.unit_term || 'month'}
                        >
                          <SelectTrigger className="h-9 capitalize">
                            <SelectValue placeholder={t('properties.select_term')} />
                          </SelectTrigger>
                          <SelectContent className='capitalize'>
                            {unitTerms.map(unit => (
                              <SelectItem key={unit} value={unit}>{t(`properties.terms.${unit}`)}</SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>

                  {/* Property Details */}
                  <div>
                    <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                      <Home className="h-4 w-4 mr-1 text-primary" /> {t('properties.property_features')}
                    </h3>

                    <div className="space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.bedrooms')}</p>
                          <Input
                            value={editProperty.bedrooms || 0}
                            onChange={(e) => handlePropertyChange('bedrooms', Number(e.target.value))}
                            disabled={editProperty.isStudio}
                            type="number"
                            min={0}
                            className="h-9"
                          />
                        </div>

                        <div>
                          <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.bathrooms')}</p>
                          <Input
                            value={editProperty.bathrooms || 0}
                            onChange={(e) => handlePropertyChange('bathrooms', Number(e.target.value))}
                            type="number"
                            min={0}
                            className="h-9"
                          />
                        </div>
                      </div>

                      <div className="flex items-center gap-2 py-1 px-3 bg-muted/20 rounded">
                        <Switch
                          checked={editProperty.isStudio || false}
                          onCheckedChange={(e) => {
                            handlePropertyChange('isStudio', e)
                          }}
                          className="data-[state=checked]:bg-primary"
                        />
                        <span className="text-sm">{t('properties.studio')}</span>
                      </div>

                      <div className="flex gap-3">
                        <div className='w-full'>
                          <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.area')}</p>

                          <Input
                            value={editProperty.area || ''}
                            onChange={(e) => handlePropertyChange('area', Number(e.target.value))}
                            type="number"
                            min={0}
                            className="h-9 w-full"
                          />
                        </div>

                        <div className='w-[60%]'>
                          <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.unit_measure')}</p>
                          <Select
                            onValueChange={(e) => handlePropertyChange('unit_measure', e)}
                            defaultValue={editProperty.unit_measure || 'sq_meter'}
                          >
                            <SelectTrigger className="h-9">
                              <SelectValue placeholder={t('properties.select_unit')} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="sq_meter">
                                {t('properties.units.sq_meter')}
                              </SelectItem>
                              <SelectItem value="sq_foot">
                                {t('properties.units.sq_foot')}
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // View Mode Layout
          <div className="grid grid-cols-1 items-stretch gap-2 sm:grid-cols-2 md:gap-4">
            <div className='space-y-4'>
              <div className="relative w-full h-40 overflow-hidden rounded-lg shadow-md">
                <img
                  src={property.image_url || "/img/property_placeholder.jpg"}
                  alt={property.title || `${property.city} ${property.street}` || t('properties.property_image')}
                  className="min-w-full h-48 object-cover bg-center transition-transform duration-300 hover:scale-105"
                />
              </div>

              {property.bills_pay_by_lessor && (
                <div className="flex items-center gap-2 py-2 px-3 rounded-lg bg-blue-50 text-sm">
                  <CheckCircle className="h-4 w-4 text-blue-600" />
                  <span className="text-blue-700">{t('properties.bills_included')}</span>
                </div>
              )}
            </div>

            {/* Location Section */}
            <div className="bg-gray-50 p-4 rounded-lg h-full">
              <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                <MapPin className="h-4 w-4 mr-1 text-primary" /> {t('properties.location')}
              </h3>

              <div className="flex items-center gap-3 mb-2">
                <Building className="h-4 w-4 text-gray-500" />
                <p className="text-sm">
                  <span className="font-medium">{property.street} {property.building}, </span>
                  <span>{t('properties.apartment')} {property.apartment}</span>
                </p>
              </div>
              <p className="text-sm text-gray-600 pl-7">{property.city}</p>
            </div>

            {/* Price Section */}
            <div className="bg-blue-50 p-4 rounded-lg col-span-1 w-full ">
              <div className='w-full flex flex-row items-center'>
                <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                  <DollarSign className="h-4 w-4 mr-1 text-primary" />
                  {t('properties.rent_information')}
                </h3>
              </div>
              <div className="flex flex-row justify-between sm:space-y-3 sm:flex-col">
                <div>
                  <p className="text-xs font-medium text-gray-500 mb-1 capitalize">
                    {t(`properties.terms.${property.unit_term}_rent`)}
                  </p>
                  <p className="text-lg font-semibold text-primary">
                    {formatCurrency(property.price, i18n.language, property.currency)}
                  </p>
                </div>

                <div>
                  <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.available_from')}</p>
                  <p className="text-sm font-medium">
                    {currentRent && currentRent.isAccepted && currentRent.date_end
                      ? formatDateWithI18n(currentRent.date_end)
                      : t('properties.today')}
                  </p>
                </div>
              </div>
            </div>

            {/* Property Details Section */}
            <div className="bg-gray-50 p-4 rounded-lg col-span-1 w-full">
              <h3 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                <Home className="h-4 w-4 mr-1 text-primary" /> {t('properties.property_details')}
              </h3>

              <div className="grid grid-cols-2 gap-3">
                <div>
                  <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.bedrooms')}</p>
                  <div className="flex items-center gap-2">
                    <BedDouble className="h-4 w-4 text-gray-500" />
                    <p className="text-sm font-medium">
                      {property.isStudio ? t('properties.studio_short') : property.bedrooms}
                    </p>
                  </div>
                </div>

                <div>
                  <p className="text-xs font-medium text-gray-500 mb-1">{t('properties.bathrooms')}</p>
                  <div className="flex items-center gap-2">
                    <Bath className="h-4 w-4 text-gray-500" />
                    <p className="text-sm font-medium">{property.bathrooms}</p>
                  </div>
                </div>

                <div className="col-span-2">
                  <p className="text-xs font-medium text-gray-500 mb-1">
                    {t(`properties.area_unit.${property.unit_measure}`)}
                  </p>
                  <div className="flex items-center gap-2">
                    <Square className="h-4 w-4 text-gray-500" />
                    <p className="text-sm font-medium">
                      {property.area} {t(`properties.units.${property.unit_measure}`)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PropertyInformationCard;