import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { LogOut } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { cn } from '@/lib/utils';

interface LogoutButtonProps {
  className?: string;
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ className }) => {
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  const { t } = useTranslation();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await logout();
      // Reset React Query cache
      queryClient.clear();
    } catch (error) {
      console.error("Logout error:", error);
      // Session errors are already handled in the logout function
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <Button
      variant="outline"
      onClick={handleLogout}
      disabled={isLoggingOut}
      className={cn("w-full justify-start text-destructive hover:text-destructive/90 hover:bg-destructive/10", className)}
    >
      {isLoggingOut ? (
        <span className="flex items-center">
          <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-destructive/30 border-t-transparent"></span>
          {t('navigation.logging_out')}
        </span>
      ) : (
        <>
          <LogOut className="mr-2 h-4 w-4" />
          {t('navigation.logout')}
        </>
      )}
    </Button>
  );
};