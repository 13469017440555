import React from 'react';
import { Rent } from '@/types';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { useTranslation } from 'react-i18next';

interface RentCancelDialogProps {
    onOpenChange: () => void;
    rentToCancel: Rent | null;
    handleConfirmCancel: () => void;
}

export const RentCancelDialog: React.FC<RentCancelDialogProps> = ({
    onOpenChange,
    rentToCancel,
    handleConfirmCancel,
}) => {
    const { t } = useTranslation();

    return (
        <AlertDialog open={!!rentToCancel} onOpenChange={onOpenChange}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{t('bookings.cancel_rent_agreement')}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {t('bookings.cancel_rent_confirmation')}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{t('bookings.cancel_no_keep')}</AlertDialogCancel>
                    <AlertDialogAction onClick={handleConfirmCancel} className="bg-destructive text-destructive-foreground hover:bg-destructive/90">
                        {t('bookings.cancel_yes_confirm')}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};