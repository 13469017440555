import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { CheckCircle2, ArrowRight } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Progress } from '@/components/ui/progress';

const PasswordResetConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);
    const [progress, setProgress] = useState(0);

    // Auto-redirect countdown
    useEffect(() => {
        // Set initial progress
        setProgress(0);

        // Start countdown timer
        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    navigate('/auth/login');
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        // Update progress bar more frequently for smoother animation
        const progressTimer = setInterval(() => {
            setProgress(prev => {
                const newProgress = prev + (100 / (5 * 10)); // 10 updates per second for 5 seconds
                return newProgress >= 100 ? 100 : newProgress;
            });
        }, 100);

        return () => {
            clearInterval(timer);
            clearInterval(progressTimer);
        };
    }, [navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-background p-4">
            <Card className="w-full max-w-md">
                <CardHeader className="space-y-1">
                    <div className="mx-auto bg-green-100 p-4 rounded-full mb-4">
                        <CheckCircle2 className="h-12 w-12 text-green-600" />
                    </div>
                    <CardTitle className="text-2xl text-center">Password Reset Successful</CardTitle>
                    <CardDescription className="text-center">
                        Your password has been reset successfully
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                    <Alert className="bg-green-50 border-green-200">
                        <AlertDescription>
                            You can now log in to your account using your new password.
                        </AlertDescription>
                    </Alert>

                    <div className="space-y-2">
                        <div className="flex justify-between text-sm">
                            <span className="text-muted-foreground">Redirecting to login page...</span>
                            <span className="font-medium">{countdown}s</span>
                        </div>
                        <Progress value={progress} className="h-2" />
                    </div>
                </CardContent>
                <CardFooter>
                    <Button asChild className="w-full">
                        <Link to="/auth/login">
                            <ArrowRight className="mr-2 h-4 w-4" />
                            Go to Login Now
                        </Link>
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default PasswordResetConfirmation;