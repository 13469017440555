import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NormalizedData } from '@/types/normalized-data-types';
import { UserProfile, UserRole } from '@/types';

// Enhanced check if a route is active - handles nested routes
export const useActiveRoute = () => {
    const location = useLocation();
    
    return (path: string) => {
        // Exact match (original behavior)
        if (location.pathname === path) {
            return true;
        }
        
        // Special handling for nested routes
        // For properties, consider any route that starts with /properties/ as active
        if (path === '/properties' && location.pathname.startsWith('/properties/')) {
            return true;
        }
        
        // For tenant properties, consider any route that starts with /tenant/properties/ as active
        if (path === '/tenant/properties' && location.pathname.startsWith('/tenant/properties/')) {
            return true;
        }
        
        // For bookings, consider any route that starts with /bookings/ as active
        if (path === '/bookings' && location.pathname.startsWith('/bookings/')) {
            return true;
        }
        
        // For maintenance, consider any route that starts with /maintenance/ as active
        if (path === '/maintenance' && location.pathname.startsWith('/maintenance/')) {
            return true;
        }
        
        // For bills, consider any route that starts with /bills/ as active
        if (path === '/bills' && location.pathname.startsWith('/bills/')) {
            return true;
        }
        
        // For tenant/bills route, bills navigation should be active
        if (path === '/bills' && location.pathname === '/tenant/bills') {
            return true;
        }
        
        // For lessor/bills route, bills navigation should be active 
        if (path === '/bills' && location.pathname === '/lessor/bills') {
            return true;
        }
        
        return false;
    };
};

// Count new maintenance requests
export const useMaintenanceRequestsCount = (
    userData: NormalizedData | undefined,
    userId: string | undefined,
    role: UserRole | null
) => {
    return useMemo(() => {
        if (!userData || !userId) return 0;
        if (!userData?.repairs || !userData?.repairsByTenant) return 0;

        if (role === "tenant") {
            // For tenants, count their own maintenance requests with pending status
            if (!userData.repairsByTenant[userId]?.length) return 0;
            return userData.repairsByTenant[userId].reduce((counter, repId) => {
                return counter + (userData.repairs[repId].status === "pending" ? 1 : 0);
            }, 0);
        } else {
            // For lessors, count all maintenance requests from tenants with pending status
            return Object.values(userData.repairs).filter(rep =>
                rep.tenant_id !== userId && rep.status === "pending"
            ).length;
        }
    }, [userData, role, userId]);
};

// Count pending invitations for tenants
export const usePendingInvitationsCount = (
    userData: NormalizedData | undefined,
    userId: string | undefined
) => {
    return useMemo(() => {
        if (!userData || !userId) return 0;
        if (!userData?.userRents?.asTenant || !userData.userRents.asTenant[userId]?.length) return 0;

        return userData.userRents.asTenant[userId].reduce((counter, rentId) => {
            return counter + (!userData.rents[rentId].isAccepted && userData.rents[rentId].status !== 'canceled' ? 1 : 0);
        }, 0);
    }, [userData, userId]);
};

// Count new bookings for lessors
export const useNewBookingsCount = (
    userData: NormalizedData | undefined,
    userId: string | undefined
) => {
    return useMemo(() => {
        if (!userData || !userId) return 0;
        if (!userData?.userRents?.asLessor || !userData.userRents.asLessor[userId]?.length) return 0;

        return userData.userRents.asLessor[userId].reduce((counter, rentId) => {
            const rent = userData.rents[rentId];
            return counter + (!rent.isAccepted && !rent.isArchived && rent.status !== 'canceled' ? 1 : 0);
        }, 0);
    }, [userData, userId]);
};

// Initial user profile state based on current profile
export const getInitialEditProfile = (profile: UserProfile | null, email: string | undefined) => {
    return {
        first_name: profile?.first_name || '',
        last_name: profile?.last_name || '',
        email: email || '',
        phone: profile?.phone || '',
    };
};

// Get display name
export const getUserDisplayName = (profile: UserProfile | null, email: string | undefined) => {
    if (profile?.first_name) {
        return `${profile.first_name} ${profile.last_name || ''}`.trim();
    }
    return email?.split('@')[0] || 'User';
};

// Get user initials for avatar
export const getUserInitials = (profile: UserProfile | null, email: string | undefined) => {
    if (profile?.first_name) {
        return profile.first_name.charAt(0).toUpperCase();
    }
    return email?.charAt(0).toUpperCase() || 'U';
};