import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { UserRole } from '@/types';

interface RoleSwitcherProps {
  className?: string;
  idPrefix?: string;
  onRoleChange?: (role: UserRole) => void;
}

const RoleSwitcher: React.FC<RoleSwitcherProps> = ({
  className,
  idPrefix = '',
  onRoleChange
}) => {
  const { role, profile, updateProfile } = useAuth();
  const { t } = useTranslation();

  const handleRoleChange = async (newRole: UserRole) => {
    if (!profile) return;

    try {
      await updateProfile({
        ...profile,
        role: newRole
      });

      if (onRoleChange) {
        onRoleChange(newRole);
      }
    } catch (error) {
      console.error('Error changing role:', error);
    }
  };

  // Custom button class for active/selected role
  const getButtonClass = (buttonRole: UserRole) => {
    const isCurrentRole = role === buttonRole;

    return cn(
      "flex-1 h-8",
      isCurrentRole ?
        // Applied to the active/disabled button to make it look pressed
        // The '!opacity-100' overrides the default disabled opacity
        "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20 text-primary border-transparent disabled:!opacity-100 disabled:cursor-default" :
        // Normal state for non-selected buttons
        "hover:bg-gradient-to-r hover:from-transparent hover:to-purple-500/10"
    );
  };

  return (
    <div className={cn('space-y-2', className)}>
      {/* <h4 className="text-sm font-medium">{t('profile.role_description')}</h4> */}
      <div className="flex gap-2 [&>button]:disabled:opacity-100">
        <Button
          variant={role === 'tenant' ? 'default' : 'outline'}
          size="sm"
          className={getButtonClass('tenant')}
          onClick={() => handleRoleChange('tenant')}
          disabled={role === 'tenant'}
          id={`${idPrefix}tenant-role-btn`}
        >
          {t('profile.tenant_role')}
        </Button>
        <Button
          variant={role === 'lessor' ? 'default' : 'outline'}
          size="sm"
          className={getButtonClass('lessor')}
          onClick={() => handleRoleChange('lessor')}
          disabled={role === 'lessor'}
          id={`${idPrefix}lessor-role-btn`}
        >
          {t('profile.lessor_role')}
        </Button>
      </div>
    </div>
  );
};

export default RoleSwitcher;