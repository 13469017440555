import React from 'react';
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Home, ChevronDown } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { MaintenanceRequestCard } from './MaintenanceRequestCard';
import { Repair, UserRole } from '@/types';
import { UserProfile } from '@/types';
import { useTranslation } from 'react-i18next';

interface PropertyMaintenanceCardProps {
  propertyId: string;
  propertyName: string;
  repairs: Repair[];
  isOpen: boolean;
  userRole: UserRole;
  userId: string;
  userData?: Record<string, UserProfile>;
  onToggle: (propertyId: string) => void;
  onUpdateStatus: (repair: Repair, newStatus: any) => void;
  onEditRequest: (repair: Repair) => void;
}

export const PropertyMaintenanceCard: React.FC<PropertyMaintenanceCardProps> = ({
  propertyId,
  propertyName,
  repairs,
  isOpen,
  userRole,
  userId,
  userData,
  onToggle,
  onUpdateStatus,
  onEditRequest
}) => {
  const { t } = useTranslation();
  const newRepairs = repairs.filter(repair => repair.status === 'pending') || []

  return (
    <Card className="overflow-hidden">
      <CardHeader
        className="bg-gradient-to-r from-gray-50 to-slate-50 cursor-pointer"
        onClick={() => onToggle(propertyId)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Home className="h-5 w-5 text-primary" />
            <CardTitle className='text-base sm:text-2xl'>{propertyName}</CardTitle>
          </div>
          <div className="flex items-center gap-2">
            {newRepairs.length > 0 && (<Badge variant="outline" className="bg-blue-50 border-blue-200">
              {newRepairs.length} {newRepairs.length === 1
                ? t('maintenance.request_singular')
                : t('maintenance.request_plural')}
            </Badge>)}
            <Button variant="ghost" size="icon" className="h-8 w-8">
              <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''
                }`} />
            </Button>
          </div>
        </div>
      </CardHeader>

      <Collapsible open={isOpen}>
        <CollapsibleContent>
          <CardContent className="p-4 space-y-4">
            {repairs.length === 0 ? (
              <div className="text-center py-4 text-muted-foreground">
                {t('maintenance.no_requests_for_property')}
              </div>
            ) : (
              repairs.map((repair) => (
                <MaintenanceRequestCard
                  key={repair.id}
                  repair={repair}
                  userRole={userRole}
                  userData={userData}
                  currentUserId={userId}
                  onUpdateStatus={onUpdateStatus}
                  onEditRequest={onEditRequest}
                />
              ))
            )}
          </CardContent>
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
};