"use server"

import { supabase } from "../supabase";
import { UploadImageResult } from "@/types/mutation/mutation-responses-types";

export const uploadPropertyImage = async (
  input: {
    file: File,
    propertyId: string
  }
): Promise<UploadImageResult> => {
  try {
    // Validate file type
    if (!input.file.type.startsWith('image/')) {
      throw new Error('Uploaded file must be an image');
    }

    // Validate file size (e.g., 5MB limit)
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    if (input.file.size > MAX_FILE_SIZE) {
      throw new Error('File size must be less than 5MB');
    }

    // Create a templated filename using timestamp to ensure uniqueness
    const fileExtension = input.file.name.split('.').pop();
    const fileName = `property_image.${fileExtension}`;

    // Path structure with property ID
    const filePath = `properties/${input.propertyId}/${fileName}`;

    // Upload file to Supabase Storage
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from('properties')
      .upload(filePath, input.file, {
        cacheControl: '3600',
        upsert: true // Set to true to overwrite if file exists
      });

    if (uploadError) {
      throw new Error(`Upload failed: ${uploadError.message}`);
    }

    // Get public URL for the uploaded file
    const { data: { publicUrl } } = supabase.storage
      .from('properties')
      .getPublicUrl(filePath);

    return { url: publicUrl };

  } catch (error) {
    console.error('Error uploading property image:', error);
    return {
      error: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
};

export const deletePropertyImage = async (
  propertyId: string,
  fileName: string
): Promise<void> => {
  try {
    // If fileName is a full URL, extract just the filename part
    const fileNameOnly = fileName.includes('/')
      ? fileName.split('/').pop()
      : fileName;

    if (!fileNameOnly) {
      throw new Error('Invalid file name');
    }

    const filePath = `properties/${propertyId}/${fileNameOnly}`;

    const { error } = await supabase.storage
      .from('properties')
      .remove([filePath]);

    if (error) {
      console.error('Error deleting property image:', error);
    }
  } catch (error) {
    console.error('Error in delete operation:', error);
  }
};

// Helper function to extract filename from URL
export const getFileNameFromUrl = (url: string): string | null => {
  if (!url) return null;
  try {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  } catch (error) {
    console.error('Error extracting filename from URL:', error);
    return null;
  }
};