import { gql } from "graphql-request";

export const UPDATE_CHECKLIST = gql`
  mutation UpdateChecklist(
    $id: UUID!,
    $title: String,
    $description: String,
    $last_run_date: String,
    $updated_at: String!
  ) {
    updatechecklistsCollection(
      set: {
        title: $title,
        description: $description,
        last_run_date: $last_run_date,
        updated_at: $updated_at
      },
      filter: { id: { eq: $id } }
    ) {
      records {
        id
        property_id
        title
        description
        last_run_date
        created_at
        updated_at
      }
    }
  }
`;
