import React, { useState } from 'react';
import { X, Upload, Image as ImageIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

interface ImageUploadProps {
    initialImageUrl?: string;
    onImageChange: (imageUrl: string | null, file?: File | null) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
    initialImageUrl = '',
    onImageChange
}) => {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState<string | null>(initialImageUrl || null);
    const [isHovering, setIsHovering] = useState(false);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // Create a local URL for the file
        const objectUrl = URL.createObjectURL(file);
        setImageUrl(objectUrl);
        onImageChange(objectUrl, file);
    };

    const handleRemoveImage = () => {
        setImageUrl(null);
        onImageChange(null, null);
    };

    return (
        <div className="w-full">
            {imageUrl ? (
                <div
                    className="relative rounded-lg overflow-hidden h-64 bg-muted flex items-center justify-center"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <img
                        src={imageUrl}
                        alt={t('properties.property_image')}
                        className="w-full h-full object-cover"
                    />
                    {isHovering && (
                        <div className="absolute inset-0 bg-black/50 flex p-2 justify-end items-start">
                            <Button
                                variant="destructive"
                                size="icon"
                                onClick={handleRemoveImage}
                                className="rounded-full"
                            >
                                <X className="h-3 w-3" />
                            </Button>
                        </div>
                    )}
                </div>
            ) : (
                <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-muted/40 hover:bg-muted/60 transition-colors">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <Upload className="w-10 h-10 mb-3 text-muted-foreground" />
                        <p className="mb-2 text-sm text-muted-foreground">
                            <span className="font-semibold">{t('common.click_to_upload')}</span> {t('common.or')} {t('common.drag_drop')}
                        </p>
                        <p className="text-xs text-muted-foreground">
                            PNG, JPG {t('common.or')} WebP (MAX. 5MB)
                        </p>
                    </div>
                    <input
                        id="dropzone-file"
                        type="file"
                        accept="image/png, image/jpeg, image/webp"
                        className="hidden"
                        onChange={handleImageUpload}
                    />
                </label>
            )}
        </div>
    );
};