import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

export const BillsLoadingState: React.FC = () => {
    return (
        <div className="space-y-6">
            <Skeleton className="h-24 w-full" />
            {[1, 2, 3].map(i => (
                <Skeleton key={i} className="h-64 w-full" />
            ))}
        </div>
    );
};