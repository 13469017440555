import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useAuth } from '@/contexts/AuthContext';
import { useIsMobile } from '@/hooks/use-mobile';
import { useTranslation } from 'react-i18next';

interface MainLayoutProps {
  children: React.ReactNode;
  requireAuth?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  requireAuth = true
}) => {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  // Redirect to login if authentication is required and user is not logged in
  useEffect(() => {
    if (requireAuth && !isLoading && !user) {
      navigate('/auth/login');
    }
  }, [requireAuth, user, isLoading, navigate]);

  // Show loading state while auth is being determined
  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent"></div>
      </div>
    );
  }

  // If authentication is required but user is not logged in, redirect will happen in useEffect
  if (requireAuth && !user) {
    return null;
  }

  // Content is now just the page itself - navigation is handled at the AppShell level
  return (
    <div className="h-full w-full">
      <ScrollArea className="h-full px-4">
        {children}
      </ScrollArea>
    </div>
  );
}