import React, { useState, useMemo, useEffect } from 'react';
import { MainLayout } from '@/components/layout/MainLayout';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { useAuth } from '@/contexts/AuthContext';
import { Skeleton } from '@/components/ui/skeleton';
import { useAllBills } from '@/lib/query-hooks/useAllBills';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { Property } from '@/types';
import PropertyFilter from '@/components/bills/shared/PropertyFilter';
import CurrentBillsTabTenant from '@/components/bills/tenant/CurrentBillsTabTenant';
import HistoryBillsTabTenant from '@/components/bills/tenant/HistoryBillsTabTenant';
import { useTranslation } from 'react-i18next';

const TenantBills: React.FC = () => {
  const [activeTab, setActiveTab] = useState('current');
  const { user, role } = useAuth();
  const { t } = useTranslation();

  // Property filter
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(null);

  // For history filter
  const [startDate, setStartDate] = useState(() => subMonths(startOfMonth(new Date()), 3));
  const [endDate, setEndDate] = useState(() => endOfMonth(new Date()));

  // Calculate month and year for different views
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // Get user data to access property information
  const { data: userData, isLoading: isLoadingUserData } = useUserData(user?.id || '');

  // Extract tenant properties from userData - ONLY from userProperties.asTenant
  const tenantProperties = useMemo(() => {
    if (!userData || !user?.id) return {};

    // Get property IDs from the asTenant mapping only
    const tenantPropertyIds = userData.userProperties?.asTenant?.[user.id] || [];

    // Create a map of authorized properties
    const propertiesObj: Record<string, Property> = {};

    tenantPropertyIds.forEach(propId => {
      if (userData.properties[propId]) {
        propertiesObj[propId] = userData.properties[propId];
      }
    });

    console.log(`Tenant ${user.id} has access to ${Object.keys(propertiesObj).length} properties:`,
      Object.keys(propertiesObj));

    return propertiesObj;
  }, [userData, user?.id]);

  // For current bills
  const currentBillsHook = useAllBills({
    userId: user?.id || '',
    role: 'tenant',
    month: currentMonth,
    year: currentYear,
    monthsToFetch: 3,
    propertyId: selectedPropertyId, // Apply property filter if selected
    isCurrentPeriod: true,
    properties: tenantProperties // Only pass tenant properties
  });

  // For historical bills
  const historyBillsHook = useAllBills({
    userId: user?.id || '',
    role: 'tenant',
    startDate,
    endDate,
    monthsToFetch: Math.max(
      1,
      Math.ceil(
        (endDate.getTime() - startDate.getTime()) / (30 * 24 * 60 * 60 * 1000)
      )
    ),
    propertyId: selectedPropertyId,
    isCurrentPeriod: false,
    properties: tenantProperties // Only pass tenant properties
  });

  // Post-filter current bills to ensure we only show bills for tenant properties
  const filteredCurrentBills = useMemo(() => {
    if (!currentBillsHook.bills?.length) return currentBillsHook.bills || [];

    // Create a set of authorized property IDs for O(1) lookups
    const authorizedPropertyIds = new Set(Object.keys(tenantProperties));

    // Filter bills
    return currentBillsHook.bills.filter(bill =>
      authorizedPropertyIds.has(bill.property_id)
    );
  }, [currentBillsHook.bills, tenantProperties]);

  // Post-filter history bills
  const filteredHistoryBills = useMemo(() => {
    if (!historyBillsHook.bills?.length) return historyBillsHook.bills || [];

    // Create a set of authorized property IDs
    const authorizedPropertyIds = new Set(Object.keys(tenantProperties));

    // Filter bills
    return historyBillsHook.bills.filter(bill =>
      authorizedPropertyIds.has(bill.property_id)
    );
  }, [historyBillsHook.bills, tenantProperties]);

  // Handle property filter changes
  const handlePropertyFilterChange = (propertyId: string | null) => {
    setSelectedPropertyId(propertyId);
    console.log(`Property filter changed to: ${propertyId || 'all properties'}`);
  };

  // Update date range and refetch historical data
  const handleDateRangeChange = () => {
    if (activeTab === 'history') {
      historyBillsHook.refetch();
    }
  };

  // Loading state
  const isLoading = isLoadingUserData ||
    (activeTab === 'current' ? currentBillsHook.isLoading : historyBillsHook.isLoading);

  if (isLoading && !userData) {
    return (
      <MainLayout>
        <div className="space-y-6 pt-2 pb-8">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">{t('bills.my_bills')}</h1>
            <p className="text-muted-foreground mt-1">
              {t('bills.tenant_bills_description')}
            </p>
          </div>

          <Tabs defaultValue="current">
            <TabsList className="mb-6">
              <TabsTrigger value="current">{t('bills.current_bills')}</TabsTrigger>
              <TabsTrigger value="history">{t('bills.history')}</TabsTrigger>
            </TabsList>
          </Tabs>

          <Skeleton className="h-10 w-full mb-6" />

          <div className="space-y-6">
            {[1, 2, 3].map(i => (
              <Skeleton key={i} className="h-64 w-full" />
            ))}
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{t('bills.my_bills')}</h1>
          <p className="text-muted-foreground mt-1">
            {t('bills.tenant_bills_description')}
          </p>
        </div>

        <Tabs
          defaultValue="current"
          value={activeTab}
          onValueChange={(value) => {
            setActiveTab(value);
          }}
        >
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
            <TabsList>
              <TabsTrigger value="current">{t('bills.current_bills')}</TabsTrigger>
              <TabsTrigger value="history">{t('bills.history')}</TabsTrigger>
            </TabsList>

            {/* Property filter - only show if there are multiple properties */}
            {Object.keys(tenantProperties).length > 1 && (
              <PropertyFilter
                properties={tenantProperties}
                selectedPropertyId={selectedPropertyId}
                onSelectProperty={handlePropertyFilterChange}
              />
            )}
          </div>

          <TabsContent value="current" className="space-y-6">
            <CurrentBillsTabTenant
              billsByMonth={currentBillsHook.billsByMonth}
              allBills={filteredCurrentBills}
              isLoading={currentBillsHook.isLoading}
              isPayingBill={currentBillsHook.isPayingBill}
              payBill={currentBillsHook.payBill}
              refetch={currentBillsHook.refetch}
              properties={tenantProperties}
              selectedPropertyId={selectedPropertyId}
            />
          </TabsContent>

          <TabsContent value="history" className="space-y-6">
            <HistoryBillsTabTenant
              billsByMonth={historyBillsHook.billsByMonth}
              allBills={filteredHistoryBills}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={historyBillsHook.isLoading}
              isPayingBill={historyBillsHook.isPayingBill}
              payBill={historyBillsHook.payBill}
              refetch={historyBillsHook.refetch}
              properties={tenantProperties}
              selectedPropertyId={selectedPropertyId}
              handleDateRangeChange={handleDateRangeChange}
            />
          </TabsContent>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default TenantBills;