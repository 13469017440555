import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ClipboardList, Plus, ArrowRight, AlertTriangle, Check, Clock, ClipboardCheck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useAuth } from '@/contexts/AuthContext';
import { usePropertyChecklists } from '@/lib/query-hooks/get-hooks';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface PropertyChecklistsCardProps {
  propertyId: string;
  isMobile: boolean;
}

const PropertyChecklistsCard: React.FC<PropertyChecklistsCardProps> = ({ propertyId, isMobile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  // Use our custom hook to get checklists for this property
  const {
    totalChecklists,
    activeChecklists,
    lastRunDate,
    itemStatusCounts,
    isLoading
  } = usePropertyChecklists(propertyId, user?.id || '');

  // Format the last run date
  const formattedLastRunDate = lastRunDate
    ? formatDate(lastRunDate, i18n.language)
    : t('checklists.never');

  // Show loading state if data is still loading
  if (isLoading) {
    return (
      <Card className="flex flex-col animate-pulse overflow-hidden">
        <CardHeader className="bg-gradient-to-r from-gray-50 to-gray-100">
          <CardTitle className="flex items-center text-lg">
            <div className="h-5 w-5 bg-muted rounded-full mr-2"></div>
            <div className="h-4 w-24 bg-muted rounded"></div>
          </CardTitle>
        </CardHeader>
        <CardContent className="flex-grow pt-4">
          <div className="space-y-3">
            <div className="h-4 bg-muted rounded w-3/4"></div>
            <div className="h-4 bg-muted rounded w-1/2"></div>
            <div className="h-4 bg-muted rounded w-2/3"></div>
          </div>
        </CardContent>
        <CardFooter className="pt-0">
          <div className="h-9 bg-muted rounded w-full"></div>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card className="flex flex-col overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50 pb-4">
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center gap-2">
            <ClipboardCheck className="h-5 w-5 text-indigo-500" />
            <div>
              <CardTitle>{t('checklists.title')}</CardTitle>
              <CardDescription>{t('checklists.inventory_description')}</CardDescription>
            </div>
          </div>
          {totalChecklists > 0 && (
            <div className="text-nowrap bg-indigo-100 text-indigo-700 px-2 py-1 rounded-full text-xs font-medium">
              {totalChecklists} {totalChecklists === 1
                ? t('checklists.list_singular')
                : t('checklists.list_plural')
              }
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent className={cn("flex-grow", totalChecklists > 0 ? "pt-4" : "pt-0")}>
        {totalChecklists === 0 ? (
          <div className="text-center py-8">
            <div className="mx-auto h-16 w-16 rounded-full bg-muted flex items-center justify-center mb-3">
              <ClipboardList className="h-8 w-8 text-muted-foreground" />
            </div>
            <p className="font-medium">{t('checklists.no_checklists')}</p>
            <p className="text-sm text-muted-foreground mt-1 mb-4">
              {t('checklists.create_first')}
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="bg-gray-50 rounded-lg p-3 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">{t('checklists.total_checklists')}</span>
                <span className="font-medium">{totalChecklists}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">{t('checklists.active_checklists')}</span>
                <span className="font-medium">{activeChecklists}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">{t('checklists.last_check')}</span>
                <span className="font-medium">{formattedLastRunDate}</span>
              </div>
            </div>

            {(itemStatusCounts.completed > 0 || itemStatusCounts.issue > 0 || itemStatusCounts.pending > 0) && (
              <>
                <Separator />
                <div>
                  <p className="text-sm font-medium mb-2">{t('checklists.inventory_status')}</p>
                  <div className="grid grid-cols-3 gap-2 text-center">
                    <div className="flex flex-col items-center rounded-lg bg-green-50 border border-green-100 py-1.5 px-1">
                      <div className='flex flex-row space-x-2 items-center justify-center'>
                        <div className="h-6 w-6 rounded-full bg-green-100 flex items-center justify-center mb-1">
                          <Check className="h-3 w-3 text-green-600" />
                        </div>
                        <div className="font-medium text-green-700">{itemStatusCounts.completed || 0}</div>
                      </div>
                      <div className="text-center text-xs text-green-600">{t('checklists.completed')}</div>
                    </div>

                    <div className="flex flex-col items-center rounded-lg bg-red-50 border border-red-100 py-1.5 px-1">
                      <div className='flex flex-row space-x-2 items-center justify-center'>
                        <div className="h-6 w-6 rounded-full bg-red-100 flex items-center justify-center mb-1">
                          <AlertTriangle className="h-3 w-3 text-red-600" />
                        </div>
                        <div className="font-medium text-red-700">{itemStatusCounts.issue || 0}</div>
                      </div>
                      <div className="text-center text-xs text-red-600">{t('checklists.issues')}</div>
                    </div>

                    <div className="flex flex-col items-center rounded-lg bg-gray-50 border border-gray-100 py-1.5 px-1">
                      <div className='flex flex-row space-x-2 items-center justify-center'>
                        <div className="h-6 w-6 rounded-full bg-gray-100 flex items-center justify-center mb-1">
                          <Clock className="h-3 w-3 text-gray-600" />
                        </div>
                        <div className="font-medium text-gray-700">{itemStatusCounts.pending || 0}</div>
                      </div>
                      <div className="text-center text-xs text-gray-600">{t('checklists.pending')}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </CardContent>
      <CardFooter className="pt-0 pb-4">
        <Button
          className="w-full"
          variant={totalChecklists > 0 ? "outline" : "default"}
          onClick={() => navigate(`/properties/${propertyId}/checklists`)}
        >
          {totalChecklists > 0 ? (
            <>
              {t('checklists.view_all')}
              <ArrowRight className="size-4 ml-2" />
            </>
          ) : (
            <>
              <Plus className="size-4 mr-2" />
              {t('checklists.create')}
            </>
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PropertyChecklistsCard;