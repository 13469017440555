import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checklist, InventoryItem } from '@/types';
import { Card, CardContent } from '@/components/ui/card';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Plus, Trash, Save } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

interface ChecklistFormProps {
  propertyId: string;
  checklist?: Checklist;
  checklistItems?: InventoryItem[];
  onSubmit: (data: {
    title: string;
    description?: string;
    items: Array<{
      id?: string;
      name: string;
      note?: string;
    }>;
  }) => void;
  isLoading?: boolean;
}

interface ChecklistItem {
  id?: string;
  name: string;
  note?: string;
}

const ChecklistForm: React.FC<ChecklistFormProps> = ({
  propertyId,
  checklist,
  checklistItems = [],
  onSubmit,
  isLoading = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Create localized schema validation
  const formSchema = z.object({
    title: z.string().min(1, t('checklists.title_required')),
    description: z.string().optional(),
  });

  // Store original values for comparison
  const originalTitle = useMemo(() => checklist?.title || '', [checklist]);
  const originalDescription = useMemo(() => checklist?.description || '', [checklist]);
  const originalItems = useMemo(() =>
    checklistItems.map(item => ({
      id: item.id,
      name: item.name,
      note: item.note || '',
    })),
    [checklistItems]
  );

  // Initialize items from the provided checklist items or empty array
  const [items, setItems] = useState<ChecklistItem[]>(
    checklistItems.map(item => ({
      id: item.id,
      name: item.name,
      note: item.note || '',
    })) || []
  );

  // Update items if the checklistItems prop changes
  useEffect(() => {
    if (checklistItems?.length) {
      setItems(checklistItems.map(item => ({
        id: item.id,
        name: item.name,
        note: item.note || '',
      })));
    }
  }, [checklistItems]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: checklist?.title || '',
      description: checklist?.description || '',
    },
  });

  // Watch form values to react to changes
  const { title, description } = form.watch();

  // Check if the form has been modified
  const hasFormChanged = useMemo(() => {
    // Check if title or description changed
    const isTitleChanged = title !== originalTitle;
    const isDescriptionChanged = description !== originalDescription;

    // Check if items array length changed
    if (items.length !== originalItems.length) {
      return true;
    }

    // Check if any item has been modified
    const areItemsChanged = !isEqual(
      items.map(item => ({ name: item.name, note: item.note || '' })),
      originalItems.map(item => ({ name: item.name, note: item.note || '' }))
    );

    return isTitleChanged || isDescriptionChanged || areItemsChanged;
  }, [title, description, items, originalTitle, originalDescription, originalItems]);

  const handleAddItem = () => {
    setItems([...items, { name: '', note: '' }]);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleItemNameChange = (index: number, name: string) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], name };
    setItems(newItems);
  };

  const handleItemNoteChange = (index: number, note: string) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], note };
    setItems(newItems);
  };

  const handleFormSubmit = (values: z.infer<typeof formSchema>) => {
    // Validate items have names
    const emptyItems = items.some(item => !item.name.trim());
    if (emptyItems) {
      toast({
        title: t('common.error'),
        description: t('checklists.error_empty_item_names'),
        variant: "destructive",
      });
      return;
    }

    // Check that we have at least one item
    if (items.length === 0) {
      toast({
        title: t('common.error'),
        description: t('checklists.error_empty_items'),
        variant: "destructive",
      });
      return;
    }

    // Submit form data with all necessary data
    onSubmit({
      title: values.title,
      description: values.description || '',
      items: items.map(item => ({
        id: item.id,
        name: item.name,
        note: item.note || ''
      }))
    });
  };

  // Check if form is valid and has items and has changed
  const isFormValid = !!title && items.length > 0;
  const isSaveEnabled = isFormValid && hasFormChanged && !isLoading;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleFormSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 gap-6">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('checklists.checklist_title')}</FormLabel>
                <FormControl>
                  <Input placeholder={t('checklists.enter_title')} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('checklists.description_optional')}</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder={t('checklists.enter_description')}
                    className="h-24"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">{t('checklists.checklist_items')}</h3>
            <Button type="button" onClick={handleAddItem} size="sm">
              <Plus className="h-4 w-4 mr-2" /> {t('checklists.add_item')}
            </Button>
          </div>

          {items.length === 0 ? (
            <Card>
              <CardContent className="p-6 text-center text-muted-foreground">
                <p>{t('checklists.no_items_yet')}</p>
              </CardContent>
            </Card>
          ) : (
            <div className="space-y-3">
              {items.map((item, index) => (
                <Card key={index}>
                  <CardContent className="p-4">
                    <div className="flex items-start gap-3">
                      <div className="flex-1">
                        <Input
                          value={item.name}
                          onChange={(e) => handleItemNameChange(index, e.target.value)}
                          placeholder={t('checklists.item_name')}
                          className="mb-2"
                        />
                        <Textarea
                          value={item.note || ''}
                          onChange={(e) => handleItemNoteChange(index, e.target.value)}
                          placeholder={t('checklists.item_notes_optional')}
                          className="h-20 text-sm"
                        />
                      </div>
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <Trash className="h-4 w-4 text-destructive" />
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end gap-3 pt-4">
          <Button
            type="button"
            variant="outline"
            onClick={!checklist
              ? () => navigate(`/properties/${propertyId}`)
              : () => navigate(`/properties/${propertyId}/checklists/${checklist.id}`)}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            disabled={!isSaveEnabled}
          >
            <Save className="mr-2 h-4 w-4" />
            {isLoading
              ? t('common.saving')
              : (checklist ? t('checklists.update_checklist') : t('checklists.create_checklist'))
            }
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default ChecklistForm;