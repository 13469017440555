import { gql } from "graphql-request";

export const DELETE_PROPERTY = gql`
  mutation DeleteProperty($id: ID!) {
    deleteFrompropertiesCollection(
      filter: { id: { eq: $id } }
    ) {
      records {
        id
        lessor_id
        status
        title
        city
        street
        building
        block
        apartment
        manager_id
        bills_pay_by_lessor
        wi_fi_network
        wi_fi_key
        door_code
        isStudio
        bedrooms
        bathrooms
        area
        image_url
        price
        currency
        unit_term
        unit_measure
        updated_at
        created_at
      }
    }
  }
`;
