import React from 'react';
import { Property, RentStatus } from '@/types';
import { Filter } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { getPropertyAddress } from '@/lib/helpers';
import { BookingsFilterDrawer } from './BookingsFilterDrawer';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

interface BookingFiltersProps {
  selectedStatus: RentStatus | 'all';
  selectedAcceptance: 'all' | 'accepted' | 'not-accepted';
  selectedProperty: string;
  userProperties: Property[];
  onStatusChange: (value: RentStatus | 'all') => void;
  onAcceptanceChange: (value: 'all' | 'accepted' | 'not-accepted') => void;
  onPropertyChange: (value: string) => void;
  onResetFilters: () => void;
}

export const BookingFilters: React.FC<BookingFiltersProps> = ({
  selectedStatus,
  selectedAcceptance,
  selectedProperty,
  userProperties,
  onStatusChange,
  onAcceptanceChange,
  onPropertyChange,
  onResetFilters
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-[1fr_auto] gap-4">
      <div className="flex flex-wrap items-center gap-2">
        {/* Mobile drawer for filters */}
        <BookingsFilterDrawer
          selectedStatus={selectedStatus}
          selectedAcceptance={selectedAcceptance}
          selectedProperty={selectedProperty}
          userProperties={userProperties}
          onStatusChange={onStatusChange}
          onAcceptanceChange={onAcceptanceChange}
          onPropertyChange={onPropertyChange}
          onResetFilters={onResetFilters}
        />

        {/* Desktop filters */}
        <div className="hidden md:flex items-center gap-2 flex-wrap">
          <div className="flex items-center gap-2">
            <Filter className="h-4 w-4 text-muted-foreground" />
            <span className="text-sm text-muted-foreground">{t('bookings.filters')}:</span>
          </div>

          <Select
            value={selectedStatus}
            onValueChange={(value) => onStatusChange(value as RentStatus | 'all')}
          >
            <SelectTrigger className="h-9 w-[130px]">
              <SelectValue placeholder={t('bookings.status')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">{t('bookings.all_statuses')}</SelectItem>
              <SelectItem value="active">{t('bookings.status_badge.active')}</SelectItem>
              <SelectItem value="pending">{t('bookings.status_badge.pending')}</SelectItem>
              <SelectItem value="canceled">{t('bookings.status_badge.canceled')}</SelectItem>
            </SelectContent>
          </Select>

          <Select
            value={selectedAcceptance}
            onValueChange={(value) => onAcceptanceChange(value as 'all' | 'accepted' | 'not-accepted')}
          >
            <SelectTrigger className="h-9 w-[150px]">
              <SelectValue placeholder={t('bookings.acceptance')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">{t('bookings.all')}</SelectItem>
              <SelectItem value="accepted">{t('bookings.accepted')}</SelectItem>
              <SelectItem value="not-accepted">{t('bookings.not_accepted')}</SelectItem>
            </SelectContent>
          </Select>

          {userProperties.length > 1 && (
            <Select
              value={selectedProperty}
              onValueChange={onPropertyChange}
            >
              <SelectTrigger className="h-9 w-[160px]">
                <SelectValue placeholder={t('maintenance.property')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">{t('bookings.all_properties')}</SelectItem>
                {userProperties.map(property => (
                  <SelectItem key={property.id} value={property.id}>
                    {property.title || getPropertyAddress(property).substring(0, 20) + '...'}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}

          {(selectedStatus !== 'all' || selectedAcceptance !== 'all' || selectedProperty !== 'all') && (
            <Button
              variant="ghost"
              size="sm"
              onClick={onResetFilters}
              className="h-9 px-2"
            >
              {t('bookings.reset_filters')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};