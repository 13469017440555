import { gql } from 'graphql-request';

export const GET_USER = gql`
  query GetUser($email: String!, $userId: UUID) {
    # Get user profile
    profilesCollection(
    filter: {
    or: [
    { email: { eq: $email }},
     {id: {eq: $userId}}
    ]
  }
) {
    edges {
      node {
        id
        role
        first_name
        last_name
        email
        phone
        image_url
        updated_at
        created_at
      }
    }
  }
}
  `;