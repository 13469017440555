import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '@/lib/supabase';
import { createClient, SupabaseClient, User } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { UserProfile, UserRole } from '@/types';
import { CreateUserProfileInput, UpdateUserProfileInput } from '@/types/mutation/mutation-input-types';
import { useUserProfile } from '@/lib/query-hooks/useUserProfile';
import { useCreateUserProfile } from '@/lib/query-hooks/mutation/useCreateUserProfile';
import { useUpdateUserProfile } from '@/lib/query-hooks/mutation/useUpdateUserProfile';
import { toast } from '@/hooks/use-toast';
import { fetchUser } from '@/lib/fetch-functions/fetch-user';

interface AuthContextType {
  user: User | null;
  profile: UserProfile | null;
  isLoading: boolean;
  isProfileLoading: boolean;
  error: Error | null;
  role: UserRole | null;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string, role: UserRole, rentId?: string) => Promise<string>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: (profile: Partial<UpdateUserProfileInput>) => Promise<void>;
  setRole: (role: UserRole) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<UserRole | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [lastRole, setLastRole] = useState<UserRole | null>(null)

  const navigate = useNavigate();

  // Get user profile from our hook when user.email is available
  const {
    data: profile,
    isLoading: isProfileLoading,
    refetch: refetchProfile
  } = useUserProfile(user?.email || '', user?.id || null);

  // Create profile mutation
  const {
    createUserProfileAsync
  } = useCreateUserProfile({
    onSuccess: (newProfile) => {
      toast({
        title: "Profile created",
        description: "Your profile has been created successfully.",
      });
      setRole(newProfile.role);
    },
    onError: (err) => {
      toast({
        title: "Error creating profile",
        description: err.message,
        variant: "destructive",
      });
    }
  });

  // Update profile mutation
  const {
    updateUserProfileAsync
  } = useUpdateUserProfile({
    onSuccess: (updatedProfile) => {
      if (lastRole !== updatedProfile.role) {
        toast({
          title: "Profile updated",
          description: "Your profile has been updated successfully.",
        });
      } else {
        toast({
          title: `Switched to ${updatedProfile.role} mode`,
        });
      }
      setRole(updatedProfile.role);
      setLastRole(null);
    },
    onError: (err) => {
      toast({
        title: "Error updating profile",
        description: err.message,
        variant: "destructive",
      });
    }
  });

  // Initial auth state check and subscription to auth changes
  useEffect(() => {
    const checkUser = async () => {
      try {
        setIsLoading(true);

        // Get current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          throw sessionError;
        }

        if (session?.user) {
          setUser(session.user);
        }
      } catch (err) {
        console.error('Auth check error:', err);
        setError(err instanceof Error ? err : new Error('Authentication check failed'));
      } finally {
        setIsLoading(false);
      }
    };

    // Call initial check
    checkUser();

    // Set up auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session?.user) {
          setUser(session.user);
        } else {
          setUser(null);
          setRole(null);
        }
      }
    );

    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Update role when profile changes
  useEffect(() => {
    if (profile) {
      setRole(profile.role);
    }
  }, [profile]);

  // Login function
  const login = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const { data, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        throw signInError;
      }

      if (data?.user) {
        // Fetch user profile
        await refetchProfile();
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err instanceof Error ? err : new Error('Login failed'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  // Register function
  const register = async (email: string, password: string, firstName: string, lastName: string, userRole: UserRole, rentId?: string) => {
    try {
      setIsLoading(true);

      // Register user with Supabase Auth
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/email-confirmation${rentId ? `?rentId=${rentId}` : ''}`,
          data: { userName: firstName }
        }
      });

      if (signUpError) {
        throw signUpError;
      }

      if (!data?.user) {
        throw new Error('User creation failed');
      }

      // Wait a moment for Supabase Auth to complete its internal processes
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Create user profile with retry logic
      try {
        // Create user profile using the minimal CreateUserProfileInput
        const createProfileInput: CreateUserProfileInput = {
          id: data.user.id,
          email: data.user.email || email,
          role: userRole,
          first_name: firstName,
          last_name: lastName || ''
        };

        // Create the profile with retry logic
        await createUserProfileAsync(createProfileInput);

        // Return the user ID for rent updates
        return data.user.id;
      } catch (profileError) {
        console.error('Profile creation error:', profileError);

        // Even if profile creation fails, we want to return the user ID
        // so the rent can be updated if needed
        return data.user.id;
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(err instanceof Error ? err : new Error('Registration failed'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  // Logout function
  const logout = async () => {
    try {
      setIsLoading(true);

      // Try the Supabase signOut, but don't wait for it
      // or make the logout experience dependent on it
      supabase.auth.signOut().catch(e => {
        console.warn("Supabase signOut API call failed:", e);
      });

      // Clear all auth-related data from local storage manually
      localStorage.removeItem('supabase.auth.token');
      localStorage.removeItem('supabase.auth.refreshToken');

      // For Supabase Storage older versions (if applicable)
      localStorage.removeItem('supabase.auth.expires_at');
      localStorage.removeItem('supabase.auth.access_token');

      // Clear cookies related to auth (if any)
      document.cookie.split(";").forEach(c => {
        const cookie = c.trim();
        const cookieName = cookie.split("=")[0];
        if (cookieName.includes("supabase") || cookieName.includes("auth")) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      });

      // Clear your app state
      setUser(null);
      setRole(null);

      // Properly invalidate React Query cache if needed
      // Note: This requires accessing the queryClient, which should be done properly
      // You would need to inject the queryClient into your component or context

      // Navigate to login page
      navigate('/auth/login');

    } catch (err) {
      console.error('Logout error:', err);

      // Still clear state and navigate away
      setUser(null);
      setRole(null);
      navigate('/auth/login');
    } finally {
      setIsLoading(false);
    }
  };

  // Reset password function
  const resetPassword = async (email: string) => {
    try {
      setIsLoading(true);
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: window.location.origin + '/auth/reset-password',
      });

      if (resetError) {
        throw resetError;
      }
    } catch (err) {
      console.error('Password reset error:', err);
      setError(err instanceof Error ? err : new Error('Password reset failed'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  // Update profile function
  const updateProfile = async (profileData: UserProfile) => {
    if (!profile || !user) {
      throw new Error('No profile to update');
    }

    try {
      // Ensure we have the required fields for the update input

      const updateInput: UserProfile = {
        id: profile.id,
        updated_at: new Date().toISOString(),
        created_at: profile.created_at,
        ...profileData
      };
      setLastRole(profileData.role);
      await updateUserProfileAsync(updateInput);
      refetchProfile()
    } catch (err) {
      console.error('Profile update error:', err);
      throw err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        isLoading,
        isProfileLoading,
        error,
        role,
        login,
        register,
        logout,
        resetPassword,
        updateProfile,
        setRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};