import React from 'react';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { format } from 'date-fns';
import { Button } from '@/components/ui/button';
import { Home, Calendar, X, Check, DollarSign } from 'lucide-react';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { formatDate, formatCurrency } from '@/utils/i18n-utils';
import { Badge } from '@/components/ui/badge';
import { Property, Rent, RentStatus } from '@/types';
import { getPropertyAddress } from '@/lib/helpers';
import { useUpdateRent } from '@/lib/query-hooks/mutation/useUpdateRent';

interface TenantInvitationCardProps {
    property: Property;
    rent: Rent;
    onAccepted: () => void;
    onRejected: () => void;
    userId: string;
}

const TenantInvitationCard: React.FC<TenantInvitationCardProps> = ({
    property,
    rent,
    onAccepted,
    onRejected,
    userId
}) => {
    const { t, i18n } = useTranslation();
    // Use the update rent hook
    const {
        updateRentAsync,
        isLoading
    } = useUpdateRent({
        userId,
        onSuccess: (updatedRent) => {
            if (updatedRent.status === 'active' && updatedRent.isAccepted) {
                toast.success('Invitation accepted successfully!');
                onAccepted();
            } else if (updatedRent.status === 'canceled') {
                toast.info('Invitation rejected');
                onRejected();
            }
        },
        onError: (error) => {
            toast.error(`Error: ${error.message}`);
        }
    });

    const handleAccept = async () => {
        const now = new Date();

        // Check if current date is within the rental period
        const startDate = new Date(rent.date_start);
        const endDate = new Date(rent.date_end);

        // Determine status based on date range
        let status = 'pending' as RentStatus;
        if (now >= startDate && now <= endDate) {
            status = 'active';
        }

        // Update the rent with optimistic update
        await updateRentAsync({
            ...rent,
            isAccepted: true,
            status,
            updated_at: now.toISOString()
        });
    };

    const handleReject = async () => {
        const now = new Date();

        // Update the rent with optimistic update
        await updateRentAsync({
            ...rent,
            status: 'canceled',
            updated_at: now.toISOString()
        });
    };

    // Function to render the card header with image and gradient overlay
    const renderCardHeader = () => {
        return (
            <div className="relative w-full h-48">
                {/* Property Image */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${property.image_url || '/placeholder-property.jpg'})` }}
                />

                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-black/10" />

                {/* Content */}
                <div className="absolute bottom-0 left-0 p-6 w-full">
                    <Badge className="mb-2 bg-blue-700/80 text-white hover:bg-blue-700/100 w-fit">
                        {t('tenant.new_invitation')}
                    </Badge>
                    <h3 className="text-xl font-semibold text-white">
                        {property.title || getPropertyAddress(property)}
                    </h3>
                    <p className="text-blue-100 mt-1">
                        {getPropertyAddress(property)}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <Card className="overflow-hidden">
            {renderCardHeader()}

            <CardContent className="p-6">
                <div className="flex flex-col space-y-4">
                    <div className="flex items-start gap-3">
                        <Home className="h-5 w-5 text-muted-foreground mt-0.5" />
                        <div>
                            <p className="font-medium">{t('properties.property_details')}</p>
                            <p className="text-sm text-muted-foreground">
                                {property.isStudio ? t('properties.studio_short') : `${property.bedrooms} ${t('properties.bed')}`} • {property.bathrooms} {t('properties.bath')} • {property.area} {t(`properties.units.${property.unit_measure}`)}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-3">
                        <DollarSign className="h-5 w-5 text-muted-foreground mt-0.5" />
                        <div>
                            <p className="font-medium">{t('properties.rent')}</p>
                            <p className="text-sm text-muted-foreground">
                                {formatCurrency(rent.price_rent, i18n.language, rent.currency)}/{t(`properties.terms.${rent.unit}`)}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-3">
                        <Calendar className="h-5 w-5 text-muted-foreground mt-0.5" />
                        <div>
                            <p className="font-medium">{t('properties.lease_terms')}</p>
                            <p className="text-sm text-muted-foreground">
                                {t('properties.starting')} {formatDate(rent.date_start, i18n.language)} • {rent.contract_term} {rent.contract_term === 1
                                    ? t(`properties.terms.${rent.unit}`)
                                    : t(`properties.terms.${rent.unit}_plural`)}
                            </p>
                        </div>
                    </div>
                </div>
            </CardContent>

            <CardFooter className="p-6 flex justify-end space-x-2 border-t bg-muted/20">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={handleReject}
                    disabled={isLoading}
                    className="text-red-600 hover:text-red-700 hover:bg-red-50"
                >
                    <X className="mr-1 h-4 w-4" /> {t('tenant.reject')}
                </Button>
                <Button
                    size="sm"
                    onClick={handleAccept}
                    disabled={isLoading}
                    className="bg-green-600 hover:bg-green-700"
                >
                    <Check className="mr-1 h-4 w-4" /> {t('tenant.accept')}
                </Button>
            </CardFooter>
        </Card>
    );
};

export default TenantInvitationCard;