import { UserRole } from '@/types';

// Get the appropriate home page route based on user role
export const getHomeRouteForRole = (role: UserRole | null): string => {
  switch (role) {
    case 'tenant':
      return '/dashboard';
    case 'lessor':
      return '/dashboard';
    default:
      return '/dashboard';
  }
};

// Check if a user with the given role can access a specific route
export const canAccessRoute = (role: UserRole | null, allowedRoles: UserRole[]): boolean => {
  if (!role || allowedRoles.length === 0) {
    return false;
  }

  return allowedRoles.includes(role);
};

// Check if user is authenticated (has valid session)
export const isAuthenticated = (user: any): boolean => {
  return !!user;
};

// Function to validate email format
export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Function to validate password strength
export const isStrongPassword = (password: string): { isValid: boolean; message: string } => {
  if (password.length < 8) {
    return { isValid: false, message: 'Password must be at least 8 characters' };
  }

  // Check for mix of uppercase, lowercase, numbers, special characters
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumbers = /[0-9]/.test(password);
  const hasSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

  const strength = [hasUppercase, hasLowercase, hasNumbers, hasSpecialChars].filter(Boolean).length;

  if (strength < 3) {
    return {
      isValid: false,
      message: 'Password should include at least 3 of the following: uppercase letters, lowercase letters, numbers, and special characters'
    };
  }

  return { isValid: true, message: 'Password is strong' };
};
