// useInviteTenant.ts
import { useState } from 'react';
import { fetchUser } from '@/lib/fetch-functions/fetch-user';
import { useCreateRent } from '../query-hooks/mutation/useCreateRent';
import { CreateRentInput } from '@/types/mutation/mutation-input-types';
import { Currency, Rent, RentStatus, UnitTerm } from '@/types';
import { useQueryClient } from '@tanstack/react-query';

interface InviteTenantParams {
  email: string;
  propertyId: string;
  lessorId: string;
  lessorName: string;
  propertyTitle: string;
  managerId: string | null;
  dateStart: string;
  dateEnd: string;
  priceRent: number;
  currency: Currency;
  unit: UnitTerm;
}

interface InviteTenantOptions {
  onSuccess?: (rent: Rent, userExists: boolean) => void;
  onError?: (error: Error) => void;
}

export function useInviteTenant(lessorId: string, options?: InviteTenantOptions) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const queryClient = useQueryClient();

  // Create new rent hook with proper lessorId
  const {
    createRentAsync,
    isLoading: isCreatingRent,
  } = useCreateRent({
    userId: lessorId,
    onSuccess: () => { },
    onError: (err) => {
      setError(err);
      options?.onError?.(err);
    },
  });

  const inviteTenant = async (params: InviteTenantParams) => {
    setIsLoading(true);
    setError(null);
    let createdRent = null;

    try {
      const email = params.email.trim().toLowerCase();

      // Check if user exists first
      const existingUser = await fetchUser(email, null);
      const userExists = !!existingUser?.id;

      // Create the appropriate rent input
      const rentInput: CreateRentInput = {
        lessor_id: params.lessorId,
        tenant_id: userExists ? existingUser.id : null, // Only set tenant_id if user exists
        manager_id: params.managerId,
        property_id: params.propertyId,
        unit: params.unit,
        date_start: params.dateStart,
        date_end: params.dateEnd,
        status: 'pending' as RentStatus,
        contract_url: null,
        contract_term: null,
        currency: params.currency,
        price_rent: params.priceRent,
        isAccepted: false,
        isArchived: false,
      };

      // Create the rent
      createdRent = await createRentAsync(rentInput);

      // Generate registration link with invitation parameters
      const registrationLink = new URL(`${window.location.origin}/auth/register`);
      registrationLink.searchParams.append('email', email);
      registrationLink.searchParams.append('rentId', createdRent.id);
      
      // If user doesn't exist, send the invitation via Make.com webhook
      if (!userExists) {
        // Send invitation via the webhook
        const webhookResponse = await fetch('https://hook.us1.make.com/zjcikdlq8dmltpbfroxg8q63wer7aeot', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            link: registrationLink.toString(),
            lessorName: params.lessorName,
            propertyTitle: params.propertyTitle
          })
        });

        if (!webhookResponse.ok) {
          throw new Error('Failed to send invitation email');
        }
      }

      // Success! Update queries and return
      queryClient.invalidateQueries({queryKey: ['userData', params.lessorId]});
      options?.onSuccess?.(createdRent, userExists);
      
      return { 
        rent: createdRent, 
        userExists
      };

    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to invite tenant');
      setError(error);
      options?.onError?.(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    inviteTenant,
    isLoading: isLoading || isCreatingRent,
    error,
  };
}