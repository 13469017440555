import { Property } from "@/types";
import { UpdatePropertyHookInput, UpdatePropertyInput } from "@/types/mutation/mutation-input-types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateProperty } from "@/lib/actions/update-property";
import { getFileNameFromUrl, deletePropertyImage, uploadPropertyImage } from "@/lib/actions/property-image-upload";

// React Query Mutation Hook
interface PropertyUpdateOptions {
    onSuccess?: (property: Property) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
}

export function useUpdateProperty(options: PropertyUpdateOptions) {
    console.log('Update Property Request Hook triggered');
    const queryClient = useQueryClient();

    if (!options) {
        throw new Error('options is required');
    }

    const mutation = useMutation<Property, Error, UpdatePropertyHookInput, MutationContext>({
        mutationFn: async (input) => {
            const { image_file, ...restInput } = input;
            // const { created_at, ...restInput } = input;

            let variables: UpdatePropertyInput = { ...restInput };
            // Handle image upload if a new file is provided
            if (image_file) {
                // If there's an existing image, delete it first
                if (input.image_url) {
                    const fileName = getFileNameFromUrl(input.image_url);
                    if (fileName) {
                        await deletePropertyImage(input.id, fileName);
                    }
                }

                // Upload the new image
                const uploadResult = await uploadPropertyImage({
                    file: image_file,
                    propertyId: input.id
                });

                if (uploadResult.error) {
                    throw new Error(`Image upload failed: ${uploadResult.error}`);
                }

                // Update the image_url in the input
                variables = {
                    ...restInput,
                    image_url: uploadResult.url,
                    updated_at: new Date().toISOString()
                };
            }

            return updateProperty(variables);
        },

        // Add optimistic update
        onMutate: async (input) => {
            if (!input) {
                throw new Error('input data is required');
            }
            if (!input.id) {
                throw new Error('property ID is required');
            }

            const queryKey = ['userData', options.userId];
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);

            if (previousData) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                    // Handle image file - for optimistic UI, we can't know the URL yet
                    // so we'll keep the existing one and it will be updated after the mutation
                    const { image_file, ...updateData } = input;

                    // Update the property in the cache
                    if (newData.properties[input.id]) {
                        const now = new Date().toISOString();
                        newData.properties[input.id] = {
                            ...newData.properties[input.id],
                            ...updateData,
                            updated_at: now
                        };

                        // Handle lessor change if needed
                        const oldProperty = old.properties[input.id];
                        if (oldProperty && oldProperty.lessor_id !== input.lessor_id) {
                            // Remove from old lessor's properties
                            if (oldProperty.lessor_id && newData.userProperties.asLessor[oldProperty.lessor_id]) {
                                newData.userProperties.asLessor[oldProperty.lessor_id] =
                                    newData.userProperties.asLessor[oldProperty.lessor_id]
                                        .filter(id => id !== input.id);
                            }

                            // Add to new lessor's properties
                            if (input.lessor_id) {
                                if (!newData.userProperties.asLessor[input.lessor_id]) {
                                    newData.userProperties.asLessor[input.lessor_id] = [];
                                }
                                if (!newData.userProperties.asLessor[input.lessor_id].includes(input.id)) {
                                    newData.userProperties.asLessor[input.lessor_id].push(input.id);
                                }
                            }
                        }
                    }

                    return newData;
                });
            }

            return { previousData };
        },

        onError: (error, _newData, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }
            console.error('Error updating Property', error);
            options?.onError?.(error);
        },

        onSuccess: (updatedProperty) => {
            const queryKey = ['userData', options.userId];

            // Update the cache with the real data from the server
            queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                if (!old) return old;

                const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                // Replace property with the updated one from the server
                newData.properties[updatedProperty.id] = updatedProperty;

                return newData;
            });

            options?.onSuccess?.(updatedProperty);
        },
        retry: false
    });

    return {
        updateProperty: mutation.mutate,
        updatePropertyAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}
