"use server"

import { UPDATE_USER_PROFILE } from "@/queries-graphql/mutation/update-user-profile-mutation";
import { UserProfile, UserRole } from "@/types";
import { graphqlClient } from "@/lib/graphql-client";
import { UpdateUserProfileResponse } from "@/types/mutation/mutation-responses-types";
import { UpdateUserProfileInput } from "@/types/mutation/mutation-input-types";

// Function to update a project
export const updateUserProfile = async (
    input: UpdateUserProfileInput
): Promise<UserProfile> => {
    const client = graphqlClient;
    try {
        const variables = { ...input };
        const data = await client.request<UpdateUserProfileResponse>(
            UPDATE_USER_PROFILE,
            variables
        );
        const response = data.updateprofilesCollection.records[0];
        //console.log(response);
        return response;
    } catch (error) {
        console.error('Error updating User profile:', error);
        throw error;
    }
};