import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Eye, EyeOff, KeyRound } from 'lucide-react';
import { Label } from '@/components/ui/label';

interface PasswordInputProps {
    id: string;
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
    showIcon?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
    id,
    label,
    value,
    onChange,
    placeholder = "Enter password",
    required = true,
    showIcon = true,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="space-y-2">
            <Label htmlFor={id}>{label}</Label>
            <div className="relative">
                {showIcon && (
                    <KeyRound className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                )}
                <Input
                    id={id}
                    type={showPassword ? "text" : "password"}
                    value={value}
                    onChange={onChange}
                    required={required}
                    className={showIcon ? "pl-10 pr-10" : "pr-10"}
                    placeholder={placeholder}
                />
                <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute right-0 top-0 h-9 w-9"
                    onClick={togglePasswordVisibility}
                    tabIndex={-1} // So it doesn't get tab focus before the more important form elements
                >
                    {showPassword ? (
                        <EyeOff className="h-4 w-4 text-muted-foreground" />
                    ) : (
                        <Eye className="h-4 w-4 text-muted-foreground" />
                    )}
                    <span className="sr-only">
                        {showPassword ? "Hide password" : "Show password"}
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default PasswordInput;