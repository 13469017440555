import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { useAuth } from '@/contexts/AuthContext';
import { TenantDashboard } from './TenantDashboard';
import { LessorDashboard } from './LessorDashboard';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';

// Loading Skeleton for Dashboard
const DashboardSkeleton: React.FC = () => {
  return (
    <div className="space-y-6">
      <div className="h-8 w-64 bg-muted rounded-md animate-pulse mb-6"></div>

      {/* Stats cards skeleton */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="bg-muted rounded-md h-28 p-4 animate-pulse">
            <div className="h-4 w-24 bg-muted-foreground/20 rounded mb-3"></div>
            <div className="h-8 w-16 bg-muted-foreground/20 rounded mb-2"></div>
            <div className="flex items-center">
              <div className="h-4 w-4 bg-muted-foreground/20 rounded-full mr-2"></div>
              <div className="h-3 w-32 bg-muted-foreground/20 rounded"></div>
            </div>
          </div>
        ))}
      </div>

      {/* Content section skeleton */}
      <div>
        <div className="h-6 w-40 bg-muted rounded-md animate-pulse mb-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-muted rounded-md h-64 p-4 animate-pulse">
              <div className="h-6 w-3/4 bg-muted-foreground/20 rounded mb-2"></div>
              <div className="h-4 w-1/2 bg-muted-foreground/20 rounded mb-6"></div>
              <div className="space-y-3">
                <div className="flex justify-between">
                  <div className="h-4 w-20 bg-muted-foreground/20 rounded"></div>
                  <div className="h-4 w-20 bg-muted-foreground/20 rounded"></div>
                </div>
                <div className="flex justify-between">
                  <div className="h-4 w-20 bg-muted-foreground/20 rounded"></div>
                  <div className="h-4 w-20 bg-muted-foreground/20 rounded"></div>
                </div>
                <div className="flex justify-between">
                  <div className="h-4 w-20 bg-muted-foreground/20 rounded"></div>
                  <div className="h-4 w-20 bg-muted-foreground/20 rounded"></div>
                </div>
              </div>
              <div className="mt-6 h-9 w-full bg-muted-foreground/20 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Dashboard: React.FC = () => {
  const { user, role, isLoading } = useAuth();
  const navigate = useNavigate();

  // Show full-page loading state when determining auth status
  if (isLoading) {
    return (
      <MainLayout>
        <DashboardSkeleton />
      </MainLayout>
    );
  }

  // Show error state if no user or role is available
  if (!user || !role) {
    return (
      <MainLayout>
        <div className="flex flex-col items-center justify-center h-[60vh]">
          <AlertCircle className="h-12 w-12 text-red-500 mb-4" />
          <h2 className="text-xl font-semibold mb-2">Authentication Error</h2>
          <p className="text-muted-foreground mb-6">Unable to determine user role or authentication status.</p>
          <Button onClick={() => navigate('/auth/login')}>
            Go to Login
          </Button>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {role === 'tenant' ? <TenantDashboard /> : <LessorDashboard />}
    </MainLayout>
  );
};

export default Dashboard;