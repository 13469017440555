import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';
import { SidebarNav } from './SidebarNav';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useAuth } from '@/contexts/AuthContext';
import { MobileHeader } from './MobileHeader';
import { BottomNav } from './BottomNav';

// Mobile navigation components
const MobileHeaderNav = () => {
    return (
        <MobileHeader />
    );
};

const MobileBottomNav = () => {
    return (
        <BottomNav />
    );
};

// The App Shell component that wraps the entire app
export const AppShell = () => {
    const isMobile = useIsMobile();
    const { user } = useAuth();
    const {
        data: userData,
        isLoading: isLoadingUserData,
        isError: isErrorUserData,
        refetch
    } = useUserData(user?.id || '');

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Desktop Sidebar */}
            {!isMobile && (
                <aside className="relative w-64 bg-muted/40 border-r">
                    {userData && (
                        <SidebarNav
                            userData={userData}
                            isLoadingUserData={isLoadingUserData}
                            isErrorUserData={isErrorUserData}
                            refetchAllUserData={refetch}
                            onCloseSidebar={() => { }}
                        />
                    )}
                </aside>
            )}

            {/* Mobile navigation */}
            {isMobile && <MobileHeaderNav />}
            {isMobile && <MobileBottomNav />}

            {/* Main content with router outlet */}
            <main
                className={`flex-1 flex flex-col overflow-y-auto ${isMobile ? 'pt-14 pb-16' : ''
                    }`}
            >
                <Outlet />
            </main>
        </div>
    );
};