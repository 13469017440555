import React from 'react';
import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface BillsEmptyStateProps {
    title?: string;
    description?: string;
    isFilterActive?: boolean;
}

export const BillsEmptyState: React.FC<BillsEmptyStateProps> = ({
    title,
    description,
    isFilterActive = false
}) => {
    const { t } = useTranslation();

    // Use provided titles or fallback to translations
    const displayTitle = title || t('bills.no_bills');
    const displayDescription = description || t('bills.no_bills_for_period');

    return (
        <div className="text-center py-12">
            <div className="mx-auto w-12 h-12 rounded-full bg-muted flex items-center justify-center">
                <AlertCircle className="h-6 w-6 text-muted-foreground" />
            </div>
            <h3 className="mt-4 text-lg font-medium">{displayTitle}</h3>
            <p className="mt-1 text-muted-foreground">
                {displayDescription}
                {isFilterActive && t('bills.for_selected_property')}
            </p>
        </div>
    );
};