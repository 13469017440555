import React from 'react';
import { useAuth } from '@/contexts/AuthContext';
import LessorBills from './LessorBills';
import TenantBills from './TenantBills';
import { Skeleton } from '@/components/ui/skeleton';
import { MainLayout } from '@/components/layout/MainLayout';

/**
 * Router component that renders the appropriate Bills component
 * based on the user's role
 */
const Bills: React.FC = () => {
  const { profile, isProfileLoading, role } = useAuth();

  // Show loading state while profile is loading
  if (isProfileLoading) {
    return (
      <MainLayout>
        <div className="space-y-6">
          <Skeleton className="h-10 w-1/2" />
          <Skeleton className="h-4 w-full" />
          <div className="space-y-4">
            <Skeleton className="h-8 w-full" />
            {[1, 2, 3].map(i => (
              <Skeleton key={i} className="h-64 w-full" />
            ))}
          </div>
        </div>
      </MainLayout>
    );
  }

  // Route to the appropriate component based on the user's role
  // if (profile?.role === 'lessor') {
  //   return <LessorBills key="lessor" />;
  // } else if (profile?.role === 'tenant') {
  //   return <TenantBills key="tenant" />;
  // } else {
  //   return <TenantBills key="default" />;
  // }
  return (
    role === 'tenant' ? <TenantBills /> : <LessorBills />

  )
};

export default Bills;