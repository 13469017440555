"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Property } from "@/types";
import { UpdatePropertyInput } from "@/types/mutation/mutation-input-types";
import { UpdatePropertyResponse } from "@/types/mutation/mutation-responses-types";
import { UPDATE_PROPERTY } from "@/queries-graphql/mutation/update-property-mutation";

// Function to update Property
export const updateProperty = async (
    input: UpdatePropertyInput
): Promise<Property> => {
    const client = graphqlClient;
    try {
        const variables = { ...input };
        const data = await client.request<UpdatePropertyResponse>(
            UPDATE_PROPERTY,
            variables
        );

        const response = data.updatepropertiesCollection.records[0];
        return response;
    } catch (error) {
        console.error('Error updating Property:', error);
        throw error;
    }
};
