import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerFooter } from '@/components/ui/drawer';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Settings, User, Shield, ArrowLeft, Globe } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';
import { useAuth } from '@/contexts/AuthContext';
import { LogoutButton } from '../profile/LogoutButton';
import RoleSwitcher from '@/components/nav/RoleSwitcher';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import ProfileEditor from '@/components/profile/ProfileEditor';
import ProfileEmailUpdate from '@/components/profile/ProfileEmailUpdate';
import ProfilePasswordChange from '@/components/profile/ProfilePasswordChange';
import { ScrollArea } from '@/components/ui/scroll-area';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';

interface UserSettingsDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const UserSettingsDrawer: React.FC<UserSettingsDrawerProps> = ({
  open,
  onOpenChange
}) => {
  const { t, i18n } = useTranslation();
  const { user, profile, logout } = useAuth();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('profile');

  // Open profile drawer and close settings drawer
  const handleOpenProfileDrawer = () => {
    if (isMobile) {
      setProfileDrawerOpen(true);
      onOpenChange(false);
    } else {
      navigate('/profile');
      onOpenChange(false);
    }
  };

  // Handle language change
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  // Current language
  const currentLanguage = i18n.language || 'en';

  // If no authenticated user, don't show anything
  if (!user) {
    return null;
  }

  // Main settings content
  const settingsContent = (
    <ScrollArea>
      <div className="space-y-2 px-4 pb-4">
        <div className="space-y-4">
          {/* User info summary */}
          <div className="flex items-center space-x-3 mb-2">
            <div className="h-10 w-10 rounded-full bg-gradient-to-br from-indigo-500 to-purple-500 text-primary-foreground flex items-center justify-center font-semibold">
              {profile?.first_name?.[0] || user.email?.[0] || 'U'}
            </div>
            <div>
              <p className="font-medium">
                {profile?.first_name || 'User'} {profile?.last_name || ''}
              </p>
              <p className="text-sm text-muted-foreground">{user.email}</p>
            </div>
          </div>

          {/* Profile link */}
          <Button
            className='flex w-full items-center justify-start gap-2 py-2 text-sm hover:text-primary transition-colors'
            variant="outline"
            onClick={() => {
              onOpenChange(false)
              navigate("/profile")
              handleOpenProfileDrawer
            }}
          >
            <User className="h-4 w-4" />
            <span>{t('profile.settings')}</span>
          </Button>

          {/* Language Settings */}
          <div className="p-4 border rounded-md space-y-2">
            <div className="flex items-center gap-2 mb-2">
              <Globe className="h-4 w-4 text-primary" />
              <h3 className="text-sm font-medium">{t('language.select')}</h3>
            </div>
            <RadioGroup
              defaultValue={currentLanguage}
              onValueChange={changeLanguage}
              className="grid grid-cols-2 gap-2"
            >

              <div className="flex items-center space-x-2 border p-3 rounded-md hover:bg-muted/50">
                <RadioGroupItem value="en" id="drawer-lang-en" />
                <Label htmlFor="drawer-lang-en" className="text-sm font-normal cursor-pointer">
                  {t('language.en')}
                </Label>
              </div>
              <div className="flex items-center space-x-2 border p-3 rounded-md hover:bg-muted/50">
                <RadioGroupItem value="uk" id="drawer-lang-uk" />
                <Label htmlFor="drawer-lang-uk" className="text-sm font-normal cursor-pointer">
                  {t('language.uk')}
                </Label>
              </div>
            </RadioGroup>
          </div>
        </div>
        <div className="space-y-2">
          {/* Divider */}
          <div className="border-t pt-4"></div>

          {/* Role Switcher - kept for quick access */}
          <div>
            <RoleSwitcher
              onRoleChange={() => onOpenChange(false)}
              idPrefix="drawer-"
            />
          </div>

          {/* Logout button */}
          <div className="flex justify-stretch items-stretch pt-2 w-full">
            <LogoutButton />
          </div>
        </div>
      </div>
    </ScrollArea>
  );

  // Profile settings content
  const profileContent = (
    <div className="space-y-6 p-2">
      {/* Back button - only shown on mobile */}
      <Button
        variant="ghost"
        size="sm"
        className="flex items-center gap-1 mb-4"
        onClick={() => {
          setProfileDrawerOpen(false);
          // Re-open the settings drawer
          setTimeout(() => onOpenChange(true), 100);
        }}
      >
        <ArrowLeft className="h-4 w-4" />
        <span>{t('common.back')}</span>
      </Button>

      {/* Profile Header */}
      <div className="flex flex-col gap-4 items-start">
        <div className="flex gap-4 items-center">
          <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center">
            {profile?.image_url ? (
              <img
                src={profile.image_url}
                alt="Profile"
                className="w-full h-full rounded-full object-cover"
              />
            ) : (
              <User className="h-8 w-8 text-primary" />
            )}
          </div>
          <div>
            <h2 className="text-xl font-bold">
              {profile?.first_name} {profile?.last_name}
            </h2>
            <p className="text-sm text-muted-foreground">
              {user?.email}
            </p>
            <div className="flex items-center mt-1">
              <span className="text-xs px-2 py-0.5 rounded bg-primary/10 text-primary capitalize">
                {profile?.role || 'User'}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      {/* Tabs for different sections */}
      <Tabs value={activeTab} onValueChange={setActiveTab} className="mt-4">
        <TabsList className="grid grid-cols-2 w-full">
          <TabsTrigger value="profile" className="flex items-center gap-2">
            <User className="h-4 w-4" />
            <span>{t('profile.title')}</span>
          </TabsTrigger>
          <TabsTrigger value="security" className="flex items-center gap-2">
            <Shield className="h-4 w-4" />
            <span>{t('profile.security')}</span>
          </TabsTrigger>
        </TabsList>

        {/* Profile Tab */}
        <TabsContent value="profile" className="space-y-4 mt-4">
          <ProfileEditor />

          <div className="bg-gray-50 p-4 rounded-lg border">
            <h3 className="text-md font-medium mb-4">{t('profile.preferences')}</h3>

            {/* Language Settings */}
            <div className="space-y-2 mb-4">
              <div className="flex items-center gap-2 mb-2">
                <Globe className="h-4 w-4 text-primary" />
                <h3 className="text-sm font-medium">{t('language.select')}</h3>
              </div>
              <RadioGroup
                defaultValue={currentLanguage}
                onValueChange={changeLanguage}
                className="grid grid-cols-2 gap-2"
              >
                <div className="flex items-center space-x-2 border p-3 rounded-md hover:bg-muted/50">
                  <RadioGroupItem value="en" id="profile-lang-en" />
                  <Label htmlFor="profile-lang-en" className="text-sm font-normal cursor-pointer">
                    {t('language.en')}
                  </Label>
                </div>
                <div className="flex items-center space-x-2 border p-3 rounded-md hover:bg-muted/50">
                  <RadioGroupItem value="uk" id="profile-lang-uk" />
                  <Label htmlFor="profile-lang-uk" className="text-sm font-normal cursor-pointer">
                    {t('language.uk')}
                  </Label>
                </div>
              </RadioGroup>
            </div>

            <RoleSwitcher className="mb-4" />

            <Separator className="my-4" />

            <div className="space-y-3">
              <h4 className="text-sm font-medium">{t('profile.account_actions')}</h4>
              <LogoutButton />
            </div>
          </div>
        </TabsContent>

        {/* Security Tab */}
        <TabsContent value="security" className="space-y-4 mt-4">
          <div className="space-y-4">
            {/* Email Update */}
            <ProfileEmailUpdate />

            {/* Password Change */}
            <ProfilePasswordChange />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );

  // Use Drawer for mobile, Sheet for desktop
  if (isMobile) {
    return (
      <>
        {/* Settings Drawer */}
        <Drawer open={open && !profileDrawerOpen} onOpenChange={onOpenChange}>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>{t('profile.account')}</DrawerTitle>
            </DrawerHeader>
            {settingsContent}
            <DrawerFooter>
              <Button variant="outline" onClick={() => onOpenChange(false)}>
                {t('common.close')}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        {/* Profile Settings Drawer */}
        <Drawer open={profileDrawerOpen} onOpenChange={setProfileDrawerOpen}>
          <DrawerContent className="max-h-[85vh]">
            <DrawerHeader>
              <DrawerTitle>{t('profile.settings')}</DrawerTitle>
            </DrawerHeader>
            <ScrollArea>
              {profileContent}
            </ScrollArea>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  // Desktop version uses Sheet
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{t('profile.account')}</SheetTitle>
        </SheetHeader>
        {settingsContent}
      </SheetContent>
    </Sheet>
  );
};