import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { UserRole } from '@/types';
import { getHomeRouteForRole } from '@/utils/auth-utils';

interface ProtectedRouteProps {
  allowedRoles?: UserRole[];
  redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedRoles = [],
  redirectPath = '/auth/login',
}) => {
  const { user, role, isLoading, isProfileLoading } = useAuth();
  const location = useLocation();

  // If still loading auth state or profile, show a loading spinner
  if (isLoading || isProfileLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent"></div>
      </div>
    );
  }

  // If not authenticated, redirect to login
  if (!user) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  // If profile is loaded but role is null, user might need to complete profile setup
  if (!role) {
    return <Navigate to="/auth/complete-profile" state={{ from: location }} replace />;
  }

  // If roles are specified and user doesn't have any of the allowed roles
  if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
    // Redirect to appropriate dashboard based on role
    const rolePath = getHomeRouteForRole(role);
    return <Navigate to={rolePath} replace />;
  }

  // If authenticated and has allowed role, render the child routes
  return <Outlet />;
};

export default ProtectedRoute;