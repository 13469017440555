import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { AlertCircle, CheckCircle2 } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { usePasswordReset } from '@/lib/auth-hooks/userPasswordReset';
import { supabase } from '@/lib/supabase';
import PasswordInput from '@/components/ui/custom-ui/PasswordInput';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();

  const {
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    error,
    isLoading,
    isValidResetLink,
    isCheckingLink,
    isSuccess,
    resetPassword
  } = usePasswordReset({
    onSuccess: () => {
      // We'll let the hook handle the navigation
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    resetPassword();
  };

  if (isCheckingLink) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background p-4">
        <Card className="w-full max-w-md">
          <CardHeader className="space-y-1">
            <CardTitle className="text-2xl text-center">Verifying Reset Link</CardTitle>
            <CardDescription className="text-center">
              Please wait while we verify your password reset link
            </CardDescription>
          </CardHeader>
          <CardContent className="flex justify-center py-6">
            <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent"></div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center">Reset Password</CardTitle>
          <CardDescription className="text-center">
            {isSuccess ? "Your password has been reset" : "Enter your new password"}
          </CardDescription>
        </CardHeader>

        {isValidResetLink === false ? (
          <CardContent className="space-y-4">
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error || 'Invalid reset link'}</AlertDescription>
            </Alert>

            <Button asChild className="w-full mt-4">
              <Link to="/auth/forgot-password">
                Request New Reset Link
              </Link>
            </Button>
          </CardContent>
        ) : isSuccess ? (
          <CardContent className="space-y-4">
            <div className="flex justify-center my-4">
              <div className="h-16 w-16 bg-green-100 rounded-full flex items-center justify-center">
                <CheckCircle2 className="h-8 w-8 text-green-600" />
              </div>
            </div>
            <Alert className="bg-green-50 border-green-200">
              <AlertDescription>
                Your password has been successfully reset. You will be redirected to the login page shortly.
              </AlertDescription>
            </Alert>
            <Button
              asChild
              className="w-full mt-4"
              onClick={() => {
                supabase.auth.signOut();
                navigate('/auth/reset-confirmation');
              }}
            >
              <Link to="/auth/login">
                Go to Login Page
              </Link>
            </Button>
          </CardContent>
        ) : (
          <form onSubmit={handleSubmit}>
            <CardContent className="space-y-4">
              {error && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              {/* Using our new PasswordInput component */}
              <PasswordInput
                id="password"
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
              />

              <PasswordInput
                id="confirmPassword"
                label="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
              />

              <p className="text-sm text-muted-foreground">
                Password must be at least 6 characters long.
              </p>
            </CardContent>
            <CardFooter className="flex flex-col gap-4">
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                    Resetting Password...
                  </>
                ) : (
                  "Reset Password"
                )}
              </Button>
              <div className="text-sm text-center">
                Remember your password?{" "}
                <Link to="/auth/login" className="text-primary hover:underline">
                  Sign in
                </Link>
              </div>
            </CardFooter>
          </form>
        )}
      </Card>
    </div>
  );
};

export default ResetPassword;