import { gql } from "graphql-request";

export const BATCH_DELETE_INVENTORY_ITEMS = gql`
  mutation BatchDeleteInventoryItems($itemIds: [UUID!]!) {
    deleteFrominventory_itemsCollection(
      filter: { id: { in: $itemIds } },
      atMost: 50
    ) {
      records {
        id
        checklist_id
        name
        note
        status
        created_at
        updated_at
      }
    }
  }
`;
