// src/lib/hooks/useProfileWithInvitation.ts
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { useCreateUserProfile } from '@/lib/query-hooks/mutation/useCreateUserProfile';
import { fetchRent } from '@/lib/fetch-functions/fetch-rent';
import { toast } from '@/hooks/use-toast';
import { getHomeRouteForRole } from '@/utils/auth-utils';
import { CreateUserProfileInput } from '@/types/mutation/mutation-input-types';
import { UserRole } from '@/types';
import { updateRent } from '@/lib/actions/update-rent'; // Direct import of the action

/**
 * Hook to handle profile creation with optional invitation flow
 */
export function useProfileWithInvitation() {
  const { user, profile, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  // Get all invitation params from URL
  const rentId = searchParams.get('rentId');
  const invitedEmail = searchParams.get('email');
  const urlUserId = searchParams.get('userId');  // Get userId from URL if present
  
  const isInvitationFlow = !!rentId;
  const effectiveUserId = urlUserId || user?.id || '';

  // Form state
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    role: isInvitationFlow ? 'tenant' as UserRole : 'tenant' as UserRole
  });
  
  // State management
  const [error, setError] = useState<string | null>(null);
  const [validatingInvitation, setValidatingInvitation] = useState(isInvitationFlow);
  const [rentData, setRentData] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Create user profile mutation
  const { createUserProfileAsync, isLoading: isCreatingProfile } = useCreateUserProfile({
    onSuccess: async (newProfile) => {
      // Show success toast for profile creation
      toast({
        title: "Profile created",
        description: "Your profile has been created successfully.",
      });
      
      // If this is an invitation flow, we need to update the rent with tenant ID
      if (isInvitationFlow && rentId && rentData) {
        try {
          // Use the direct updateRent action instead of the hook
          // This avoids the dependency on user ID in the useUpdateRent hook
          await updateRentWithTenant(newProfile.id);
          
          toast({
            title: "Invitation accepted",
            description: "You've been successfully assigned to this property.",
          });
          
          navigate('/tenant/properties');
        } catch (error) {
          console.error("Error updating rent:", error);
          toast({
            title: "Error accepting invitation",
            description: error instanceof Error ? error.message : "Failed to accept invitation",
            variant: "destructive",
          });
        } finally {
          setIsProcessing(false);
        }
      } else {
        // Regular flow - redirect to appropriate dashboard based on role
        navigate(getHomeRouteForRole(newProfile.role));
        setIsProcessing(false);
      }
    },
    onError: (err) => {
      console.error("Profile creation error:", err);
      setError(`Error creating profile: ${err.message}`);
      toast({
        title: "Error creating profile",
        description: err.message,
        variant: "destructive",
      });
      setIsProcessing(false);
    }
  });

  // Function to update rent with tenant ID using direct action
  const updateRentWithTenant = async (tenantId: string) => {
    if (!rentData) {
      throw new Error("Rent data not available");
    }
    
    try {
      // Use the direct updateRent action
      const updatedRent = await updateRent({
        ...rentData,
        tenant_id: tenantId,
        updated_at: new Date().toISOString()
      });
      
      if (!updatedRent) {
        throw new Error("Failed to update rent");
      }
      
      return updatedRent;
    } catch (error) {
      console.error('Error updating rent:', error);
      throw new Error('Failed to complete the invitation process: ' + 
        (error instanceof Error ? error.message : 'Unknown error'));
    }
  };

  // Validate the invitation if this is an invitation flow
  useEffect(() => {
    if (!isInvitationFlow) return;
    
    const validateInvitation = async () => {
      setValidatingInvitation(true);
      
      try {
        if (!rentId) {
          throw new Error('Missing invitation information');
        }
        
        // Fetch rent data to validate
        const data = await fetchRent(rentId);
        
        if (!data) {
          throw new Error('The invitation is no longer valid');
        }
        
        // Check if the property is already assigned to someone
        if (data.tenant_id) {
          throw new Error('This property has already been assigned to another tenant');
        }
        
        // Store the rent data for later use when updating
        setRentData(data);
      } catch (error) {
        console.error('Invalid invitation:', error);
        setError(error instanceof Error ? error.message : 'Invalid invitation');
      } finally {
        setValidatingInvitation(false);
      }
    };

    validateInvitation();
  }, [rentId, isInvitationFlow]);

  // Verify the user email matches the invitation email (if specified)
  useEffect(() => {
    if (isInvitationFlow && user && invitedEmail && user.email !== invitedEmail) {
      setError(`This invitation was sent to ${invitedEmail}. Please sign in with that email address.`);
    }
  }, [isInvitationFlow, user, invitedEmail]);

  // Redirect if user already has a profile (except for invitation flow)
  useEffect(() => {
    if (profile && !isInvitationFlow) {
      navigate(getHomeRouteForRole(profile.role));
    }
  }, [profile, navigate, isInvitationFlow]);

  // Function to handle form submission
  const handleProfileSubmit = async () => {
    // User must be authenticated to create a profile
    if (!user) {
      setError('You must be logged in to complete this process');
      return;
    }
    
    // Use the effective user ID (from URL or auth context)
    if (!effectiveUserId) {
      setError('User ID not available. Please try logging in again.');
      return;
    }
    
    // Validate form data
    if (!formData.firstName.trim()) {
      setError('First name is required');
      return;
    }
    
    if (!formData.lastName.trim()) {
      setError('Last name is required');
      return;
    }
    
    // Clear any previous errors and set processing state
    setError(null);
    setIsProcessing(true);
    
    try {
      // Create the user profile
      await createUserProfileAsync({
        id: effectiveUserId,
        email: user.email || '',
        role: formData.role,
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: formData.phone || null,
      } as CreateUserProfileInput);
      
      // Note: The success callback will handle redirecting based on flow type
    } catch (error) {
      console.error('Profile creation error:', error);
      setError('Failed to create your profile. Please try again.');
      setIsProcessing(false);
    }
  };

  // Helper to update form fields
  const updateFormField = (field: keyof typeof formData, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  return {
    // Form data
    formData,
    
    // State
    error,
    isInvitationFlow,
    
    // Loading states
    isLoading: authLoading || validatingInvitation,
    isProcessing: isProcessing || isCreatingProfile,
    
    // Actions and methods
    updateFormField,
    handleSubmit: handleProfileSubmit,
    setRole: (role: UserRole) => updateFormField('role', role),
    
    // Extra context
    rentId,
    invitedEmail,
    effectiveUserId
  };
}