import React, { useState, useMemo } from 'react';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { Property } from '@/types';
import { toast } from '@/hooks/use-toast';
import { BillsLoadingState } from '../shared/BillsLoadingState';
import { BillsEmptyState } from '../shared/BillsEmptyState';
import { DateRangePicker } from '../shared/DateRangePicker';
import { TenantPropertyBillsGroup } from './TenantPropertyBillsGroup';
import { TenantMonthBillsCard } from './TenantMonthBillsCard';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/i18n-utils';

interface HistoryBillsTabTenantProps {
  billsByMonth: Array<{
    monthKey: string;
    displayName: string;
    bills: CombinedBill[];
    totalAmount: number;
    unpaidAmount: number;
    allPaid: boolean;
  }>;
  allBills: CombinedBill[]; // All bills for filtering
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  isLoading: boolean;
  isPayingBill: boolean;
  payBill: (billId: string) => Promise<any>;
  refetch: () => void;
  properties: Record<string, Property>;
  selectedPropertyId: string | null;
  handleDateRangeChange: () => void;
}

const HistoryBillsTabTenant: React.FC<HistoryBillsTabTenantProps> = ({
  billsByMonth,
  allBills,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isLoading,
  isPayingBill,
  payBill,
  refetch,
  properties,
  selectedPropertyId,
  handleDateRangeChange
}) => {
  const { t, i18n } = useTranslation();
  // Object to track which property cards are open
  const [openPropertyKeys, setOpenPropertyKeys] = useState<string[]>([]);

  // Apply selectedPropertyId filter if needed - billsByMonth is already filtered to contain only tenant properties
  const filteredBillsByMonth = useMemo(() => {
    // If no specific property is selected, show all (already filtered to tenant properties)
    if (!selectedPropertyId) return billsByMonth;

    // Further filter by selected property
    return billsByMonth
      .map(month => ({
        ...month,
        bills: month.bills.filter(bill => bill.property_id === selectedPropertyId),
        totalAmount: month.bills
          .filter(bill => bill.property_id === selectedPropertyId)
          .reduce((sum, bill) => sum + bill.amount, 0),
        unpaidAmount: month.bills
          .filter(bill => bill.property_id === selectedPropertyId && !bill.isPaid)
          .reduce((sum, bill) => sum + bill.amount, 0),
        allPaid: month.bills
          .filter(bill => bill.property_id === selectedPropertyId)
          .every(bill => bill.isPaid)
      }))
      .filter(month => month.bills.length > 0); // Remove months with no bills after filtering
  }, [billsByMonth, selectedPropertyId]);

  const toggleProperty = (monthKey: string, propertyId: string) => {
    const compositeKey = `${monthKey}-${propertyId}`;

    if (openPropertyKeys.includes(compositeKey)) {
      // If already open, close it
      setOpenPropertyKeys(openPropertyKeys.filter(key => key !== compositeKey));
    } else {
      // If closed, open it 
      setOpenPropertyKeys([...openPropertyKeys, compositeKey]);
    }
  };

  const handlePayBill = async (billId: string) => {
    try {
      await payBill(billId);
      toast({
        title: t('bills.payment_successful'),
        description: t('bills.payment_successful_description'),
      });
      refetch();
    } catch (error) {
      toast({
        title: t('bills.payment_failed'),
        description: t('bills.payment_failed_description'),
        variant: "destructive"
      });
    }
  };

  const handlePayAllBills = (monthKey: string) => {
    const month = filteredBillsByMonth.find(m => m.monthKey === monthKey);
    if (!month) return;

    // Pay all unpaid bills for this month
    const unpaidBills = month.bills.filter(bill => !bill.isPaid);
    if (unpaidBills.length === 0) return;

    toast({
      title: t('bills.processing_payments'),
      description: t('bills.processing_month_payments', {
        count: unpaidBills.length,
        month: month.displayName
      }),
    });

    // In a real app, you might want to batch these or use a dedicated API endpoint
    Promise.all(unpaidBills.map(bill => payBill(bill.id)))
      .then(() => {
        toast({
          title: t('bills.all_bills_paid'),
          description: t('bills.all_payments_processed'),
        });
        refetch();
      })
      .catch(() => {
        toast({
          title: t('common.error'),
          description: t('bills.payment_error'),
          variant: "destructive"
        });
      });
  };

  if (isLoading) {
    return <BillsLoadingState />;
  }

  return (
    <div className="space-y-6">
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onApply={handleDateRangeChange}
      />

      {filteredBillsByMonth.length > 0 ? (
        filteredBillsByMonth.map(monthGroup => (
          <TenantMonthBillsCard
            key={monthGroup.monthKey}
            monthKey={monthGroup.monthKey}
            displayName={monthGroup.displayName}
            billsCount={monthGroup.bills.length}
            paidBillsCount={monthGroup.bills.filter(b => b.isPaid).length}
            unpaidAmount={monthGroup.unpaidAmount}
            allPaid={monthGroup.allPaid}
            isPayingBill={isPayingBill}
            onPayAllBills={() => handlePayAllBills(monthGroup.monthKey)}
          >
            {/* Group by property */}
            {(() => {
              // Get properties for this month
              const propertyIds = [...new Set(monthGroup.bills.map(bill => bill.property_id))];
              const monthPropertyGroups = propertyIds.map(propId => {
                const propertyBills = monthGroup.bills.filter(bill => bill.property_id === propId);
                const property = properties[propId];

                return {
                  propertyId: propId,
                  propertyAddress: property
                    ? `${property.street} ${property.building}`
                    : propertyBills[0]?.propertyAddress || t('properties.toast.unknown_property'),
                  bills: propertyBills,
                  totalAmount: propertyBills.reduce((sum, bill) => sum + bill.amount, 0),
                  allPaid: propertyBills.every(bill => bill.isPaid)
                };
              });

              return monthPropertyGroups.map(propertyGroup => (
                <TenantPropertyBillsGroup
                  key={`${monthGroup.monthKey}-${propertyGroup.propertyId}`}
                  monthKey={monthGroup.monthKey}
                  propertyId={propertyGroup.propertyId}
                  propertyAddress={propertyGroup.propertyAddress}
                  bills={propertyGroup.bills}
                  totalAmount={propertyGroup.totalAmount}
                  allPaid={propertyGroup.allPaid}
                  isOpen={openPropertyKeys.includes(`${monthGroup.monthKey}-${propertyGroup.propertyId}`)}
                  isPayingBill={isPayingBill}
                  onToggle={() => toggleProperty(monthGroup.monthKey, propertyGroup.propertyId)}
                  onPayBill={handlePayBill}
                />
              ));
            })()}
          </TenantMonthBillsCard>
        ))
      ) : (
        <BillsEmptyState
          title={t('bills.no_bills')}
          description={t('bills.no_bills_for_period') +
            (selectedPropertyId ? t('bills.for_selected_property') : '')}
          isFilterActive={!!selectedPropertyId}
        />
      )}
    </div>
  );
};

export default HistoryBillsTabTenant;