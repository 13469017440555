import { gql } from "graphql-request";

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserRole(
  $id: UUID! 
  $first_name: String
  $last_name: String
  $email: String
  $phone: String
  $role: String
  $image_url: String
  $updated_at: String!
  ) {
    updateprofilesCollection(
      set: { 
      role: $role
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
      image_url: $image_url
      updated_at: $updated_at
       }
      filter: { id: { eq: $id } }
    ) {
      records {
        id
        role
        first_name
        last_name
        email
        phone
        image_url
        updated_at
        created_at
      }
    }
  }
`;
