import React, { useState, useMemo } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { AlertCircle, CheckCircle, Droplets, Flame, House, Wifi, Zap, ChevronDown, ChevronUp, Calendar } from 'lucide-react';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { format, parseISO, isThisMonth, isAfter, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface UtilityBillsCardProps {
  billsByType: Record<string, CombinedBill[]>;
  formatDateFn?: (dateString: string) => string;
}

const UtilityBillsCard: React.FC<UtilityBillsCardProps> = ({
  billsByType,
  formatDateFn
}) => {
  const { t, i18n } = useTranslation();
  const [openMonth, setOpenMonth] = useState<string | null>(null);

  // Format date function that uses either provided formatter or i18n
  const formatDateString = (dateString: string) => {
    if (formatDateFn) {
      return formatDateFn(dateString);
    }
    return formatDate(dateString, i18n.language);
  };

  // Group bills by month
  const billsByMonth = useMemo(() => {
    const grouped: Record<string, {
      monthKey: string;
      displayName: string;
      bills: CombinedBill[];
      totalAmount: number;
      unpaidAmount: number;
      isPastDue: boolean;
    }> = {};

    // Process all bills across all types
    Object.values(billsByType).flat().forEach(bill => {
      try {
        const date = parseISO(bill.dueDate);
        if (isNaN(date.getTime())) return;

        const monthKey = format(date, 'yyyy-MM');
        const displayName = format(date, 'MMMM yyyy');

        if (!grouped[monthKey]) {
          grouped[monthKey] = {
            monthKey,
            displayName,
            bills: [],
            totalAmount: 0,
            unpaidAmount: 0,
            isPastDue: !bill.isPaid && isAfter(new Date(), date)
          };
        }

        grouped[monthKey].bills.push(bill);
        grouped[monthKey].totalAmount += bill.amount;

        if (!bill.isPaid) {
          grouped[monthKey].unpaidAmount += bill.amount;
        }
      } catch (error) {
        console.error('Error processing bill date:', error);
      }
    });

    // Sort by month (newest first)
    return Object.values(grouped).sort((a, b) =>
      b.monthKey.localeCompare(a.monthKey)
    );
  }, [billsByType]);

  // Show only recent months (current month + previous month by default)
  const recentMonths = useMemo(() => {
    return billsByMonth.slice(0, 2); // Show up to 2 most recent months
  }, [billsByMonth]);

  // Check if we have older bills that aren't shown
  const hasOlderBills = billsByMonth.length > recentMonths.length;

  // Get the appropriate icon for bill type
  const getIconForBillType = (type: string) => {
    switch (type) {
      case 'water':
        return <Droplets className="size-4 shrink-0 mr-1 text-blue-500" />;
      case 'electricity':
        return <Zap className="size-4 shrink-0 mr-1 text-yellow-500" />;
      case 'gas':
        return <Flame className="size-4 shrink-0 mr-1 text-orange-500" />;
      case 'internet':
        return <Wifi className="size-4 shrink-0 mr-1 text-indigo-500" />;
      case 'rent':
        return <House className="size-4 shrink-0 mr-1 text-purple-500" />;
      default:
        return null;
    }
  };

  // Get translated bill type
  const getTranslatedBillType = (type: string) => {
    return t(`bills.types.${type}`);
  };

  const toggleMonth = (monthKey: string) => {
    setOpenMonth(openMonth === monthKey ? null : monthKey);
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-slate-50 to-gray-50 pb-4">
        <CardTitle>{t('properties.utility_bills')}</CardTitle>
        <CardDescription>{t('bills.recent_bills')}</CardDescription>
      </CardHeader>
      <CardContent className="pt-4">
        {billsByMonth.length === 0 ? (
          <div className="text-center py-6">
            <div className="mx-auto h-12 w-12 rounded-full bg-muted flex items-center justify-center mb-3">
              <AlertCircle className="h-6 w-6 text-muted-foreground" />
            </div>
            <p className="text-muted-foreground">{t('bills.no_bills')}</p>
          </div>
        ) : (
          <div className="space-y-4">
            {/* Recent bills by month */}
            {recentMonths.map((monthData) => (
              <Collapsible
                key={monthData.monthKey}
                open={openMonth === monthData.monthKey}
                onOpenChange={() => toggleMonth(monthData.monthKey)}
                className="border rounded-lg overflow-hidden"
              >
                <CollapsibleTrigger asChild>
                  <div className="flex items-center justify-between p-3 bg-muted/30 border-b cursor-pointer hover:bg-muted/40">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-indigo-600" />
                      <h3 className="text-sm font-medium">{monthData.displayName}</h3>
                      {monthData.unpaidAmount > 0 && (
                        <span className="bg-red-100 text-red-800 text-xs px-2 py-0.5 rounded-full">
                          {formatCurrency(monthData.unpaidAmount, i18n.language)} {t('bills.unpaid').toLowerCase()}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center gap-3">
                      <span className="text-sm font-medium">
                        {formatCurrency(monthData.totalAmount, i18n.language)}
                      </span>
                      {openMonth === monthData.monthKey ?
                        <ChevronUp className="h-4 w-4 text-gray-500" /> :
                        <ChevronDown className="h-4 w-4 text-gray-500" />
                      }
                    </div>
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className="divide-y">
                    {/* Group bills by type within month */}
                    {Object.entries(
                      monthData.bills.reduce((acc, bill) => {
                        const type = bill.type === 'utility' ? bill.utilityType || 'other' : bill.type;
                        if (!acc[type]) acc[type] = [];
                        acc[type].push(bill);
                        return acc;
                      }, {} as Record<string, CombinedBill[]>)
                    ).map(([type, bills]) => (
                      <div key={`${monthData.monthKey}-${type}`} className="p-2">
                        <div className="px-2 py-1 mb-1">
                          <h4 className="text-xs font-medium flex items-center text-gray-500">
                            {getIconForBillType(type)}
                            {getTranslatedBillType(type)}
                          </h4>
                        </div>
                        <div className="space-y-1">
                          {bills.map(bill => (
                            <div
                              key={bill.id}
                              className="p-2 flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2 hover:bg-muted/10 transition-colors rounded-md"
                            >
                              <div>
                                <p className="text-sm font-medium">{bill.description}</p>
                                <p className="text-xs text-muted-foreground">
                                  {t('bills.due_date')}: {formatDateString(bill.dueDate)}
                                </p>
                              </div>
                              <div className="flex items-center gap-3">
                                <span className="text-sm font-medium">
                                  {formatCurrency(bill.amount, i18n.language)}
                                </span>
                                {bill.isPaid ? (
                                  <span className="inline-flex items-center text-green-600 text-xs bg-green-50 px-2 py-0.5 rounded-full">
                                    <CheckCircle className="size-3 mr-1" /> {t('bills.paid')}
                                  </span>
                                ) : (
                                  <span className="inline-flex items-center text-amber-600 text-xs bg-amber-50 px-2 py-0.5 rounded-full">
                                    <AlertCircle className="size-3 mr-1" /> {t('bills.unpaid')}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            ))}

            {/* Link to view all bills if there are older ones */}
            {hasOlderBills && (
              <div className="text-center pt-2">
                <Button
                  variant="link"
                  className="text-sm"
                  onClick={() => {/* Navigate to bills page */ }}
                >
                  {t('bills.view_all_history')}
                </Button>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default UtilityBillsCard;