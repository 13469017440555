import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, parseISO } from 'date-fns';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Rent, Currency, UnitTerm } from '@/types';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface RentUpdateDialogProps {
    rent: Rent;
    open: boolean;
    onClose: () => void;
    onSubmit: (editedRent: Rent) => void
}

export const RentUpdateDialog: React.FC<RentUpdateDialogProps> = ({
    rent,
    open,
    onClose,
    onSubmit
}) => {
    const { t, i18n } = useTranslation();
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    // Create a stable reference to the initial values
    const rentRef = React.useRef(rent);

    // Define form schema with translations
    const formSchema = z.object({
        date_start: z.string().min(1, { message: t('invitation.form.start_date_required') }),
        date_end: z.string().min(1, { message: t('invitation.form.end_date_required') }),
        price_rent: z.coerce.number().min(1, { message: t('invitation.form.price_required') }),
        currency: z.enum(['usd', 'uah'] as const),
        unit: z.enum(['day', 'week', 'month', 'year'] as const),
    });

    type FormValues = z.infer<typeof formSchema>;

    // Format the initial values
    const initialValues = {
        date_start: format(parseISO(rent.date_start), 'yyyy-MM-dd'),
        date_end: format(parseISO(rent.date_end), 'yyyy-MM-dd'),
        price_rent: rent.price_rent,
        currency: rent.currency,
        unit: rent.unit,
    };

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: initialValues,
        mode: 'onChange'
    });

    // Watch for form changes
    useEffect(() => {
        const subscription = form.watch(() => {
            // Compare current values with initial values
            const formValues = form.getValues();
            const isChanged =
                formValues.date_start !== initialValues.date_start ||
                formValues.date_end !== initialValues.date_end ||
                formValues.price_rent !== initialValues.price_rent ||
                formValues.currency !== initialValues.currency ||
                formValues.unit !== initialValues.unit;

            setHasChanges(isChanged);
        });

        return () => subscription.unsubscribe();
    }, [form, initialValues]);

    // Reset form when dialog opens
    useEffect(() => {
        if (open) {
            rentRef.current = rent;
        }
    }, [open, rent]);

    // Reset form when dialog opens - with stable dependencies
    useEffect(() => {
        if (open) {
            form.reset({
                date_start: format(parseISO(rentRef.current.date_start), 'yyyy-MM-dd'),
                date_end: format(parseISO(rentRef.current.date_end), 'yyyy-MM-dd'),
                price_rent: rentRef.current.price_rent,
                currency: rentRef.current.currency,
                unit: rentRef.current.unit,
            });
            setHasChanges(false);
        }
    }, [open, form]);

    const handleOnSubmit = (values: FormValues) => {
        // Convert form dates back to ISO strings for the API
        const updatedRent: Rent = {
            ...rent,
            date_start: new Date(values.date_start).toISOString(),
            date_end: new Date(values.date_end).toISOString(),
            price_rent: values.price_rent,
            currency: values.currency as Currency,
            unit: values.unit as UnitTerm
        }
        onSubmit(updatedRent);
        onClose();
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle>{t('bookings.update_rent_agreement')}</DialogTitle>
                    <DialogDescription>
                        {t('bookings.update_rent_description')}
                    </DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <FormField
                                control={form.control}
                                name="date_start"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('invitation.form.start_date')}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="date"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="date_end"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('invitation.form.end_date')}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="date"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <FormField
                                control={form.control}
                                name="price_rent"
                                render={({ field: { value, onChange, ...fieldProps } }) => (
                                    <FormItem>
                                        <FormLabel>{t('properties.price')}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="number"
                                                value={value}
                                                onChange={(e) => {
                                                    const numValue = e.target.valueAsNumber;
                                                    onChange(isNaN(numValue) ? 0 : numValue);
                                                }}
                                                {...fieldProps}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="currency"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('properties.currency')}</FormLabel>
                                        <Select
                                            onValueChange={field.onChange}
                                            value={field.value}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={t('properties.select_currency')} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value="usd">{t('currencies.usd')}</SelectItem>
                                                <SelectItem value="uah">{t('currencies.uah')}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="unit"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('invitation.form.unit')}</FormLabel>
                                        <Select
                                            onValueChange={field.onChange}
                                            value={field.value}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={t('invitation.form.select_unit')} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value="day">{t('properties.terms.day')}</SelectItem>
                                                <SelectItem value="week">{t('properties.terms.week')}</SelectItem>
                                                <SelectItem value="month">{t('properties.terms.month')}</SelectItem>
                                                <SelectItem value="year">{t('properties.terms.year')}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <DialogFooter>
                            <Button type="button" variant="outline" onClick={onClose}>
                                {t('common.cancel')}
                            </Button>
                            <Button type="submit" disabled={!hasChanges}>
                                {t('bookings.save_changes')}
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};