import { UserProfile, UserRole } from "@/types";

// Query key factory
export const queryKeys = {
    userData: (userId: string) => ['userData', userId],
    userProfile: (email: string, userId?: string | null) => ['userProfile', email, userId],
    user: (userId: string) => ['user', userId],
    property: (propertyId: string) => ['property', propertyId],
    rent: (rentId: string) => ['rent', rentId],
    userProperties: (userId: string, role: UserProfile) => ['userProperties', userId, role],
    propertyRents: (propertyId: string) => ['propertyRents', propertyId],
    archivedRents: (userId: string, role: UserRole) => ['archivedRents', userId, role],
    // userRepairs: (userId: string, role: 'lessor' | 'tenant') => ['userRepairs', userId, role]
    // Add more as needed
};