import React from 'react';
import { Button } from '@/components/ui/button';
import { CheckCircle } from 'lucide-react';
import { parseISO, isFuture } from 'date-fns';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { BillTypeIcon } from './BillTypeIcon';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface BillItemProps {
    bill: CombinedBill;
    onPayBill: (billId: string) => void;
    isPayingBill: boolean;
    showOverdueStatus?: boolean;
}

export const BillItem: React.FC<BillItemProps> = ({
    bill,
    onPayBill,
    isPayingBill,
    showOverdueStatus = false
}) => {
    const { t, i18n } = useTranslation();
    const isUnpaid = !bill.isPaid;

    return (
        <div
            className={`flex flex-col sm:flex-row gap-3 sm:items-center justify-between p-3 rounded-lg border ${isUnpaid ? 'border-red-100 bg-red-50' : ''
                }`}
        >
            <div className="flex items-start sm:items-center gap-3">
                <div className="pt-1 sm:pt-0">
                    <BillTypeIcon bill={bill} />
                </div>
                <div>
                    <p className="font-medium">{bill.description}</p>
                    <p className="text-sm text-muted-foreground">
                        {t('bills.due_date')}: {formatDate(bill.dueDate, i18n.language)}
                        {isUnpaid && showOverdueStatus && (
                            isFuture(parseISO(bill.dueDate))
                                ? ` (${t('bills.upcoming')})`
                                : ` (${t('bills.overdue')})`
                        )}
                    </p>
                </div>
            </div>
            <div className="flex items-center justify-between sm:justify-end gap-3 mt-2 sm:mt-0">
                <span className="font-semibold">{bill.currency === 'uah' ? '₴' : '$'}{bill.amount.toFixed(2)}</span>
                {isUnpaid ? (
                    <Button
                        size="sm"
                        onClick={(e) => {
                            // Stop event propagation to prevent issues with parent collapsible
                            if (e) e.stopPropagation();
                            onPayBill(bill.id);
                        }}
                        disabled={isPayingBill}
                    >
                        {t('bills.pay_now')}
                    </Button>
                ) : (
                    <span className="space-x-2 inline-flex items-center text-green-600 text-sm whitespace-nowrap">
                        <CheckCircle className="h-4 w-4 mr-1 flex-shrink-0" /> {i18n.language === 'en' ? t('bills.paid_on') : t('bills.paid')}
                        {bill.paidDate && (
                            <span className="hidden sm:inline">
                                {formatDate(bill.paidDate, i18n.language)}</span>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};