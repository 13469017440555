import { gql } from "graphql-request";

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem(
    $id: UUID!,
    $name: String,
    $note: String,
    $status: String,
    $updated_at: String!
  ) {
    updateinventory_itemsCollection(
      set: {
        name: $name,
        note: $note,
        status: $status,
        updated_at: $updated_at
      },
      filter: { id: { eq: $id } }
    ) {
      records {
        id
        checklist_id
        name
        note
        status
        created_at
        updated_at
      }
    }
  }
`;
