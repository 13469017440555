// NoPropertiesCard.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Building, Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface NoPropertiesCardProps {
    onAddProperty: () => void;
}

export const NoPropertiesCard: React.FC<NoPropertiesCardProps> = ({ onAddProperty }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent className="p-6 text-center">
                <Building className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                <h3 className="text-lg font-medium mb-2">{t('dashboard.no_properties_yet')}</h3>
                <p className="text-muted-foreground mb-4">{t('dashboard.start_adding_properties')}</p>
                <Button onClick={onAddProperty}>
                    <Plus className="mr-2 h-4 w-4" /> {t('dashboard.add_first_property')}
                </Button>
            </CardContent>
        </Card>
    );
};