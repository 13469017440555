import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Building, Home, DollarSign, Wrench, Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { useUserData } from '@/lib/query-hooks/useUserData';
import {
  useActiveRoute,
  useMaintenanceRequestsCount,
  usePendingInvitationsCount,
  useNewBookingsCount
} from '@/utils/nav-utils';
import NotificationBadge from '@/components/nav/NotificationBadge';

export const BottomNav: React.FC = () => {
  const { user, role } = useAuth();
  const { t } = useTranslation();
  const isActive = useActiveRoute();
  const { data: userData } = useUserData(user?.id || '');

  // Use our utility hooks for notification counts
  const newMaintenanceRequests = useMaintenanceRequestsCount(userData, user?.id, role);
  const pendingInvitationsCount = usePendingInvitationsCount(userData, user?.id);
  const newBookings = useNewBookingsCount(userData, user?.id);

  // If no authenticated user, don't show the navigation
  if (!user || !role) {
    return null;
  }

  return (
    <nav className="fixed bottom-0 left-0 w-full bg-background/90 backdrop-blur-md border-t py-2 px-4 z-50 lg:hidden shadow-md">
      <div className={`grid ${role === 'tenant' ? "grid-cols-4" : 'grid-cols-5'}  gap-2 items-center max-w-screen-xl mx-auto`}>
        <Link to="/dashboard" className="flex flex-col items-center">
          <div className={cn(
            "flex flex-col w-full items-center justify-center p-2 rounded-lg transition-all",
            isActive('/dashboard')
              ? "bg-gradient-to-t from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
              : "hover:bg-gradient-to-t  from-transparent to-purple-500/10 shadow-none"
          )}>
            <div className={cn(
              "p-2 rounded-md mb-1",
              isActive('/dashboard')
                ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white "
                : "bg-muted"
            )}>
              <Home className="h-4 w-4" />
            </div>
            <span className={cn(
              "text-xs font-medium",
              isActive('/dashboard') ? "text-primary" : "text-muted-foreground"
            )}>
              {t('navigation.home')}
            </span>
          </div>
        </Link>

        {role === 'lessor' ? (
          <Link to="/properties" className="flex flex-col items-center">
            <div className={cn(
              "flex flex-col w-full items-center justify-center p-2 rounded-lg transition-all",
              isActive('/properties')
                ? "bg-gradient-to-t from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                : "hover:bg-gradient-to-t  from-transparent to-purple-500/10 shadow-none"
            )}>
              <div className={cn(
                "p-2 rounded-md mb-1 relative",
                isActive('/properties')
                  ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                  : "bg-muted"
              )}>
                <Building className="h-4 w-4" />
              </div>
              <span className={cn(
                "text-xs font-medium",
                isActive('/properties') ? "text-primary" : "text-muted-foreground"
              )}>
                {t('navigation.properties')}
              </span>
            </div>
          </Link>
        ) : (
          <Link to="/tenant/properties" className="flex flex-col items-center">
            <div className={cn(
              "flex flex-col w-full items-center justify-center p-2 rounded-lg transition-all",
              isActive('/tenant/properties')
                ? "bg-gradient-to-t from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                : "hover:bg-gradient-to-t  from-transparent to-purple-500/10 shadow-none"
            )}>
              <div className={cn(
                "p-2 rounded-md mb-1 relative",
                isActive('/tenant/properties')
                  ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                  : "bg-muted"
              )}>
                <Building className="h-4 w-4" />
                <NotificationBadge count={pendingInvitationsCount} />
              </div>
              <span className={cn(
                "text-xs font-medium",
                isActive('/tenant/properties') ? "text-primary" : "text-muted-foreground"
              )}>
                {t('navigation.rentals')}
              </span>
            </div>
          </Link>
        )}

        <Link to="/bills" className="flex flex-col items-center">
          <div className={cn(
            "flex flex-col w-full items-center justify-center p-2 rounded-lg transition-all",
            isActive('/bills')
              ? "bg-gradient-to-t from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
              : "hover:bg-gradient-to-t  from-transparent to-purple-500/10 shadow-none"
          )}>
            <div className={cn(
              "p-2 rounded-md mb-1 relative",
              isActive('/bills')
                ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                : "bg-muted"
            )}>
              <DollarSign className="h-4 w-4" />
            </div>
            <span className={cn(
              "text-xs font-medium",
              isActive('/bills') ? "text-primary" : "text-muted-foreground"
            )}>
              {t('navigation.bills')}
            </span>
          </div>
        </Link>

        {role === 'lessor' && (
          <Link to="/bookings" className="flex flex-col items-center">
            <div className={cn(
              "flex flex-col w-full items-center justify-center p-2 rounded-lg transition-all",
              isActive('/bookings')
                ? "bg-gradient-to-t from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                : "hover:bg-gradient-to-t  from-transparent to-purple-500/10 shadow-none"
            )}>
              <div className={cn(
                "p-2 rounded-md mb-1 relative",
                isActive('/bookings')
                  ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                  : "bg-muted"
              )}>
                <Calendar className="h-4 w-4" />
                <NotificationBadge count={newBookings} />
              </div>
              <span className={cn(
                "text-xs font-medium",
                isActive('/bookings') ? "text-primary" : "text-muted-foreground"
              )}>
                {t('navigation.bookings')}
              </span>
            </div>
          </Link>
        )}

        <Link to="/maintenance" className="flex flex-col items-center">
          <div className={cn(
            "flex flex-col w-full items-center justify-center p-2 rounded-lg transition-all",
            isActive('/maintenance')
              ? "bg-gradient-to-t from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
              : "hover:bg-gradient-to-t  from-transparent to-purple-500/10 shadow-none"
          )}>
            <div className={cn(
              "p-2 rounded-md mb-1 relative",
              isActive('/maintenance')
                ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                : "bg-muted"
            )}>
              <Wrench className="h-4 w-4" />
              <NotificationBadge count={newMaintenanceRequests} />
            </div>
            <span className={cn(
              "text-xs font-medium",
              isActive('/maintenance') ? "text-primary" : "text-muted-foreground"
            )}>
              {t('navigation.repairs')}
            </span>
          </div>
        </Link>
      </div>
    </nav>
  );
};