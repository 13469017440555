import { Repair } from "@/types";
import { UpdateRepairInput } from "@/types/mutation/mutation-input-types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateRepairRequest } from "@/lib/actions/update-repair";

// React Query Mutation Hook
interface RepairUpdateOptions {
    onSuccess?: (repair: Repair) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
}

export function useUpdateRepair(options: RepairUpdateOptions) {
    console.log('Update Repair Request Hook triggered');
    const queryClient = useQueryClient();
    if (!options) {
        throw new Error('options is required');
    }
    const mutation = useMutation<Repair, Error, Repair, MutationContext>({
        mutationFn: (input) => {
            const { created_at, ...restInput } = input;
            return updateRepairRequest(restInput);
        },

        // Add optimistic update
        onMutate: async (input) => {

            if (!input) {
                throw new Error('input data is required');
            }
            if (!input.id) {
                throw new Error('repair ID is required');
            }

            const queryKey = ['userData', options.userId];
            //console.log("onMutate triggered", queryKey);
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);
            //console.log('Previous data:', previousData);
            if (previousData) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;
                    // Update the repair in the cache
                    if (newData.repairs[input.id]) {
                        const now = new Date().toISOString();
                        newData.repairs[input.id] = {
                            ...input,
                            updated_at: now
                        };

                        // Handle property change if needed
                        const oldRepair = old.repairs[input.id];
                        if (oldRepair && oldRepair.property_id !== input.property_id) {
                            // Remove from old property's repairs
                            if (oldRepair.property_id && newData.repairsByProperty[oldRepair.property_id]) {
                                newData.repairsByProperty[oldRepair.property_id] = newData.repairsByProperty[oldRepair.property_id]
                                    .filter(id => id !== input.id);
                            }

                            // Add to new property's repairs
                            if (input.property_id) {
                                if (!newData.repairsByProperty[input.property_id]) {
                                    newData.repairsByProperty[input.property_id] = [];
                                }
                                if (!newData.repairsByProperty[input.property_id].includes(input.id)) {
                                    newData.repairsByProperty[input.property_id].push(input.id);
                                }
                            }
                        }

                        // Handle tenant change if needed
                        if (oldRepair && oldRepair.tenant_id !== input.tenant_id) {
                            // Remove from old tenant's repairs
                            if (oldRepair.tenant_id && newData.repairsByTenant[oldRepair.tenant_id]) {
                                newData.repairsByTenant[oldRepair.tenant_id] = newData.repairsByTenant[oldRepair.tenant_id]
                                    .filter(id => id !== input.id);
                            }

                            // Add to new tenant's repairs
                            if (input.tenant_id) {
                                if (!newData.repairsByTenant[input.tenant_id]) {
                                    newData.repairsByTenant[input.tenant_id] = [];
                                }
                                if (!newData.repairsByTenant[input.tenant_id].includes(input.id)) {
                                    newData.repairsByTenant[input.tenant_id].push(input.id);
                                }
                            }
                        }
                    }

                    return newData;
                });
            }

            return { previousData };
        },

        onError: (error, _newData, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }
            console.error('Error updating User Role', error);
            options?.onError?.(error);
        },

        onSuccess: (updatedRepair) => {
            const queryKey = ['userData', options.userId];

            // Update the cache with the real data from the server
            // queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
            //     if (!old) return old;

            //     const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

            //     // Replace repair with the updated one from the server
            //     newData.repairs[updatedRepair.id] = updatedRepair;

            //     return newData;
            // });

            options?.onSuccess?.(updatedRepair);
        },
        retry: false
    });

    return {
        updateRepair: mutation.mutate,
        updateRepairAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}