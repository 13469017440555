import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { CheckCircle2, AlertCircle, Loader, LogIn } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Progress } from '@/components/ui/progress';
import { supabase } from '@/lib/supabase';

// States for the verification process
type VerificationStatus = 'verifying' | 'success' | 'error';

const EmailChangeConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [status, setStatus] = useState<VerificationStatus>('verifying');
    const [error, setError] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(5);
    const [progress, setProgress] = useState(0);

    // Process the email change verification
    useEffect(() => {
        const verifyEmailChange = async () => {
            try {
                // Check if we have the necessary tokens in the URL
                // Extract hash params (Supabase usually puts them after #)
                const hashParams = new URLSearchParams(location.hash.substring(1));
                const accessToken = hashParams.get('access_token');
                const refreshToken = hashParams.get('refresh_token');
                const type = hashParams.get('type');

                // If not in hash, check query params
                const queryParams = new URLSearchParams(location.search);
                const emailChangeToken = queryParams.get('token') || queryParams.get('email_change_token');

                // Verify based on what we have
                if (accessToken && refreshToken && (type === 'recovery' || type === 'email_change' || type === 'emailChange')) {
                    // Set the session with the tokens
                    const { error } = await supabase.auth.setSession({
                        access_token: accessToken,
                        refresh_token: refreshToken,
                    });

                    if (error) throw error;

                    setStatus('success');
                }
                else if (emailChangeToken) {
                    // Handle token-based verification
                    // This approach depends on Supabase's implementation
                    // May need adjustment based on actual API

                    // Example using session verification
                    const { data, error } = await supabase.auth.getSession();

                    if (error || !data.session) {
                        throw new Error('Unable to verify email change. Please try again.');
                    }

                    setStatus('success');
                }
                else {
                    throw new Error('Invalid verification link. Please request a new one.');
                }
            } catch (error) {
                console.error('Email verification error:', error);
                setStatus('error');
                setError(error instanceof Error ? error.message : 'An error occurred during verification');
            }
        };

        verifyEmailChange();
    }, [location]);

    // Set up countdown and redirect on success
    useEffect(() => {
        if (status === 'success') {
            // Sign out to ensure clean state
            supabase.auth.signOut();

            // Start countdown timer
            const timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        navigate('/auth/login');
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            // Update progress bar more frequently for smoother animation
            const progressTimer = setInterval(() => {
                setProgress(prev => {
                    const newProgress = prev + (100 / (5 * 10)); // 10 updates per second for 5 seconds
                    return newProgress >= 100 ? 100 : newProgress;
                });
            }, 100);

            return () => {
                clearInterval(timer);
                clearInterval(progressTimer);
            };
        }
    }, [status, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-background p-4">
            <Card className="w-full max-w-md">
                <CardHeader className="space-y-1">
                    {status === 'verifying' ? (
                        <>
                            <div className="mx-auto bg-primary/10 p-4 rounded-full mb-4">
                                <Loader className="h-12 w-12 text-primary animate-spin" />
                            </div>
                            <CardTitle className="text-2xl text-center">Verifying Email Change</CardTitle>
                            <CardDescription className="text-center">
                                Please wait while we update your email address...
                            </CardDescription>
                        </>
                    ) : status === 'success' ? (
                        <>
                            <div className="mx-auto bg-green-100 p-4 rounded-full mb-4">
                                <CheckCircle2 className="h-12 w-12 text-green-600" />
                            </div>
                            <CardTitle className="text-2xl text-center">Email Address Updated</CardTitle>
                            <CardDescription className="text-center">
                                Your email address has been successfully changed
                            </CardDescription>
                        </>
                    ) : (
                        <>
                            <div className="mx-auto bg-red-100 p-4 rounded-full mb-4">
                                <AlertCircle className="h-12 w-12 text-red-600" />
                            </div>
                            <CardTitle className="text-2xl text-center">Verification Failed</CardTitle>
                            <CardDescription className="text-center">
                                We were unable to verify your email change
                            </CardDescription>
                        </>
                    )}
                </CardHeader>

                <CardContent className="space-y-6">
                    {status === 'verifying' ? (
                        <p className="text-center text-sm text-muted-foreground">
                            This will only take a moment...
                        </p>
                    ) : status === 'success' ? (
                        <>
                            <Alert className="bg-green-50 border-green-200">
                                <AlertDescription>
                                    You have successfully changed your email address. Please log in with your new email on your next visit.
                                </AlertDescription>
                            </Alert>

                            <div className="space-y-2">
                                <div className="flex justify-between text-sm">
                                    <span className="text-muted-foreground">Redirecting to login page...</span>
                                    <span className="font-medium">{countdown}s</span>
                                </div>
                                <Progress value={progress} className="h-2" />
                            </div>
                        </>
                    ) : (
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>
                                {error || "The verification link is invalid or has expired. Please try again."}
                            </AlertDescription>
                        </Alert>
                    )}
                </CardContent>

                <CardFooter>
                    {status === 'success' ? (
                        <Button
                            className="w-full"
                            onClick={() => navigate('/auth/login')}
                        >
                            <LogIn className="mr-2 h-4 w-4" />
                            Log In Now
                        </Button>
                    ) : status === 'error' ? (
                        <Button
                            className="w-full"
                            onClick={() => navigate('/profile')}
                        >
                            Return to Profile
                        </Button>
                    ) : null}
                </CardFooter>
            </Card>
        </div>
    );
};

export default EmailChangeConfirmation;