"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Rent } from "@/types";
import { UpdateRentInput } from "@/types/mutation/mutation-input-types";
import { UpdateRentResponse } from "@/types/mutation/mutation-responses-types";
import { UPDATE_RENT } from "@/queries-graphql/mutation/update-rent-mutation";

// Function to update an existing Rent record
export const updateRent = async (
    input: UpdateRentInput
): Promise<Rent> => {
    const client = graphqlClient;
    try {
        const variables = { ...input };

        const data = await client.request<UpdateRentResponse>(
            UPDATE_RENT,
            variables
        );
        const response = data.updaterentsCollection.records[0];
        return response;
    } catch (error) {
        console.error('Error updating Rent:', error);
        throw error;
    }
};