import React, { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { toast } from '@/hooks/use-toast';
import { useProperty, usePropertyRents, useUser } from '@/lib/query-hooks/get-hooks';
import { ArrowLeft } from 'lucide-react';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { parseISO, isWithinInterval, format, addDays } from 'date-fns';
import { Rent } from '@/types';
import { useAuth } from '@/contexts/AuthContext';
import { useInviteTenant } from '@/lib/auth-hooks/useInviteTenant';
import { PropertyInfoCard } from '@/components/tenant-invitation/PropertyInfoCard';
import { InvitationForm } from '@/components/tenant-invitation/InvitationForm';
import { useTranslation } from 'react-i18next';

const InviteTenant: React.FC = () => {
  const { id: propertyId } = useParams<{ id: string }>();
  const { user, profile } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const userId = user?.id || '';

  // Get all user data
  const {
    data: userData,
    isLoading: isLoadingUserData,
  } = useUserData(userId);

  // Invite tenant hook
  const {
    inviteTenant,
    isLoading: isInvitingTenant,
  } = useInviteTenant(userId, {
    onSuccess: (rent, userExists) => {
      if (userExists) {
        toast({
          title: t('invitation.toast.invitation_sent'),
          description: t('invitation.toast.invitation_sent_existing'),
        });
      } else {
        toast({
          title: t('invitation.toast.invitation_sent'),
          description: t('invitation.toast.invitation_sent_new'),
        });
      }

      navigate(`/properties/${propertyId}`);
    },
    onError: (error) => {
      toast({
        title: t('invitation.toast.invitation_failed'),
        description: error.message || t('invitation.toast.invitation_error'),
        variant: 'destructive',
      });
      setIsSubmitting(false);
    }
  });

  // Data fetching hooks
  const property = useProperty(propertyId, userId);
  const propertyRents = usePropertyRents(propertyId, userId);

  // Get active and accepted rents to check for date overlaps
  const acceptedRents = useMemo(() => {
    return propertyRents.filter(rent =>
      rent.isAccepted && (rent.status === "active" || rent.status === "pending")
    );
  }, [propertyRents]);

  // Function to check if a date range overlaps with existing rents
  const checkDateOverlap = (startDate: string, endDate: string) => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    return acceptedRents.some(rent => {
      const rentStart = parseISO(rent.date_start);
      const rentEnd = parseISO(rent.date_end);

      // Check if there's an overlap
      return (
        isWithinInterval(start, { start: rentStart, end: rentEnd }) ||
        isWithinInterval(end, { start: rentStart, end: rentEnd }) ||
        (start <= rentStart && end >= rentEnd)
      );
    });
  };

  // Get available date ranges
  const getAvailableDateRanges = () => {
    if (!acceptedRents.length) {
      return [{
        start: format(new Date(), 'yyyy-MM-dd'),
        end: null // No end date limitation
      }];
    }

    // Sort rents by start date
    const sortedRents = [...acceptedRents].sort((a, b) =>
      parseISO(a.date_start).getTime() - parseISO(b.date_start).getTime()
    );

    const ranges = [];
    const today = new Date();

    // Check if there's a gap before the first rent
    const firstRentStart = parseISO(sortedRents[0].date_start);
    if (firstRentStart > today) {
      ranges.push({
        start: format(today, 'yyyy-MM-dd'),
        end: format(addDays(firstRentStart, -1), 'yyyy-MM-dd')
      });
    }

    // Find gaps between rents
    for (let i = 0; i < sortedRents.length - 1; i++) {
      const currentRentEnd = parseISO(sortedRents[i].date_end);
      const nextRentStart = parseISO(sortedRents[i + 1].date_start);

      if (nextRentStart > addDays(currentRentEnd, 1)) {
        ranges.push({
          start: format(addDays(currentRentEnd, 1), 'yyyy-MM-dd'),
          end: format(addDays(nextRentStart, -1), 'yyyy-MM-dd')
        });
      }
    }

    // Check if there's a gap after the last rent
    const lastRentEnd = parseISO(sortedRents[sortedRents.length - 1].date_end);
    ranges.push({
      start: format(addDays(lastRentEnd, 1), 'yyyy-MM-dd'),
      end: null // No end date limitation
    });

    return ranges;
  };

  // Handle invitation submission
  const handleInvite = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    // Check for date overlaps
    const hasOverlap = checkDateOverlap(values.date_start, values.date_end);
    if (hasOverlap) {
      toast({
        title: t('invitation.toast.invalid_date_range'),
        description: t('invitation.toast.date_overlap'),
        variant: 'destructive',
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await inviteTenant({
        email: values.email,
        propertyId: property.id,
        propertyTitle: property.title || `${property.city}, ${property.street} ${property.building}`,
        lessorId: user.id,
        lessorName: profile.first_name,
        managerId: property.manager_id,
        dateStart: values.date_start,
        dateEnd: values.date_end,
        priceRent: values.price_rent,
        currency: values.currency,
        unit: values.unit,
      });
    } catch (error) {
      console.error("Error in invitation process:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isLoading = isLoadingUserData || isSubmitting || isInvitingTenant;
  const availableDateRanges = getAvailableDateRanges();

  // Check if property exists
  if (!property) {
    return (
      <MainLayout>
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">{t('invitation.property_not_found')}</h1>
          <p>{t('invitation.property_not_exist')}</p>

          <Button onClick={() => navigate('/properties')} className="mt-4">
            {t('invitation.back_to_properties')}
          </Button>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-8 pt-2 pb-8">
        <Button
          variant="ghost"
          onClick={() => navigate(`/properties/${propertyId}`)}
          className="mb-4"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('invitation.back_to_property')}
        </Button>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Property Information Card */}
          <div className="w-full md:w-1/3">
            <PropertyInfoCard
              property={property}
              acceptedRents={acceptedRents}
            />
          </div>

          {/* Invitation Form */}
          <div className="w-full md:w-2/3">
            <InvitationForm
              property={property}
              isLoading={isLoading}
              onSubmit={handleInvite}
              availableDateRanges={availableDateRanges}
              checkDateOverlap={checkDateOverlap}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default InviteTenant;