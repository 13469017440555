"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { InventoryItem } from "@/types";
import { UpdateInventoryItemResponse } from "@/types/mutation/checklist-response-types";
import { UPDATE_INVENTORY_ITEM } from "@/queries-graphql/mutation/update-inventory-item-mutation";
import { UpdateInventoryItemInput } from "@/types/mutation/checklist-input-types";

export async function updateInventoryItem(input: UpdateInventoryItemInput): Promise<InventoryItem> {
  const client = graphqlClient;
  try {
    const variables = { ...input }

    const data = await client.request<UpdateInventoryItemResponse>(
      UPDATE_INVENTORY_ITEM,
      variables
    );

    const inventoryItem = data.updateinventory_itemsCollection.records[0];

    if (!inventoryItem) {
      throw new Error("Failed to update inventory item");
    }

    return inventoryItem;
  } catch (error) {
    console.error("Error updating inventory item:", error);
    throw error;
  }
}
