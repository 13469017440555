import React from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { CreditCard, ChevronDown, ChevronUp } from 'lucide-react';
import { StatusBadge } from '../shared/StatusBadge';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/i18n-utils';

interface LessorMonthBillsGroupProps {
    monthKey: string;
    displayName: string;
    isCurrentMonth: boolean;
    totalBills: number;
    totalPaidBills: number;
    totalUnpaidBills: number;
    unpaidAmount: number;
    isOpen: boolean;
    isPayingBill: boolean;
    onToggle: () => void;
    onPayAllBills: () => void;
    children: React.ReactNode;
}

export const LessorMonthBillsGroup: React.FC<LessorMonthBillsGroupProps> = ({
    monthKey,
    displayName,
    isCurrentMonth,
    totalBills,
    totalPaidBills,
    totalUnpaidBills,
    unpaidAmount,
    isOpen,
    isPayingBill,
    onToggle,
    onPayAllBills,
    children
}) => {
    const { t, i18n } = useTranslation();
    const hasUnpaidBills = totalUnpaidBills > 0;

    return (
        <Card key={monthKey}>
            <CardHeader className="px-4 py-5 space-y-3 sm:space-y-0 sm:flex sm:flex-row sm:items-center sm:justify-between">
                <div className="space-y-1">
                    <div className="flex flex-wrap items-center gap-2">
                        <CardTitle className="text-base sm:text-lg">{displayName}</CardTitle>
                        {isCurrentMonth && (
                            <StatusBadge variant="current" label={t('bills.status_badges_group.current')} />
                        )}
                        {hasUnpaidBills && (
                            <StatusBadge variant="unpaid" count={totalUnpaidBills} />
                        )}
                    </div>
                    <CardDescription className="text-sm">
                        {t('bills.bill_status_count', {
                            paid: totalPaidBills,
                            total: totalBills,
                            defaultValue: '{{paid}} of {{total}} bills paid'
                        })}
                        {hasUnpaidBills &&
                            ` · ${formatCurrency(unpaidAmount, i18n.language)} ${t('bills.unpaid').toLowerCase()}`
                        }
                    </CardDescription>
                </div>

                <div className="flex items-center gap-2 mt-2 sm:mt-0">
                    {hasUnpaidBills && (
                        <Button
                            size="sm"
                            onClick={onPayAllBills}
                            disabled={isPayingBill}
                            className="flex-shrink-0"
                        >
                            <CreditCard className="h-4 w-4 mr-2" />
                            <span className="whitespace-nowrap">{t('bills.pay_all')}</span>
                        </Button>
                    )}

                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={onToggle}
                        className="ml-auto sm:ml-0"
                        aria-label={isOpen ? t('common.close') : t('common.open')}
                    >
                        {isOpen ?
                            <ChevronUp className="h-4 w-4" /> :
                            <ChevronDown className="h-4 w-4" />
                        }
                    </Button>
                </div>
            </CardHeader>

            {isOpen && (
                <CardContent className="space-y-4">
                    {children}
                </CardContent>
            )}
        </Card>
    );
};