"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Checklist } from "@/types";
import { CreateChecklistResponse } from "@/types/mutation/checklist-response-types";
import { CREATE_CHECKLIST } from "@/queries-graphql/mutation/create-checklist-mutation";
import { CreateChecklistInput } from "@/types/mutation/checklist-input-types";


export async function createChecklist(input: CreateChecklistInput): Promise<Checklist> {
  const client = graphqlClient;
  try {
    const variables = { ...input }

    const data = await client.request<CreateChecklistResponse>(
      CREATE_CHECKLIST,
      variables
    );

    const checklist = data.insertIntochecklistsCollection.records[0];

    if (!checklist) {
      throw new Error("Failed to create checklist");
    }

    return checklist;
  } catch (error) {
    console.error("Error creating checklist:", error);
    throw error;
  }
}
