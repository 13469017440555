import { Repair } from "@/types";
import { UpdateRepairInput } from "@/types/mutation/mutation-input-types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateRepairRequest } from "@/lib/actions/update-repair";
import { CreateRepairInput } from "@/types/mutation/mutation-input-types";
import { createRepairRequest } from "@/lib/actions/create-repair";
import { v4 as uuidv4 } from 'uuid';

// React Query Mutation Hook
interface RepairCreateOptions {
    onSuccess?: (repair: Repair) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
    tempId: string;
}

export function useCreateRepair(options: RepairCreateOptions) {
    console.log('Create Repair Request Hook triggered');
    const queryClient = useQueryClient();
    if (!options) {
        throw new Error('options is required');
    }
    if (!options.userId) {
        throw new Error('user ID is required');
    }
    const mutation = useMutation<Repair, Error, CreateRepairInput, MutationContext>({
        mutationFn: (input) => createRepairRequest(input),

        // Add optimistic update
        onMutate: async (input) => {

            if (!input) {
                throw new Error('input data is required');
            }

            const queryKey = ['userData', options.userId];
            //console.log("onMutate triggered", queryKey);
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);
            //console.log('Previous data:', previousData);

            // Generate a temporary ID for optimistic update
            const tempId = uuidv4();
            const now = new Date().toISOString();

            if (previousData) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                    // Create temporary repair object
                    const tempRepair: Repair = {
                        id: tempId,
                        ...input,
                        created_at: now,
                        updated_at: now
                    };

                    // Add repair to repairs collection
                    newData.repairs[tempId] = tempRepair;

                    // Update relationship maps
                    if (input.property_id) {
                        if (!newData.repairsByProperty[input.property_id]) {
                            newData.repairsByProperty[input.property_id] = [];
                        }
                        newData.repairsByProperty[input.property_id].push(tempId);
                    }

                    if (input.tenant_id) {
                        if (!newData.repairsByTenant[input.tenant_id]) {
                            newData.repairsByTenant[input.tenant_id] = [];
                        }
                        newData.repairsByTenant[input.tenant_id].push(tempId);
                    }

                    return newData;
                });
            }

            return { previousData, tempId };
        },

        onError: (error, _newData, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }
            console.error('Error creating User Role', error);
            options?.onError?.(error);
        },

        onSuccess: (newRepair, variables, context) => {
            const queryKey = ['userData', options.userId];

            // Update the cache with the real data from the server
            queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                if (!old || !context?.tempId) return old;

                const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                // Replace temporary repair with the real one
                delete newData.repairs[context.tempId];
                newData.repairs[newRepair.id] = newRepair;

                // Update relationship maps - replace tempId with real id
                if (variables.property_id) {
                    if (newData.repairsByProperty[variables.property_id]) {
                        const index = newData.repairsByProperty[variables.property_id].indexOf(context.tempId);
                        if (index !== -1) {
                            newData.repairsByProperty[variables.property_id][index] = newRepair.id;
                        }
                    }
                }

                if (variables.tenant_id) {
                    if (newData.repairsByTenant[variables.tenant_id]) {
                        const index = newData.repairsByTenant[variables.tenant_id].indexOf(context.tempId);
                        if (index !== -1) {
                            newData.repairsByTenant[variables.tenant_id][index] = newRepair.id;
                        }
                    }
                }

                return newData;
            });

            options?.onSuccess?.(newRepair);
        },
        retry: false
    });

    return {
        createRepair: mutation.mutate,
        createRepairAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}