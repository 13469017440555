import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useIsMobile } from '@/hooks/use-mobile';
import { GlobeIcon } from 'lucide-react';

interface LanguageSwitcherProps {
    variant?: 'default' | 'outline' | 'ghost';
    size?: 'default' | 'sm' | 'lg' | 'icon';
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    variant = 'outline',
    size = 'icon'
}) => {
    const { i18n, t } = useTranslation();
    const isMobile = useIsMobile();
    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    // Get current language
    const currentLanguage = i18n.language || 'en';

    // Language options
    const languages = [
        { code: 'en', name: t('language.en'), short_name: t('language.en_short') },
        { code: 'uk', name: t('language.uk'), short_name: t('language.uk_short') }
    ];

    // Find current language name for display
    const currentLanguageName = languages.find(lang => lang.code === currentLanguage)?.name || 'English';
    const currentLanguageNameMob = languages.find(lang => lang.code === currentLanguage)?.short_name || 'EN';

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant={variant} size={size} aria-label={t('language.select')}>
                    {size === 'icon' ? (
                        <GlobeIcon className="h-4 w-4" />
                    ) : (
                        <>
                            <GlobeIcon className=" h-4 w-4" />
                            {isMobile ? currentLanguageNameMob : currentLanguageName}
                        </>
                    )}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                {languages.map((language) => (
                    <DropdownMenuItem
                        key={language.code}
                        onClick={() => changeLanguage(language.code)}
                        className={currentLanguage === language.code ? 'bg-muted' : ''}
                    >
                        {isMobile ? language.short_name : language.name}
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default LanguageSwitcher;