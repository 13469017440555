import React, { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { ArrowLeft, MapPin, Info } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import { useCreateProperty } from '@/lib/query-hooks/mutation/useCreateProperty';
import { CreatePropertyHookInput } from '@/types/mutation/mutation-input-types';
import { PropertyStatus } from '@/types';
import { ToastAction } from '@/components/ui/toast';
import { PropertyDetailsForm } from '@/components/properties/PropertyDetailsForm';
import { useTranslation } from 'react-i18next';

const AddProperty: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userId = user?.id ? user.id : '';
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  // Create New Property
  const {
    createPropertyAsync,
    isLoading: newPropertyCreationInProgress,
  } = useCreateProperty({
    userId: userId,
    onSuccess: (property) => {
      toast({
        title: t('properties.toast.property_updated'),
        description: t('properties.toast.property_updated_desc'),
      });
      navigate(`/properties/${property.id}`);
    },
    onError: (error) => {
      toast({
        title: t('properties.toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>,
      });
    },
  });

  const handleCreate = async (formData: CreatePropertyHookInput) => {
    try {
      setIsUploading(true);

      // Set the lessor_id to the current user and status to vacant
      const propertyData: CreatePropertyHookInput = {
        ...formData,
        lessor_id: userId,
        status: 'vacant' as PropertyStatus,
      };

      await createPropertyAsync(propertyData);
    } catch (error) {
      console.error(error);
      toast({
        title: t('properties.toast.error_title'),
        description: t('common.error'),
        variant: 'destructive',
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <MainLayout>
      <div className="max-w-4xl mx-auto space-y-6 pt-2 pb-8">
        <div className="flex items-center justify-between">
          <Button variant="ghost" onClick={() => navigate('/properties')}>
            <ArrowLeft className="mr-2 h-4 w-4" /> {t('navigation.back_to_properties')}
          </Button>
        </div>

        <Card className="border-none shadow-none bg-transparent">
          <CardHeader className="px-0 pt-0">
            <CardTitle className="text-3xl font-bold tracking-tight">{t('properties.add_new_property')}</CardTitle>
            <CardDescription className="text-muted-foreground">
              {t('properties.enter_details')}
            </CardDescription>
          </CardHeader>
          <CardContent className="px-0 pt-0">
            <Card className="mb-6 overflow-hidden">
              <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
                <div className="flex items-center gap-2">
                  <MapPin className="h-5 w-5 text-blue-600" />
                  <div>
                    <CardTitle>{t('properties.property_details')}</CardTitle>
                    <CardDescription>{t('properties.basic_information')}</CardDescription>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="pt-6">
                <div className="flex items-center p-4 mb-6 bg-blue-50 rounded-lg">
                  <Info className="h-5 w-5 text-blue-600 flex-shrink-0 mr-3" />
                  <p className="text-sm text-blue-700">
                    {t('properties.property_info_description')}
                  </p>
                </div>

                <PropertyDetailsForm
                  onSubmit={handleCreate}
                  isLoading={newPropertyCreationInProgress || isUploading}
                />
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </MainLayout>
  );
};

export default AddProperty;