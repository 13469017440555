import React from 'react';
import { Button } from '@/components/ui/button';
import { Plus, SlidersHorizontal } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

interface RequestFilterHeaderProps {
  title: string;
  description: string;
  userRole: UserRole;
  filterStatus: string;
  sortBy: 'date' | 'priority';
  onCreateRequest: () => void;
  onOpenFilters: () => void;
}

export const RequestFilterHeader: React.FC<RequestFilterHeaderProps> = ({
  title,
  description,
  userRole,
  filterStatus,
  sortBy,
  onCreateRequest,
  onOpenFilters
}) => {
  const { t } = useTranslation();

  // Get human-readable status for the filter badge
  const getStatusDisplayName = (status: string): string => {
    if (status === 'all') return t('maintenance.all');
    if (status === 'in_progress') return t('maintenance.statuses.in_progress');
    return t(`maintenance.statuses.${status}`);
  };

  // Get sort method for the sort badge
  const getSortDisplayName = (sort: 'date' | 'priority'): string => {
    return t(`maintenance.${sort}`);
  };

  return (
    <div className="flex flex-col md:flex-row justify-between items-start sm:items-center gap-4">
      <div>
        <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{title}</h1>
        <p className="text-muted-foreground mt-1">{description}</p>
      </div>

      <div className="flex flex-wrap gap-2 items-center w-full sm:w-auto">
        {/* Filter badges to show current selections */}
        <div className="flex flex-wrap gap-2 mr-auto sm:mr-2">
          {filterStatus !== 'all' && (
            <Badge variant="secondary">
              {t('maintenance.status')}: {getStatusDisplayName(filterStatus)}
            </Badge>
          )}
          <Badge variant="secondary">
            {t('maintenance.sort_by')}: {getSortDisplayName(sortBy)}
          </Badge>
        </div>

        {/* Simple buttons for filter and create */}
        <div className="flex gap-2 ml-auto">
          <Button
            variant="outline"
            size="icon"
            onClick={onOpenFilters}
            title={`${t('maintenance.filter_by')} & ${t('maintenance.sort_by')}`}
          >
            <SlidersHorizontal className="h-4 w-4" />
          </Button>

          {userRole === 'tenant' && (
            <Button onClick={onCreateRequest}>
              <Plus className="mr-2 h-4 w-4" /> {t('maintenance.new_request')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};