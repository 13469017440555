// DashboardProperties.tsx
import React from 'react';
import { Button } from '@/components/ui/button';
import { Property } from '@/types';
import { useTranslation } from 'react-i18next';
import {
    NoPropertiesCard,
    DashboardPropertyCard
} from '@/components/lessor-dashboard';

interface DashboardPropertiesProps {
    properties: Property[];
    getTenantName: (property: Property) => string;
    getLeaseStartDate: (property: Property) => string | null;
    onViewDetails: (propertyId: string) => void;
    onInviteTenant: (propertyId: string) => void;
    onViewAllProperties: () => void;
    onAddProperty: () => void;
}

export const DashboardProperties: React.FC<DashboardPropertiesProps> = ({
    properties,
    getTenantName,
    getLeaseStartDate,
    onViewDetails,
    onInviteTenant,
    onViewAllProperties,
    onAddProperty,
}) => {
    const { t } = useTranslation();

    if (properties.length === 0) {
        return <NoPropertiesCard onAddProperty={onAddProperty} />;
    }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {properties.slice(0, 3).map((property) => (
                    <DashboardPropertyCard
                        key={property.id}
                        property={property}
                        tenantName={getTenantName(property)}
                        leaseStartDate={getLeaseStartDate(property)}
                        onViewDetails={onViewDetails}
                        onInviteTenant={onInviteTenant}
                    />
                ))}
            </div>

            {properties.length > 3 && (
                <div className="mt-4 text-center">
                    <Button variant="outline" onClick={onViewAllProperties}>
                        {t('dashboard.view_all_properties')}
                    </Button>
                </div>
            )}
        </>
    );
};