import { Rent } from "@/types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateRent } from "@/lib/actions/update-rent";

// React Query Mutation Hook
interface RentUpdateOptions {
    onSuccess?: (rent: Rent) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
}

export function useUpdateRent(options: RentUpdateOptions) {
    console.log('Update Rent Hook triggered');
    const queryClient = useQueryClient();

    if (!options) {
        throw new Error('options is required');
    }
    if (!options.userId) {
        throw new Error('user ID is required');
    }

    const mutation = useMutation<Rent, Error, Rent, MutationContext>({
        mutationFn: (input) => {
            const { created_at, ...restInput } = input;
            return updateRent(restInput);
        },

        // Add optimistic update
        onMutate: async (input) => {
            if (!input) {
                throw new Error('input data is required');
            }
            if (!input.id) {
                throw new Error('rent ID is required');
            }

            const queryKey = ['userData', options.userId];
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);

            if (previousData) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;
                    // Update the rent in the cache
                    if (newData.rents[input.id]) {
                        const now = new Date().toISOString();
                        const oldRent = old.rents[input.id];

                        // Update the rent object
                        newData.rents[input.id] = {
                            ...input,
                            updated_at: now
                        };

                        // Handle property change if needed
                        if (oldRent && oldRent.property_id !== input.property_id) {
                            // Remove from old property's rents
                            if (oldRent.property_id && newData.propertyRents[oldRent.property_id]) {
                                newData.propertyRents[oldRent.property_id] = newData.propertyRents[oldRent.property_id]
                                    .filter(id => id !== input.id);
                            }

                            // Add to new property's rents
                            if (input.property_id) {
                                if (!newData.propertyRents[input.property_id]) {
                                    newData.propertyRents[input.property_id] = [];
                                }
                                if (!newData.propertyRents[input.property_id].includes(input.id)) {
                                    newData.propertyRents[input.property_id].push(input.id);
                                }
                            }

                            // Update tenant's properties access
                            if (input.tenant_id) {
                                // Remove old property from tenant's properties if no other active rents
                                if (oldRent.property_id &&
                                    !Object.values(newData.rents).some(r =>
                                        r.id !== input.id &&
                                        r.property_id === oldRent.property_id &&
                                        r.tenant_id === input.tenant_id)) {

                                    if (newData.userProperties.asTenant[input.tenant_id]) {
                                        newData.userProperties.asTenant[input.tenant_id] =
                                            newData.userProperties.asTenant[input.tenant_id]
                                                .filter(propId => propId !== oldRent.property_id);
                                    }
                                }

                                // Add new property to tenant's properties
                                if (input.property_id) {
                                    if (!newData.userProperties.asTenant[input.tenant_id]) {
                                        newData.userProperties.asTenant[input.tenant_id] = [];
                                    }
                                    if (!newData.userProperties.asTenant[input.tenant_id].includes(input.property_id)) {
                                        newData.userProperties.asTenant[input.tenant_id].push(input.property_id);
                                    }
                                }
                            }
                        }

                        // Handle tenant change if needed
                        if (oldRent && oldRent.tenant_id !== input.tenant_id) {
                            // Remove from old tenant's rents
                            if (oldRent.tenant_id && newData.userRents.asTenant[oldRent.tenant_id]) {
                                newData.userRents.asTenant[oldRent.tenant_id] = newData.userRents.asTenant[oldRent.tenant_id]
                                    .filter(id => id !== input.id);

                                // Remove property from old tenant's properties if no other active rents
                                if (oldRent.property_id &&
                                    !Object.values(newData.rents).some(r =>
                                        r.id !== input.id &&
                                        r.property_id === oldRent.property_id &&
                                        r.tenant_id === oldRent.tenant_id)) {

                                    if (newData.userProperties.asTenant[oldRent.tenant_id]) {
                                        newData.userProperties.asTenant[oldRent.tenant_id] =
                                            newData.userProperties.asTenant[oldRent.tenant_id]
                                                .filter(propId => propId !== oldRent.property_id);
                                    }
                                }
                            }

                            // Add to new tenant's rents
                            if (input.tenant_id) {
                                if (!newData.userRents.asTenant[input.tenant_id]) {
                                    newData.userRents.asTenant[input.tenant_id] = [];
                                }
                                if (!newData.userRents.asTenant[input.tenant_id].includes(input.id)) {
                                    newData.userRents.asTenant[input.tenant_id].push(input.id);
                                }

                                // Add property to new tenant's properties
                                if (input.property_id) {
                                    if (!newData.userProperties.asTenant[input.tenant_id]) {
                                        newData.userProperties.asTenant[input.tenant_id] = [];
                                    }
                                    if (!newData.userProperties.asTenant[input.tenant_id].includes(input.property_id)) {
                                        newData.userProperties.asTenant[input.tenant_id].push(input.property_id);
                                    }
                                }
                            }
                        }

                        // Handle lessor change if needed
                        if (oldRent && oldRent.lessor_id !== input.lessor_id) {
                            // Remove from old lessor's rents
                            if (oldRent.lessor_id && newData.userRents.asLessor[oldRent.lessor_id]) {
                                newData.userRents.asLessor[oldRent.lessor_id] = newData.userRents.asLessor[oldRent.lessor_id]
                                    .filter(id => id !== input.id);
                            }

                            // Add to new lessor's rents
                            if (input.lessor_id) {
                                if (!newData.userRents.asLessor[input.lessor_id]) {
                                    newData.userRents.asLessor[input.lessor_id] = [];
                                }
                                if (!newData.userRents.asLessor[input.lessor_id].includes(input.id)) {
                                    newData.userRents.asLessor[input.lessor_id].push(input.id);
                                }
                            }
                        }
                    }

                    return newData;
                });
            }

            return { previousData };
        },

        onError: (error, _newData, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }
            console.error('Error updating Rent', error);
            options?.onError?.(error);
        },

        onSuccess: (updatedRent) => {
            options?.onSuccess?.(updatedRent);
        },
        retry: false
    });

    return {
        updateRent: mutation.mutate,
        updateRentAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}