import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "./query-factory";
import { fetchUser } from "../fetch-functions/fetch-user";

// Hook to fetch user data
export function useUserProfile(email: string = '', userId: string | null = null) {
  console.log("Get user profile Hook triggered");
  const query = useQuery({
    queryKey: queryKeys.userProfile(email, userId),
    queryFn: async () => {
      const profile = await fetchUser(email, userId);
      if (!profile) {
        // Return an empty object or default values instead of undefined
        // This prevents the "Query data cannot be undefined" error
        return null;
      }
      return profile;
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!email || !!userId,
    // Add retry logic for new user registration
    retry: 3,
    retryDelay: 1000,
  });

  return {
    ...query,
    refetch: query.refetch,
  }
}