
import { GraphQLClient } from 'graphql-request';

// Create a GraphQL client instance with mock endpoint
const SUPABASE_GRAPHQL_URL = `${import.meta.env.VITE_SUPABASE_URL!}/graphql/v1`;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY!;

// const SUPABASE_GRAPHQL_URL = `${process.env.NEXT_PUBLIC_SUPABASE_URL!}/graphql/v1`;
// const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!;

export const graphqlClient = new GraphQLClient(SUPABASE_GRAPHQL_URL, {
  headers: {
    apiKey: SUPABASE_ANON_KEY,
    //Authorization: `Bearer ${SUPABASE_ANON_KEY}`,
  },
});

// Update the auth token when it changes (not used in mock implementation)
export const updateGraphQLAuth = (token: string | null) => {
  // No-op for mock implementation
};

// Helper function to handle GraphQL responses (returns mock data in this implementation)
export const extractData = <T>(response: any, key: string): T => {
  // For development, return mock data
  console.log(`Mock extractData called for key: ${key}`);
  return {} as T;
};
