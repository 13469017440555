import React from 'react';
import { Rent, UserProfile, Property } from '@/types';
import { BookingTable } from './BookingTable';
import { BookingCard } from './BookingCard';
import { useIsMobile } from '@/hooks/use-mobile';
import { Archive } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface BookingsViewProps {
  rents: Rent[];
  getPropertyDetails: (propertyId: string) => Property | null;
  getTenantDetails: (tenantId: string) => UserProfile | null;
  showArchived: boolean;
  onEdit: (rent: Rent) => void;
  onCancel: (rent: Rent) => void;
  isLoading: boolean;
  formatDateFn?: (dateString: string) => string;
}

export const BookingsView: React.FC<BookingsViewProps> = ({
  rents,
  getPropertyDetails,
  getTenantDetails,
  showArchived,
  onEdit,
  onCancel,
  isLoading,
  formatDateFn
}) => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();

  // Since the child components already use the formatDate utility,
  // we don't need to pass the formatDateFn to them

  if (isLoading) {
    return (
      <div className="py-24 text-center">
        <div className="inline-flex h-10 w-10 animate-spin items-center justify-center rounded-full border-2 border-primary border-t-transparent"></div>
        <h3 className="mt-4 text-lg font-semibold">{t('bookings.loading_rentals', 'Loading rentals...')}</h3>
      </div>
    );
  }

  if (rents.length === 0) {
    return (
      <div className="py-24 text-center">
        <div className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-muted">
          <Archive className="h-5 w-5 text-muted-foreground" />
        </div>
        <h3 className="mt-4 text-lg font-semibold">{t('bookings.no_rentals_found', 'No rentals found')}</h3>
        <p className="mt-2 text-sm text-muted-foreground">
          {showArchived
            ? t('bookings.no_archived_rentals', "No archived rentals match your filters.")
            : t('bookings.no_active_rentals', "No active rentals match your filters.")}
        </p>
      </div>
    );
  }

  return (
    <>
      {/* Mobile view - card-based layout */}
      {isMobile && (
        <div className="space-y-2">
          {rents.map((rent) => (
            <BookingCard
              key={rent.id}
              rent={rent}
              property={getPropertyDetails(rent.property_id)}
              tenant={getTenantDetails(rent.tenant_id)}
              showArchived={showArchived}
              onEdit={onEdit}
              onCancel={onCancel}
            />
          ))}
        </div>
      )}

      {/* Desktop view - table layout */}
      {!isMobile && (
        <BookingTable
          rents={rents}
          getPropertyDetails={getPropertyDetails}
          getTenantDetails={getTenantDetails}
          showArchived={showArchived}
          onEdit={onEdit}
          onCancel={onCancel}
        />
      )}
    </>
  );
};