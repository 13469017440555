import { UnitTerm } from "@/types"

export const maintenanceTypesNames = [
    { value: 'appliance', name: "Appliance" },
    { value: 'electrical', name: "Electrical" },
    { value: 'plumbing', name: "Plumbing" },
    { value: 'structural', name: "Structural" },
    { value: 'hvac', name: "HVAC" },
    { value: 'other', name: "Other" },
]

export const maintenancePriority = [
    { value: 'low', name: "Low" },
    { value: 'medium', name: "Medium" },
    { value: 'high', name: "High" },
]

export const unitTerms: UnitTerm[] = ['day', 'week', 'month', 'year'];