import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { CircleCheckBig, Home, Droplets, Zap, Flame, Wifi, Receipt } from 'lucide-react';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface DashboardBillsProps {
    bills: CombinedBill[];
    onPayBill: (billId: string) => Promise<void>;
    isPayingBill: boolean;
}

/**
 * Component to display recent bills on the dashboard
 */
const DashboardBills: React.FC<DashboardBillsProps> = ({
    bills,
    onPayBill,
    isPayingBill
}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // Function to get icon for bill type
    const getBillIcon = (bill: CombinedBill) => {
        if (bill.type === 'rent') {
            return <Home className="h-4 w-4 text-purple-500 mr-2" />;
        }

        switch (bill.utilityType) {
            case 'water':
                return <Droplets className="h-4 w-4 text-blue-500 mr-2" />;
            case 'electricity':
                return <Zap className="h-4 w-4 text-yellow-500 mr-2" />;
            case 'gas':
                return <Flame className="h-4 w-4 text-orange-500 mr-2" />;
            case 'internet':
                return <Wifi className="h-4 w-4 text-indigo-500 mr-2" />;
            default:
                return <Receipt className="h-4 w-4 text-gray-500 mr-2" />;
        }
    };

    // Format a date using i18n
    const formatDateString = (dateString: string) => {
        return formatDate(dateString, i18n.language);
    };

    // Show empty state if no bills
    if (bills.length === 0) {
        return (
            <Card>
                <CardContent className="p-6 text-center">
                    <CircleCheckBig className="h-12 w-12 mx-auto text-green-700 mb-4" />
                    <h3 className="text-lg font-medium mb-2">{t('dashboard.no_pending_bills')}</h3>
                    <p className="text-muted-foreground">{t('dashboard.all_caught_up')}</p>
                </CardContent>
            </Card>
        );
    }

    // Show bills list
    return (
        <Card>
            <CardContent className="p-4">
                <div className="space-y-4">
                    {bills.slice(0, 3).map((bill) => {
                        const property = bill.propertyAddress || t('common.unknown');
                        return (
                            <div key={bill.id} className="flex flex-col sm:flex-row sm:items-center justify-between border-b pb-3 last:border-0 gap-3">
                                <div className="flex items-start gap-3">
                                    <div>
                                        <div className="flex items-center">
                                            {getBillIcon(bill)}
                                            <p className="font-medium">{bill.description}</p>
                                        </div>
                                        <p className="text-sm text-muted-foreground ml-6">
                                            {property} - {t('bills.due_date')}: {formatDateString(bill.dueDate)}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4 self-end sm:self-auto">
                                    <span className="font-semibold">
                                        {formatCurrency(bill.amount, i18n.language, bill.currency)}
                                    </span>
                                    <Button size="sm" onClick={() => onPayBill(bill.id)} disabled={isPayingBill}>
                                        {t('bills.pay_now')}
                                    </Button>
                                </div>
                            </div>
                        );
                    })}

                    {bills.length > 3 && (
                        <div className="text-center pt-2">
                            <Button variant="ghost" size="sm" onClick={() => navigate('/bills')}>
                                {t('dashboard.view_more_bills', { count: bills.length - 3 })}
                            </Button>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default DashboardBills;