"use server"

import { InventoryItem } from "@/types";
import { BatchDeleteInventoryItemsResponse } from "@/types/mutation/checklist-response-types";
import { graphqlClient } from "@/lib/graphql-client";
import { BATCH_DELETE_INVENTORY_ITEMS } from "@/queries-graphql/mutation/batch-delete-inventory-items-mutation";

// Helper function to chunk an array
function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export async function batchDeleteInventoryItemsByIds(itemIds: string[]): Promise<InventoryItem[]> {
  const client = graphqlClient;
  try {
    if (itemIds.length === 0) {
      return [];
    }

    // Process in chunks of 50 items (or less)
    const chunks = chunkArray(itemIds, 50);
    const allDeletedItems: InventoryItem[] = [];

    // Process each chunk
    for (const chunk of chunks) {
      const data = await client.request<BatchDeleteInventoryItemsResponse>(
        BATCH_DELETE_INVENTORY_ITEMS,
        {
          itemIds: chunk
        }
      );

      const deletedItems = data.deleteFrominventory_itemsCollection.records;
      if (deletedItems && deletedItems.length > 0) {
        allDeletedItems.push(...deletedItems);
      }
    }

    return allDeletedItems;
  } catch (error) {
    console.error("Error batch deleting inventory items:", error);
    throw error;
  }
}

// Function to delete all items for a checklist
export async function batchDeleteInventoryItemsByChecklist(checklistId: string, itemIds: string[]): Promise<InventoryItem[]> {
  // If itemIds are provided, use those. Otherwise, you might need to fetch them first.
  if (!itemIds || itemIds.length === 0) {
    console.warn("No item IDs provided for deletion");
    return [];
  }

  return batchDeleteInventoryItemsByIds(itemIds);
}
