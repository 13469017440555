import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { HelmetProvider } from "react-helmet-async";
import './i18n';
import './index.css'

createRoot(document.getElementById("root")!).render(
    <HelmetProvider>
        <App />
    </HelmetProvider>
);
