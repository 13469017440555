"use server"

import { graphqlClient } from "../graphql-client";
import { GET_RENT } from "@/queries-graphql/get/get-rent";
import { RawRentResponse } from "@/types/raw-responses";
import { Rent } from "@/types";

export async function fetchRent(rentId: string): Promise<Rent> {
    try {
        if (rentId) {
            const rentResponse: RawRentResponse = await graphqlClient.request(GET_RENT, { rentId });
            const rent = rentResponse.rentsCollection.edges[0].node;
            return rent;
        }
        console.log("rent ID required");
    }
    catch (err) {
        console.log("Rent fetching error", err);
    }
}