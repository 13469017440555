import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatusBadgeProps {
    variant: 'current' | 'unpaid' | 'paid' | 'partial';
    count?: number;
    label?: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
    variant,
    count,
    label
}) => {
    const { t } = useTranslation();

    const classes = {
        current: 'bg-blue-100 text-blue-800 rounded-full whitespace-nowrap',
        unpaid: 'bg-red-100 text-red-800 rounded-full whitespace-nowrap',
        paid: 'bg-green-100 text-green-800 rounded-full whitespace-nowrap',
        partial: 'bg-amber-100 text-amber-800 rounded-full whitespace-nowrap'
    };

    // Generate automatic label based on variant if not provided
    const getDefaultLabel = () => {
        if (count !== undefined) {
            return `${count} ${t('bills.unpaid').toLowerCase()}`;
        }

        switch (variant) {
            case 'current':
                return t('bills.status_badges.current');
            case 'paid':
                return t('bills.status_badges.all_paid');
            case 'partial':
                return t('bills.status_badges.partial');
            case 'unpaid':
                return t('bills.status_badges.unpaid');
            default:
                return '';
        }
    };

    return (
        <span className={`w-auto ml-2 text-xs font-normal px-2 py-1 ${classes[variant]} flex-shrink-0`}>
            {label || getDefaultLabel()}
        </span>
    );
};