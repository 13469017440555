import React from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { CreditCard } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/i18n-utils';

interface TenantMonthBillsCardProps {
    monthKey: string;
    displayName: string;
    billsCount: number;
    paidBillsCount: number;
    unpaidAmount: number;
    allPaid: boolean;
    isPayingBill: boolean;
    onPayAllBills: () => void;
    children: React.ReactNode;
}

export const TenantMonthBillsCard: React.FC<TenantMonthBillsCardProps> = ({
    monthKey,
    displayName,
    billsCount,
    paidBillsCount,
    unpaidAmount,
    allPaid,
    isPayingBill,
    onPayAllBills,
    children
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Card key={monthKey}>
            <CardHeader className="px-4 py-5 space-y-3 sm:space-y-0 sm:flex sm:flex-row sm:items-center sm:justify-between">
                <div className="space-y-1">
                    <CardTitle className="text-base sm:text-lg">{displayName}</CardTitle>
                    <CardDescription className="text-sm">
                        {t('bills.bill_status_count', {
                            paid: paidBillsCount,
                            total: billsCount
                        })}
                    </CardDescription>
                </div>
                {!allPaid && (
                    <Button
                        size="sm"
                        className="flex items-center gap-2 mt-2 sm:mt-0"
                        onClick={onPayAllBills}
                        disabled={isPayingBill}
                    >
                        <CreditCard className="h-4 w-4" />
                        <span className="whitespace-nowrap">
                            {t('bills.pay_all_amount', {
                                amount: formatCurrency(unpaidAmount, i18n.language)
                            })}
                        </span>
                    </Button>
                )}
            </CardHeader>
            <CardContent className="space-y-6">
                {children}
            </CardContent>
        </Card>
    );
};