import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Home,
    Building,
    DollarSign,
    Calendar,
    Wrench,
    ChevronRight,
    ArrowRight,
    Menu,
    X,
    User,
    UsersRound,
    ClipboardList
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import LanguageSwitcher from '@/components/profile/LanguageSwitcher';
import '../styles/landing-mobile-tabs.css';

const LandingPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [activeTab, setActiveTab] = useState<'dashboard' | 'properties' | 'rentals' | 'bills' | 'bookings' | 'maintenance'>('dashboard');
    const [role, setRole] = useState<'lessor' | 'tenant'>('lessor');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Add a ref for the mobile screenshot container
    const screenshotContainerRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Lessor tabs
    const lessorTabs = [
        { id: 'dashboard', label: t('landing.tabs.dashboard.label'), icon: Home, description: t('landing.tabs.dashboard.description') },
        { id: 'properties', label: t('landing.tabs.properties.label'), icon: Building, description: t('landing.tabs.properties.description'), details: t('landing.tabs.properties.details') },
        { id: 'bills', label: t('landing.tabs.bills.label'), icon: DollarSign, description: t('landing.tabs.bills.description') },
        { id: 'bookings', label: t('landing.tabs.bookings.label'), icon: Calendar, description: t('landing.tabs.bookings.description') },
        { id: 'maintenance', label: t('landing.tabs.maintenance.label'), icon: Wrench, description: t('landing.tabs.maintenance.description') },
    ];

    // Tenant tabs
    const tenantTabs = [
        { id: 'dashboard', label: t('landing.tabs.dashboard.label'), icon: Home, description: t('landing.tabs.dashboard.description') },
        { id: 'rentals', label: t('landing.tabs.rentals.label'), icon: Building, description: t('landing.tabs.rentals.description') },
        { id: 'bills', label: t('landing.tabs.bills.label'), icon: DollarSign, description: t('landing.tabs.bills.description') },
        { id: 'maintenance', label: t('landing.tabs.maintenance.label'), icon: Wrench, description: t('landing.tabs.maintenance.description') },
    ];

    // Screen images for each tab - in a real app, replace with actual screenshots
    const screenImagesLessor = {
        dashboard: '/img/landing/lessor_dashboard_desktop.avif',
        properties: '/img/landing/lessor_properties_desktop.avif',
        bills: '/img/landing/lessor_bills_desktop.avif',
        bookings: '/img/landing/lessor_bookings_desktop.avif',
        maintenance: '/img/landing/lessor_repairs_desktop.avif',
    };

    const screenImagesLessorMob = {
        dashboard: '/img/landing/lessor_dashboard_mob.avif',
        properties: '/img/landing/lessor_properties_mob.avif',
        bills: '/img/landing/lessor_bills_mob.avif',
        bookings: '/img/landing/lessor_bookings_mob.avif',
        maintenance: '/img/landing/lessor_repairs_mob.avif',
    };

    const screenImagesTenant = {
        dashboard: '/img/landing/tenant_dashboard_desktop.avif',
        rentals: '/img/landing/tenant_properties_desktop.avif',
        bills: '/img/landing/tenant_bills_desktop.avif',
        maintenance: '/img/landing/tenant_repairs_desktop.avif',
    };

    const screenImagesTenantMob = {
        dashboard: '/img/landing/tenant_dashboard_mob.avif',
        rentals: '/img/landing/tenant_properties_mob.avif',
        bills: '/img/landing/tenant_bills_mob.avif',
        maintenance: '/img/landing/tenant_repairs_mob.avif',
    };

    const displayTabs = role === 'lessor' ? lessorTabs : tenantTabs;

    // Ensure an active tab exists in current role's tabs
    useEffect(() => {
        const tabIds = displayTabs.map(tab => tab.id);
        if (!tabIds.includes(activeTab as string)) {
            setActiveTab(tabIds[0] as any);
        }
    }, [role, displayTabs, activeTab]);

    // Add effect to scroll screenshot to top when tab changes on mobile
    useEffect(() => {
        // Only run on mobile devices
        const isMobile = window.innerWidth < 1024; // lg breakpoint

        if (isMobile && screenshotContainerRef.current) {
            // Scroll the container to the top
            screenshotContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [activeTab]); // Rerun when activeTab changes

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                    "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Rento",
                "url": "https://www.rento.com.ua",
                "logo": "https://www.rento.com.ua/img/logo.svg",
                "sameAs": [
                // "https://www.facebook.com/rento",
                // "https://www.linkedin.com/company/rento"
                ]
                `
                    }
                </script>
                <title>{t('landing.meta.title')}</title>

                <meta name="description" content="Manage your rental properties, pay bills, and track bookings, maintenance and more." />
                <meta name="keywords" content="rental management, rent tracking, property management, tenant app, utilities bills" />
                <meta name="author" content="Rento Team" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

                {/* Open Graph (Facebook, LinkedIn) */}
                <meta property="og:title" content="Rento - Rental Property Management App" />
                <meta property="og:description" content="Manage your rental properties, pay bills, and track bookings, maintenance and more." />
                <meta property="og:image" content="https://www.rento.com.ua/img/og-image.jpg" />
                <meta property="og:url" content="https://www.rento.com.ua" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Rento - Rental Property Management App" />
                <meta name="twitter:description" content="Easily manage your rental properties with Rento." />
                <meta name="twitter:image" content="https://www.rento.com.ua/img/og-image.jpg" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.rento.com.ua" />

                {/* Ukrainian Meta Tags */}
                <meta property="og:locale" content="uk_UA" />
                <meta property="og:title" content="Rento - керувати орендою легко" lang="uk" />
                <meta property="og:description" content="Для орендарів і орендодавців. Відстежуйте платежі, заявки на ремонт та умови договору в одному місці." lang="uk" />
                <meta name="description" content="Керуйте своєю орендою без зусиль за допомогою Rento. Платіть рахунки, відстежуйте обслуговування та ведіть документи в одному місці." lang="uk" />

            </Helmet>

            <div className="flex flex-col min-h-screen bg-background overflow-hidden relative">
                {/* Blurred background spots */}
                <div className="fixed top-20 -left-20 sm:left-20 w-80 h-80 sm:w-96 sm:h-96 rounded-full bg-indigo-500/20 blur-[80px] sm:blur-[120px] z-0"></div>
                <div className="fixed bottom-20 left-3/4 sm:left-1/4 w-64 h-64 sm:w-96 sm:h-96 rounded-full bg-purple-500/20 blur-[90px] z-0"></div>
                <div className="fixed top-[60%] right-10 sm:-right-10 w-72 h-72  sm:w-80 sm:h-80 rounded-full bg-indigo-500/20 blur-[100px] z-0"></div>

                {/* Header - now with sticky positioning and backdrop blur */}
                <header className="fixed top-0 left-0 right-0 z-50 border-b bg-background/40 backdrop-blur-md supports-[backdrop-filter]:bg-background/30 shadow-sm transition-all duration-200">
                    <div className="container flex h-16 items-center">
                        <div className="flex w-full justify-between items-center">
                            <div className="relative z-50 flex items-center gap-2">
                                <button onClick={() => scrollToSection('home')}>
                                    <span className="font-sansation text-2xl font-bold bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">{t('app.name')}</span>
                                </button>
                            </div>

                            <nav className="hidden lg:flex w-full justify-center items-center gap-6 absolute z-20 left-1/2 transform -translate-x-1/2">
                                <button onClick={() => scrollToSection('demo')} className="text-sm font-medium transition-colors hover:text-primary">{t('landing.header.demo')}</button>
                                <button onClick={() => scrollToSection('features')} className="text-sm font-medium transition-colors hover:text-primary">{t('landing.header.features')}</button>
                                <button onClick={() => scrollToSection('cta')} className="text-sm font-medium transition-colors hover:text-primary">{t('landing.header.get_started')}</button>
                            </nav>

                            <div className="flex items-center gap-4 relative z-50">
                                {/* Language Switcher */}
                                <LanguageSwitcher variant="ghost" size="sm" />

                                <div className='hidden sm:block'>
                                    <Link to="/auth/login">
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            className="hover:bg-gradient-to-r  from-transparent to-purple-500/10">
                                            {t('landing.header.login')}
                                        </Button>
                                    </Link>
                                </div>
                                <Link to="/auth/register">
                                    <Button
                                        size="sm"
                                        className="bg-gradient-to-r from-indigo-500 to-purple-500 hover:opacity-90 shadow-sm">
                                        {t('landing.header.sign_up')}</Button>
                                </Link>
                                <div className='lg:hidden'>
                                    <Button
                                        size='sm'
                                        variant='outline'
                                        onClick={() => setIsMenuOpen(true)}
                                    >
                                        <Menu />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Mobile Menu Overlay */}
                {isMenuOpen && (
                    <div className="fixed inset-0 z-50 bg-background/95 backdrop-blur-sm flex flex-col">
                        <div className="container flex h-16 items-center border-b">
                            <div className="flex w-full justify-between items-center">
                                <div className="flex items-center gap-2">
                                    <span className="font-sansation text-2xl font-bold bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">{t('app.name')}</span>
                                </div>
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    <X className="h-5 w-5" />
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center flex-1 gap-8 p-4">
                            <div className="flex justify-center w-full mb-4">
                                <LanguageSwitcher variant="outline" size="default" />
                            </div>

                            <nav className="flex flex-col items-center gap-6 text-lg">
                                <button onClick={() => {
                                    scrollToSection('demo');
                                    setIsMenuOpen(false);
                                }} className="font-medium transition-colors hover:text-primary">
                                    {t('landing.header.demo')}
                                </button>
                                <button onClick={() => {
                                    scrollToSection('features');
                                    setIsMenuOpen(false);
                                }} className="font-medium transition-colors hover:text-primary">
                                    {t('landing.header.features')}
                                </button>
                                <button onClick={() => {
                                    scrollToSection('cta');
                                    setIsMenuOpen(false);
                                }} className="font-medium transition-colors hover:text-primary">
                                    {t('landing.header.get_started')}
                                </button>
                            </nav>

                            <div className="flex flex-col w-full gap-4 max-w-xs">
                                <Link to="/auth/login" className="w-full">
                                    <Button
                                        variant="outline"
                                        size="lg"
                                        className="w-full"
                                        onClick={() => { setIsMenuOpen(false) }}>
                                        {t('landing.header.login')}
                                    </Button>
                                </Link>
                                <Link to="/auth/register" className="w-full">
                                    <Button
                                        size="lg"
                                        className="w-full bg-gradient-to-r from-indigo-500 to-purple-500 hover:opacity-90"
                                        onClick={() => {
                                            setIsMenuOpen(false)
                                        }}>
                                        {t('landing.header.sign_up')}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}


                <main className="flex-1">

                    {/* Hero Section */}
                    <section id={'home'} className="pt-24 pb-12 lg:pt-32 overflow-hidden">
                        <div className="container px-4 md:px-6">
                            <div className="flex flex-col items-center gap-4 text-center">
                                <div className="space-y-2">
                                    <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl">
                                        {t('landing.hero.title')} <span className="pr-2 bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">{t('landing.hero.title_span')}</span>
                                    </h1>
                                    <p className="mx-auto max-w-[420px] md:max-w-[540px] text-muted-foreground md:text-xl">
                                        {role === 'lessor'
                                            ? t('landing.hero.lessor_subtitle')
                                            : t('landing.hero.tenant_subtitle')}

                                    </p>
                                </div>

                                {/* Role Switcher */}
                                <div className="min-w-[300px] max-w-[350px] sm:min-w-[380px] my-6 p-4 bg-gradient-to-r from-indigo-500/5 to-purple-500/5 rounded-xl border border-indigo-500/20 shadow-md">
                                    <div className="flex items-center justify-center gap-4 sm:gap-8">

                                        <div className="flex items-center gap-2">
                                            <Building className={cn(
                                                "h-5 w-5 transition-colors",
                                                role === 'lessor' ? "text-purple-500" : "text-muted-foreground"
                                            )} />
                                            <span className={cn(
                                                "text-sm font-medium",
                                                role === 'lessor' ? "text-foreground" : "text-muted-foreground"
                                            )}>
                                                {t('landing.hero.lessor')}
                                            </span>
                                        </div>
                                        <Switch
                                            checked={role === 'tenant'}
                                            onCheckedChange={(checked) => setRole(checked ? 'tenant' : 'lessor')}
                                            className={cn(
                                                "relative z-10",
                                                "data-[state=checked]:bg-gradient-to-r from-indigo-500 to-purple-500",
                                                "data-[state=unchecked]:bg-gradient-to-r from-purple-500 to-indigo-500",
                                                "focus-visible:ring-offset-2",
                                                "touch-manipulation"
                                            )}
                                        />

                                        <div className="flex items-center gap-2">
                                            <Home className={cn(
                                                "h-5 w-5 transition-colors",
                                                role === 'tenant' ? "text-indigo-500" : "text-muted-foreground"
                                            )} />
                                            <span className={cn(
                                                "text-sm font-medium",
                                                role === 'tenant' ? "text-foreground" : "text-muted-foreground"
                                            )}>
                                                {t('landing.hero.tenant')}
                                            </span>
                                        </div>

                                    </div>

                                    <p className="text-xs text-center mt-3 text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.hero.lessor_description')
                                            : t('landing.hero.tenant_description')}
                                    </p>
                                </div>

                                <div className="flex flex-col sm:flex-row gap-4 min-[400px]:gap-2">
                                    <Link to="/auth/register">
                                        <Button size="lg" className="gap-1.5 bg-gradient-to-r from-indigo-500 to-purple-500 hover:opacity-90">
                                            {t('landing.hero.get_started_free')}
                                            <ArrowRight className="h-4 w-4" />
                                        </Button>
                                    </Link>
                                    <Link to="#demo">
                                        <Button
                                            size="lg"
                                            variant="outline"
                                            className="gap-1.5 border-primary/50 hover:border-primary"
                                            onClick={() => scrollToSection('demo')}
                                        >
                                            {t('landing.hero.view_demo')}
                                            <ChevronRight className="h-4 w-4" />
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* App Preview Section with Tabs */}
                    <section className="py-20 2xl:pt-32 relative h-[100vh] 2xl:h-auto flex flex-col items-center justify-center" id="demo">
                        <div className="container px-4 md:px-6 flex flex-col justify-between lg:justify-center 2xl:justify-start h-full">
                            <h2 className="text-xl font-bold text-center mb-4 md:mb-8 md:text-2xl bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">
                                {role === 'lessor' ? t('landing.demo.lessor_title') : t('landing.demo.tenant_title')}
                            </h2>

                            <div className="flex flex-col md:flex-row gap-2 lg:gap-4 items-center justify-center">
                                {/* Interactive App Navigation - Styled as buttons (hidden on mobile, shown on desktop) */}
                                <div className="hidden lg:flex md:w-1/3 flex-col gap-4">
                                    {displayTabs.map((tab) => (
                                        <button
                                            key={tab.id}
                                            onClick={() => setActiveTab(tab.id as any)}
                                            className={cn(
                                                "flex items-center gap-4 p-4 rounded-xl text-left transition-all",
                                                activeTab === tab.id
                                                    ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                                                    : "hover:bg-gradient-to-r from-transparent to-purple-500/10 shadow-none"
                                            )}
                                        >
                                            <div className={cn(
                                                "p-3 rounded-lg",
                                                activeTab === tab.id ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white" : "bg-muted"
                                            )}>
                                                <tab.icon className="h-5 w-5" />
                                            </div>
                                            <div>
                                                <h3 className="font-medium">{tab.label}</h3>
                                                <p className="text-sm text-muted-foreground">{tab.description}</p>
                                            </div>
                                        </button>

                                    ))}
                                </div>

                                {/* App Screenshot - full width on mobile, adjusted for desktop */}
                                <div className="w-full h-full md:w-2/3 bg-background rounded-xl border p-2 md:p-4 shadow-xl max-w-full mx-auto">
                                    {/* Desktop screenshot container */}
                                    <div className="hidden lg:block rounded-lg overflow-y-auto no-scrollbar h-[65vh] md:max-h-[60vh] md:h-auto bg-muted relative">
                                        <img
                                            src={role === 'lessor' ? screenImagesLessor[activeTab] : screenImagesTenant[activeTab]}
                                            alt={`${activeTab} screen`}
                                            className="w-full h-auto object-cover object-top"
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement;
                                                target.src = "/placeholder.svg";
                                            }}
                                        />
                                    </div>

                                    {/* Mobile screenshot container with ref for auto-scroll */}
                                    <div
                                        ref={screenshotContainerRef}
                                        className="lg:hidden rounded-lg bg-background overflow-y-auto no-scrollbar h-[60vh] md:h-[60vh] relative"
                                    >
                                        <img
                                            src={role === 'lessor' ? screenImagesLessorMob[activeTab] : screenImagesTenantMob[activeTab]}
                                            alt={`${activeTab} screen`}
                                            className="w-full h-auto object-cover object-top"
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement;
                                                target.src = "/placeholder.svg";
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Mobile Bottom Tabs - shown on mobile, hidden on desktop */}
                            <div className="lg:hidden mt-6 pb-2">
                                <p className="text-sm text-center font-medium text-muted-foreground mb-2">
                                    {t('landing.demo.explore_features', {
                                        role: t(`landing.demo.for_role.${role}`)
                                    })}
                                </p>
                                <div className={`grid ${role === 'lessor' ? "grid-cols-5" : "grid-cols-4"} gap-2`}>
                                    {displayTabs.map((tab) => (
                                        <button
                                            key={tab.id}
                                            onClick={() => setActiveTab(tab.id as any)}
                                            className={cn(
                                                "flex flex-col items-center p-2 rounded-lg transition-all",
                                                activeTab === tab.id
                                                    ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                                                    : "hover:bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-none"
                                            )}
                                        >
                                            <div className={cn(
                                                "p-2 rounded-md mb-1",
                                                activeTab === tab.id
                                                    ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                                                    : "bg-muted"
                                            )}>
                                                <tab.icon className="h-4 w-4" />
                                            </div>
                                            <span className={cn(
                                                "text-xs font-medium",
                                                activeTab === tab.id ? "text-primary" : "text-muted-foreground"
                                            )}>
                                                {tab.label}
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </section>

                    {/* Features Section */}
                    <section className="pt-20 pb-12 md:py-24 lg:py-32 relative" id="features">
                        <div className="absolute top-1/4 right-0 w-96 h-96 rounded-full bg-indigo-500/10 blur-[150px] z-0"></div>
                        <div className="container px-4 md:px-6">
                            <div className="flex flex-col items-center gap-4 text-center">
                                <div className="space-y-2">
                                    {currentLanguage === 'en'
                                        ? (<h2 className="py-2 text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">
                                            <span className='font-sansation'>{t('app.name')}</span> {t('landing.features.title')}
                                        </h2>)
                                        : (<h2 className="py-2 text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">
                                            {t('landing.features.title')} <span className='font-sansation'>{t('app.name')}</span>
                                        </h2>)}
                                    <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                                        {role === 'lessor'
                                            ? t('landing.features.lessor_subtitle')
                                            : t('landing.features.tenant_subtitle')}
                                    </p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
                                <div className="relative z-5 flex flex-col items-center text-center p-6 backdrop-blur-sm bg-background/90 supports-[backdrop-filter]:bg-background/50 rounded-xl border border-indigo-500/10 shadow-lg">
                                    <Building className="h-10 w-10 text-primary mb-4" />
                                    <h3 className="text-xl font-bold mb-2">
                                        {role === 'lessor'
                                            ? t('landing.features.property_management.lessor_title')
                                            : t('landing.features.property_management.tenant_title')}
                                    </h3>
                                    <p className="text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.features.property_management.lessor_description')
                                            : t('landing.features.property_management.tenant_description')}
                                    </p>
                                </div>

                                <div className="relative z-5 flex flex-col items-center text-center p-6 backdrop-blur-sm bg-background/90 supports-[backdrop-filter]:bg-background/50 rounded-xl border border-purple-500/10 shadow-lg">
                                    <Calendar className="h-10 w-10 text-primary mb-4" />
                                    <h3 className="text-xl font-bold mb-2">
                                        {role === 'lessor'
                                            ? t('landing.features.booking_system.lessor_title')
                                            : t('landing.features.booking_system.tenant_title')}
                                    </h3>
                                    <p className="text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.features.booking_system.lessor_description')
                                            : t('landing.features.booking_system.tenant_description')}
                                    </p>
                                </div>

                                <div className="relative z-5 flex flex-col items-center text-center p-6 backdrop-blur-sm bg-background/90 supports-[backdrop-filter]:bg-background/50 rounded-xl border border-indigo-500/10 shadow-lg z-5">
                                    <DollarSign className="h-10 w-10 text-primary mb-4" />
                                    <h3 className="text-xl font-bold mb-2">{t('landing.features.bill_tracking.title')}</h3>
                                    <p className="text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.features.bill_tracking.lessor_description')
                                            : t('landing.features.bill_tracking.tenant_description')}
                                    </p>
                                </div>

                                <div className="flex relative z-5 flex-col items-center text-center p-6 backdrop-blur-sm bg-background/90 supports-[backdrop-filter]:bg-background/50 rounded-xl border border-purple-500/10 shadow-lg">
                                    <Wrench className="h-10 w-10 text-primary mb-4" />
                                    <h3 className="text-xl font-bold mb-2">{t('landing.features.maintenance.title')}</h3>
                                    <p className="text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.features.maintenance.lessor_description')
                                            : t('landing.features.maintenance.tenant_description')}
                                    </p>
                                </div>

                                <div className="flex flex-col relative z-5 items-center text-center p-6 backdrop-blur-sm bg-background/90 supports-[backdrop-filter]:bg-background/50 rounded-xl border border-indigo-500/10 shadow-lg">
                                    <Home className="h-10 w-10 text-primary mb-4" />
                                    <h3 className="text-xl font-bold mb-2">{t('landing.features.dashboard.title')}</h3>
                                    <p className="text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.features.dashboard.lessor_description')
                                            : t('landing.features.dashboard.tenant_description')}
                                    </p>
                                </div>

                                <div className="flex flex-col relative z-5 items-center text-center p-6 backdrop-blur-sm bg-background/90 supports-[backdrop-filter]:bg-background/50 rounded-xl border border-purple-500/10 shadow-lg">
                                    {
                                        role === 'lessor'
                                            ? <ClipboardList className="h-10 w-10 text-primary mb-4" />
                                            : <ArrowRight className="h-10 w-10 text-primary mb-4" />
                                    }

                                    <h3 className="text-xl font-bold mb-2">
                                        {role === 'lessor'
                                            ? t('landing.features.checklist.title')
                                            : t('landing.features.navigation.title')
                                        }</h3>
                                    <p className="text-muted-foreground">
                                        {role === 'lessor'
                                            ? t('landing.features.checklist.description')
                                            : t('landing.features.navigation.tenant_description')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Call to Action */}
                    <section id='cta' className="py-12 md:py-24 lg:py-32 relative overflow-hidden">
                        <div className="absolute inset-0 bg-grid-white/10 bg-[size:20px_20px] opacity-10"></div>
                        <div className="absolute -top-40 -right-40 w-80 h-80 rounded-full bg-indigo-500/10 blur-[100px] -z-10"></div>
                        <div className="absolute -bottom-40 -left-40 w-80 h-80 rounded-full bg-purple-500/10 blur-[100px] -z-10"></div>

                        <div className="container px-4 md:px-6 relative">
                            <div className="flex flex-col items-center gap-4 text-center">
                                <div>
                                    <h2 className="py-4 text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">{t('landing.cta.title')}</h2>
                                    <p className="mx-auto max-w-[700px] text-muted-foreground md:text-xl">
                                        {role === 'lessor'
                                            ? t('landing.cta.lessor_description')
                                            : t('landing.cta.tenant_description')}
                                    </p>
                                </div>
                                <div className="flex flex-col sm:flex-row gap-4 mt-6">
                                    <Link to="/auth/register">
                                        <Button size="lg" className="bg-gradient-to-r from-indigo-500 to-purple-500 hover:opacity-90">
                                            {t('landing.cta.sign_up_free')}
                                        </Button>
                                    </Link>
                                    <Link to="/auth/login">
                                        <Button size="lg" variant="outline" className="border-primary/50 hover:border-primary">
                                            {t('landing.cta.login')}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                {/* Footer */}
                <footer className="border-t py-6 md:py-0">
                    <div className="container flex flex-col md:flex-row items-center justify-between gap-4 md:h-16 px-4 md:px-6">
                        <p className="text-sm text-muted-foreground">
                            © {new Date().getFullYear()} <span className="font-sansation bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent font-semibold">{t('app.name')}</span>. {t('landing.footer.rights_reserved')}
                        </p>
                        <div className="flex items-center gap-4">
                            <Link to="/privacy" className="text-sm text-muted-foreground hover:text-foreground">
                                {t('landing.footer.privacy')}
                            </Link>
                            <Link to="/terms" className="text-sm text-muted-foreground hover:text-foreground">
                                {t('landing.footer.terms')}
                            </Link>
                            <Link to="/contact" className="text-sm text-muted-foreground hover:text-foreground">
                                {t('landing.footer.contact')}
                            </Link>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default LandingPage;