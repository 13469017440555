import { Rent } from "@/types";
import { CreateRentInput } from "@/types/mutation/mutation-input-types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { createRent } from "@/lib/actions/create-rent";
import { v4 as uuidv4 } from 'uuid';

// React Query Mutation Hook
interface RentCreateOptions {
    onSuccess?: (rent: Rent) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
    tempId: string;
}

export function useCreateRent(options: RentCreateOptions) {
    console.log('Create Rent Hook triggered');
    const queryClient = useQueryClient();

    if (!options) {
        throw new Error('options is required');
    }
    if (!options.userId) {
        throw new Error('user ID is required');
    }

    const mutation = useMutation<Rent, Error, CreateRentInput, MutationContext>({
        mutationFn: (input) => createRent(input),

        // Add optimistic update
        onMutate: async (input) => {
            if (!input) {
                throw new Error('input data is required');
            }

            const queryKey = ['userData', options.userId];
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);

            // Generate a temporary ID for optimistic update
            const tempId = uuidv4();
            const now = new Date().toISOString();

            if (previousData) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                    // Create temporary rent object
                    const tempRent: Rent = {
                        id: tempId,
                        ...input,
                        created_at: now,
                        updated_at: now
                    };

                    // Add rent to rents collection
                    newData.rents[tempId] = tempRent;

                    // Update relationship maps
                    // Property to rent relationship
                    if (input.property_id) {
                        if (!newData.propertyRents[input.property_id]) {
                            newData.propertyRents[input.property_id] = [];
                        }
                        newData.propertyRents[input.property_id].push(tempId);
                    }

                    // User to rent relationships
                    // As lessor
                    if (input.lessor_id) {
                        if (!newData.userRents.asLessor[input.lessor_id]) {
                            newData.userRents.asLessor[input.lessor_id] = [];
                        }
                        newData.userRents.asLessor[input.lessor_id].push(tempId);
                    }

                    // As tenant
                    if (input.tenant_id) {
                        if (!newData.userRents.asTenant[input.tenant_id]) {
                            newData.userRents.asTenant[input.tenant_id] = [];
                        }
                        newData.userRents.asTenant[input.tenant_id].push(tempId);
                    }

                    // Update userProperties relationships based on this new rent
                    if (input.property_id && input.tenant_id) {
                        if (!newData.userProperties.asTenant[input.tenant_id]) {
                            newData.userProperties.asTenant[input.tenant_id] = [];
                        }
                        if (!newData.userProperties.asTenant[input.tenant_id].includes(input.property_id)) {
                            newData.userProperties.asTenant[input.tenant_id].push(input.property_id);
                        }
                    }

                    return newData;
                });
            }

            return { previousData, tempId };
        },

        onError: (error, _newData, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }
            console.error('Error creating Rent', error);
            options?.onError?.(error);
        },

        onSuccess: (newRent, variables, context) => {
            const queryKey = ['userData', options.userId];

            // Update the cache with the real data from the server
            queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                if (!old || !context?.tempId) return old;

                const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                // Replace temporary rent with the real one
                delete newData.rents[context.tempId];
                newData.rents[newRent.id] = newRent;

                // Update relationship maps - replace tempId with real id

                // Update property to rent relationship
                if (variables.property_id) {
                    if (newData.propertyRents[variables.property_id]) {
                        const index = newData.propertyRents[variables.property_id].indexOf(context.tempId);
                        if (index !== -1) {
                            newData.propertyRents[variables.property_id][index] = newRent.id;
                        }
                    }
                }

                // Update user to rent relationships
                // As lessor
                if (variables.lessor_id) {
                    if (newData.userRents.asLessor[variables.lessor_id]) {
                        const index = newData.userRents.asLessor[variables.lessor_id].indexOf(context.tempId);
                        if (index !== -1) {
                            newData.userRents.asLessor[variables.lessor_id][index] = newRent.id;
                        }
                    }
                }

                // As tenant
                if (variables.tenant_id) {
                    if (newData.userRents.asTenant[variables.tenant_id]) {
                        const index = newData.userRents.asTenant[variables.tenant_id].indexOf(context.tempId);
                        if (index !== -1) {
                            newData.userRents.asTenant[variables.tenant_id][index] = newRent.id;
                        }
                    }
                }

                return newData;
            });

            options?.onSuccess?.(newRent);
        },
        retry: false
    });

    return {
        createRent: mutation.mutate,
        createRentAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}