import React from 'react';
import { CircleCheckBig, Clock2 } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface AcceptanceBadgeProps {
  isAccepted: boolean;
  showBadge?: boolean;
  size?: 'sm' | 'default';
}

export const AcceptanceBadge: React.FC<AcceptanceBadgeProps> = ({
  isAccepted,
  showBadge = false,
  size = 'default'
}) => {
  const { t } = useTranslation();

  if (showBadge) {
    // Show as a badge
    return (
      <Badge
        variant={isAccepted ? "default" : "outline"}
        className={cn(
          "flex items-center",
          isAccepted ? "bg-green-100 text-green-800 hover:bg-green-200" : "bg-gray-100",
          size === 'sm' ? "text-xs py-0 px-2 h-5" : ""
        )}
      >
        {isAccepted ? (
          <>
            <CircleCheckBig className="h-3 w-3 mr-1" />
            {t('bookings.accepted')}
          </>
        ) : (
          <>
            <Clock2 className="h-3 w-3 mr-1" />
            {t('bookings.pending')}
          </>
        )}
      </Badge>
    );
  }

  // Show as just an icon
  return isAccepted ? (
    <CircleCheckBig className="text-green-600 h-5 w-5" />
  ) : (
    <Clock2 className="text-muted-foreground h-5 w-5" />
  );
};