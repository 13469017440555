import { gql } from "graphql-request";

// CREATE_RENT mutation
export const CREATE_RENT = gql`
  mutation CreateRent($lessor_id: String!, $tenant_id: String!, $manager_id: String, 
    $property_id: String!, $unit: String!, $date_start: String!, 
    $date_end: String!, $status: String!, $contract_url: String, 
    $contract_term: Int!, $currency: Currency!, $price_rent: Int!, 
    $isAccepted: Boolean!, $isArchived: Boolean!) {
    insertIntorentsCollection(
      objects: {
        lessor_id: $lessor_id,
        tenant_id: $tenant_id,
        manager_id: $manager_id,
        property_id: $property_id,
        unit: $unit,
        date_start: $date_start,
        date_end: $date_end,
        status: $status,
        contract_url: $contract_url,
        contract_term: $contract_term,
        currency: $currency,
        price_rent: $price_rent,
        isAccepted: $isAccepted,
        isArchived: $isArchived
      }
    ) {
      records {
        id
        lessor_id
        tenant_id
        manager_id
        property_id
        unit
        date_start
        date_end
        status
        contract_url
        contract_term
        currency
        price_rent
        isAccepted
        isArchived
        created_at
        updated_at
      }
    }
  }
`;