import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import ChecklistForm from '@/components/checklists/ChecklistForm';
import { useAuth } from '@/contexts/AuthContext';
import { useProperty } from '@/lib/query-hooks/get-hooks';
import { useCreateChecklist } from '@/lib/query-hooks/mutation/useCreateChecklist';
import { Skeleton } from '@/components/ui/skeleton';
import { useTranslation } from 'react-i18next';

const ChecklistCreate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const userId = user?.id || '';
  const propertyId = id || '';

  // Get property data
  const property = useProperty(propertyId, userId);

  // Create checklist mutation
  const {
    createChecklistAsync,
    isLoading,
    isError
  } = useCreateChecklist({
    userId,
    onSuccess: () => {
      toast({
        title: t('checklists.toast.created_title'),
        description: t('checklists.toast.created_description'),
      });
      navigate(`/properties/${propertyId}/checklists`);
    },
    onError: (error) => {
      toast({
        title: t('common.error'),
        description: t('checklists.toast.creation_error'),
        variant: "destructive",
      });
    }
  });

  if (!property) {
    return (
      <MainLayout>
        <div className="max-w-3xl mx-auto space-y-6 pt-2 pb-8">
          <Skeleton className="h-4 w-32 mb-8" />
          <Skeleton className="h-8 w-64 mb-2" />
          <Skeleton className="h-4 w-48 mb-8" />
          <div className="space-y-4">
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-40 w-full" />
          </div>
        </div>
      </MainLayout>
    );
  }

  const handleSubmit = async (data: any) => {
    if (!propertyId) return;

    try {
      await createChecklistAsync({
        property_id: propertyId,
        title: data.title,
        description: data.description || '',
        items: data.items.map((item: any) => ({
          name: item.name,
          note: item.note || '',
        })),
      });
    } catch (error) {
      // Error will be handled by the onError callback in the hook
      console.error("Failed to create checklist:", error);
    }
  };

  return (
    <MainLayout>
      <div className="max-w-3xl mx-auto space-y-6 pt-2 pb-8">
        <Button
          variant="ghost"
          onClick={() => navigate(`/properties/${propertyId}/checklists`)}
          className="-ml-2 px-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('checklists.back_to_checklists')}
        </Button>

        <div>
          <h1 className="text-3xl font-bold tracking-tight">{t('checklists.create_new')}</h1>
          <p className="text-muted-foreground mt-1">
            {t('checklists.for_property', {
              street: property.street,
              apartment: property.apartment,
              city: property.city
            })}
          </p>
        </div>

        <ChecklistForm
          propertyId={propertyId}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

export default ChecklistCreate;