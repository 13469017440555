import { gql } from 'graphql-request';

export const GET_ALL_DATA = gql`
query GetUserData($user_id: UUID!) {
  # Get user profile
  profilesCollection(filter: { id: { eq: $user_id } }) {
    edges {
      node {
        id
        role
        first_name
        last_name
        email
        phone
        updated_at
        created_at
      }
    }
  }
  
  # Fetch properties owned by the user (as Lessor)
  propertiesCollection(filter: { lessor_id: { eq: $user_id } }) {
    edges {
      node {
        id
        lessor_id
        title
        status
        city
        street
        building
        block
        apartment
        manager_id
        bills_pay_by_lessor
        wi_fi_network
        wi_fi_key
        door_code
        isStudio
        bedrooms
        bathrooms
        area
        image_url
        price
        currency
        unit_term
        unit_measure
        updated_at
        created_at

        # Fetch checklists with inventory items
        checklistsCollection {
          edges {
            node {
              id
              title
              property_id
              description
              last_run_date
              updated_at
              created_at

              # Nested inventory items
              inventory_itemsCollection {
                edges {
                  node {
                    id
                    name
                    note
                    status
                    updated_at
                    created_at
                  }
                }
              }
            }
          }
        }

        # Fetch rents with tenant details & rent bills
        rentsCollection {
          edges {
            node {
              id
              tenant_id
              lessor_id
              manager_id
              property_id
              unit
              date_start
              date_end
              status
              contract_url
              contract_term
              currency
              price_rent
              isAccepted
              isArchived
              updated_at
              created_at
              tenant: get_profile_by_tenant_id_by_rents {
               edges{
                node{
                  id
                role
                first_name
                last_name
                email
                phone
                updated_at
                created_at
                }
              }
              }
              manager: get_profile_by_manager_id_by_rents {
               edges{
                node{
                  id
                role
                first_name
                last_name
                email
                phone
                updated_at
                created_at
                }
              }
              }

              # Get the latest rent bill (limited to 1, will be the most recent)
              rents_billsCollection(first: 1) {
                edges {
                  node {
                    id
                    lessor_id
                    tenant_id
                    property_id
                    rent_id
                    bill_debt
                    isPaid
                    date_from
                    due_date
                    paid_at
                    number_of_days
                    comment
                    currency
                    price_rent
                    invoice_url
                    receipts_urls
                    updated_at
                    created_at
                  }
                }
              }
            }
          }
        }

        # Fetch maintenance/repair requests for this property
        repairsCollection {
          edges {
            node {
              id
              tenant_id
              property_id
              title
              description
              type
              status
              priority
              completed_by_tenant
              updated_at
              created_at
            }
          }
        }
      }
    }
  }

  # Fetch active rent data if user is a Tenant
  rentsCollection(filter: { tenant_id: { eq: $user_id } }) {
    edges {
      node {
        id
        tenant_id
        lessor_id
        manager_id
        property_id
        
        unit
        date_start
        date_end
        status
        contract_url
        contract_term
        currency
        price_rent
        isAccepted
        isArchived
        updated_at
        created_at
        lessor: get_profile_by_lessor_id_by_rents {
               edges{
                node{
                  id
                role
                first_name
                last_name
                email
                phone
                updated_at
                created_at
                }
              }
              }
          manager: get_profile_by_manager_id_by_rents {
                edges{
                  node{
                    id
                role
                first_name
                last_name
                email
                phone
                updated_at
                created_at
                  }
                }
              }

        # Get all unpaid bills plus the most recent bill for tenant view
        rents_billsCollection(
          filter: { isPaid: { eq: false } }
        ) {
          edges {
            node {
              id
              lessor_id
              tenant_id
              property_id
              rent_id
              bill_debt
              isPaid
              date_from
              due_date
              paid_at
              number_of_days
              comment
              currency
              price_rent
              invoice_url
              receipts_urls
              updated_at
              created_at
            }
          }
        }

        # Using the actual foreign key relationship field name
        properties {
          id
          lessor_id
          title
          status
          city
          street
          building
          block
          apartment
          manager_id
          bills_pay_by_lessor
          wi_fi_network
          wi_fi_key
          door_code
          isStudio
          bedrooms
          bathrooms
          area
          image_url
          price
          currency
          unit_term
          unit_measure
          updated_at
          created_at
          
        }
      }
    }
  }
  
  # Get all unpaid rent bills for this user to show on dashboard
  rents_billsCollection(
    filter: {
      and: [
        { 
          or: [
            { tenant_id: { eq: $user_id } },
            { lessor_id: { eq: $user_id } }
          ]
        },
        { isPaid: { eq: false } }
      ]
    },
    first: 3
  ) {
    edges {
      node {
        id
        lessor_id
        tenant_id
        property_id
        rent_id
        bill_debt
        isPaid
        date_from
        due_date
        paid_at
        number_of_days
        comment
        currency
        price_rent
        invoice_url
        receipts_urls
        updated_at
        created_at
      }
    }
  }
  
  # Fetch repairs/maintenance requests separately
  repairsCollection(filter: { tenant_id: { eq: $user_id } }) {
    edges {
      node {
        id
        tenant_id
        property_id
        title
        description
        type
        status
        priority
        completed_by_tenant
        updated_at
        created_at
      }
    }
  }
}
`