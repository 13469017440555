import React from 'react';
import { Link } from 'react-router-dom';
import {
  Building, Home, DollarSign, Wrench, AlertCircle, Calendar
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { NormalizedData } from '@/types/normalized-data-types';
import { Skeleton } from '@/components/ui/skeleton';
import { useAuth } from '@/contexts/AuthContext';
import { LogoutButton } from '@/components/profile/LogoutButton';
import LanguageSwitcher from '@/components/profile/LanguageSwitcher';
import {
  useActiveRoute,
  useMaintenanceRequestsCount,
  usePendingInvitationsCount,
  useNewBookingsCount,
  getUserInitials
} from '@/utils/nav-utils';
import NotificationBadge from '@/components/nav/NotificationBadge';
import RoleSwitcher from '@/components/nav/RoleSwitcher';

interface SidebarNavProps {
  onCloseSidebar?: () => void;
  userData: NormalizedData;
  isLoadingUserData: boolean;
  isErrorUserData: boolean;
  refetchAllUserData: () => void;
}

export const SidebarNav = React.memo(({
  onCloseSidebar,
  userData,
  isLoadingUserData,
  isErrorUserData,
  refetchAllUserData
}: SidebarNavProps) => {
  const { user, profile, role } = useAuth();
  const isActive = useActiveRoute();
  const { t } = useTranslation();

  // Use our utility hooks for notification counts
  const newMaintenanceRequests = useMaintenanceRequestsCount(userData, user?.id, role);
  const pendingInvitationsCount = usePendingInvitationsCount(userData, user?.id);
  const newBookings = useNewBookingsCount(userData, user?.id);
  const unpaidBillsCount = 0; // Placeholder for future implementation

  // Base sidebar layout that's consistent across all states
  return (
    <div className="h-full w-64 bg-muted/40 border-r pt-5 px-4 pb-4 flex flex-col">
      <div className="mb-6 w-full flex justify-start items-center">
        <h2 className="font-sansation text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-indigo-500 to-purple-500">
          Rento
        </h2>
      </div>

      {/* Error state */}
      {isErrorUserData && (
        <div className="flex flex-col justify-center items-center h-96">
          <AlertCircle className="h-8 w-8 text-red-500 mb-4" />
          <p className="text-lg font-medium mb-2">{t('common.error_loading_data')}</p>
          <Button onClick={() => refetchAllUserData()}>{t('common.retry')}</Button>
        </div>
      )}

      {/* Loading state with skeletons */}
      {isLoadingUserData && (
        <>
          <div className="space-y-1 flex-1">
            <Skeleton className="h-10 w-full mb-1 bg-blue-200" />
            <Skeleton className="h-10 w-full mb-1 bg-blue-200" />
            <Skeleton className="h-10 w-full mb-1 bg-blue-200" />
            <Skeleton className="h-10 w-full mb-1 bg-blue-200" />
          </div>
          <div className="border-t pt-4 mt-4">
            <div className="flex items-center mb-4">
              <Skeleton className="h-8 w-8 rounded-full bg-blue-200" />
              <div className="ml-2 space-y-1">
                <Skeleton className="h-4 w-24 bg-blue-200" />
                <Skeleton className="h-3 w-16 bg-blue-200" />
              </div>
            </div>
            <Skeleton className="h-10 w-full" />
          </div>
        </>
      )}

      {/* Content when data is loaded */}
      {!isLoadingUserData && !isErrorUserData && userData && user && (
        <>
          <nav className="space-y-2 flex-1">
            <Link to="/dashboard" onClick={onCloseSidebar} className="block ">
              <div className={cn(
                "flex items-center px-3 py-2 rounded-lg transition-all duration-200",
                isActive('/dashboard')
                  ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                  : "hover:bg-gradient-to-r  from-transparent to-purple-500/10 shadow-none"
              )}>
                <div className={cn(
                  "p-2 mr-3 rounded-md",
                  isActive('/dashboard')
                    ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                    : "bg-muted"
                )}>
                  <Home className="h-4 w-4" />
                </div>
                <span className={cn(
                  "font-medium",
                  isActive('/dashboard') ? "text-primary" : "text-muted-foreground"
                )}>
                  {t('navigation.dashboard')}
                </span>
              </div>
            </Link>

            {role === 'lessor' && (
              <Link to="/properties" onClick={onCloseSidebar} className="block">
                <div className={cn(
                  "flex items-center px-3 py-2 rounded-lg transition-all duration-200",
                  isActive('/properties') || isActive('/properties/:id')
                    ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                    : "hover:bg-gradient-to-r  from-transparent to-purple-500/10 shadow-none"
                )}>
                  <div className={cn(
                    "p-2 mr-3 rounded-md relative",
                    isActive('/properties') || isActive('/properties/:id')
                      ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                      : "bg-muted"
                  )}>
                    <Building className="h-4 w-4" />
                  </div>
                  <span className={cn(
                    "font-medium",
                    isActive('/properties') || isActive('/properties/:id') ? "text-primary" : "text-muted-foreground"
                  )}>
                    {t('navigation.properties')}
                  </span>
                </div>
              </Link>
            )}

            {role === 'tenant' && (
              <Link to="/tenant/properties" onClick={onCloseSidebar} className="block">
                <div className={cn(
                  "flex items-center px-3 py-2 rounded-lg transition-all duration-200",
                  isActive('/tenant/properties')
                    ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                    : "hover:bg-gradient-to-r  from-transparent to-purple-500/10 shadow-none"
                )}>
                  <div className={cn(
                    "p-2 mr-3 rounded-md relative",
                    isActive('/tenant/properties')
                      ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                      : "bg-muted"
                  )}>
                    <Building className="h-4 w-4" />
                    {pendingInvitationsCount > 0 && (
                      <NotificationBadge count={pendingInvitationsCount} />
                    )}
                  </div>
                  <span className={cn(
                    "font-medium",
                    isActive('/tenant/properties') ? "text-primary" : "text-muted-foreground"
                  )}>
                    {t('navigation.my_rentals')}
                  </span>
                </div>
              </Link>
            )}

            <Link to="/bills" onClick={onCloseSidebar} className="block">
              <div className={cn(
                "flex items-center px-3 py-2 rounded-lg transition-all duration-200",
                isActive('/bills')
                  ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                  : "hover:bg-gradient-to-r  from-transparent to-purple-500/10 shadow-none"
              )}>
                <div className={cn(
                  "p-2 mr-3 rounded-md relative",
                  isActive('/bills')
                    ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                    : "bg-muted"
                )}>
                  <DollarSign className="h-4 w-4" />
                  {unpaidBillsCount > 0 && (
                    <NotificationBadge count={unpaidBillsCount} />
                  )}
                </div>
                <span className={cn(
                  "font-medium",
                  isActive('/bills') ? "text-primary" : "text-muted-foreground"
                )}>
                  {t('navigation.bills')}
                </span>
              </div>
            </Link>

            <Link to="/maintenance" onClick={onCloseSidebar} className="block">
              <div className={cn(
                "flex items-center px-3 py-2 rounded-lg transition-all duration-200",
                isActive('/maintenance')
                  ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                  : "hover:bg-gradient-to-r  from-transparent to-purple-500/10 shadow-none"
              )}>
                <div className={cn(
                  "p-2 mr-3 rounded-md relative",
                  isActive('/maintenance')
                    ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                    : "bg-muted"
                )}>
                  <Wrench className="h-4 w-4" />
                  {newMaintenanceRequests > 0 && (
                    <NotificationBadge count={newMaintenanceRequests} />
                  )}
                </div>
                <span className={cn(
                  "font-medium",
                  isActive('/maintenance') ? "text-primary" : "text-muted-foreground"
                )}>
                  {t('navigation.maintenance')}
                </span>
              </div>
            </Link>

            {role === 'lessor' && (
              <Link to="/bookings" onClick={onCloseSidebar} className="block">
                <div className={cn(
                  "flex items-center px-3 py-2 rounded-lg transition-all duration-200",
                  isActive('/bookings')
                    ? "bg-gradient-to-r from-indigo-500/10 to-purple-500/10 shadow-inner shadow-indigo-500/20"
                    : "hover:bg-gradient-to-r  from-transparent to-purple-500/10 shadow-none"
                )}>
                  <div className={cn(
                    "p-2 mr-3 rounded-md relative",
                    isActive('/bookings')
                      ? "bg-gradient-to-r from-indigo-500 to-purple-500 text-white"
                      : "bg-muted"
                  )}>
                    <Calendar className="h-4 w-4" />
                    {newBookings > 0 && (
                      <NotificationBadge count={newBookings} />
                    )}
                  </div>
                  <span className={cn(
                    "font-medium",
                    isActive('/bookings') ? "text-primary" : "text-muted-foreground"
                  )}>
                    {t('navigation.bookings')}
                  </span>
                </div>
              </Link>
            )}
          </nav>

          <div className="border-t pt-4 mt-4">
            <Link to="/profile" onClick={onCloseSidebar}>
              <div className="flex items-center mb-4 cursor-pointer hover:bg-accent p-2 rounded-md">
                <div className="h-8 w-8 rounded-full bg-gradient-to-br from-indigo-500 to-purple-500 text-primary-foreground flex items-center justify-center font-semibold">
                  {getUserInitials(profile, user.email)}
                </div>
                <div className="ml-2">
                  <p className="text-sm font-medium">
                    {profile?.first_name || 'User'} {profile?.last_name || ''}
                  </p>
                  <p className="text-xs text-muted-foreground capitalize"> {role && t(`profile.roles.${role}`)}</p>
                </div>
              </div>
            </Link>

            {/* Add role switcher directly in sidebar for quick access */}
            <div className="mb-4">
              <RoleSwitcher idPrefix="sidebar-" />
            </div>

            <div className="flex justify-between items-center gap-2 mb-4">
              <LogoutButton />
              <LanguageSwitcher variant="outline" size="icon" />
            </div>
          </div>
        </>
      )}
    </div>
  );
});