import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Currency, Property, UnitOfMeasure } from '@/types';
import {
  Building,
  Home,
  MapPin,
  Wifi,
  KeyRound,
  DollarSign,
  Bed,
  Bath,
  PencilRuler,
  Ruler,
} from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { CreatePropertyHookInput, CreatePropertyInput } from '@/types/mutation/mutation-input-types';
import { ImageUpload } from './ImageUpload';
import { useTranslation } from 'react-i18next';
import { formatCurrency, getLocalizedUnit } from '@/utils/i18n-utils';
import { unitTerms } from '@/consts';

// Zod schema with translated error messages
const createFormSchema = (t: ReturnType<typeof useTranslation>['t']) => z.object({
  title: z.string().nullable(),
  city: z.string().min(1, t('properties.city') + " " + t('common.error')),
  street: z.string().min(1, t('properties.street') + " " + t('common.error')),
  building: z.string().min(1, t('properties.building') + " " + t('common.error')),
  block: z.string().nullable(),
  apartment: z.string().min(1, t('properties.apartment') + " " + t('common.error')),
  manager_id: z.string().nullable(),
  bills_pay_by_lessor: z.boolean().default(false),
  wi_fi_network: z.string().nullable(),
  wi_fi_key: z.string().nullable(),
  door_code: z.string().nullable(),
  isStudio: z.boolean().default(false),
  bedrooms: z.number().nullable(),
  bathrooms: z.number().nullable(),
  area: z.number().nullable(),
  image_url: z.string().nullable().default(''),
  price: z.number().min(1, t('properties.price') + " " + t('common.error')),
  currency: z.enum(['uah', 'usd'] as const).default('uah'),
  unit_measure: z.enum(['sq_foot', 'sq_meter'] as const).default('sq_meter'),
  unit_term: z.enum(['day', 'week', 'month'] as const).default('month'),
});

type PropertyDetailsFormProps = {
  property?: Property;
  onSubmit: (data: CreatePropertyHookInput) => void;
  isLoading: boolean;
};

export function PropertyDetailsForm({ property, onSubmit, isLoading }: PropertyDetailsFormProps) {
  const { t, i18n } = useTranslation();
  const [imageFile, setImageFile] = useState<File | null>(null);

  const formSchema = createFormSchema(t);

  const form = useForm<CreatePropertyInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: null,
      city: '',
      street: '',
      building: '',
      block: null,
      apartment: '',
      manager_id: null,
      bills_pay_by_lessor: false,
      wi_fi_network: null,
      wi_fi_key: null,
      door_code: null,
      isStudio: false,
      bedrooms: null,
      bathrooms: null,
      area: null,
      image_url: '',
      price: 1,
      currency: 'uah',
      unit_measure: 'sq_meter',
      unit_term: 'month',
    },
  });

  const handleImageChange = (imageUrl: string | null, file?: File | null) => {
    form.setValue('image_url', imageUrl || '');
    setImageFile(file || null);
  };

  const handleSubmit = (data: CreatePropertyHookInput) => {
    // Include the image file when submitting
    onSubmit({
      ...data,
      image_file: imageFile
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        {/* Image Upload Section - Added at the top */}
        <div className="bg-muted/40 p-6 rounded-lg space-y-4">
          <h2 className="text-xl font-semibold">{t('properties.property_image')}</h2>
          <ImageUpload
            initialImageUrl={form.getValues('image_url') || undefined}
            onImageChange={handleImageChange}
          />
          <FormDescription>
            {t('properties.upload_image')}
          </FormDescription>
        </div>

        {/* Basic Property Info Section */}
        <div className="bg-muted/40 p-6 rounded-lg space-y-6">
          <h2 className="text-xl font-semibold">{t('properties.basic_information')}</h2>

          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('properties.title')}</FormLabel>
                <FormControl>
                  <div className="flex items-center space-x-2">
                    <Home className="h-4 w-4 text-muted-foreground" />
                    <Input placeholder={t('properties.title')} {...field} value={field.value || ''} />
                  </div>
                </FormControl>
                <FormDescription>
                  {t('properties.property_description')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Location fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.city')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <MapPin className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.city')} {...field} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="street"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.street')}</FormLabel>
                  <FormControl>
                    <Input placeholder={t('properties.street')} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <FormField
              control={form.control}
              name="building"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.building')}</FormLabel>
                  <FormControl>
                    <Input placeholder={t('properties.building')} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="block"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.block')} (Optional)</FormLabel>
                  <FormControl>
                    <Input placeholder={t('properties.block')} {...field} value={field.value || ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="apartment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.apartment')}</FormLabel>
                  <FormControl>
                    <Input placeholder={t('properties.apartment')} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Property Details Section */}
        <div className="bg-muted/40 p-6 rounded-lg space-y-6">
          <h2 className="text-xl font-semibold">{t('properties.property_details')} </h2>

          <FormField
            control={form.control}
            name="isStudio"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">{t('properties.studio')}</FormLabel>
                  <FormDescription>
                    {t('properties.studio_description')}
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <FormField
              control={form.control}
              name="bedrooms"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.bedrooms')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Bed className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.number')} {...field} value={field.value || ''} disabled={form.watch('isStudio')} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bathrooms"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.bathrooms')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Bath className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.number')} {...field} value={field.value || ''} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="area"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.area')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <PencilRuler className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.total_area')} {...field} value={field.value || ''} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="unit_measure"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.unit_measure')}</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('properties.select_unit')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="sq_meter">{t('properties.units.sq_meter')}</SelectItem>
                      <SelectItem value="sq_foot">{t('properties.units.sq_foot')}</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Rental Information Section */}
        <div className="bg-muted/40 p-6 rounded-lg space-y-6">
          <h2 className="text-xl font-semibold">{t('properties.rental_information')}</h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.price')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <DollarSign className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.rental_price')} {...field} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="currency"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.currency')}</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('properties.select_currency')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="usd">{t('currencies.usd')}</SelectItem>
                      <SelectItem value="uah">{t('currencies.uah')}</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="unit_term"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.billing_period')}</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('properties.select_term')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="day">{t('properties.terms.day')}</SelectItem>
                      <SelectItem value="week">{t('properties.terms.week')}</SelectItem>
                      <SelectItem value="month">{t('properties.terms.month')}</SelectItem>
                      <SelectItem value="year">{t('properties.terms.year')}</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="bills_pay_by_lessor"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">{t('properties.bills_included')}</FormLabel>
                  <FormDescription>
                    {t('properties.bills_included_description')}
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Access Information */}
        <div className="bg-muted/40 p-6 rounded-lg space-y-6">
          <h2 className="text-xl font-semibold">{t('properties.access_information')}</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <FormField
              control={form.control}
              name="wi_fi_network"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.wifi_network')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <Wifi className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.enter_wifi_network')} {...field} value={field.value || ''} />
                    </div>
                  </FormControl>
                  <FormDescription className='ml-6'>
                    {t('properties.will_be_shared_with_tenants')}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="wi_fi_key"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('properties.wifi_password')}</FormLabel>
                  <FormControl>
                    <div className="flex items-center space-x-2">
                      <KeyRound className="h-4 w-4 text-muted-foreground" />
                      <Input placeholder={t('properties.enter_wifi_password')} {...field} value={field.value || ''} />
                    </div>
                  </FormControl>
                  <FormDescription className='ml-6'>
                    {t('properties.will_be_shared_with_tenants')}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="door_code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('properties.door_code')}</FormLabel>
                <FormControl>
                  <div className="flex items-center space-x-2">
                    <KeyRound className="h-4 w-4 text-muted-foreground" />
                    <Input placeholder={t('properties.enter_door_code')} {...field} value={field.value || ''} />
                  </div>
                </FormControl>
                <FormDescription className='ml-6'>
                  {t('properties.will_be_shared_with_tenants')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Form Submit Button */}
        <div className="flex justify-end gap-4">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                {t('properties.saving')}
              </>
            ) : (
              t('properties.add_property')
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}