"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { formatDate } from "@/utils/i18n-utils"
import { useTranslation } from "react-i18next"
import { enUS, uk } from "date-fns/locale"

interface DatePickerProps {
  date: Date | undefined
  setDate: (date: Date | undefined) => void
  disabled?: boolean
  className?: string
  placeholder?: string
  locale?: string
}

export function DatePicker({
  date,
  setDate,
  disabled = false,
  className,
  placeholder,
  locale = "en"
}: DatePickerProps) {
  const { t, i18n } = useTranslation()

  // Use provided locale or fall back to the current i18n language
  const currentLocale = locale || i18n.language

  // Default placeholder text with translation support
  const defaultPlaceholder = placeholder || t('common.select_date', 'Select date')

  // Map locale string to date-fns locale object
  const getDateFnsLocale = (localeCode: string) => {
    switch (localeCode) {
      case 'uk':
        return uk
      case 'en':
      default:
        return enUS
    }
  }

  // Get date-fns locale object based on current language
  const dateFnsLocale = getDateFnsLocale(currentLocale)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
            className
          )}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ?
            // Use i18n-utils formatting for the selected date
            formatDate(date.toISOString(), currentLocale, "PPP")
            :
            <span>{defaultPlaceholder}</span>
          }
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          locale={dateFnsLocale}
        // Add translations for months and weekdays if needed
        // className="rounded-md border"
        />
      </PopoverContent>
    </Popover>
  )
}