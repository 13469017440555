import React from 'react';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface DashboardErrorStateProps {
    onRetry: () => void;
}

/**
 * Error state component for the dashboard
 */
const DashboardErrorState: React.FC<DashboardErrorStateProps> = ({ onRetry }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col justify-center items-center h-96">
            <AlertCircle className="h-8 w-8 text-red-500 mb-4" />
            <p className="text-lg font-medium mb-2">{t('dashboard.error_loading')}</p>
            <Button onClick={onRetry}>{t('dashboard.retry')}</Button>
        </div>
    );
};

export default DashboardErrorState;