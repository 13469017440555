"use server"

import { graphqlClient } from "../graphql-client";
import { GET_ARCHIVED_RENTS } from "@/queries-graphql/get/get-archived-rents";
import { RawUserArchivedRentsResponse } from "@/types/raw-responses";
import { ArchivedRentsResponse } from "@/types/normalized-data-types";
import { Rent, UserRole } from "@/types";
import { FetchArchivedRentsParams } from "@/types/queries-inputs";

export async function fetchArchivedRents({
    user_id,
    role,
    first = 20,
    after = null
}: FetchArchivedRentsParams): Promise<ArchivedRentsResponse> {
    try {
        if (!user_id) {
            console.log("user ID required");
            return {
                rentsById: {},
                allRents: [],
                pageInfo: {
                    hasNextPage: false,
                    endCursor: null
                }
            };
        }

        // Create base variables object
        const variables: {
            user_id: string;
            role: string;
            first: number;
            after?: string;
            lessor_id: string | null;
        } = {
            user_id,
            role,
            first,
            lessor_id: user_id,
        };

        // Only include after if it's not null
        if (after) {
            variables.after = after;
        }

        const rawData: RawUserArchivedRentsResponse = await graphqlClient.request(
            GET_ARCHIVED_RENTS,
            variables
        );

        const rents = rawData.rentsCollection.edges.map(edge => edge.node);
        console.log(rents);
        const rentsById: Record<string, Rent> = {};
        const allRents: string[] = [];

        for (const rent of rents) {
            rentsById[rent.id] = rent;
            allRents.push(rent.id);
        }

        return {
            rentsById,
            allRents,
            pageInfo: rawData.rentsCollection.pageInfo
        };
    } catch (err) {
        console.log("Archived rents fetching error", err);
        return {
            rentsById: {},
            allRents: [],
            pageInfo: {
                hasNextPage: false,
                endCursor: null
            }
        };
    }
}