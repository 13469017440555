import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { toast } from '@/hooks/use-toast';
import { UserRole } from '@/types';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { fetchRent } from '@/lib/fetch-functions/fetch-rent';
import { fetchUser } from '@/lib/fetch-functions/fetch-user';
import { updateRent } from '@/lib/actions/update-rent'; // Direct import of the update function

const Register: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { register, isLoading: authLoading, user, role } = useAuth();
  const navigate = useNavigate();

  const emailParam = searchParams.get('email') || '';
  const rentId = searchParams.get('rentId');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(emailParam || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userRole, setUserRole] = useState<UserRole>(rentId ? 'tenant' : 'tenant');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHandlingRentInvitation, setIsHandlingRentInvitation] = useState(false);
  const [rentData, setRentData] = useState<any>(null);

  // Check if we have a valid rent invitation
  useEffect(() => {
    if (rentId) {
      const validateRentInvitation = async () => {
        try {
          const rent = await fetchRent(rentId);
          if (!rent) {
            setError("The invitation you're trying to accept is invalid or has expired.");
            return;
          }

          // Store rent data for later use
          setRentData(rent);

          // Force tenant role for rent invitations
          setUserRole('tenant');
        } catch (err) {
          console.error("Error validating rent invitation:", err);
          setError("There was an error validating your invitation.");
        }
      };

      validateRentInvitation();
    }
  }, [rentId]);

  // Redirect if user is already logged in
  useEffect(() => {
    if (user && role) {
      // If this is an invitation and user is already logged in,
      // we can try to handle it directly
      if (rentId && user.id && role === 'tenant') {
        handleExistingUserRentInvitation(user.id);
      } else {
        // Otherwise just redirect based on role
        if (role === 'tenant') {
          navigate('/tenant/properties');
        } else if (role === 'lessor') {
          navigate('/properties');
        } else {
          navigate('/');
        }
      }
    }
  }, [user, role, navigate, rentId]);

  // Handle invitation for already logged in users
  const handleExistingUserRentInvitation = async (userId: string) => {
    if (!rentId || !rentData) return;

    try {
      setIsHandlingRentInvitation(true);

      // Update the rent with the current user as tenant
      await updateRent({
        ...rentData,
        tenant_id: userId,
        updated_at: new Date().toISOString()
      });

      // Show success message
      toast({
        title: "Invitation Accepted",
        description: "You've been successfully added as a tenant to this property."
      });

      // Redirect to tenant properties
      navigate('/tenant/properties');
    } catch (err) {
      console.error("Error handling rent invitation:", err);
      toast({
        title: "Error Accepting Invitation",
        description: "There was a problem accepting your invitation.",
        variant: "destructive"
      });
    } finally {
      setIsHandlingRentInvitation(false);
    }
  };

  // Pre-fill email if provided in URL
  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam);
    }

    // If this is a rental invitation, force tenant role
    if (rentId) {
      setUserRole('tenant');
    }
  }, [emailParam, rentId]);

  const validateForm = () => {
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return false;
    }

    if (password.length < 6) {
      setError("Password must be at least 6 characters");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!validateForm()) {
      return;
    }

    try {
      setIsSubmitting(true);

      // First, check if a user with this email already exists
      const existingUser = await fetchUser(email, null);

      if (existingUser) {
        toast({
          title: "Account already exists",
          description: "A user with this email is already registered. Please log in instead.",
          variant: "destructive",
        });
        // Redirect to login page after a short delay
        setTimeout(() => navigate('/auth/login'), 2000);
        return;
      }

      // Proceed with registration
      // The third argument (rentId) tells the register function this is an invitation
      const userId = await register(email, password, firstName, lastName, userRole, rentId || undefined);

      if (rentId && userId && rentData) {
        try {
          // Update the rent with the newly created user as tenant
          await updateRent({
            ...rentData,
            tenant_id: userId,
            updated_at: new Date().toISOString()
          });

          toast({
            title: "Invitation Accepted",
            description: "Your account has been created and you've been added as a tenant."
          });

          // Redirect to tenant properties view
          // navigate('/tenant/properties');
        } catch (rentErr) {
          console.error("Error updating rent with new tenant:", rentErr);
          // Still redirect to properties but show an error about the invitation
          toast({
            title: "Account Created",
            description: "Your account was created but there was an issue with the rental invitation.",
            variant: "destructive"
          });
          navigate('/tenant/properties');
        }
      }
      // Regular registration flow - redirect to confirmation page
      navigate(`/auth/registration-confirmation?email=${encodeURIComponent(email)}`);

    } catch (error: any) {
      console.error("Registration error:", error);
      setError(error.message || "Registration failed. Please try again.");
      toast({
        title: "Registration Failed",
        description: error.message || "There was a problem creating your account.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isLoading = authLoading || isSubmitting || isHandlingRentInvitation;

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center">Create an account</CardTitle>
          <CardDescription className="text-center">
            Enter your information to create your account
            {rentId && (
              <div className="mt-2 text-sm font-medium text-primary">
                You've been invited to join as a tenant
              </div>
            )}
          </CardDescription>
        </CardHeader>
        <form onSubmit={handleSubmit}>
          <CardContent className="space-y-4">
            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            <div className='flex flex-row space-x-2'>
              <div className="space-y-2">
                <Label htmlFor="first_name">First Name</Label>
                <Input
                  id="first_name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="last_name">Last Name</Label>
                <Input
                  id="last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading || !!emailParam}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <Input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="space-y-2">
              <Label>I would like to use application mostly as:</Label>
              <p className="text-xs text-muted-foreground mt-1">
                Role can be switched in the app
              </p>
              <RadioGroup
                value={userRole}
                onValueChange={(value) => setUserRole(value as UserRole)}
                disabled={isLoading || !!rentId}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="tenant" id="tenant" />
                  <Label htmlFor="tenant">Tenant (I rent property)</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="lessor" id="lessor" />
                  <Label htmlFor="lessor">Lessor (I own property)</Label>
                </div>
              </RadioGroup>
              {rentId && (
                <p className="text-xs text-muted-foreground mt-1">
                  You're being invited as a tenant, so this option is pre-selected.
                </p>
              )}
            </div>
          </CardContent>
          <CardFooter className="flex flex-col gap-4">
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? (
                <>
                  <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                  Creating account...
                </>
              ) : (
                "Create account"
              )}
            </Button>
            <div className="text-sm text-center">
              Already have an account?{" "}
              <Link to="/auth/login" className="text-primary hover:underline">
                Sign in
              </Link>
            </div>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default Register;