// In create-user-profile.ts
"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { UserProfile, UserRole } from "@/types";
import { CreateUserProfileInput } from "@/types/mutation/mutation-input-types";
import { CreateUserProfileResponse } from "@/types/mutation/mutation-responses-types";
import { CREATE_USER_PROFILE } from "@/queries-graphql/mutation/new-user-profile-mutation";

// Function to add delay
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Function to create a user profile with retry logic
export const createUserProfile = async (
    input: CreateUserProfileInput,
    maxRetries = 3,
    retryDelay = 1000
): Promise<UserProfile> => {
    const client = graphqlClient;
    let lastError;

    for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
            console.log(`Creating user profile, attempt ${attempt}/${maxRetries}`);

            const variables = { ...input };
            const data = await client.request<CreateUserProfileResponse>(
                CREATE_USER_PROFILE,
                variables
            );

            const response = data.insertIntoprofilesCollection.records[0];
            console.log("Profile created successfully");
            return response;
        } catch (error) {
            console.error(`Error creating user profile (attempt ${attempt}):`, error);
            lastError = error;

            // If this is a foreign key constraint error, wait before retrying
            if (
                error instanceof Error &&
                error.message.includes("foreign key constraint") &&
                attempt < maxRetries
            ) {
                console.log(`Waiting ${retryDelay}ms before retry...`);
                await delay(retryDelay);
                // Increase delay for next retry
                retryDelay *= 2;
            } else {
                // For other errors or final attempt, break the loop
                break;
            }
        }
    }

    throw lastError || new Error('Failed to create user profile after multiple attempts');
};