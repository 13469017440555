import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Home } from 'lucide-react';
import { Property } from '@/types';
import { NormalizedData } from '@/types/normalized-data-types';
import DashboardPropertyCard from './DashboardPropertyCard';

interface DashboardPropertiesProps {
    properties: Property[];
    userData?: NormalizedData;
}

/**
 * Component to display all tenant properties on the dashboard
 */
const DashboardProperties: React.FC<DashboardPropertiesProps> = ({ properties, userData }) => {
    if (!properties.length) {
        return (
            <Card>
                <CardContent className="p-6 text-center">
                    <Home className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                    <h3 className="text-lg font-medium mb-2">No rentals yet</h3>
                    <p className="text-muted-foreground mb-4">You don't have any active rentals</p>
                </CardContent>
            </Card>
        );
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {properties.map((property) => {
                // Find the associated rent to get rent-specific data
                const rentIds = userData?.propertyRents?.[property.id] || [];
                const rent = rentIds.length > 0 ? userData?.rents[rentIds[0]] : null;

                return (
                    <DashboardPropertyCard
                        key={property.id}
                        property={property}
                        rent={rent}
                    />
                );
            })}
        </div>
    );
};

export default DashboardProperties;