import React from 'react';
import { Button } from '@/components/ui/button';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerFooter } from '@/components/ui/drawer';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter } from '@/components/ui/sheet';
import { RepairStatus, UserRole } from '@/types';
import { useIsMobile } from '@/hooks/use-mobile';
import {
    RadioGroup,
    RadioGroupItem,
} from "@/components/ui/radio-group"
import { Label } from '@/components/ui/label';
import {
    Filter,
    Clock,
    Wrench,
    CheckCircle,
    XCircle,
    RotateCcw,
    Calendar,
    AlertCircle,
} from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { useTranslation } from 'react-i18next';

interface MaintenanceFilterDrawerProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    filterStatus: string;
    sortBy: 'date' | 'priority';
    onFilterChange: (status: string) => void;
    onSortChange: (sort: 'date' | 'priority') => void;
}

const MaintenanceFilterDrawer: React.FC<MaintenanceFilterDrawerProps> = ({
    isOpen,
    onOpenChange,
    filterStatus,
    sortBy,
    onFilterChange,
    onSortChange,
}) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    // The common content for both mobile drawer and desktop sheet
    const filterContent = (
        <>
            <div className="space-y-6 p-1">
                <div className="space-y-3">
                    <h3 className="font-medium flex items-center">
                        <Filter className="mr-2 h-4 w-4" />
                        {t('maintenance.filter_by')}
                    </h3>
                    <RadioGroup
                        value={filterStatus}
                        onValueChange={onFilterChange}
                        className="space-y-3"
                    >
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="all" id="status-all" />
                            <Label htmlFor="status-all" className="flex-1 cursor-pointer">{t('maintenance.all')}</Label>
                        </div>
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="pending" id="status-pending" />
                            <Label htmlFor="status-pending" className="flex items-center cursor-pointer">
                                <Clock className="mr-2 h-4 w-4 text-amber-500" /> {t('maintenance.statuses.pending')}
                            </Label>
                        </div>
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="in_progress" id="status-in-progress" />
                            <Label htmlFor="status-in-progress" className="flex items-center cursor-pointer">
                                <Wrench className="mr-2 h-4 w-4 text-blue-500" /> {t('maintenance.statuses.in_progress')}
                            </Label>
                        </div>
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="completed" id="status-completed" />
                            <Label htmlFor="status-completed" className="flex items-center cursor-pointer">
                                <CheckCircle className="mr-2 h-4 w-4 text-green-500" /> {t('maintenance.statuses.completed')}
                            </Label>
                        </div>
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="canceled" id="status-canceled" />
                            <Label htmlFor="status-canceled" className="flex items-center cursor-pointer">
                                <XCircle className="mr-2 h-4 w-4 text-gray-500" /> {t('maintenance.statuses.canceled')}
                            </Label>
                        </div>
                    </RadioGroup>
                </div>

                <Separator />

                <div className="space-y-3">
                    <h3 className="font-medium flex items-center">
                        <RotateCcw className="mr-2 h-4 w-4" />
                        {t('maintenance.sort_by')}
                    </h3>
                    <RadioGroup
                        value={sortBy}
                        onValueChange={(value) => onSortChange(value as 'date' | 'priority')}
                        className="space-y-3"
                    >
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="date" id="sort-date" />
                            <Label htmlFor="sort-date" className="flex items-center cursor-pointer">
                                <Calendar className="mr-2 h-4 w-4" /> {t('maintenance.date')}
                            </Label>
                        </div>
                        <div className="flex items-center space-x-2 rounded-md border p-2 hover:bg-muted">
                            <RadioGroupItem value="priority" id="sort-priority" />
                            <Label htmlFor="sort-priority" className="flex items-center cursor-pointer">
                                <AlertCircle className="mr-2 h-4 w-4" /> {t('maintenance.priority')}
                            </Label>
                        </div>
                    </RadioGroup>
                </div>
            </div>
        </>
    );

    if (isMobile) {
        return (
            <Drawer open={isOpen} onOpenChange={onOpenChange}>
                <DrawerContent>
                    <DrawerHeader>
                        <DrawerTitle>{t('maintenance.drawer_title')}</DrawerTitle>
                    </DrawerHeader>
                    <div className="px-4 pb-2">
                        {filterContent}
                    </div>
                    <DrawerFooter>
                        <Button onClick={() => onOpenChange(false)}>{t('maintenance.apply_filters')}</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        );
    }

    return (
        <Sheet open={isOpen} onOpenChange={onOpenChange}>
            <SheetContent className="sm:max-w-md">
                <SheetHeader>
                    <SheetTitle>{t('maintenance.drawer_title')}</SheetTitle>
                </SheetHeader>
                <div className="pt-4">
                    {filterContent}
                </div>
                <SheetFooter className="pt-4">
                    <Button onClick={() => onOpenChange(false)}>{t('maintenance.apply_filters')}</Button>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
};

export default MaintenanceFilterDrawer;