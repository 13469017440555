import { useQuery } from "@tanstack/react-query";
import { fetchUserData } from "../fetch-functions/fetch-all-user-data";
import { queryKeys } from "./query-factory";

// Hook to fetch user data
export function useUserData(userId: string) {
  console.log("Get all user data Hook triggerd");
  const query = useQuery({
    queryKey: queryKeys.userData(userId),
    queryFn: () => fetchUserData(userId),
    refetchOnWindowFocus: false, // Prevent unnecessary refetching
    staleTime: Infinity,
    enabled: !!userId,

  });
  return {
    ...query,
    refetch: query.refetch,
  }
}