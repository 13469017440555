import React from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

export const DashboardSkeleton: React.FC = () => {
    return (
        <div className="space-y-6 pt-2 pb-8">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <Skeleton className="h-10 w-64 bg-blue-200" />
                <Skeleton className="h-10 w-40 bg-blue-200" />
            </div>

            {/* Skeleton cards for metrics */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {Array(4).fill(0).map((_, index) => (
                    <Card key={index}>
                        <CardHeader className="pb-2">
                            <Skeleton className="h-4 w-32 mb-2 bg-blue-200" />
                            <Skeleton className="h-8 w-20 bg-blue-200" />
                        </CardHeader>
                        <CardContent>
                            <div className="flex items-center">
                                <Skeleton className="h-4 w-4 mr-1 rounded-full bg-blue-200" />
                                <Skeleton className="h-4 w-48 bg-blue-200" />
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>

            <div>
                <Skeleton className="h-8 w-48 mb-4 bg-blue-200" />
                {/* Skeleton property cards */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Array(3).fill(0).map((_, index) => (
                        <Card key={index} className="hover:shadow-md transition-shadow">
                            <CardHeader>
                                <Skeleton className="h-6 w-full mb-2 bg-blue-200" />
                                <Skeleton className="h-4 w-3/4 bg-blue-200" />
                            </CardHeader>
                            <CardContent>
                                <div className="flex flex-col space-y-2">
                                    {Array(3).fill(0).map((_, i) => (
                                        <div key={i} className="flex justify-between">
                                            <Skeleton className="h-4 w-24 bg-blue-200" />
                                            <Skeleton className="h-4 w-24 bg-blue-200" />
                                        </div>
                                    ))}
                                </div>
                                <Skeleton className="h-10 w-full mt-4 bg-blue-200" />
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};