import React, { useState, useCallback, useMemo } from 'react';
import { MainLayout } from '@/components/layout/MainLayout';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription } from '@/components/ui/sheet';
import { toast } from '@/hooks/use-toast';
import { ToastAction } from '@/components/ui/toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Card, CardContent } from '@/components/ui/card';
import { MaintenanceRequestType, Repair, RepairPriority, RepairStatus } from '@/types';
import { useUserRepairs } from '@/lib/query-hooks/get-hooks';
import { useUserProperties } from '@/lib/query-hooks/get-hooks';
import { useCreateRepair } from '@/lib/query-hooks/mutation/useCreateRepair';
import { useUpdateRepair } from '@/lib/query-hooks/mutation/useUpdateRepair';
import { CreateRepairInput } from '@/types/mutation/mutation-input-types';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useAuth } from '@/contexts/AuthContext';
import { getPropertyAddress } from '@/lib/helpers';
import { useTranslation } from 'react-i18next';

// Import the component modules we created
import { PropertyMaintenanceCard } from '@/components/maintenance/PropertyMaintenanceCard';
import { MaintenanceRequestForm } from '@/components/maintenance/MaintenanceRequestForm';
import { RequestFilterHeader } from '@/components/maintenance/RequestFilterHeader';
import { EmptyMaintenanceState } from '@/components/maintenance/EmptyMaintenanceState';
import MaintenanceFilterDrawer from '@/components/maintenance/MaintenanceFilterDrawer';

const MaintenanceRequests = () => {
  const { t } = useTranslation();
  const { user, role: userRole } = useAuth();
  const userId = user?.id || '';

  // Fetch user data and properties
  const { data: userData, isLoading: isLoadingUserData } = useUserData(userId);
  const { repairsByProperty, properties, isLoading } = useUserRepairs(userId, userRole);
  const userProperties = useUserProperties(userId, userRole);

  // Set up mutations
  const {
    createRepairAsync,
    isLoading: newRepairCreationInProgress,
  } = useCreateRepair({
    userId: userId,
    onSuccess: () => {
      toast({
        title: t('maintenance.requests'),
        description: t('common.create') + " " + t('maintenance.successful'),
      });
    },
    onError: (error) => {
      toast({
        title: t('properties.toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>,
      })
    },
  });

  const {
    updateRepairAsync,
    isLoading: updateRepairInProgress,
  } = useUpdateRepair({
    userId: userId,
    onSuccess: () => {
      toast({
        title: t('maintenance.requests'),
        description: t('common.edit') + " " + t('maintenance.successful'),
      });
    },
    onError: (error) => {
      toast({
        title: t('properties.toast.error_title'),
        description: error.message,
        variant: 'destructive',
        action: <ToastAction altText={t('common.retry')}>{t('common.retry')}</ToastAction>,
      })
    },
  });

  // States
  const [isRepairSheetOpen, setIsRepairSheetOpen] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [newRequest, setNewRequest] = useState({
    propertyId: '',
    title: '',
    description: '',
    type: 'appliance' as MaintenanceRequestType,
    priority: 'low' as RepairPriority
  });
  const [editRequest, setEditRequest] = useState<Repair | null>(null);
  const [isNewRequest, setIsNewRequest] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [sortBy, setSortBy] = useState<'date' | 'priority'>('date');
  const [openPropertyIds, setOpenPropertyIds] = useState<string[]>([]);

  // Get all repairs as flat array for filtering
  const allRepairs = useMemo(() => {
    return Object.values(repairsByProperty).flat();
  }, [repairsByProperty]);

  // Filter repairs based on status
  const filteredRepairs = useMemo(() => {
    let filtered = allRepairs;

    // Filter by status if not "all"
    if (filterStatus !== 'all') {
      filtered = filtered.filter(repair => repair.status === filterStatus as RepairStatus);
    }

    // Sort repairs
    return [...filtered].sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      } else if (sortBy === 'priority') {
        const priorityOrder = { high: 0, medium: 1, low: 2 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      }
      return 0;
    });
  }, [allRepairs, filterStatus, sortBy]);

  // Group the filtered repairs by property
  const groupedRepairs = useMemo(() => {
    const result: Record<string, Repair[]> = {};

    filteredRepairs.forEach(repair => {
      if (!result[repair.property_id]) {
        result[repair.property_id] = [];
      }
      result[repair.property_id].push(repair);
    });

    return result;
  }, [filteredRepairs]);

  // Toggle property card open/closed state
  const togglePropertyCard = useCallback((propertyId: string) => {
    setOpenPropertyIds(prev =>
      prev.includes(propertyId)
        ? prev.filter(id => id !== propertyId)
        : [...prev, propertyId]
    );
  }, []);

  // Handle creating a new repair request
  const handleCreateRequest = useCallback(() => {
    if (!newRequest.propertyId || !newRequest.title || !newRequest.type) {
      toast({
        title: t('common.error'),
        description: t('maintenance.form_incomplete'),
        variant: 'destructive',
      })
      return;
    }

    const newRepair: CreateRepairInput = {
      property_id: newRequest.propertyId,
      tenant_id: userId,
      title: newRequest.title,
      description: newRequest.description,
      type: newRequest.type,
      status: 'pending',
      priority: newRequest.priority,
      completed_by_tenant: false
    };

    createRepairAsync(newRepair);
    setIsRepairSheetOpen(false);
    setIsNewRequest(false);
    setNewRequest({
      propertyId: '',
      title: '',
      description: '',
      type: 'appliance' as MaintenanceRequestType,
      priority: 'low' as RepairPriority
    });
  }, [newRequest, userId, createRepairAsync, t]);

  // Handle updating repair status
  const handleUpdateStatus = useCallback((repair: Repair, newStatus: RepairStatus) => {
    const date = new Date()
    updateRepairAsync(
      {
        ...repair,
        status: newStatus,
        updated_at: date.toISOString(),
        completed_by_tenant: repair.tenant_id === userId && newStatus === "completed" ? true : false
      },
      {
        onSuccess: () => {
          toast({
            title: t('maintenance.request_status_changed'),
            description: t(`maintenance.status_actions.${newStatus}`),
          });
        },
        onError: () => {
          toast({
            title: t('properties.toast.error_title'),
            description: t('common.error_loading'),
            variant: 'destructive',
          })
        }
      }
    );
  }, [updateRepairAsync, userId, t]);

  // Handle updating repair request
  const handleUpdateRequest = useCallback(() => {
    if (!editRequest || !editRequest.title || !editRequest.type) {
      toast({
        title: t('common.error'),
        description: t('maintenance.form_incomplete'),
        variant: 'destructive',
      })
      return;
    }

    const date = new Date()
    updateRepairAsync(
      {
        ...editRequest,
        updated_at: date.toISOString()
      }
    );
    setIsRepairSheetOpen(false);
    setEditRequest(null)
    setIsNewRequest(false);
  }, [updateRepairAsync, editRequest, t]);

  // Handle form field changes
  const handleFormChange = useCallback((field: string, value: any) => {
    if (isNewRequest) {
      setNewRequest(prev => ({ ...prev, [field]: value }));
    } else if (editRequest) {
      setEditRequest(prev => prev ? { ...prev, [field]: value } : null);
    }
  }, [isNewRequest, editRequest]);

  // Handle edit request
  const handleEditRequest = useCallback((repair: Repair) => {
    setIsNewRequest(false);
    setEditRequest(repair);
    setIsRepairSheetOpen(true);
  }, []);

  // Handle opening new request form
  const handleOpenNewRequest = useCallback(() => {
    setIsNewRequest(true);
    setEditRequest(null);
    setIsRepairSheetOpen(true);

    // If user has only one property, auto-select it
    if (userProperties.length === 1) {
      setNewRequest(prev => ({
        ...prev,
        propertyId: userProperties[0].id
      }));
    }
  }, [userProperties]);

  // Handle closing request sheet
  const handleSheetOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setIsRepairSheetOpen(false);
      // Reset the forms when closing
      if (isNewRequest) {
        setNewRequest({
          propertyId: '',
          title: '',
          description: '',
          type: 'appliance',
          priority: 'low'
        });
      }
      setEditRequest(null);
      setIsNewRequest(false);
    }
  }, [isNewRequest]);

  // Handle filter changes
  const handleFilterChange = useCallback((status: string) => {
    setFilterStatus(status);
  }, []);

  // Handle sort changes
  const handleSortChange = useCallback((sort: 'date' | 'priority') => {
    setSortBy(sort);
  }, []);

  // Get property name by ID (for display purposes)
  const getPropertyName = useCallback((propertyId: string) => {
    const property = properties.find(p => p.id === propertyId);
    if (property) {
      return property.title || getPropertyAddress(property);
    }
    return t('common.unknown_property');
  }, [properties, t]);

  // Loading state
  if (isLoading) {
    return (
      <MainLayout>
        <div className="space-y-6 pt-2 pb-8 mx-auto">
          <h1 className="text-3xl font-bold tracking-tight">{t('maintenance.requests')}</h1>
          <div className="grid grid-cols-1 gap-6">
            <Card>
              <CardContent className="p-6">
                <div className="space-y-4">
                  <Skeleton className="h-6 w-3/4" />
                  <Skeleton className="h-20 w-full" />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="p-6">
                <div className="space-y-4">
                  <Skeleton className="h-6 w-3/4" />
                  <Skeleton className="h-20 w-full" />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0 mx-auto">
        {/* Header with filter button */}
        <RequestFilterHeader
          title={t('maintenance.requests')}
          description={
            userRole === 'tenant'
              ? t('maintenance.tenant_description')
              : t('maintenance.lessor_description')
          }
          userRole={userRole}
          filterStatus={filterStatus}
          sortBy={sortBy}
          onCreateRequest={handleOpenNewRequest}
          onOpenFilters={() => setIsFilterDrawerOpen(true)}
        />

        {/* Main content - property cards with repairs */}
        {Object.keys(groupedRepairs).length === 0 ? (
          <EmptyMaintenanceState
            userRole={userRole}
            onCreateRequest={handleOpenNewRequest}
          />
        ) : (
          <div className="space-y-6">
            {Object.entries(groupedRepairs).map(([propertyId, repairs]) => (
              <PropertyMaintenanceCard
                key={propertyId}
                propertyId={propertyId}
                propertyName={getPropertyName(propertyId)}
                repairs={repairs}
                isOpen={openPropertyIds.includes(propertyId)}
                userRole={userRole}
                userId={userId}
                userData={userData?.users}
                onToggle={togglePropertyCard}
                onUpdateStatus={handleUpdateStatus}
                onEditRequest={handleEditRequest}
              />
            ))}
          </div>
        )}

        {/* Filter drawer/sheet */}
        <MaintenanceFilterDrawer
          isOpen={isFilterDrawerOpen}
          onOpenChange={setIsFilterDrawerOpen}
          filterStatus={filterStatus}
          sortBy={sortBy}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
        />

        {/* Create/Edit request sheet */}
        <Sheet open={isRepairSheetOpen} onOpenChange={handleSheetOpenChange}>
          <SheetContent className="sm:max-w-md p-0">
            <div className="flex flex-col h-full">
              <div className="p-6 pb-0">
                <SheetHeader>
                  <SheetTitle>{isNewRequest ? t('maintenance.new_request') : t('maintenance.edit_request')}</SheetTitle>
                  <SheetDescription>
                    {isNewRequest
                      ? t('maintenance.submit_request')
                      : t('maintenance.update_details')
                    }
                  </SheetDescription>
                </SheetHeader>
              </div>

              <div className="flex-1 px-6 overflow-hidden">
                <MaintenanceRequestForm
                  isNew={isNewRequest}
                  formData={isNewRequest ? newRequest : editRequest || {}}
                  properties={userProperties}
                  isLoading={isNewRequest ? newRepairCreationInProgress : updateRepairInProgress}
                  onFormChange={handleFormChange}
                  onSubmit={isNewRequest ? handleCreateRequest : handleUpdateRequest}
                  onCancel={() => setIsRepairSheetOpen(false)}
                />
              </div>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </MainLayout>
  );
};

export default MaintenanceRequests;