import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Building, Users, DollarSign, Plus } from 'lucide-react';
import { Property } from '@/types';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useUserProperties } from '@/lib/query-hooks/get-hooks';
import { useAuth } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/i18n-utils';
import { Button } from '@/components/ui/button';

// Import our modular components
import {
  DashboardMetricCard,
  DashboardSkeleton,
  DashboardErrorState,
  DashboardProperties
} from '@/components/lessor-dashboard';


// Main LessorDashboard component
export const LessorDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();

  // Fetch the core user data
  const {
    data: userData,
    isLoading: isLoadingUserData,
    isError: isErrorUserData,
    refetch: refetchAllUserData
  } = useUserData(user?.id || '');

  // Get all lessor properties
  const properties = useUserProperties(user?.id || '', 'lessor');

  // Loading state
  if (isLoadingUserData || !user) {
    return <DashboardSkeleton />;
  }

  // Error state
  if (isErrorUserData || !userData) {
    return <DashboardErrorState onRetry={refetchAllUserData} />;
  }

  // Calculate dashboard metrics
  const totalProperties = properties.length;
  const occupiedProperties = properties.filter(prop => prop.status === 'occupied').length;
  const vacantProperties = properties.filter(prop => prop.status === 'vacant').length;

  // Calculate total revenue (only from occupied properties)
  const totalRevenue = properties
    .filter(prop => prop.status === 'occupied')
    .reduce((sum, prop) => {
      const price = prop.price;
      return isNaN(price) ? sum : sum + price;
    }, 0);

  // Function to get tenant name from property
  const getTenantName = (property: Property): string => {
    if (!userData.propertyRents?.[property.id]?.length) return t('properties.status.vacant');

    const rentId = userData.propertyRents[property.id][0]; // Get the first rent
    const rent = userData.rents[rentId];
    if (!rent) return t('properties.status.vacant');

    const tenant = userData.users[rent.tenant_id];
    if (!tenant) return t('tenant.no_tenant');

    return `${tenant.first_name} ${tenant.last_name || ''}`;
  };

  // Function to get lease start date
  const getLeaseStartDate = (property: Property): string | null => {
    if (!userData.propertyRents?.[property.id]?.length) return null;

    const rentId = userData.propertyRents[property.id][0]; // Get the first rent
    const rent = userData.rents[rentId];
    if (!rent) return null;

    return rent.date_start;
  };

  // Navigation handlers
  const handleAddProperty = () => navigate('/properties/add');
  const handleViewDetails = (propertyId: string) => navigate(`/properties/${propertyId}`);
  const handleInviteTenant = (propertyId: string) => navigate(`/properties/${propertyId}/invite`);
  const handleViewAllProperties = () => navigate('/properties');

  return (
    <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">
          {t('dashboard.lessor_title')}
        </h1>
        <Button onClick={handleAddProperty}>
          <Plus className="mr-2 h-4 w-4" /> {t('properties.add_property')}
        </Button>
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <DashboardMetricCard
          title={t('dashboard.total_properties')}
          value={totalProperties}
          description={t('dashboard.properties_under_management')}
          icon={Building}
        />

        <DashboardMetricCard
          title={t('dashboard.occupied_units')}
          value={occupiedProperties}
          description={
            totalProperties > 0
              ? t('dashboard.occupancy_rate', {
                rate: Math.round((occupiedProperties / totalProperties) * 100)
              })
              : t('dashboard.no_properties')
          }
          icon={Users}
        />

        <DashboardMetricCard
          title={t('dashboard.vacant_units')}
          value={vacantProperties}
          description={t('dashboard.available_for_tenants')}
          icon={Building}
        />

        <DashboardMetricCard
          title={t('dashboard.monthly_revenue')}
          value={formatCurrency(totalRevenue, i18n.language, 'USD')}
          description={t('dashboard.based_on_current_rates')}
          icon={DollarSign}
        />
      </div>

      {/* Properties Section */}
      <div>
        <h2 className="text-xl font-semibold mb-4">
          {t('dashboard.recent_properties')}
        </h2>

        <DashboardProperties
          properties={properties}
          getTenantName={getTenantName}
          getLeaseStartDate={getLeaseStartDate}
          onViewDetails={handleViewDetails}
          onInviteTenant={handleInviteTenant}
          onViewAllProperties={handleViewAllProperties}
          onAddProperty={handleAddProperty}
        />
      </div>
    </div>
  );
};