"use server"

import { graphqlClient } from "../graphql-client";
import { GET_ALL_DATA } from "@/queries-graphql/get/get-all-user-data-query";
// import { UserDataResponse } from "@/types"; // Import your response type
import { RawUserDataResponse } from "@/types/raw-responses";
import { normalizeUserData } from "./normalize-raw-data-helper";
import { NormalizedData } from "@/types/normalized-data-types";

export async function fetchUserData(user_id: string): Promise<NormalizedData> {
    try {
        if (user_id) {
            // Fetch data from GraphQL API
            const rawData: RawUserDataResponse = await graphqlClient.request(GET_ALL_DATA, { user_id });

            // Normalize the data
            const normUserData = normalizeUserData(rawData) as NormalizedData;

            // Add the standalone unpaid rent bills to the normalized data
            // These come from the separate rent_billsCollection query
            if (rawData.rents_billsCollection?.edges) {
                rawData.rents_billsCollection.edges.forEach(edge => {
                    const bill = edge.node;

                    // Add the bill to rentBills collection
                    normUserData.rentBills[bill.id] = bill;

                    // Add to rentBillsByRent relationship
                    if (bill.rent_id) {
                        if (!normUserData.rentBillsByRent[bill.rent_id]) {
                            normUserData.rentBillsByRent[bill.rent_id] = [];
                        }

                        // Only add if not already present
                        if (!normUserData.rentBillsByRent[bill.rent_id].includes(bill.id)) {
                            normUserData.rentBillsByRent[bill.rent_id].push(bill.id);
                        }
                    }
                });
            }

            return normUserData;
        }
        throw new Error("user ID required");
    }
    catch (err) {
        console.error("User data fetching error", err);
        throw err;
    }
}