import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { ArrowLeft, Plus, ClipboardList } from 'lucide-react';
import ChecklistCard from '@/components/checklists/ChecklistCard';
import { toast } from '@/hooks/use-toast';
import { useAuth } from '@/contexts/AuthContext';
import { usePropertyChecklists } from '@/lib/query-hooks/get-hooks';
import { useProperty } from '@/lib/query-hooks/get-hooks';
import { Skeleton } from '@/components/ui/skeleton';
import { useTranslation } from 'react-i18next';

const PropertyChecklists: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userId = user?.id || '';
  const propertyId = id || '';

  // Get property data
  const property = useProperty(propertyId, userId);

  // Get checklists data
  const {
    checklists,
    inventoryItems,
    isLoading,
    isError
  } = usePropertyChecklists(propertyId, userId);

  if (isLoading) {
    return (
      <MainLayout>
        <div className="space-y-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <Skeleton className="h-4 w-32 mb-2" />
              <Skeleton className="h-10 w-64 mb-1" />
              <Skeleton className="h-4 w-48" />
            </div>
            <Skeleton className="h-10 w-36" />
          </div>

          <div>
            <Skeleton className="h-6 w-48 mb-4" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} className="h-64 w-full" />
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

  if (isError || !property) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <h2 className="text-2xl font-bold">{t('properties.property_not_found')}</h2>
            <p className="text-muted-foreground mt-2">{t('properties.property_not_exist')}</p>
            <Button className="mt-4" onClick={() => navigate('/properties')}>
              {t('navigation.back_to_properties')}
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }

  const handleRunChecklist = (checklistId: string) => {
    navigate(`/properties/${id}/checklists/${checklistId}/run`);
  };

  const handleEditChecklist = (checklistId: string) => {
    navigate(`/properties/${id}/checklists/${checklistId}/edit`);
  };

  return (
    <MainLayout>
      <div className="space-y-6 pt-2 pb-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div>
            <Button
              variant="ghost"
              onClick={() => navigate(`/properties/${id}`)}
              className="mb-2 -ml-2 px-2"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              {t('checklists.back_to_property', 'Back to Property')}
            </Button>
            <h1 className="text-3xl font-bold tracking-tight">{property.street}</h1>
            <p className="text-muted-foreground mt-1">
              {property.city}, {property.apartment}
            </p>
          </div>

          <Button onClick={() => navigate(`/properties/${id}/checklists/new`)}>
            <Plus className="mr-2 h-4 w-4" /> {t('checklists.create_new')}
          </Button>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">{t('checklists.property_checklists', 'Property Checklists')}</h2>

          {checklists.length === 0 ? (
            <Card>
              <CardContent className="p-8 text-center">
                <ClipboardList className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                <h3 className="text-lg font-medium mb-2">{t('checklists.no_checklists')}</h3>
                <p className="text-muted-foreground mb-4">
                  {t('checklists.create_first_description', 'Create your first checklist for this property to track inventory items and maintenance needs.')}
                </p>
                <Button onClick={() => navigate(`/properties/${id}/checklists/new`)}>
                  <Plus className="mr-2 h-4 w-4" /> {t('checklists.create_first_button', 'Create First Checklist')}
                </Button>
              </CardContent>
            </Card>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {checklists.map(checklist => (
                <ChecklistCard
                  key={checklist.id}
                  checklist={checklist}
                  propertyId={propertyId}
                  items={inventoryItems[checklist.id] || []}
                  onEdit={() => handleEditChecklist(checklist.id)}
                  onRun={() => handleRunChecklist(checklist.id)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default PropertyChecklists;