import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { LockKeyhole, Edit, CheckCircle2, AlertCircle } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import PasswordInput from '../ui/custom-ui/PasswordInput';
import { toast } from '@/hooks/use-toast';
import { useTranslation } from 'react-i18next';

interface ProfilePasswordChangeProps {
    className?: string;
}

const ProfilePasswordChange: React.FC<ProfilePasswordChangeProps> = ({ className }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);

    // Form state
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const resetForm = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setError(null);
        setIsSubmitted(false);
    };

    const handleCloseDialog = () => {
        resetForm();
        setIsChangePasswordDialogOpen(false);
    };

    const validateForm = (): boolean => {
        // Empty fields validation
        if (!currentPassword.trim() || !newPassword.trim() || !confirmPassword.trim()) {
            setError(t('profile.password.all_fields_required'));
            return false;
        }

        // Password match validation
        if (newPassword !== confirmPassword) {
            setError(t('profile.password.passwords_dont_match'));
            return false;
        }

        // Password length validation
        if (newPassword.length < 6) {
            setError(t('profile.password.min_length'));
            return false;
        }

        // Ensure new password is different from current password
        if (currentPassword === newPassword) {
            setError(t('profile.password.must_be_different'));
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            // Verify current password first by signing in
            const { error: signInError } = await supabase.auth.signInWithPassword({
                email: user?.email || '',
                password: currentPassword,
            });

            if (signInError) {
                throw new Error(t('profile.password.incorrect_password'));
            }

            // Update password
            const { error: updateError } = await supabase.auth.updateUser({
                password: newPassword
            });

            if (updateError) {
                throw updateError;
            }

            setIsSubmitted(true);

            toast({
                title: t('profile.password.success_title'),
                description: t('profile.password.success_description'),
            });
        } catch (error) {
            console.error('Password change error:', error);
            let errorMessage = t('profile.password.general_error');

            if (error instanceof Error) {
                errorMessage = error.message;
            }

            setError(errorMessage);

            toast({
                title: t('profile.password.failed_title'),
                description: errorMessage,
                variant: "destructive",
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Calculate last password update time
    const getLastUpdated = () => {
        try {
            if (user?.updated_at) {
                const date = new Date(user.updated_at);
                return date.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
            }
            return t('profile.password.not_available');
        } catch (e) {
            return t('profile.password.not_available');
        }
    };

    return (
        <>
            <Card className={className}>
                <CardHeader>
                    <CardTitle className="text-xl">{t('profile.password.title')}</CardTitle>
                    <CardDescription>
                        {t('profile.password.subtitle')}
                    </CardDescription>
                </CardHeader>
                <CardContent className="pt-0 flex-grow">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <LockKeyhole className="mr-2 h-4 w-4 text-muted-foreground" />
                            <span>••••••••</span>
                        </div>
                        <div className="text-xs text-muted-foreground">
                            {t('profile.password.last_updated')}: {getLastUpdated()}
                        </div>
                    </div>
                </CardContent>
                <CardFooter className="w-full border-t bg-muted/50 px-6 py-4 self-end">
                    <Button
                        variant="outline"
                        className="gap-1"
                        onClick={() => setIsChangePasswordDialogOpen(true)}
                    >
                        <Edit className="h-4 w-4" />
                        <span>{t('profile.password.change_button')}</span>
                    </Button>
                </CardFooter>
            </Card>

            <Dialog open={isChangePasswordDialogOpen} onOpenChange={handleCloseDialog}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle>{t('profile.password.dialog_title')}</DialogTitle>
                        <DialogDescription>
                            {t('profile.password.dialog_description')}
                        </DialogDescription>
                    </DialogHeader>

                    {isSubmitted ? (
                        <div className="space-y-4 py-4">
                            <div className="flex items-center justify-center py-4">
                                <div className="h-16 w-16 bg-green-100 rounded-full flex items-center justify-center">
                                    <CheckCircle2 className="h-8 w-8 text-green-600" />
                                </div>
                            </div>

                            <Alert className="bg-green-50 border-green-200">
                                <AlertDescription>
                                    {t('profile.password.success_message')}
                                </AlertDescription>
                            </Alert>

                            <div className="text-center mt-4">
                                <Button onClick={handleCloseDialog}>
                                    {t('common.close')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-4 py-4">
                            {error && (
                                <Alert variant="destructive">
                                    <AlertCircle className="h-4 w-4" />
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}

                            <PasswordInput
                                id="currentPassword"
                                label={t('profile.password.current_password')}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                placeholder={t('profile.password.current_password_placeholder')}
                            />

                            <PasswordInput
                                id="newPassword"
                                label={t('profile.password.new_password')}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder={t('profile.password.new_password_placeholder')}
                            />

                            <PasswordInput
                                id="confirmPassword"
                                label={t('profile.password.confirm_password')}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder={t('profile.password.confirm_password_placeholder')}
                            />

                            <div className="mt-2">
                                <p className="text-sm text-muted-foreground">
                                    {t('profile.password.requirements')}
                                </p>
                            </div>

                            <div className="flex justify-end gap-2 pt-4">
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={handleCloseDialog}
                                >
                                    {t('common.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                                            {t('profile.password.updating')}
                                        </>
                                    ) : (
                                        t('profile.password.update_button')
                                    )}
                                </Button>
                            </div>
                        </form>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProfilePasswordChange;