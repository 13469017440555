import React from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface NotificationBadgeProps {
  count: number;
  className?: string;
  variant?: 'default' | 'destructive' | 'outline' | 'secondary';
  size?: 'default' | 'sm';
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  count,
  className,
  variant = 'destructive',
  size = 'default'
}) => {
  if (count <= 0) return null;

  return (
    <Badge
      variant={variant}
      className={cn(
        "flex items-center justify-center text-xs",
        size === 'sm' ? "h-4 min-w-4 px-1" : "h-5 min-w-5 px-1.5",
        "absolute -top-1 -right-1",
        className
      )}
    >
      {count}
    </Badge>
  );
};

export default NotificationBadge;