import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import { Rent, UserProfile, Property } from '@/types';
import { Button } from '@/components/ui/button';
import { getPropertyAddress } from '@/lib/helpers';
import { Building, ChevronDown, ChevronUp, Edit, Ban, User, Calendar, DollarSign } from 'lucide-react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { BookingStatusBadge } from './BookingStatusBadge';
import { AcceptanceBadge } from './AcceptanceBadge';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface BookingCardProps {
  rent: Rent;
  property: Property | null;
  tenant: UserProfile | null;
  showArchived: boolean;
  onEdit: (rent: Rent) => void;
  onCancel: (rent: Rent) => void;
}

export const BookingCard: React.FC<BookingCardProps> = ({
  rent,
  property,
  tenant,
  showArchived,
  onEdit,
  onCancel
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  // Format date using i18n helper
  const formatDateString = (dateString: string) => {
    if (!dateString) return t('common.no_date');
    return formatDate(dateString, i18n.language);
  };

  return (
    <Card className="overflow-hidden mb-4">
      <CardHeader
        className="p-0 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {/* Header section */}
        <div className="p-4 bg-slate-50 dark:bg-slate-900 flex justify-between items-center">
          <div className="font-medium flex items-center gap-2">
            <Building className="h-4 w-4 text-primary" />
            <span className="truncate max-w-[180px]">
              {property ? (property.title || getPropertyAddress(property)) : t('common.unknown')}
            </span>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-1"
          >
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </Button>
        </div>
      </CardHeader>

      <CardContent className="p-0">
        {/* Main content - always visible */}
        <div className="p-4 grid grid-cols-2 gap-x-2 gap-y-3">
          <div className='flex flex-col items-start'>
            <p className="text-xs text-muted-foreground">{t('bookings.status')}</p>
            <div className="mt-1 w-auto">
              <BookingStatusBadge status={rent.status} />
            </div>
          </div>
          <div>
            <p className="text-xs text-muted-foreground">{t('bookings.acceptance')}</p>
            <div className="mt-1">
              <AcceptanceBadge isAccepted={rent.isAccepted} />
            </div>
          </div>
          <div>
            <p className="text-xs text-muted-foreground flex items-center gap-1">
              <Calendar className="size-3" /> {t('bookings.start_date')}
            </p>
            <p className="text-sm">{formatDateString(rent.date_start)}</p>
          </div>
          <div>
            <p className="text-xs text-muted-foreground flex items-center gap-1">
              <DollarSign className="size-3" /> {t('bookings.price')}
            </p>
            <p className="text-sm font-medium">
              {formatCurrency(rent.price_rent, i18n.language, rent.currency)} /
              {t(`properties.terms.${rent.unit}`)}
            </p>
          </div>
        </div>

        {/* Expanded content */}
        {isExpanded && (
          <div className="px-4 pb-4 pt-0">
            <div className="border-t pt-3 grid grid-cols-2 gap-x-2 gap-y-3">
              <div>
                <p className="text-xs text-muted-foreground flex items-center gap-1">
                  <User className="size-3" /> {t('bookings.tenant')}
                </p>
                <p className="text-sm truncate">
                  {tenant ?
                    (tenant.first_name && tenant.last_name ?
                      `${tenant.first_name} ${tenant.last_name}` :
                      tenant.email) :
                    t('bookings.unknown_tenant')}
                </p>
              </div>
              <div>
                <p className="text-xs text-muted-foreground flex items-center gap-1">
                  <Calendar className="size-3" /> {t('bookings.end_date')}
                </p>
                <p className="text-sm">{formatDateString(rent.date_end)}</p>
              </div>

              {!showArchived ? (
                <div className="col-span-2 flex justify-end gap-2 mt-2">
                  {(rent.status === 'active' || rent.status === 'pending') && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => onCancel(rent)}
                      className="h-8 px-3 flex items-center gap-1"
                    >
                      <Ban className="h-3 w-3" />
                      {t('bookings.cancel')}
                    </Button>
                  )}
                  {(rent.status !== 'canceled' && !rent.isArchived) && (
                    <Button
                      variant="default"
                      size="sm"
                      onClick={() => onEdit(rent)}
                      className="h-8 px-3 flex items-center gap-1"
                    >
                      <Edit className="h-3 w-3" />
                      {t('common.edit')}
                    </Button>
                  )
                  }
                </div>
              ) : (
                <div className="col-span-2 text-right text-sm text-muted-foreground mt-2">
                  {t('bookings.archived')}
                </div>
              )}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};