import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import ChecklistForm from '@/components/checklists/ChecklistForm';
import { useAuth } from '@/contexts/AuthContext';
import { useProperty, usePropertyChecklists } from '@/lib/query-hooks/get-hooks';
import { useUpdateChecklist } from '@/lib/query-hooks/mutation/useUpdateChecklist';
import { Skeleton } from '@/components/ui/skeleton';
import { useTranslation } from 'react-i18next';

const ChecklistEdit: React.FC = () => {
  const { id, checklistId } = useParams<{ id: string, checklistId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const userId = user?.id || '';
  const propertyId = id || '';

  // Get property data
  const property = useProperty(propertyId, userId);

  // Get checklist data
  const {
    checklists,
    inventoryItems,
    isLoading: isLoadingChecklists,
    isError
  } = usePropertyChecklists(propertyId, userId);

  // Find the specific checklist
  const checklist = checklistId
    ? checklists.find(c => c.id === checklistId)
    : undefined;

  // Get items for this checklist
  const checklistItems = checklist ? inventoryItems[checklist.id] || [] : [];

  // Track items to delete (when removing items that already exist in the database)
  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);

  // Update checklist mutation
  const {
    updateChecklistAsync,
    isLoading: isUpdating,
    isError: isUpdateError
  } = useUpdateChecklist({
    userId,
    onSuccess: () => {
      toast({
        title: t('checklists.toast.updated_title', 'Checklist Updated'),
        description: t('checklists.toast.updated_description', 'Your checklist has been updated successfully.'),
      });
      navigate(`/properties/${propertyId}/checklists/${checklistId}`);
    },
    onError: (error) => {
      toast({
        title: t('common.error'),
        description: t('checklists.toast.update_error', 'There was a problem updating your checklist.'),
        variant: "destructive",
      });
    }
  });

  // Reset items to delete when checklist changes
  useEffect(() => {
    setItemsToDelete([]);
  }, [checklist]);

  if (isLoadingChecklists) {
    return (
      <MainLayout>
        <div className="max-w-3xl mx-auto space-y-6 pt-2 pb-8">
          <Skeleton className="h-4 w-32 mb-8" />
          <Skeleton className="h-8 w-64 mb-2" />
          <Skeleton className="h-4 w-48 mb-8" />
          <div className="space-y-4">
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-40 w-full" />
          </div>
        </div>
      </MainLayout>
    );
  }

  if (!property || !checklist) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <h2 className="text-2xl font-bold">{t('common.not_found')}</h2>
            <p className="text-muted-foreground mt-2">{t('checklists.not_found_description')}</p>
            <Button className="mt-4" onClick={() => navigate('/properties')}>
              {t('navigation.back_to_properties')}
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }

  const handleSubmit = async (data: any) => {
    if (!propertyId || !checklistId) return;

    try {
      // Keep track of existing item IDs from the form
      const existingItemIds = data.items
        .filter((item: any) => item.id)
        .map((item: any) => item.id);

      // Find items that were in the original list but not in the form anymore
      const removedItemIds = checklistItems
        .filter(item => !existingItemIds.includes(item.id))
        .map(item => item.id);

      // Combine with any items marked for deletion
      const allItemsToDelete = [...itemsToDelete, ...removedItemIds];

      // Update the checklist
      await updateChecklistAsync({
        id: checklistId,
        property_id: propertyId,
        title: data.title,
        description: data.description || '',
        items: data.items,
        itemsToDelete: allItemsToDelete.length > 0 ? allItemsToDelete : undefined
      });
    } catch (error) {
      // Error will be handled by the onError callback in the hook
      console.error("Failed to update checklist:", error);
    }
  };

  return (
    <MainLayout>
      <div className="max-w-3xl mx-auto space-y-6 pt-2 pb-8">
        <Button
          variant="ghost"
          onClick={() => navigate(`/properties/${propertyId}/checklists/${checklistId}`)}
          className="-ml-2 px-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('checklists.back_to_checklists')}
        </Button>

        <div>
          <h1 className="text-3xl font-bold tracking-tight">{t('checklists.edit_checklist', 'Edit Checklist')}</h1>
          <p className="text-muted-foreground mt-1">
            {t('checklists.for_property', {
              street: property.street,
              apartment: property.apartment,
              city: property.city
            })}
          </p>
        </div>

        <ChecklistForm
          propertyId={propertyId}
          checklist={checklist}
          checklistItems={checklistItems}
          onSubmit={handleSubmit}
          isLoading={isUpdating}
        />
      </div>
    </MainLayout>
  );
};

export default ChecklistEdit;