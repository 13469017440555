import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Home, Calendar, DoorClosed, Eye, EyeOff, DollarSign } from 'lucide-react';
import { Property, Rent } from '@/types';
import { getPropertyAddress, decryptData } from '@/lib/helpers';
import { UserProfile } from '@/types';
import { useTranslation } from 'react-i18next';
import { formatDate, formatCurrency, getLocalizedUnit } from '@/utils/i18n-utils';

interface TenantPropertyCardProps {
    property: Property;
    activeRent?: Rent | null;
    lessor?: UserProfile | null;
    showAccessInfo?: boolean;
}

const TenantPropertyCard: React.FC<TenantPropertyCardProps> = ({
    property,
    activeRent,
    lessor,
    showAccessInfo = false
}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [showDoorCode, setShowDoorCode] = useState<boolean>(false);
    const [showWifiKey, setShowWifiKey] = useState<boolean>(false);

    const toggleDoorCode = () => {
        setShowDoorCode(prev => !prev);
    };

    const toggleWifiKey = () => {
        setShowWifiKey(prev => !prev);
    };

    // Function to render the card header with image and gradient overlay
    const renderCardHeader = () => {
        return (
            <div className="relative w-full h-48">
                {/* Property Image */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${property.image_url || '/placeholder-property.jpg'})` }}
                />

                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-black/10" />

                {/* Content */}
                <div className="absolute bottom-0 left-0 p-6 w-full">
                    <h3 className="text-xl font-semibold text-white">
                        {property.title || getPropertyAddress(property)}
                    </h3>
                    <p className="text-blue-100 mt-1">
                        {getPropertyAddress(property)}
                    </p>
                </div>
            </div>
        );
    };

    // Format property details based on whether it's a studio or regular apartment
    const getPropertyDetails = () => {
        const bedText = property.isStudio
            ? t('properties.studio_short')
            : `${property.bedrooms} ${t('properties.bed')}`;

        const bathText = `${property.bathrooms} ${t('properties.bath')}`;

        const areaText = `${property.area} ${t(`properties.units.${property.unit_measure}`)}`;

        return `${bedText} • ${bathText} • ${areaText}`;
    };

    return (
        <Card className="flex flex-col items-stretch overflow-hidden">
            {renderCardHeader()}

            <CardContent className="flex flex-grow p-6 items-stretch">
                <div className="flex flex-col space-y-4 justify-between h-full w-full">

                    <div className='flex flex-grow'>
                        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4'>
                            <div className='space-y-2'>
                                <div className="flex items-start gap-3">
                                    <Home className="h-5 w-5 text-muted-foreground mt-0.5" />
                                    <div>
                                        <p className="font-medium">{t('properties.property_details')}</p>
                                        <p className="text-sm text-muted-foreground">
                                            {getPropertyDetails()}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-start gap-3">
                                    <DollarSign className="h-5 w-5 text-muted-foreground mt-0.5" />
                                    <div>
                                        <p className="font-medium capitalize">
                                            {t(`properties.terms.${property.unit_term}_rent`)}
                                        </p>
                                        <p className="text-sm text-muted-foreground">
                                            {formatCurrency(property.price, i18n.language, property.currency)}
                                            /{getLocalizedUnit(property.unit_term, i18n.language)}
                                        </p>
                                    </div>
                                </div>

                                {activeRent && (
                                    <div className="flex items-start gap-3">
                                        <Calendar className="h-5 w-5 text-muted-foreground mt-0.5" />
                                        <div>
                                            <p className="font-medium">{t('properties.lease_information')}</p>
                                            <p className="text-sm text-muted-foreground">
                                                {t('properties.starting')} {formatDate(activeRent.date_start, i18n.language)} •
                                                {activeRent.contract_term} {activeRent.contract_term === 1
                                                    ? t(`properties.terms.${activeRent.unit}`)
                                                    : t(`properties.terms.${activeRent.unit}_plural`)}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                {showAccessInfo && (
                                    <div className="flex items-start gap-3">
                                        <DoorClosed className="h-5 w-5 text-muted-foreground mt-0.5" />
                                        <div>
                                            <p className="font-medium">{t('properties.access_information')}</p>

                                            <div className="space-y-2 mt-1">
                                                <div className="flex items-center">
                                                    <p className="text-sm text-muted-foreground mr-2">{t('properties.door_code')}:</p>
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-mono bg-muted rounded px-2 py-1 mr-1">
                                                            {showDoorCode
                                                                ? (property.door_code ? decryptData(property.door_code) : t('common.not_provided'))
                                                                : '••••••'}
                                                        </div>
                                                        {property.door_code && (
                                                            <Button
                                                                variant="ghost"
                                                                size="icon"
                                                                onClick={toggleDoorCode}
                                                                className="h-6 w-6"
                                                            >
                                                                {showDoorCode ? <EyeOff className="h-3 w-3" /> : <Eye className="h-3 w-3" />}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-sm text-muted-foreground mr-2">{t('properties.wifi_network')}:</p>
                                                    <div className="text-sm font-mono bg-muted rounded px-2 py-1">
                                                        {property.wi_fi_network || t('common.not_provided')}
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-sm text-muted-foreground mr-2">{t('properties.wifi_password')}:</p>
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-mono bg-muted rounded px-2 py-1 mr-1">
                                                            {showWifiKey
                                                                ? (property.wi_fi_key ? decryptData(property.wi_fi_key) : t('common.not_provided'))
                                                                : '••••••'}
                                                        </div>
                                                        {property.wi_fi_key && (
                                                            <Button
                                                                variant="ghost"
                                                                size="icon"
                                                                onClick={toggleWifiKey}
                                                                className="h-6 w-6"
                                                            >
                                                                {showWifiKey ? <EyeOff className="h-3 w-3" /> : <Eye className="h-3 w-3" />}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Button
                        size="sm"
                        className="mt-2"
                        onClick={() => navigate("/tenant/bills")}
                    >
                        {t('bills.view_related_bills')}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default TenantPropertyCard;