import React, { useState } from 'react';
import { InventoryItem, InventoryItemStatus } from '@/types';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Check, AlertTriangle, Clock } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface ChecklistItemProps {
  item: InventoryItem;
  editable?: boolean;
  onStatusChange?: (id: string, status: InventoryItemStatus) => void;
  onNotesChange?: (id: string, notes: string) => void;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  item,
  editable = false,
  onStatusChange,
  onNotesChange
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(item.note || '');
  const { t } = useTranslation();

  const handleStatusChange = (status: InventoryItemStatus) => {
    if (onStatusChange) {
      onStatusChange(item.id, status);
    }
  };

  const handleSaveNotes = () => {
    if (onNotesChange) {
      onNotesChange(item.id, notes);
    }
    setIsEditing(false);
  };

  return (
    <div className={cn(
      "border rounded-md p-4 mb-2 transition-colors",
      item.status === 'completed' && "border-l-4 border-l-green-500 bg-green-50",
      item.status === 'issue' && "border-l-4 border-l-red-500 bg-red-50",
      item.status === 'pending' && "border-gray-200"
    )}>
      <div className="flex items-start justify-between space-x-6">
        <div className="flex-1">
          <p className="font-medium">{item.name}</p>
          {item.note && !isEditing && (
            <p className="text-sm text-gray-600 mt-1">{item.note}</p>
          )}
          {isEditing ? (
            <div className="mt-2 space-y-2">
              <Textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder={t('checklists.add_notes_about_item')}
                className="h-20 text-sm"
              />
              <div className="flex gap-2">
                <Button size="sm" onClick={handleSaveNotes}>{t('common.save')}</Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    setNotes(item.note || '');
                    setIsEditing(false);
                  }}
                >
                  {t('common.cancel')}
                </Button>
              </div>
            </div>
          ) : editable && (
            <button
              className="text-sm text-blue-600 mt-1.5 hover:underline focus:outline-none"
              onClick={() => setIsEditing(true)}
            >
              {item.note ? t('checklists.edit_notes') : t('checklists.add_notes')}
            </button>
          )}
        </div>
        {editable ? (
          <div className="flex gap-2 items-center">
            {/* Completed Button with Green Gradient */}
            <button
              className={cn(
                "h-8 w-8 rounded-md flex items-center justify-center text-white transition-all shadow-sm",
                item.status === 'completed'
                  ? "bg-gradient-to-r from-green-500 to-emerald-600 shadow-lg"
                  : "bg-gray-100 hover:bg-gradient-to-r hover:from-green-400 hover:to-emerald-500 text-gray-500 hover:text-white"
              )}
              onClick={() => handleStatusChange('completed')}
              aria-label="Mark as completed"
            >
              <Check className="h-4 w-4" />
            </button>

            {/* Issue Button with Red Gradient */}
            <button
              className={cn(
                "h-8 w-8 rounded-md flex items-center justify-center text-white transition-all shadow-sm",
                item.status === 'issue'
                  ? "bg-gradient-to-r from-red-500 to-rose-600 shadow-lg"
                  : "bg-gray-100 hover:bg-gradient-to-r hover:from-red-400 hover:to-rose-500 text-gray-500 hover:text-white"
              )}
              onClick={() => handleStatusChange('issue')}
              aria-label="Mark as issue"
            >
              <AlertTriangle className="h-4 w-4" />
            </button>

            {/* Pending Button with Yellow Gradient */}
            <button
              className={cn(
                "h-8 w-8 rounded-md flex items-center justify-center text-white transition-all shadow-sm",
                item.status === 'pending'
                  ? "bg-gradient-to-r from-amber-400 to-yellow-500 shadow-lg"
                  : "bg-gray-100 hover:bg-gradient-to-r hover:from-amber-300 hover:to-yellow-400 text-gray-500 hover:text-white"
              )}
              onClick={() => handleStatusChange('pending')}
              aria-label="Mark as pending"
            >
              <Clock className="h-4 w-4" />
            </button>
          </div>
        ) : (
          <div className="flex items-center">
            {item.status === 'completed' && (
              <span className="inline-flex items-center text-green-600 bg-green-100 px-2 py-1 rounded text-xs">
                <Check className="h-3 w-3 mr-1" />
                {t('checklists.completed')}
              </span>
            )}
            {item.status === 'issue' && (
              <span className="inline-flex items-center text-red-600 bg-red-100 px-2 py-1 rounded text-xs">
                <AlertTriangle className="h-3 w-3 mr-1" />
                {t('checklists.issues')}
              </span>
            )}
            {item.status === 'pending' && (
              <span className="inline-flex items-center text-amber-600 bg-amber-100 px-2 py-1 rounded text-xs">
                <Clock className="h-3 w-3 mr-1" />
                {t('checklists.pending')}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChecklistItem;