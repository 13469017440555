import React, { ReactNode } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

interface DashboardMetricCardProps {
    title: string;
    value: string | number;
    description: string;
    icon: ReactNode;
}

const DashboardMetricCard: React.FC<DashboardMetricCardProps> = ({
    title,
    value,
    description,
    icon,
}) => {
    return (
        <Card>
            <CardHeader className="pb-2">
                <CardDescription>{title}</CardDescription>
                <CardTitle className="capitalize text-2xl sm:text-3xl">
                    {value}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex items-center">
                    {icon}
                    <span className="text-sm text-muted-foreground ml-1">{description}</span>
                </div>
            </CardContent>
        </Card>
    );
};

export default DashboardMetricCard;