import { gql } from "graphql-request";

export const UPDATE_REPAIR = gql`
  mutation UpdateRepair(
  $id: UUID! 
  $tenant_id: String
  $property_id: String
  $title: String
  $description: String
  $type: String
  $status: String
  $priority: String
  $completed_by_tenant: Boolean
  $updated_at: String!
  ) {
    updaterepairsCollection(
      set: { 
      property_id: $property_id
      title: $title
      description: $description
      type: $type
      status: $status
      priority: $priority
      completed_by_tenant: $completed_by_tenant
      updated_at: $updated_at
       }
      filter: { id: { eq: $id } }
    ) {
      records {
        id
        title
        description
        type
        status
        priority
        completed_by_tenant
        updated_at
        created_at
      }
    }
  }
`;
