"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Repair } from "@/types";
import { CreateRepairInput } from "@/types/mutation/mutation-input-types";
import { CreateRepairResponse } from "@/types/mutation/mutation-responses-types";
import { CREATE_REPAIR } from "@/queries-graphql/mutation/new-repair-mutation";

// Function to update Repair request
export const createRepairRequest = async (
    input: CreateRepairInput
): Promise<Repair> => {
    const client = graphqlClient;
    try {
        const variables = { ...input };

        const data = await client.request<CreateRepairResponse>(
            CREATE_REPAIR,
            variables
        );
        const response = data.insertIntorepairsCollection.records[0];
        //console.log(response);
        return response;
    } catch (error) {
        console.error('Error creating Repair:', error);
        throw error;
    }
};