import { gql } from "graphql-request";

export const BATCH_CREATE_INVENTORY_ITEMS = gql`
  mutation BatchCreateInventoryItems(
    $items: [inventory_itemsInsertInput!]!
  ) {
    insertIntoinventory_itemsCollection(
      objects: $items
    ) {
      records {
        id
        checklist_id
        name
        note
        status
        created_at
        updated_at
      }
    }
  }
`;