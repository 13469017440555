import { Property } from "@/types";
import { CreatePropertyHookInput, CreatePropertyInput } from "@/types/mutation/mutation-input-types";
import { NormalizedData } from "@/types/normalized-data-types";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { createProperty } from "@/lib/actions/create-property";
import { uploadPropertyImage } from "@/lib/actions/property-image-upload";
import { updateProperty } from "@/lib/actions/update-property";
import { v4 as uuidv4 } from 'uuid';

// React Query Mutation Hook
interface PropertyCreateOptions {
    onSuccess?: (property: Property) => void;
    onError?: (error: Error) => void;
    userId: string;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
    tempId: string;
    localImgUrl?: string;
}

export function useCreateProperty(options: PropertyCreateOptions) {
    console.log('Create Property Hook triggered');
    const queryClient = useQueryClient();

    if (!options) {
        throw new Error('options is required');
    }
    if (!options.userId) {
        throw new Error('user ID is required');
    }

    const mutation = useMutation<Property, Error, CreatePropertyHookInput, MutationContext>({
        mutationFn: async (input) => {
            const { image_file, ...restInput } = input;

            // Create the property with the updated input
            const newProprety = await createProperty({ ...restInput, image_url: '' });

            // Handle image upload if present
            if (image_file && newProprety) {
                try {
                    // Upload the image
                    const uploadResult = await uploadPropertyImage({
                        file: input.image_file,
                        propertyId: newProprety.id
                    });

                    if (uploadResult.error) {
                        throw new Error(uploadResult.error);
                    }

                    // Replace the local img_url with the actual URL from the server
                    const now = new Date();
                    const updateInput = {
                        ...newProprety,
                        image_url: uploadResult.url,
                        updated_at: now.toISOString()
                    };
                    return updateProperty(updateInput)

                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }

            return newProprety;
        },

        // Add optimistic update
        onMutate: async (input) => {
            if (!input) {
                throw new Error('input data is required');
            }

            const queryKey = ['userData', options.userId];
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<NormalizedData>(queryKey);

            // Generate a temporary ID for optimistic update
            const tempId = uuidv4();
            const now = new Date().toISOString();

            // Generate a local image URL if there's an image file
            let localImgUrl: string | undefined;
            if (input.image_file) {
                localImgUrl = URL.createObjectURL(input.image_file);
            }

            if (previousData) {
                // Optimistically update the cache
                queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                    // Create temporary property object
                    const tempProperty: Property = {
                        id: tempId,
                        ...input,
                        // Use the local image URL for optimistic update
                        image_url: localImgUrl || input.image_url,
                        created_at: now,
                        updated_at: now
                    };

                    // Remove the file property as it's not part of the Property type
                    delete (tempProperty as any).img_file;

                    // Add property to properties collection
                    newData.properties[tempId] = tempProperty;

                    // Update relationship maps
                    if (input.lessor_id) {
                        // Add to userProperties.asLessor relationship
                        if (!newData.userProperties.asLessor[input.lessor_id]) {
                            newData.userProperties.asLessor[input.lessor_id] = [];
                        }
                        newData.userProperties.asLessor[input.lessor_id].push(tempId);
                    }

                    // Initialize empty arrays for the new property in relationship maps
                    newData.propertyRents[tempId] = [];
                    newData.checklistsByProperty[tempId] = [];
                    newData.repairsByProperty[tempId] = [];

                    return newData;
                });
            }

            return { previousData, tempId, localImgUrl };
        },

        onError: (error, _newData, context) => {
            // Roll back to the previous value if there's an error
            const queryKey = ['userData', options.userId];
            if (context?.previousData) {
                queryClient.setQueryData<NormalizedData>(queryKey, context.previousData);
            }

            // Revoke the object URL to prevent memory leaks
            if (context?.localImgUrl) {
                URL.revokeObjectURL(context.localImgUrl);
            }

            console.error('Error creating Property', error);
            options?.onError?.(error);
        },

        onSuccess: (newProperty, variables, context) => {
            const queryKey = ['userData', options.userId];

            // Update the cache with the real data from the server
            queryClient.setQueryData<NormalizedData>(queryKey, (old) => {
                if (!old || !context?.tempId) return old;

                const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;

                // Replace temporary property with the real one
                delete newData.properties[context.tempId];
                newData.properties[newProperty.id] = newProperty;

                // Update relationship maps - replace tempId with real id
                if (variables.lessor_id) {
                    if (newData.userProperties.asLessor[variables.lessor_id]) {
                        const index = newData.userProperties.asLessor[variables.lessor_id].indexOf(context.tempId);
                        if (index !== -1) {
                            newData.userProperties.asLessor[variables.lessor_id][index] = newProperty.id;
                        }
                    }
                }

                // Update the relationship maps with the new property ID
                if (newData.propertyRents[context.tempId]) {
                    newData.propertyRents[newProperty.id] = newData.propertyRents[context.tempId];
                    delete newData.propertyRents[context.tempId];
                } else {
                    newData.propertyRents[newProperty.id] = [];
                }

                if (newData.checklistsByProperty[context.tempId]) {
                    newData.checklistsByProperty[newProperty.id] = newData.checklistsByProperty[context.tempId];
                    delete newData.checklistsByProperty[context.tempId];
                } else {
                    newData.checklistsByProperty[newProperty.id] = [];
                }

                if (newData.repairsByProperty[context.tempId]) {
                    newData.repairsByProperty[newProperty.id] = newData.repairsByProperty[context.tempId];
                    delete newData.repairsByProperty[context.tempId];
                } else {
                    newData.repairsByProperty[newProperty.id] = [];
                }

                return newData;
            });

            // Revoke the object URL to prevent memory leaks
            if (context?.localImgUrl) {
                URL.revokeObjectURL(context.localImgUrl);
            }

            options?.onSuccess?.(newProperty);
        },
        retry: false
    });

    return {
        createProperty: mutation.mutate,
        createPropertyAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}