import { gql } from "graphql-request";

export const CREATE_REPAIR = gql`
  mutation CreateRepair(
  $tenant_id: String!
  $property_id: String!
  $title: String!
  $description: String
  $type: String!
  $status: String!
  $priority: String!
  $completed_by_tenant: Boolean
  ) {
    insertIntorepairsCollection(
      objects: { 
      tenant_id: $tenant_id
      property_id: $property_id
      title: $title
      description: $description
      type: $type
      status: $status
      priority: $priority
      completed_by_tenant: $completed_by_tenant
       }
    ) {
      records {
        id
        title
        description
        type
        status
        priority
        completed_by_tenant
        updated_at
        created_at
      }
    }
  }
`;
