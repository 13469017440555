import React from 'react';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
  CheckCircle,
  XCircle,
  Wrench,
  Clock,
  Thermometer,
  Zap,
  PaintRoller,
  Droplets,
  WashingMachine,
  Pencil
} from 'lucide-react';
import { format } from 'date-fns';
import { MaintenanceRequestType, Repair, RepairStatus, UserRole } from '@/types';
import { getUserName } from '@/lib/helpers';
import { UserProfile } from '@/types';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface MaintenanceRequestCardProps {
  repair: Repair;
  userRole: UserRole;
  userData?: Record<string, UserProfile>;
  currentUserId: string;
  onUpdateStatus: (repair: Repair, newStatus: RepairStatus) => void;
  onEditRequest: (repair: Repair) => void;
}

export const MaintenanceRequestCard: React.FC<MaintenanceRequestCardProps> = ({
  repair,
  userRole,
  userData,
  currentUserId,
  onUpdateStatus,
  onEditRequest
}) => {
  const { t, i18n } = useTranslation();

  // Helper functions for visual elements
  const getRequestTypeIcon = (type: MaintenanceRequestType) => {
    switch (type) {
      case 'appliance':
        return <WashingMachine className="h-5 w-5 text-blue-500" />;
      case 'electrical':
        return <Zap className="h-5 w-5 text-amber-500" />;
      case 'plumbing':
        return <Droplets className="h-5 w-5 text-cyan-500" />;
      case 'structural':
        return <PaintRoller className="h-5 w-5 text-purple-500" />;
      case 'hvac':
        return <Thermometer className="h-5 w-5 text-red-500" />;
      default:
        return <Wrench className="h-5 w-5 text-gray-500" />;
    }
  };

  const getRequestStatusBadge = (status: RepairStatus) => {
    switch (status) {
      case 'pending':
        return (
          <Badge variant="outline" className="bg-amber-50 text-amber-700 border-amber-200 hover:bg-amber-100">
            <Clock className="mr-1 h-3 w-3" /> {t('maintenance.statuses.pending')}
          </Badge>
        );
      case 'in_progress':
        return (
          <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-200 hover:bg-blue-100">
            <Wrench className="mr-1 h-3 w-3" /> {t('maintenance.statuses.in_progress')}
          </Badge>
        );
      case 'completed':
        return (
          <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200 hover:bg-green-100">
            <CheckCircle className="mr-1 h-3 w-3" /> {t('maintenance.statuses.completed')}
          </Badge>
        );
      case 'canceled':
        return (
          <Badge variant="outline" className="bg-gray-50 text-gray-700 border-gray-200 hover:bg-gray-100">
            <XCircle className="mr-1 h-3 w-3" /> {t('maintenance.statuses.canceled')}
          </Badge>
        );
      default:
        return <Badge variant="outline">{t('common.unknown')}</Badge>;
    }
  };

  const getPriorityBadge = (priority: string) => {
    switch (priority) {
      case 'high':
        return (
          <Badge className="bg-red-100 text-red-800 hover:bg-red-200">
            {t('maintenance.priorities_badge.high')}
          </Badge>
        );
      case 'medium':
        return (
          <Badge className="bg-amber-100 text-amber-800 hover:bg-amber-200">
            {t('maintenance.priorities_badge.medium')}
          </Badge>
        );
      case 'low':
        return (
          <Badge className="bg-blue-100 text-blue-800 hover:bg-blue-200">
            {t('maintenance.priorities_badge.low')}
          </Badge>
        );
      default:
        return <Badge>{t('common.unknown')}</Badge>;
    }
  };

  const getHeaderBgColor = (status: RepairStatus) => {
    switch (status) {
      case 'completed': return 'bg-green-50';
      case 'in_progress': return 'bg-blue-50';
      case 'canceled': return 'bg-gray-50';
      default: return 'bg-amber-50'; // pending
    }
  };

  // Format date using i18n
  const formatDateWithI18n = (dateString: string) => {
    return formatDate(dateString, i18n.language, 'PPpp'); // Full date and time format
  };

  return (
    <Card className="overflow-hidden border shadow-sm">
      <CardHeader className={`p-4 ${getHeaderBgColor(repair.status)} border-b`}>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            {getRequestTypeIcon(repair.type)}
            <div>
              <CardTitle className="text-base font-medium">{repair.title}</CardTitle>
              <CardDescription className="mt-1">
                {formatDateWithI18n(repair.created_at)}
              </CardDescription>
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            {getRequestStatusBadge(repair.status)}
            {getPriorityBadge(repair.priority)}
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-4">
        <p className="text-sm whitespace-pre-line">
          {repair.description || t('common.no_description')}
        </p>
      </CardContent>

      <CardFooter className="px-4 pt-2 sm:py-2 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 border-t">
        <div className="text-sm text-muted-foreground">
          {userRole === 'lessor' && userData && (
            <div className="flex items-center gap-1">
              <span>{t('maintenance.reported_by')}:</span>
              <span className="font-medium">{getUserName(userData[repair.tenant_id])}</span>
            </div>
          )}
        </div>

        <div className="flex flex-wrap gap-2 items-center w-full sm:w-auto">
          {repair.tenant_id !== currentUserId && repair.status === 'pending' && (
            <>
              <Button
                size="sm"
                variant="outline"
                onClick={() => onUpdateStatus(repair, 'canceled')}
              >
                <XCircle className="mr-2 h-4 w-4" />
                {t('common.cancel')}
              </Button>
              <Button
                size="sm"
                className="bg-blue-500 hover:bg-blue-600"
                onClick={() => onUpdateStatus(repair, 'in_progress')}
              >
                <Wrench className="mr-2 h-4 w-4" />
                {t('maintenance.start_work')}
              </Button>

            </>
          )}

          {repair.status === 'in_progress' && (
            <>
              <Button
                size="sm"
                variant="outline"
                onClick={() => onUpdateStatus(repair, 'canceled')}
              >
                <XCircle className="mr-2 h-4 w-4" />
                {t('common.cancel')}
              </Button>
              <Button
                size="sm"
                className="bg-green-600 hover:bg-green-700"
                onClick={() => onUpdateStatus(repair, 'completed')}
              >
                <CheckCircle className="mr-2 h-4 w-4" />
                {t('maintenance.mark_complete')}
              </Button>

            </>
          )}

          {repair.tenant_id === currentUserId && repair.status === 'in_progress' && (
            <Button
              className='bg-green-600 hover:bg-green-700'
              size="sm"
              onClick={() => onUpdateStatus(repair, "completed")}
            >
              <CheckCircle className="mr-2 h-4 w-4" />
              {t('maintenance.mark_complete')}
            </Button>
          )}

          {repair.status === 'pending' && repair.tenant_id === currentUserId && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => onEditRequest(repair)}
            >
              <Pencil className="mr-2 h-4 w-4" />
              {t('common.edit')}
            </Button>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};