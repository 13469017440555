import React from 'react';
import { Rent, UserProfile, Property } from '@/types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Building, Edit, Ban } from 'lucide-react';
import { parseISO } from 'date-fns';
import { BookingStatusBadge } from './BookingStatusBadge';
import { AcceptanceBadge } from './AcceptanceBadge';
import { getPropertyAddress } from '@/lib/helpers';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface BookingTableProps {
  rents: Rent[];
  getPropertyDetails: (propertyId: string) => Property | null;
  getTenantDetails: (tenantId: string) => UserProfile | null;
  showArchived: boolean;
  onEdit: (rent: Rent) => void;
  onCancel: (rent: Rent) => void;
}

export const BookingTable: React.FC<BookingTableProps> = ({
  rents,
  getPropertyDetails,
  getTenantDetails,
  showArchived,
  onEdit,
  onCancel
}) => {
  const { t, i18n } = useTranslation();

  // Format date helper function using i18n
  const formatDateString = (dateString: string) => {
    try {
      if (!dateString) return t('common.no_date', 'No date provided');
      const date = parseISO(dateString);
      if (isNaN(date.getTime())) return t('common.invalid_date', 'Invalid date');
      return formatDate(dateString, i18n.language);
    } catch (error) {
      return t('common.invalid_date', 'Invalid date');
    }
  };

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t('properties.property', 'Property')}</TableHead>
            <TableHead className="hidden lg:table-cell">{t('bookings.tenant', 'Tenant')}</TableHead>
            <TableHead>{t('bookings.status', 'Status')}</TableHead>
            <TableHead className="hidden lg:table-cell">{t('bookings.acceptance', 'Acceptance')}</TableHead>
            <TableHead>{t('bookings.start_date', 'Start Date')}</TableHead>
            <TableHead className="hidden lg:table-cell">{t('bookings.end_date', 'End Date')}</TableHead>
            <TableHead>{t('bookings.price', 'Price')}</TableHead>
            <TableHead className="text-right">{t('bookings.actions', 'Actions')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rents.map((rent) => {
            const property = getPropertyDetails(rent.property_id);
            const tenant = getTenantDetails(rent.tenant_id);

            return (
              <TableRow key={rent.id}>
                <TableCell className="font-medium">
                  <div className="flex items-center gap-2">
                    <Building className="h-4 w-4 text-primary" />
                    <span className="truncate max-w-[180px]">
                      {property ? (property.title || getPropertyAddress(property)) : t('properties.unknown_property', 'Unknown Property')}
                    </span>
                  </div>
                </TableCell>
                <TableCell className="hidden lg:table-cell">
                  {tenant ?
                    (tenant.first_name && tenant.last_name ?
                      `${tenant.first_name} ${tenant.last_name}` :
                      tenant.email) :
                    t('common.unknown', 'Unknown')}
                </TableCell>
                <TableCell>
                  <BookingStatusBadge status={rent.status} size="sm" showIcon={false} />
                </TableCell>
                <TableCell className="hidden lg:table-cell">
                  <div className='flex lg:justify-center'>
                    <AcceptanceBadge isAccepted={rent.isAccepted} />
                  </div>
                </TableCell>
                <TableCell>{formatDateString(rent.date_start)}</TableCell>
                <TableCell className="hidden lg:table-cell">{formatDateString(rent.date_end)}</TableCell>
                <TableCell>
                  {formatCurrency(rent.price_rent, i18n.language, rent.currency)} /
                  {t(`properties.terms.${rent.unit}`)}
                </TableCell>
                <TableCell className="text-right">
                  {!showArchived ? (
                    <div className="flex justify-end gap-2">
                      {(rent.status === 'active' || rent.status === 'pending') && (
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => onCancel(rent)}
                          className="hidden sm:flex items-center gap-1"
                        >
                          <Ban className="h-3 w-3" />
                          {t('bookings.cancel', 'Cancel')}
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        size="icon"
                        disabled={rent.status === 'canceled'}
                        onClick={() => onEdit(rent)}
                      >
                        <Edit className="h-4 w-4" />
                        <span className="sr-only">{t('bookings.edit', 'Edit')}</span>
                      </Button>
                      {(rent.status === 'active' || rent.status === 'pending') && (
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => onCancel(rent)}
                          className="sm:hidden"
                        >
                          <Ban className="h-4 w-4" />
                          <span className="sr-only">{t('bookings.cancel', 'Cancel')}</span>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className='text-muted-foreground'>
                      {t('bookings.archived', 'Archived')}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};