import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

const PropertyCardSkeleton: React.FC = () => {
    return (
        <Card className="overflow-hidden">
            {/* Header Skeleton */}
            <Skeleton className="w-full h-48" />

            <CardContent className="p-6">
                <div className="flex flex-col space-y-4">
                    {/* Property Details Skeleton */}
                    <div className="flex items-start gap-3">
                        <Skeleton className="h-5 w-5 rounded-full" />
                        <div className="w-full">
                            <Skeleton className="h-5 w-24 mb-2" />
                            <Skeleton className="h-4 w-full" />
                        </div>
                    </div>

                    {/* Rent Skeleton */}
                    <div className="flex items-start gap-3">
                        <Skeleton className="h-5 w-5 rounded-full" />
                        <div className="w-full">
                            <Skeleton className="h-5 w-32 mb-2" />
                            <Skeleton className="h-4 w-20" />
                        </div>
                    </div>

                    {/* Lease Terms Skeleton */}
                    <div className="flex items-start gap-3">
                        <Skeleton className="h-5 w-5 rounded-full" />
                        <div className="w-full">
                            <Skeleton className="h-5 w-36 mb-2" />
                            <Skeleton className="h-4 w-full" />
                        </div>
                    </div>

                    {/* Button Skeleton */}
                    <Skeleton className="h-10 w-full mt-2" />
                </div>
            </CardContent>
        </Card>
    );
};

export default PropertyCardSkeleton;