import React from 'react';
import {
    Droplets, Zap, Flame, House, Wifi, CreditCard
} from 'lucide-react';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';

interface BillTypeIconProps {
    bill: CombinedBill;
}

export const BillTypeIcon: React.FC<BillTypeIconProps> = ({ bill }) => {
    if (bill.type === 'rent') {
        return <House className="h-5 w-5 text-purple-500" />;
    }

    switch (bill.utilityType) {
        case 'water':
            return <Droplets className="h-5 w-5 text-blue-500" />;
        case 'electricity':
            return <Zap className="h-5 w-5 text-yellow-500" />;
        case 'gas':
            return <Flame className="h-5 w-5 text-orange-500" />;
        case 'internet':
            return <Wifi className="h-5 w-5 text-indigo-500" />;
        case 'utility':
        default:
            return <CreditCard className="h-5 w-5 text-gray-500" />;
    }
};