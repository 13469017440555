import { gql } from 'graphql-request';

export const GET_RENT = gql`
  query GetRent($rentId: UUID!) {
    # Get rent 
    rentsCollection(
    filter: {id: {eq: $rentId}}
) {
    edges {
      node {
      id
      lessor_id
      tenant_id
      manager_id
      property_id
      unit
      date_start
      date_end
      status
      contract_url
      contract_term
      currency
      price_rent
      isAccepted
      isArchived
      updated_at
      created_at
      }
    }
  }
}
  `;