import React from 'react';
import { Card, CardContent, CardDescription, CardHeader } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

/**
 * Skeleton loader component for the entire dashboard
 */
const DashboardSkeleton: React.FC = () => {
    return (
        <div className="space-y-6">
            {/* Dashboard title */}
            <div className="flex justify-between items-center">
                <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Tenant Dashboard</h1>
            </div>

            {/* Stats Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {/* Skeleton cards for stats */}
                {[1, 2, 3, 4].map((i) => (
                    <Card key={i}>
                        <CardHeader className="pb-2">
                            <CardDescription>
                                <Skeleton className="h-4 w-24" />
                            </CardDescription>
                            <Skeleton className="h-8 w-20" />
                        </CardHeader>
                        <CardContent>
                            <div className="flex items-center">
                                <Skeleton className="h-4 w-4 mr-1 rounded-full" />
                                <Skeleton className="h-4 w-36" />
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>

            {/* My Rentals Section */}
            <div>
                <h2 className="text-xl font-semibold mb-4">My Rentals</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {[1, 2, 3].map((i) => (
                        <Card key={i} className="hover:shadow-md transition-shadow">
                            <CardHeader>
                                <Skeleton className="h-6 w-3/4 mb-2" />
                                <Skeleton className="h-4 w-1/2" />
                            </CardHeader>
                            <CardContent>
                                <div className="flex flex-col space-y-2">
                                    <div className="flex justify-between items-center">
                                        <Skeleton className="h-4 w-1/4" />
                                        <Skeleton className="h-4 w-1/4" />
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <Skeleton className="h-4 w-1/3" />
                                        <Skeleton className="h-4 w-1/4" />
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <Skeleton className="h-4 w-1/4" />
                                        <Skeleton className="h-4 w-1/4" />
                                    </div>
                                </div>
                                <div className="w-full mt-4">
                                    <Skeleton className="h-10 w-full" />
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>

            {/* Recent Bills Section */}
            <div>
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
                    <h2 className="text-xl font-semibold">Recent Bills</h2>
                    <Skeleton className="h-10 w-32" />
                </div>

                <Card>
                    <CardContent className="p-4">
                        <div className="space-y-4">
                            {[1, 2, 3].map((i) => (
                                <div key={i} className="flex flex-col sm:flex-row sm:items-center justify-between border-b pb-3 last:border-0 gap-3">
                                    <div className="flex items-start gap-3">
                                        <div className="w-full sm:w-auto">
                                            <Skeleton className="h-5 w-32 mb-2" />
                                            <Skeleton className="h-4 w-48" />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4 self-end sm:self-auto">
                                        <Skeleton className="h-5 w-16" />
                                        <Skeleton className="h-8 w-20" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default DashboardSkeleton;