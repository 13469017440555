import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { ArrowLeft, CheckCircle, AlertTriangle, Clock, Save } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import ChecklistItem from '@/components/checklists/ChecklistItem';
import { useAuth } from '@/contexts/AuthContext';
import { useProperty, usePropertyChecklists } from '@/lib/query-hooks/get-hooks';
import { useUpdateChecklist } from '@/lib/query-hooks/mutation/useUpdateChecklist';
import { InventoryItem, InventoryItemStatus } from '@/types';
import { Skeleton } from '@/components/ui/skeleton';
import { useTranslation } from 'react-i18next';

const ChecklistRun: React.FC = () => {
  const { id, checklistId } = useParams<{ id: string, checklistId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const userId = user?.id || '';
  const propertyId = id || '';

  // Get property data
  const property = useProperty(propertyId, userId);

  // Get checklist data
  const {
    checklists,
    inventoryItems: originalItems,
    isLoading: isLoadingChecklists,
    isError,
    refetch
  } = usePropertyChecklists(propertyId, userId);

  // Find the specific checklist
  const checklist = checklistId
    ? checklists.find(c => c.id === checklistId)
    : undefined;

  // Get items for this checklist
  const originalChecklistItems = checklist ? originalItems[checklist.id] || [] : [];

  // Local state for items being updated during the run
  const [items, setItems] = useState<InventoryItem[]>([]);

  // Update items when original items change
  useEffect(() => {
    if (originalChecklistItems.length > 0) {
      setItems([...originalChecklistItems]);
    }
  }, [originalChecklistItems]);

  // Update checklist mutation
  const {
    updateChecklistAsync,
    isLoading: isUpdating,
    isError: isUpdateError
  } = useUpdateChecklist({
    userId,
    onSuccess: () => {
      toast({
        title: t('checklists.toast.completed_title', 'Checklist Completed'),
        description: t('checklists.toast.completed_description', 'Your checklist inspection has been saved successfully.'),
      });
      refetch();
      navigate(`/properties/${propertyId}/checklists/${checklistId}`);
    },
    onError: (error) => {
      toast({
        title: t('common.error'),
        description: error.message || t('checklists.toast.save_error', 'There was a problem saving your checklist.'),
        variant: "destructive",
      });
    }
  });

  if (isLoadingChecklists) {
    return (
      <MainLayout>
        <div className="space-y-6 pt-2 pb-8">
          <Skeleton className="h-4 w-32 mb-4" />
          <Skeleton className="h-8 w-64 mb-2" />
          <Skeleton className="h-4 w-48 mb-6" />
          <Skeleton className="h-24 w-full mb-6" />
          <div className="space-y-3">
            <div className="flex justify-between items-center mb-2">
              <Skeleton className="h-6 w-48" />
              <Skeleton className="h-10 w-36" />
            </div>
            <Skeleton className="h-20 w-full" />
            <Skeleton className="h-20 w-full" />
            <Skeleton className="h-20 w-full" />
          </div>
        </div>
      </MainLayout>
    );
  }

  if (!property || !checklist) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <h2 className="text-2xl font-bold">{t('common.not_found')}</h2>
            <p className="text-muted-foreground mt-2">{t('checklists.not_found_description')}</p>
            <Button className="mt-4" onClick={() => navigate('/properties')}>
              {t('navigation.back_to_properties')}
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }

  // Calculate completion statistics
  const totalItems = items.length;
  const completedItems = items.filter(item => item.status === 'completed').length;
  const issueItems = items.filter(item => item.status === 'issue').length;
  const pendingItems = items.filter(item => item.status === 'pending').length;

  const handleStatusChange = (itemId: string, status: InventoryItemStatus) => {
    const updatedItems = items.map(item =>
      item.id === itemId ? { ...item, status } : item
    );
    setItems(updatedItems);
  };

  const handleNotesChange = (itemId: string, note: string) => {
    const updatedItems = items.map(item =>
      item.id === itemId ? { ...item, note } : item
    );
    setItems(updatedItems);
  };

  const handleComplete = async () => {
    if (!checklistId) return;

    try {
      const now = new Date().toISOString();

      // Update the checklist with the latest run date and updated items
      await updateChecklistAsync({
        id: checklistId,
        property_id: propertyId,
        last_run_date: now,
        // Only include items that have changes
        items: items.map(item => ({
          id: item.id,
          name: item.name,
          note: item.note,
          status: item.status
        }))
      });
    } catch (error) {
      // Error will be handled by the onError callback in the hook
      console.error("Failed to complete checklist run:", error);
    }
  };

  return (
    <MainLayout>
      <div className="space-y-6 pt-2 pb-8">
        <Button
          variant="ghost"
          onClick={() => navigate(`/properties/${propertyId}/checklists/${checklistId}`)}
          className="-ml-2 px-2"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('checklists.back_to_checklists')}
        </Button>

        <div>
          <h1 className="text-3xl font-bold tracking-tight">
            {t('checklists.running_checklist', 'Running Checklist')}: {checklist.title}
          </h1>
          <p className="text-muted-foreground mt-1">
            {t('checklists.for_property', {
              street: property.street,
              apartment: property.apartment,
              city: property.city
            })}
          </p>
        </div>

        <div className="bg-muted/50 p-4 rounded-lg">
          <div className="grid grid-cols-3 gap-4 text-center">
            <div className="flex flex-col items-center justify-center p-3 bg-white rounded-lg shadow-sm">
              <CheckCircle className="h-6 w-6 text-green-600 mb-2" />
              <span className="text-xl font-bold">{completedItems}</span>
              <span className="text-sm text-muted-foreground">{t('checklists.completed')}</span>
            </div>
            <div className="flex flex-col items-center justify-center p-3 bg-white rounded-lg shadow-sm">
              <AlertTriangle className="h-6 w-6 text-red-600 mb-2" />
              <span className="text-xl font-bold">{issueItems}</span>
              <span className="text-sm text-muted-foreground">{t('checklists.issues')}</span>
            </div>
            <div className="flex flex-col items-center justify-center p-3 bg-white rounded-lg shadow-sm">
              <Clock className="h-6 w-6 text-gray-600 mb-2" />
              <span className="text-xl font-bold">{pendingItems}</span>
              <span className="text-sm text-muted-foreground">{t('checklists.pending')}</span>
            </div>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">{t('checklists.checklist_items', 'Checklist Items')}</h2>
            <Button onClick={handleComplete} disabled={isUpdating}>
              <Save className="mr-2 h-4 w-4" />
              {isUpdating
                ? t('common.saving')
                : t('checklists.complete_inspection', 'Complete Inspection')}
            </Button>
          </div>

          {items.length === 0 ? (
            <Card>
              <CardContent className="p-6 text-center">
                <p className="text-muted-foreground">{t('checklists.no_items_in_checklist')}</p>
              </CardContent>
            </Card>
          ) : (
            <div className="space-y-3">
              {items.map(item => (
                <ChecklistItem
                  key={item.id}
                  item={item}
                  editable
                  onStatusChange={handleStatusChange}
                  onNotesChange={handleNotesChange}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ChecklistRun;