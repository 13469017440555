import { gql } from "graphql-request";

export const CREATE_PROPERTY = gql`
  mutation CreateProperty(
    $lessor_id: String!
    $status: String!
    $title: String
    $city: String!
    $street: String!
    $building: String!
    $block: String
    $apartment: String!
    $manager_id: String
    $bills_pay_by_lessor: Boolean!
    $wi_fi_network: String
    $wi_fi_key: String
    $door_code: String
    $isStudio: Boolean!
    $bedrooms: Int
    $bathrooms: Int
    $area: Int
    $image_url: String!
    $price: Int!
    $currency: String!
    $unit_term: String!
    $unit_measure: String!
  ) {
    insertIntopropertiesCollection(
      objects: { 
        lessor_id: $lessor_id
        status: $status
        title: $title
        city: $city
        street: $street
        building: $building
        block: $block
        apartment: $apartment
        manager_id: $manager_id
        bills_pay_by_lessor: $bills_pay_by_lessor
        wi_fi_network: $wi_fi_network
        wi_fi_key: $wi_fi_key
        door_code: $door_code
        isStudio: $isStudio
        bedrooms: $bedrooms
        bathrooms: $bathrooms
        area: $area
        image_url: $image_url
        price: $price
        currency: $currency
        unit_term: $unit_term
        unit_measure: $unit_measure
      }
    ) {
      records {
        id
        lessor_id
        status
        title
        city
        street
        building
        block
        apartment
        manager_id
        bills_pay_by_lessor
        wi_fi_network
        wi_fi_key
        door_code
        isStudio
        bedrooms
        bathrooms
        area
        image_url
        price
        currency
        unit_term
        unit_measure
        updated_at
        created_at
      }
    }
  }
`;
