"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { RentBill, Property, UserRole } from "@/types";
import { GET_RENT_BILLS_BY_DATE } from "@/queries-graphql/get/get-rent-bills-query";

interface FetchRentBillsResponse {
  rents_billsCollection: {
    edges: Array<{
      node: RentBill;
    }>;
  };
  propertiesCollection: {
    edges: Array<{
      node: Property;
    }>;
  };
}

interface FetchRentBillsResult {
  bills: RentBill[];
  properties: Record<string, Property>;
}

interface FetchRentBillsParams {
  user_id: string;
  role: UserRole;
  start_date: string; // Format: 'YYYY-MM-DD'
  end_date: string;   // Format: 'YYYY-MM-DD'
  property_id?: string; // Optional property filter
}

export async function fetchRentBills(params: FetchRentBillsParams): Promise<FetchRentBillsResult> {
  const client = graphqlClient;
  try {
    const { user_id, role, start_date, end_date, property_id } = params;

    console.log(`Fetching rent bills for ${role} ${user_id} from ${start_date} to ${end_date}`);

    // Modify query filter if property_id is provided
    let queryVariables: any = { user_id, role, start_date, end_date };

    // Add property_id to query variables if provided (will be handled in separate filter)
    if (property_id) {
      queryVariables.property_id = property_id;
    }

    const data = await client.request<FetchRentBillsResponse>(
      GET_RENT_BILLS_BY_DATE,
      queryVariables
    );

    // Extract bills and properties from response
    let bills = data.rents_billsCollection.edges.map(edge => edge.node);

    // Apply property filter client-side if needed (double check)
    if (property_id) {
      bills = bills.filter(bill => bill.property_id === property_id);
    }

    // Sort bills by due_date (newest first) - since we removed sorting from the query
    bills.sort((a, b) => new Date(b.due_date).getTime() - new Date(a.due_date).getTime());

    // Log results for debugging
    console.log(`Found ${bills.length} bills for period ${start_date} to ${end_date}`);

    // Create a map of property IDs to properties for easier lookup
    const properties: Record<string, Property> = {};
    data.propertiesCollection.edges.forEach(edge => {
      properties[edge.node.id] = edge.node;
    });

    // Filter properties based on role and user_id to ensure we only return relevant properties
    const relevantPropertyIds = new Set(bills.map(bill => bill.property_id));
    const filteredProperties: Record<string, Property> = {};

    Object.entries(properties).forEach(([id, property]) => {
      if (relevantPropertyIds.has(id)) {
        filteredProperties[id] = property;
      }
    });

    console.log(`Found ${Object.keys(filteredProperties).length} relevant properties`);

    return {
      bills,
      properties: filteredProperties
    };
  } catch (error) {
    console.error("Error fetching rent bills:", error);
    throw error;
  }
}