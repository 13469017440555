import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/AuthContext';
import { UserSettingsDrawer } from './UserSettingsDrawer';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../profile/LanguageSwitcher';

export const MobileHeader = () => {
    const { user, profile } = useAuth();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <header className="fixed top-0 left-0 right-0 h-14 z-40 flex items-center justify-between px-4 border-b bg-background">
                <h1 className="mt-1.5 font-sansation text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-indigo-500 to-purple-500">
                    {t('app.name')}
                </h1>

                <div className="h-full flex flex-row items-center justify-end gap-2">
                    <LanguageSwitcher variant="ghost" size="icon" />

                    <Button
                        variant="ghost"
                        size="icon"
                        className="w-auto h-full items-center rounded-full "
                        onClick={() => setSettingsOpen(true)}
                        aria-label={t('profile.settings')}
                    >
                        <div className='flex flex-row items-center'>
                            <div className={`h-4 p-3 rounded-full text-sm bg-gradient-to-r ${profile?.role === 'lessor' ? "from-indigo-500 to-blue-500" : " from-blue-500 to-indigo-500"} text-primary-foreground flex items-center justify-center font-semibold`}>
                                {profile && t(`profile.roles.${profile.role}`)}
                            </div>
                            <div className="h-8 w-8 rounded-full bg-gradient-to-br from-indigo-500 to-purple-500 text-primary-foreground flex items-center justify-center font-semibold">
                                {profile ? profile.first_name?.charAt(0) : user?.email?.charAt(0) || "U"}
                            </div>
                        </div>
                    </Button>
                </div>
            </header>

            <UserSettingsDrawer
                open={settingsOpen}
                onOpenChange={setSettingsOpen}
            />
        </>
    );
};