import React from 'react';
import { CheckCircle, ChevronDown, ChevronUp } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { BillItem } from '../shared/BillItem';
import { StatusBadge } from '../shared/StatusBadge';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/utils/i18n-utils';

interface TenantPropertyBillsGroupProps {
    monthKey: string;
    propertyId: string;
    propertyAddress: string;
    bills: CombinedBill[];
    totalAmount: number;
    allPaid: boolean;
    isOpen: boolean;
    isPayingBill: boolean;
    onToggle: () => void;
    onPayBill: (billId: string) => void;
}

export const TenantPropertyBillsGroup: React.FC<TenantPropertyBillsGroupProps> = ({
    monthKey,
    propertyId,
    propertyAddress,
    bills,
    totalAmount,
    allPaid,
    isOpen,
    isPayingBill,
    onToggle,
    onPayBill
}) => {
    const { t, i18n } = useTranslation();
    const unpaidBillsCount = bills.filter(bill => !bill.isPaid).length;
    const hasUnpaidBills = unpaidBillsCount > 0;

    return (
        <Collapsible
            open={isOpen}
            onOpenChange={onToggle}
            className="space-y-3 border rounded-lg p-3"
        >
            <div>
                <CollapsibleTrigger className="text-left w-full">
                    <div className="flex flex-wrap items-center justify-between gap-2 hover:bg-muted/50 p-2 rounded-md">
                        <div className="flex items-center gap-2">
                            <h3 className="font-medium">{propertyAddress}</h3>
                            {allPaid ? (
                                <CheckCircle className="h-4 w-4 text-green-500 ml-1 flex-shrink-0" />
                            ) : (
                                <StatusBadge
                                    variant="unpaid"
                                    count={unpaidBillsCount}
                                // If the StatusBadge component is also localized, we may need to pass
                                // translated text here depending on its implementation
                                />
                            )}
                        </div>
                        <div className="flex items-center gap-2 ml-auto">
                            <span className="text-sm text-muted-foreground">
                                {formatCurrency(totalAmount, i18n.language)}
                            </span>
                            <div className="flex-shrink-0">
                                {isOpen ?
                                    <ChevronUp className="h-4 w-4" /> :
                                    <ChevronDown className="h-4 w-4" />
                                }
                            </div>
                        </div>
                    </div>
                </CollapsibleTrigger>
            </div>

            <CollapsibleContent className="space-y-4 pt-2">
                <div className="p-3 space-y-3">
                    {bills.map(bill => (
                        <BillItem
                            key={bill.id}
                            bill={bill}
                            onPayBill={onPayBill}
                            isPayingBill={isPayingBill}
                        />
                    ))}
                </div>
            </CollapsibleContent>
        </Collapsible>
    );
};