// EmailConfirmation.tsx
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { CheckCircle, AlertCircle, Loader, ArrowRight } from 'lucide-react';
import { fetchRent } from '@/lib/fetch-functions/fetch-rent';
import { updateRent } from '@/lib/actions/update-rent';
import { toast } from '@/hooks/use-toast';

const EmailConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isConfirming, setIsConfirming] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    // Extract rentId from the URL if present
    const searchParams = new URLSearchParams(location.search);
    const rentId = searchParams.get('rentId');

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                // Check if we have a session after the redirect
                const { data, error } = await supabase.auth.getSession();

                if (error) {
                    console.error("Session error:", error);
                    setIsSuccess(false);
                    setIsConfirming(false);
                    return;
                }

                // If we have a session, the confirmation was successful
                if (data?.session) {
                    setIsSuccess(true);

                    // If this was from a rent invitation and we have a user, update the rent
                    if (rentId && data.session.user) {
                        try {
                            const rentData = await fetchRent(rentId);

                            if (rentData && rentData.id) {
                                await updateRent({
                                    ...rentData,
                                    tenant_id: data.session.user.id,
                                    updated_at: new Date().toISOString()
                                });

                                toast({
                                    title: "Property Connected",
                                    description: "You've been successfully connected to the property.",
                                });
                            }
                        } catch (rentError) {
                            console.error("Error updating rent after email confirmation:", rentError);
                        }
                    }
                } else {
                    // No session means the confirmation failed or hasn't happened
                    setIsSuccess(false);
                }
            } catch (error) {
                console.error("Email confirmation error:", error);
                setIsSuccess(false);
            } finally {
                setIsConfirming(false);
            }
        };

        // Add a small delay to make sure Supabase has time to process the hash params
        const timer = setTimeout(() => {
            confirmEmail();
        }, 1000);

        return () => clearTimeout(timer);
    }, [rentId, location]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-background p-4">
            <Card className="w-full max-w-md">
                {/* Rest of your component remains the same */}
                <CardHeader className="space-y-1">
                    {isConfirming ? (
                        <>
                            <div className="mx-auto bg-primary/10 p-4 rounded-full mb-4">
                                <Loader className="h-12 w-12 text-primary animate-spin" />
                            </div>
                            <CardTitle className="text-2xl text-center">Confirming Your Email</CardTitle>
                            <CardDescription className="text-center">
                                Please wait while we verify your email address...
                            </CardDescription>
                        </>
                    ) : isSuccess ? (
                        <>
                            <div className="mx-auto bg-green-100 p-4 rounded-full mb-4">
                                <CheckCircle className="h-12 w-12 text-green-600" />
                            </div>
                            <CardTitle className="text-2xl text-center">Email Confirmed!</CardTitle>
                            <CardDescription className="text-center">
                                Your email has been successfully verified. You can now sign in to your account.
                            </CardDescription>
                        </>
                    ) : (
                        <>
                            <div className="mx-auto bg-red-100 p-4 rounded-full mb-4">
                                <AlertCircle className="h-12 w-12 text-red-600" />
                            </div>
                            <CardTitle className="text-2xl text-center">Confirmation Failed</CardTitle>
                            <CardDescription className="text-center">
                                We were unable to confirm your email address. The link may have expired or been used already.
                            </CardDescription>
                        </>
                    )}
                </CardHeader>

                {!isConfirming && (
                    <>
                        <CardContent className="text-center">
                            {isSuccess ? (
                                <p className="text-sm text-muted-foreground">
                                    Thank you for confirming your email. You can now access all features of your account.
                                </p>
                            ) : (
                                <div className="space-y-4">
                                    <p className="text-sm text-muted-foreground">
                                        There was a problem confirming your email address. This might happen if:
                                    </p>
                                    <ul className="text-sm text-left list-disc pl-5 space-y-1 text-muted-foreground">
                                        <li>The confirmation link has expired</li>
                                        <li>The link has already been used</li>
                                        <li>There was a technical issue with the verification</li>
                                    </ul>
                                </div>
                            )}
                        </CardContent>

                        <CardFooter>
                            <Button asChild className="w-full">
                                <Link to="/auth/login">
                                    <ArrowRight className="mr-2 h-4 w-4" />
                                    {isSuccess ? "Go to Login" : "Try Logging In"}
                                </Link>
                            </Button>
                        </CardFooter>
                    </>
                )}
            </Card>
        </div>
    );
};

export default EmailConfirmation;