import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Building, Home } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface NoRentalsCardProps {
    onViewProperties?: () => void;
}

const NoRentalsCard: React.FC<NoRentalsCardProps> = ({ onViewProperties }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleViewProperties = () => {
        if (onViewProperties) {
            onViewProperties();
        } else {
            navigate('/tenant/properties');
        }
    };

    return (
        <Card>
            <CardContent className="p-6 text-center">
                <Home className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                <h3 className="text-lg font-medium mb-2">{t('tenant.no_rentals')}</h3>
                <p className="text-muted-foreground mb-4">{t('tenant.no_active_agreements')}</p>
                <Button onClick={handleViewProperties}>
                    <Building className="mr-2 h-4 w-4" /> {t('navigation.rentals')}
                </Button>
            </CardContent>
        </Card>
    );
};

export default NoRentalsCard;