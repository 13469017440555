import { useState, useMemo } from "react";
import { useRentBills } from "./useRentBills";
import { format, parseISO, startOfMonth, endOfMonth, addMonths, subMonths } from 'date-fns';
import { useUserData } from "./useUserData";
import { Property, UserRole } from "@/types";
import { generateMockUtilityBills, filterUtilityBills, MockUtilityBill } from "../fetch-functions/utility-bills-mock";


// Combined bill type for UI
export interface CombinedBill {
  id: string;
  type: 'rent' | 'utility';
  utilityType?: string;
  description: string;
  amount: number;
  currency: string;
  dueDate: string;
  isPaid: boolean;
  paidDate?: string;
  property_id: string;
  propertyAddress?: string;
  tenant_id: string;
  rentBill?: any; // Actual rent bill data
  utilityBill?: MockUtilityBill; // Mocked utility bill data
}

// Interface for the hook parameters
interface UseAllBillsParams {
  userId: string;
  role: UserRole;
  month?: number;
  year?: number;
  monthsToFetch?: number;
  propertyId?: string | null;
  startDate?: Date;
  endDate?: Date;
  isCurrentPeriod?: boolean; // Flag to indicate if we're showing current period
  properties?: Record<string, Property>; // Add properties parameter to pass directly to mock generator
}

export function useAllBills({
  userId,
  role,
  month = new Date().getMonth(),
  year = new Date().getFullYear(),
  monthsToFetch = 3,
  propertyId,
  startDate,
  endDate,
  isCurrentPeriod = false, // Default to false for history mode
  properties: explicitProperties // Added parameter to pass properties explicitly
}: UseAllBillsParams) {
  // If startDate and endDate are provided, use them to calculate month/year and monthsToFetch
  const finalMonth = endDate ? endDate.getMonth() : month;
  const finalYear = endDate ? endDate.getFullYear() : year;

  // Calculate months to fetch if custom date range is provided
  let finalMonthsToFetch = monthsToFetch;
  if (startDate && endDate) {
    // Calculate months between dates (including partial months)
    const monthDiff =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth()) + 1;

    finalMonthsToFetch = Math.min(Math.max(monthDiff, 1), 6); // Limit to max 6 months
  }

  // Get userData to access rentBills for current period
  const { data: userData, isLoading: isLoadingUserData } = useUserData(userId);

  // Only fetch rent bills from API for historical data, not current period
  const {
    bills: historyRentBills,
    properties: historyProperties,
    isLoading: isLoadingRentBills,
    isError: isErrorRentBills,
    error: rentBillsError,
    refetch: refetchRentBills
  } = useRentBills({
    userId,
    role,
    month: finalMonth,
    year: finalYear,
    monthsToFetch: finalMonthsToFetch,
    propertyId,
    // Only enable this query for historical data
    enabled: !isCurrentPeriod
  });

  // Calculate date range for filtering bills
  const calculatedEndDate = endDate || endOfMonth(new Date(finalYear, finalMonth));
  const calculatedStartDate = startDate || startOfMonth(subMonths(calculatedEndDate, finalMonthsToFetch - 1));

  // Generate mock utility bills instead of fetching from API
  const utilityBills = useMemo(() => {
    // Use explicit properties if provided, otherwise fallback to current/history mode properties
    const propertiesSource = explicitProperties ||
      (isCurrentPeriod ? userData?.properties || {} : historyProperties);

    console.log(`useAllBills: Generating bills for ${Object.keys(propertiesSource).length} properties`);
    if (Object.keys(propertiesSource).length === 0) {
      console.log("Warning: No properties available for generating mock utility bills");
    }

    // Generate mock utility bills for all properties
    const allUtilityBills = generateMockUtilityBills(userId, propertiesSource, role, 6);

    // Filter bills based on date range and property
    return filterUtilityBills(allUtilityBills, calculatedStartDate, calculatedEndDate, propertyId);
  }, [userId, role, userData, historyProperties, calculatedStartDate, calculatedEndDate, propertyId, isCurrentPeriod, explicitProperties]);

  // Map utility bills to combined bill format
  const mappedUtilityBills = useMemo(() => {
    return utilityBills.map(bill => ({
      id: bill.id,
      type: 'utility' as const,
      utilityType: bill.utilityType,
      description: bill.description,
      amount: bill.amount,
      currency: bill.currency,
      dueDate: bill.dueDate,
      isPaid: bill.isPaid,
      paidDate: bill.paidDate,
      property_id: bill.property_id,
      propertyAddress: bill.propertyAddress,
      tenant_id: bill.tenant_id,
      utilityBill: bill
    }));
  }, [utilityBills]);

  // Combine both bill types - use userData for current period rent bills
  const combinedBills = useMemo<CombinedBill[]>(() => {
    const allBills: CombinedBill[] = [...mappedUtilityBills];

    // Decide which rent bills and properties to use based on if we're showing current period
    const rentBills = isCurrentPeriod ? [] : historyRentBills;
    // Use explicit properties if provided, otherwise fall back
    const billProperties = explicitProperties ||
      (isCurrentPeriod ? userData?.properties || {} : historyProperties);

    // For current period, extract rent bills from userData
    if (isCurrentPeriod && userData) {
      console.log("Using userData for current period bills");

      // For current period, get ALL rent bills from userData
      if (userData.rentBills) {
        console.log(`Found ${Object.keys(userData.rentBills).length} total rent bills in userData`);

        // Loop through all rent bills in userData
        Object.values(userData.rentBills).forEach(bill => {
          // Check if this bill matches our filters (user role and property)
          const matchesUserRole = (role === 'tenant' && bill.tenant_id === userId) ||
            (role === 'lessor' && bill.lessor_id === userId);

          const matchesProperty = !propertyId || bill.property_id === propertyId;

          if (matchesUserRole && matchesProperty) {
            const property = userData.properties[bill.property_id];
            console.log(`Adding rent bill ${bill.id} to current bills (${property?.street || 'unknown property'})`);

            allBills.push({
              id: `rent-${bill.id}`,
              type: 'rent',
              description: `Rent payment for ${property?.street || ''} ${property?.building || ''}`,
              amount: bill.price_rent || 0,
              currency: bill.currency,
              dueDate: bill.due_date,
              isPaid: bill.isPaid,
              paidDate: bill.paid_at || undefined,
              property_id: bill.property_id,
              propertyAddress: property ? `${property.street} ${property.building}` : undefined,
              tenant_id: bill.tenant_id,
              rentBill: bill
            });
          }
        });
      } else {
        console.log("No rent bills found in userData");
      }
    } else {
      // Convert rent bills to combined format for historical data
      rentBills.forEach(bill => {
        // Skip if the date is outside our range if custom dates provided
        if (startDate && endDate) {
          const billDate = new Date(bill.due_date);
          if (billDate < startDate || billDate > endDate) {
            return;
          }
        }

        const property = billProperties[bill.property_id];
        allBills.push({
          id: `rent-${bill.id}`,
          type: 'rent',
          description: `Rent payment for ${property?.street || ''} ${property?.building || ''}`,
          amount: bill.price_rent || 0,
          currency: bill.currency,
          dueDate: bill.due_date,
          isPaid: bill.isPaid,
          paidDate: bill.paid_at || undefined,
          property_id: bill.property_id,
          propertyAddress: property ? `${property.street} ${property.building}` : undefined,
          tenant_id: bill.tenant_id,
          rentBill: bill
        });
      });
    }

    // Sort by due date (newest first)
    // Important since we're not sorting in the GraphQL query
    return allBills.sort((a, b) => {
      // Ensure proper date parsing
      const dateA = new Date(a.dueDate).getTime();
      const dateB = new Date(b.dueDate).getTime();

      // Handle invalid dates
      if (isNaN(dateA) && isNaN(dateB)) return 0;
      if (isNaN(dateA)) return 1;
      if (isNaN(dateB)) return -1;

      return dateB - dateA;
    });
  }, [mappedUtilityBills, historyRentBills, userData, isCurrentPeriod, role, userId, propertyId, startDate, endDate, historyProperties, explicitProperties]);

  // Group bills by month
  const billsByMonth = useMemo(() => {
    const grouped: Record<string, {
      monthKey: string;
      displayName: string;
      bills: CombinedBill[];
      totalAmount: number;
      unpaidAmount: number;
      allPaid: boolean;
    }> = {};

    combinedBills.forEach(bill => {
      try {
        const date = new Date(bill.dueDate);
        if (isNaN(date.getTime())) {
          console.warn('Invalid date in bill:', bill);
          return;
        }

        const monthKey = format(date, 'yyyy-MM');
        const displayName = format(date, 'MMMM yyyy');

        if (!grouped[monthKey]) {
          grouped[monthKey] = {
            monthKey,
            displayName,
            bills: [],
            totalAmount: 0,
            unpaidAmount: 0,
            allPaid: true
          };
        }

        grouped[monthKey].bills.push(bill);
        grouped[monthKey].totalAmount += bill.amount;

        if (!bill.isPaid) {
          grouped[monthKey].unpaidAmount += bill.amount;
          grouped[monthKey].allPaid = false;
        }
      } catch (error) {
        console.error('Error processing bill:', error, bill);
      }
    });

    // Convert to array and sort by month (newest first)
    return Object.values(grouped).sort((a, b) =>
      b.monthKey.localeCompare(a.monthKey)
    );
  }, [combinedBills]);

  // Group bills by property
  const billsByProperty = useMemo(() => {
    const grouped: Record<string, {
      propertyId: string;
      propertyAddress?: string;
      bills: CombinedBill[];
      totalAmount: number;
      unpaidAmount: number;
      allPaid: boolean;
    }> = {};

    combinedBills.forEach(bill => {
      if (!grouped[bill.property_id]) {
        grouped[bill.property_id] = {
          propertyId: bill.property_id,
          propertyAddress: bill.propertyAddress,
          bills: [],
          totalAmount: 0,
          unpaidAmount: 0,
          allPaid: true
        };
      }

      grouped[bill.property_id].bills.push(bill);
      grouped[bill.property_id].totalAmount += bill.amount;

      if (!bill.isPaid) {
        grouped[bill.property_id].unpaidAmount += bill.amount;
        grouped[bill.property_id].allPaid = false;
      }
    });

    return Object.values(grouped);
  }, [combinedBills]);

  // Simulate paying a bill
  const payBill = async (billId: string) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 800));

    if (billId.startsWith('utility-')) {
      // Mock utility bill payment logic
      // In a real app, this would call a real API
      console.log(`Paying utility bill: ${billId}`);

      // We'd normally update state here, but since we're just using a mock,
      // we'll force a refetch to regenerate the mock data
      setTimeout(() => refetch(), 200);

      return true;
    } else {
      // Pay rent bill via database (existing logic)
      const rentBillId = billId.replace('rent-', '');

      try {
        // Import dynamically to avoid server component issues
        const { payRentBill } = await import('../actions/rent-bills-payment-service');
        const result = await payRentBill(rentBillId);

        if (!result) {
          throw new Error('Failed to process rent payment');
        }

        return result;
      } catch (error) {
        console.error('Error paying rent bill:', error);
        throw error;
      }
    }
  };

  // For simplicity, we'll use a state for tracking payment status
  const [isPayingBill, setIsPayingBill] = useState(false);

  // Wrap the payBill function to handle the loading state
  const handlePayBill = async (billId: string) => {
    setIsPayingBill(true);
    try {
      const result = await payBill(billId);
      return result;
    } finally {
      setIsPayingBill(false);
    }
  };

  // Refetch function
  const refetch = () => {
    if (!isCurrentPeriod) {
      refetchRentBills();
    }
    // For utility bills, we'll force a re-render which will regenerate the mocks
    const timestamp = Date.now();
    console.log(`Refreshing mock utility bills: ${timestamp}`);
  };

  return {
    bills: combinedBills,
    billsByMonth,
    billsByProperty,
    properties: explicitProperties || (isCurrentPeriod ? userData?.properties || {} : historyProperties),
    isLoading: (isCurrentPeriod ? isLoadingUserData : isLoadingRentBills),
    isError: (isCurrentPeriod ? false : isErrorRentBills),
    error: (isCurrentPeriod ? null : rentBillsError),
    refetch,
    payBill: handlePayBill,
    isPayingBill
  };
}