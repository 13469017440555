import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { CircleX, User, Calendar, DollarSign, Clock, CheckCircle, AlertCircle } from 'lucide-react';
import { Rent, UserProfile } from '@/types';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate, getLocalizedUnit } from '@/utils/i18n-utils';

interface RentInformationCardProps {
  propertyId: string;
  currentRent: Rent | undefined;
  tenant: UserProfile | undefined;
  handleOpenCancelDialog: (rent: Rent) => void;
  formatDateFn?: (dateString: string) => string;
}

const RentInformationCard: React.FC<RentInformationCardProps> = ({
  propertyId,
  currentRent,
  tenant,
  handleOpenCancelDialog,
  formatDateFn,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Format date function that uses either provided formatter or i18n
  const formatDateString = (dateString: string) => {
    if (formatDateFn) {
      return formatDateFn(dateString);
    }
    return formatDate(dateString, i18n.language);
  };

  // Get status color based on rent status
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active': return 'bg-green-100 text-green-800 hover:bg-green-200';
      case 'pending': return 'bg-amber-100 text-amber-800 hover:bg-amber-200';
      case 'canceled': return 'bg-red-100 text-red-800 hover:bg-red-200';
      default: return 'bg-gray-100 text-gray-800 hover:bg-gray-200';
    }
  };

  // Get translated status
  const getTranslatedStatus = (status: string) => {
    return t(`tenant.status.${status}`);
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-purple-50 to-indigo-50 pb-6">
        <CardTitle className="flex items-center justify-between space-x-2">
          <span>{t('properties.rent_information')}</span>
          {currentRent && (
            <Badge className={`text-nowrap font-normal ${getStatusColor(currentRent.status)}`}>
              {currentRent.status === 'active' && <CheckCircle className="size-3 mr-1" />}
              {currentRent.status === 'pending' && <Clock className="size-3 mr-1" />}
              {currentRent.status === 'canceled' && <AlertCircle className="size-3 mr-1" />}
              {getTranslatedStatus(currentRent.status)}
            </Badge>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        {currentRent ? (
          <>
            {tenant && (
              <div className="flex items-center gap-3 mb-4">
                <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                  <User className="h-6 w-6 text-primary" />
                </div>
                <div>
                  <p className="font-medium">{tenant.first_name} {tenant.last_name || ''}</p>
                  <p className="text-sm text-muted-foreground">{tenant.email}</p>
                </div>
              </div>
            )}

            <Separator className="my-4" />

            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <div className="h-8 w-8 rounded-full bg-indigo-100 flex items-center justify-center">
                  <Calendar className="h-4 w-4 text-primary" />
                </div>
                <div className="space-y-1">
                  <p className="text-xs font-medium text-muted-foreground">{t('properties.period')}</p>
                  <p className="font-medium">
                    {formatDateString(currentRent.date_start)} - {formatDateString(currentRent.date_end)}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <div className="h-8 w-8 rounded-full bg-emerald-100 flex items-center justify-center">
                  <DollarSign className="h-4 w-4 text-emerald-600" />
                </div>
                <div className="space-y-1">
                  <p className="text-xs font-medium text-muted-foreground">
                    {t('properties.price')} ({getLocalizedUnit(currentRent.unit, i18n.language)})
                  </p>
                  <p className="font-medium">
                    {formatCurrency(currentRent.price_rent, i18n.language, currentRent.currency)}
                  </p>
                </div>
              </div>
            </div>

            <Separator className="my-4" />


          </>
        ) : (
          <>
            <div className="text-center py-8 space-y-3">
              <div className="mx-auto h-16 w-16 rounded-full bg-muted flex items-center justify-center">
                <User className="h-8 w-8 text-muted-foreground" />
              </div>
              <div>
                <p className="font-medium text-lg">{t('tenant.no_tenant')}</p>
                <p className="text-sm text-muted-foreground mt-1 mb-4">
                  {t('tenant.no_tenant_desc')}
                </p>
              </div>

            </div>
          </>
        )}
        <div className='flex flex-row gap-2'>
          {currentRent
            && (<Button
              variant="outline"
              className="w-full border-destructive/20 text-destructive hover:bg-destructive/10 hover:text-destructive"
              onClick={() => handleOpenCancelDialog(currentRent)}
            >
              <CircleX className="size-4 shrink-0 mr-2" /> {t('tenant.cancel_rent')}
            </Button>)}

          <Button
            className="w-full"
            onClick={() => navigate(`/properties/${propertyId}/invite`)}
          >
            <User className="size-4 shrink-0 mr-2" /> {currentRent ? t('tenant.invite') : t('tenant.invite_tenant')}
          </Button>

        </div>
      </CardContent>
    </Card>
  );
};

export default RentInformationCard;