import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Eye, EyeOff, KeyRound, Save, Lock, Wifi, Asterisk } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

interface PropertyKeysCardProps {
  doorCode: string;
  wifiNetwork: string;
  wifiPassword: string;
  isEditingKeys: boolean;
  hasKeysChanges: () => boolean;
  setDoorCode: React.Dispatch<React.SetStateAction<string>>;
  setWifiNetwork: React.Dispatch<React.SetStateAction<string>>;
  setWifiPassword: React.Dispatch<React.SetStateAction<string>>;
  setIsEditingKeys: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateKeys: () => Promise<void>;
}

const PropertyKeysCard: React.FC<PropertyKeysCardProps> = ({
  doorCode,
  wifiNetwork,
  wifiPassword,
  isEditingKeys,
  hasKeysChanges,
  setDoorCode,
  setWifiNetwork,
  setWifiPassword,
  setIsEditingKeys,
  handleUpdateKeys,
}) => {
  const { t } = useTranslation();
  const [showDoorCode, setShowDoorCode] = useState<boolean>(false);
  const [showWifiPassword, setShowWifiPassword] = useState<boolean>(false);

  const toggleDoorCode = () => setShowDoorCode(!showDoorCode);
  const toggleWifiPassword = () => setShowWifiPassword(!showWifiPassword);

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-indigo-50 to-purple-50 pb-4">
        <div className="flex items-center gap-2">
          <Lock className="h-5 w-5 text-indigo-500" />
          <div>
            <CardTitle>{t('properties.access_information')}</CardTitle>
            <CardDescription>{t('properties.access_credentials')}</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="space-y-5 pt-5">
        <div className={cn(
          "rounded-lg border p-4 space-y-4",
          isEditingKeys ? "bg-white" : "bg-gray-50"
        )}>
          {/* Door Code */}
          <div>
            <div className="flex items-center gap-2 mb-2">
              <div className="h-5 w-5 rounded-full bg-purple-100 flex items-center justify-center">
                <KeyRound className="h-3 w-3 text-purple-600" />
              </div>
              <label htmlFor="door-code" className="text-sm font-medium">{t('properties.door_code')}</label>
            </div>
            {isEditingKeys ? (
              <div className="flex items-center h-9">
                <Input
                  id="door-code"
                  value={doorCode}
                  onChange={(e) => setDoorCode(e.target.value)}
                  className="h-9 text-sm font-mono"
                  type={showDoorCode ? "text" : "password"}
                  placeholder={t('properties.enter_door_code')}
                />
                <Button variant="ghost" size="icon" onClick={toggleDoorCode} className="h-9 w-9 ml-1">
                  {showDoorCode ? <EyeOff className="size-4" /> : <Eye className="size-4" />}
                </Button>
              </div>
            ) : (
              <div className="flex items-center h-9">
                <div className="flex-1 text-sm font-mono bg-white border rounded-md px-3 py-2 truncate">
                  {showDoorCode ? doorCode : '••••••'}
                </div>
                <Button variant="ghost" size="icon" onClick={toggleDoorCode} className="h-9 w-9 ml-1">
                  {showDoorCode ? <EyeOff className="size-4" /> : <Eye className="size-4" />}
                </Button>
              </div>
            )}
          </div>

          {/* WiFi Network */}
          <div>
            <div className="flex items-center gap-2 mb-2">
              <div className="h-5 w-5 rounded-full bg-indigo-100 flex items-center justify-center">
                <Wifi className="h-3 w-3 text-indigo-600" />
              </div>
              <label htmlFor="wifi-network" className="text-sm font-medium">{t('properties.wifi_network')}</label>
            </div>
            {isEditingKeys ? (
              <Input
                id="wifi-network"
                value={wifiNetwork}
                onChange={(e) => setWifiNetwork(e.target.value)}
                className="h-9 text-sm font-mono"
                placeholder={t('properties.enter_wifi_network')}
              />
            ) : (
              <div className="text-sm font-mono bg-white border rounded-md px-3 py-2 truncate">
                {wifiNetwork || t('properties.not_set')}
              </div>
            )}
          </div>

          {/* WiFi Password */}
          <div>
            <div className="flex items-center gap-2 mb-2">
              <div className="h-5 w-5 rounded-full bg-blue-100 flex items-center justify-center">
                <Asterisk className="h-3 w-3 text-blue-600" />
              </div>
              <label htmlFor="wifi-password" className="text-sm font-medium">{t('properties.wifi_password')}</label>
            </div>
            {isEditingKeys ? (
              <div className="flex items-center h-9">
                <Input
                  id="wifi-password"
                  value={wifiPassword}
                  onChange={(e) => setWifiPassword(e.target.value)}
                  className="h-9 text-sm font-mono"
                  type={showWifiPassword ? "text" : "password"}
                  placeholder={t('properties.enter_wifi_password')}
                />
                <Button variant="ghost" size="icon" onClick={toggleWifiPassword} className="h-9 w-9 ml-1">
                  {showWifiPassword ? <EyeOff className="size-4" /> : <Eye className="size-4" />}
                </Button>
              </div>
            ) : (
              <div className="flex items-center h-9">
                <div className="flex-1 text-sm font-mono bg-white border rounded-md px-3 py-2 truncate">
                  {showWifiPassword ? wifiPassword : '••••••••'}
                </div>
                <Button variant="ghost" size="icon" onClick={toggleWifiPassword} className="h-9 w-9 ml-1">
                  {showWifiPassword ? <EyeOff className="size-4" /> : <Eye className="size-4" />}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className='flex flex-row gap-2 w-full'>
          {isEditingKeys && (
            <Button variant="outline" className="w-full" onClick={() => setIsEditingKeys(false)}>
              {t('common.cancel')}
            </Button>
          )}

          <Button
            className="w-full"
            onClick={handleUpdateKeys}
            disabled={isEditingKeys && !hasKeysChanges()}
          >
            {isEditingKeys ? (
              <>
                <Save className="size-4 mr-2" /> {t('common.save')}
              </>
            ) : (
              <>
                <KeyRound className="size-4 mr-2" /> {t('properties.update_keys')}
              </>
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PropertyKeysCard;