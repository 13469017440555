"use server"

import { InventoryItem, InventoryItemStatus } from "@/types";
import { BatchCreateInventoryItemsResponse } from "@/types/mutation/checklist-response-types";
import { graphqlClient } from "@/lib/graphql-client";
import { BATCH_CREATE_INVENTORY_ITEMS } from "@/queries-graphql/mutation/batch-create-inventory-items-mutation";
import { InventoryItemInput } from "@/types/mutation/checklist-input-types";

export async function batchCreateInventoryItems(items: InventoryItemInput[]): Promise<InventoryItem[]> {
  const client = graphqlClient;
  try {
    if (items.length === 0) {
      return [];
    }

    // Format items for the mutation
    const inventoryItems = items.map(item => ({
      checklist_id: item.checklist_id,
      name: item.name,
      note: item.note || null,
      status: item.status
    }));

    // Create variables object exactly as in the working example
    const variables = { items: inventoryItems };

    // Make the request
    const data = await client.request<BatchCreateInventoryItemsResponse>(
      BATCH_CREATE_INVENTORY_ITEMS,
      variables
    );

    const createdItems = data.insertIntoinventory_itemsCollection.records;

    if (!createdItems || createdItems.length === 0) {
      throw new Error("Failed to create inventory items");
    }

    return createdItems;
  } catch (error) {
    console.error("Error batch creating inventory items:", error);
    throw error;
  }
}