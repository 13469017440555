import { useQuery } from "@tanstack/react-query";
import { fetchRentBills } from "../fetch-functions/fetch-rent-bills";
import { RentBill, Property, UserRole } from "@/types";
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
  addMonths
} from "date-fns";

interface UseFetchRentBillsParams {
  userId: string;
  role: UserRole;
  month?: number; // 0-11 (JavaScript month index)
  year?: number;  // Full year (e.g., 2023)
  monthsToFetch?: number; // Number of months to fetch (default: 3)
  propertyId?: string; // Optional property filter
  startDate?: Date; // Optional explicit start date
  endDate?: Date; // Optional explicit end date
  enabled?: boolean; // Whether to enable the query (default: true)
}

interface UseFetchRentBillsResult {
  bills: RentBill[];
  properties: Record<string, Property>;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => void;
}

export function useRentBills({
  userId,
  role,
  month = new Date().getMonth(),
  year = new Date().getFullYear(),
  monthsToFetch = 3,
  propertyId,
  startDate: explicitStartDate,
  endDate: explicitEndDate,
  enabled = true
}: UseFetchRentBillsParams): UseFetchRentBillsResult {
  // Calculate start and end dates
  // Use explicit dates if provided, otherwise calculate from month/year/monthsToFetch
  const endDate = explicitEndDate || endOfMonth(new Date(year, month));
  const startDate = explicitStartDate || startOfMonth(subMonths(endDate, monthsToFetch - 1));

  // Validate range (maximum 6 months)
  const maxAllowedStart = startOfMonth(subMonths(endDate, 5)); // 6 months including end month
  const validatedStartDate = startDate < maxAllowedStart ? maxAllowedStart : startDate;

  // Format dates for API
  const formattedStartDate = format(validatedStartDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  const queryKey = ['rentBills', userId, role, formattedStartDate, formattedEndDate, propertyId];

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey,
    queryFn: () => fetchRentBills({
      user_id: userId,
      role,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      property_id: propertyId
    }),
    enabled: enabled && !!userId && !!role, // Use enabled flag
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    bills: data?.bills || [],
    properties: data?.properties || {},
    isLoading,
    isError,
    error: error as Error | null,
    refetch
  };
}