import React from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { MainLayout } from '@/components/layout/MainLayout';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { User, Shield, ArrowLeft } from 'lucide-react';
import ProfileEmailUpdate from '@/components/profile/ProfileEmailUpdate';
import ProfilePasswordChange from '@/components/profile/ProfilePasswordChange';
import ProfileEditor from '@/components/profile/ProfileEditor';
import RoleSwitcher from '@/components/nav/RoleSwitcher';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { LogoutButton } from '@/components/profile/LogoutButton';
import { useTranslation } from 'react-i18next';

const UserProfilePage: React.FC = () => {
    const { user, profile } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleGoBack = () => {
        navigate(-1); // Navigate to the previous route
    };

    return (
        <MainLayout>
            <div className="max-w-4xl mx-auto space-y-6 pt-2 pb-8">
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-3xl font-bold tracking-tight">{t('profile.account_settings')}</h1>
                        <p className="text-muted-foreground mt-1">
                            {t('profile.account_settings_description')}
                        </p>
                    </div>
                    <Button
                        variant="ghost"
                        onClick={handleGoBack}
                        className="flex items-center gap-2"
                    >
                        <ArrowLeft className="h-4 w-4" />
                        {t('common.back')}
                    </Button>
                </div>

                <Separator />

                {/* Profile Header */}
                <div className="flex flex-col md:flex-row gap-6 items-start md:items-center justify-between">
                    <div className="flex gap-4 items-center">
                        <div className="w-20 h-20 rounded-full bg-primary/10 flex items-center justify-center">
                            {profile?.image_url ? (
                                <img
                                    src={profile.image_url}
                                    alt={t('profile.profile_picture')}
                                    className="w-full h-full rounded-full object-cover"
                                />
                            ) : (
                                <User className="h-10 w-10 text-primary" />
                            )}
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold">
                                {profile?.first_name} {profile?.last_name}
                            </h2>
                            <p className="text-muted-foreground">
                                {user?.email}
                            </p>
                            <div className="flex items-center mt-1">
                                <span className="text-xs px-2 py-0.5 rounded bg-primary/10 text-primary capitalize">
                                    {profile?.role ? t(`profile.${profile.role}_role`) : t('common.unknown')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Tabs for different sections */}
                <Tabs defaultValue="profile" className="mt-6">
                    <TabsList className="grid grid-cols-2 md:w-[400px]">
                        <TabsTrigger value="profile" className="flex items-center gap-2">
                            <User className="h-4 w-4" />
                            <span>{t('profile.title')}</span>
                        </TabsTrigger>
                        <TabsTrigger value="security" className="flex items-center gap-2">
                            <Shield className="h-4 w-4" />
                            <span>{t('profile.security')}</span>
                        </TabsTrigger>
                    </TabsList>

                    {/* Profile Tab */}
                    <TabsContent value="profile" className="space-y-6 mt-6">
                        <ProfileEditor />

                        <div className="bg-gray-50 p-6 rounded-lg border">
                            <h3 className="text-lg font-medium mb-4">{t('profile.account_preferences')}</h3>
                            <RoleSwitcher className="mb-6" />

                            <Separator className="my-6" />

                            <div className="space-y-3">
                                <h4 className="text-sm font-medium">{t('profile.account_actions')}</h4>
                                <LogoutButton />
                            </div>
                        </div>
                    </TabsContent>

                    {/* Security Tab */}
                    <TabsContent value="security" className="space-y-6 mt-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Email Update */}
                            <ProfileEmailUpdate className="h-full flex flex-col justify-stretch items-stretch" />

                            {/* Password Change */}
                            <ProfilePasswordChange className="h-full flex flex-col justify-stretch items-stretch" />
                        </div>
                    </TabsContent>
                </Tabs>
            </div>
        </MainLayout>
    );
};

export default UserProfilePage;