"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Property } from "@/types";
import { CreatePropertyInput } from "@/types/mutation/mutation-input-types";
import { CreatePropertyResponse } from "@/types/mutation/mutation-responses-types";
import { CREATE_PROPERTY } from "@/queries-graphql/mutation/new-property-mutation";

// Function to create a new Property
export const createProperty = async (
    input: CreatePropertyInput
): Promise<Property> => {
    const client = graphqlClient;
    try {
        const variables = { ...input }

        const data = await client.request<CreatePropertyResponse>(
            CREATE_PROPERTY,
            variables
        );
        const response = data.insertIntopropertiesCollection.records[0];
        return response;
    } catch (error) {
        console.error('Error creating Property:', error);
        throw error;
    }
};
