import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Mail, Edit, CheckCircle2, AlertCircle } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import PasswordInput from '../ui/custom-ui/PasswordInput';
import { toast } from '@/hooks/use-toast';
import { useTranslation } from 'react-i18next';

interface ProfileEmailUpdateProps {
    className?: string;
}

const ProfileEmailUpdate: React.FC<ProfileEmailUpdateProps> = ({ className }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [isChangeEmailDialogOpen, setIsChangeEmailDialogOpen] = useState(false);

    // Form state
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const resetForm = () => {
        setNewEmail('');
        setPassword('');
        setError(null);
        setIsSubmitted(false);
    };

    const handleCloseDialog = () => {
        resetForm();
        setIsChangeEmailDialogOpen(false);
    };

    const validateForm = (): boolean => {
        // Empty fields validation
        if (!newEmail.trim() || !password.trim()) {
            setError(t('profile.email_update.all_fields_required'));
            return false;
        }

        // Basic email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newEmail)) {
            setError(t('profile.email_update.invalid_email'));
            return false;
        }

        // Ensure new email is different from current email
        if (user?.email === newEmail) {
            setError(t('profile.email_update.same_email'));
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            // Verify password first by signing in
            const { error: signInError } = await supabase.auth.signInWithPassword({
                email: user?.email || '',
                password,
            });

            if (signInError) {
                throw new Error(t('profile.email_update.incorrect_password'));
            }

            // Initiate email change
            const { error: updateError } = await supabase.auth.updateUser(
                { email: newEmail },
                { emailRedirectTo: `${window.location.origin}/auth/email-change-confirmation` }
            );

            if (updateError) {
                throw updateError;
            }

            setIsSubmitted(true);

            toast({
                title: t('profile.email_update.verification_sent_title'),
                description: t('profile.email_update.verification_sent_description'),
            });
        } catch (error) {
            console.error('Email change error:', error);
            let errorMessage = t('profile.email_update.general_error');

            if (error instanceof Error) {
                errorMessage = error.message;
            }

            setError(errorMessage);

            toast({
                title: t('profile.email_update.failed_title'),
                description: errorMessage,
                variant: "destructive",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Card className={className}>
                <CardHeader>
                    <CardTitle className="text-xl">{t('profile.email_update.email_address')}</CardTitle>
                    <CardDescription>
                        {t('profile.email_update.email_description')}
                    </CardDescription>
                </CardHeader>
                <CardContent className="pt-0">
                    <div className="flex items-center">
                        <Mail className="mr-2 h-4 w-4 text-muted-foreground" />
                        <span className="font-medium">{user?.email}</span>
                    </div>
                </CardContent>
                <CardFooter className="border-t bg-muted/50 px-6 py-4">
                    <Button
                        variant="outline"
                        className="gap-1"
                        onClick={() => setIsChangeEmailDialogOpen(true)}
                    >
                        <Edit className="h-4 w-4" />
                        <span>{t('profile.email_update.change_email')}</span>
                    </Button>
                </CardFooter>
            </Card>

            <Dialog open={isChangeEmailDialogOpen} onOpenChange={handleCloseDialog}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle>{t('profile.email_update.change_email_address')}</DialogTitle>
                        <DialogDescription>
                            {t('profile.email_update.change_email_description')}
                        </DialogDescription>
                    </DialogHeader>

                    {isSubmitted ? (
                        <div className="space-y-4 py-4">
                            <div className="flex items-center justify-center py-4">
                                <div className="h-16 w-16 bg-green-100 rounded-full flex items-center justify-center">
                                    <CheckCircle2 className="h-8 w-8 text-green-600" />
                                </div>
                            </div>

                            <Alert className="bg-green-50 border-green-200">
                                <AlertDescription>
                                    {t('profile.email_update.verification_success')}
                                </AlertDescription>
                            </Alert>

                            <div className="text-center mt-4">
                                <p className="text-sm text-muted-foreground mb-4">
                                    {t('profile.email_update.didnt_receive')}
                                </p>
                                <Button
                                    variant="outline"
                                    onClick={() => setIsSubmitted(false)}
                                >
                                    {t('profile.email_update.try_again')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-4 py-4">
                            {error && (
                                <Alert variant="destructive">
                                    <AlertCircle className="h-4 w-4" />
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}

                            <div className="space-y-2">
                                <Label htmlFor="currentEmail">{t('profile.email_update.current_email')}</Label>
                                <Input
                                    id="currentEmail"
                                    type="email"
                                    value={user?.email || ''}
                                    disabled
                                    className="bg-muted"
                                />
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="newEmail">{t('profile.email_update.new_email')}</Label>
                                <Input
                                    id="newEmail"
                                    type="email"
                                    placeholder={t('profile.email_update.new_email_placeholder')}
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <PasswordInput
                                id="password"
                                label={t('profile.email_update.current_password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={t('profile.email_update.password_placeholder')}
                            />

                            <div className="mt-2">
                                <p className="text-sm text-muted-foreground">
                                    {t('profile.email_update.security_note')}
                                </p>
                            </div>

                            <div className="flex justify-end gap-2 pt-4">
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={handleCloseDialog}
                                >
                                    {t('common.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                                            {t('profile.email_update.processing')}
                                        </>
                                    ) : (
                                        t('profile.email_update.change_email')
                                    )}
                                </Button>
                            </div>
                        </form>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProfileEmailUpdate;