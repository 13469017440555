import React, { useState, useEffect, useMemo } from 'react';
import { MainLayout } from '@/components/layout/MainLayout';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format, parseISO, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { useAuth } from '@/contexts/AuthContext';
import { Skeleton } from '@/components/ui/skeleton';
import { useAllBills } from '@/lib/query-hooks/useAllBills';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { Property } from '@/types';
import PropertyFilter from '@/components/bills/shared/PropertyFilter';
import CurrentBillsTabLessor from '@/components/bills/lessor/CurrentBillsTabLessor';
import HistoryBillsTabLessor from '@/components/bills/lessor/HistoryBillsTabLessor';
import { useTranslation } from 'react-i18next';

const LessorBills: React.FC = () => {
  const [activeTab, setActiveTab] = useState('current');
  const { user, role } = useAuth();
  const { t } = useTranslation();

  // Property filter
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(null);

  // For history filter
  const [startDate, setStartDate] = useState(() => subMonths(startOfMonth(new Date()), 3));
  const [endDate, setEndDate] = useState(() => endOfMonth(new Date()));

  // Calculate month and year for different views
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // Get user data to access property information
  const { data: userData, isLoading: isLoadingUserData } = useUserData(user?.id || '');

  // Get lessor properties from userData
  const lessorProperties = useMemo(() => {
    if (!userData || !user?.id) return {};

    const lessorPropertyIds = userData.userProperties?.asLessor?.[user.id] || [];
    const propertiesObj: Record<string, Property> = {};

    lessorPropertyIds.forEach(propId => {
      if (userData.properties[propId]) {
        propertiesObj[propId] = userData.properties[propId];
      }
    });

    return propertiesObj;
  }, [userData, user?.id]);

  // For current bills, we want to fetch all bills for all properties
  // Fetch a larger date range to capture all potential unpaid bills
  const currentBillsHook = useAllBills({
    userId: user?.id || '',
    role: 'lessor',
    month: currentMonth,
    year: currentYear,
    monthsToFetch: 6, // Increased to fetch more unpaid bills
    propertyId: null, // Important: Don't filter by property here to get all bills
    isCurrentPeriod: true
  });

  // Fetch historical bills (for selected date range) with explicit properties
  const historyBillsHook = useAllBills({
    userId: user?.id || '',
    role: 'lessor',
    startDate: startDate,
    endDate: endDate,
    monthsToFetch: Math.max(
      1,
      Math.ceil(
        (endDate.getTime() - startDate.getTime()) / (30 * 24 * 60 * 60 * 1000)
      )
    ),
    propertyId: null, // Very important: explicitly set to null to get all properties
    isCurrentPeriod: false,
    properties: lessorProperties // Pass all lessor properties directly 
  });

  // Update date range and refetch historical data
  const handleDateRangeChange = () => {
    if (activeTab === 'history') {
      // Apply the selected property filter when fetching new data
      // We need to modify the query parameters for the refetch
      historyBillsHook.refetch();
    }
  };

  // Add console logs for debugging
  console.log(`LessorBills: User ID: ${user?.id}, Number of properties: ${Object.keys(lessorProperties).length}`);

  // Handle property filtering for history view
  const handlePropertyFilterChange = (propertyId: string | null) => {
    setSelectedPropertyId(propertyId);
    console.log(`Property filter changed to: ${propertyId || 'all properties'}`);

    // We'll use client-side filtering instead of refetching
    // This way we maintain all bills for all properties in the data
  };

  // Loading state
  const isLoading = isLoadingUserData ||
    (activeTab === 'current' ? currentBillsHook.isLoading : historyBillsHook.isLoading);

  if (isLoading && !userData) {
    return (
      <MainLayout>
        <div className="space-y-6 pt-4 pb-8">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">{t('bills.property_bills')}</h1>
            <p className="text-muted-foreground mt-1">
              {t('bills.property_bills_description')}
            </p>
          </div>

          <Tabs defaultValue="current">
            <TabsList className="mb-6">
              <TabsTrigger value="current">{t('bills.current_bills')}</TabsTrigger>
              <TabsTrigger value="history">{t('bills.history')}</TabsTrigger>
            </TabsList>
          </Tabs>

          <Skeleton className="h-10 w-full mb-6" />

          <div className="space-y-6">
            {[1, 2, 3].map(i => (
              <Skeleton key={i} className="h-64 w-full" />
            ))}
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{t('bills.property_bills')}</h1>
          <p className="text-muted-foreground mt-1">
            {t('bills.property_bills_description')}
          </p>
        </div>

        <Tabs
          defaultValue="current"
          value={activeTab}
          onValueChange={(value) => {
            setActiveTab(value);
          }}
        >
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
            <TabsList>
              <TabsTrigger value="current">{t('bills.current_bills')}</TabsTrigger>
              <TabsTrigger value="history">{t('bills.history')}</TabsTrigger>
            </TabsList>

            {/* Property filter - only show if there are multiple properties */}
            {Object.keys(lessorProperties).length > 1 && (
              <PropertyFilter
                properties={lessorProperties}
                selectedPropertyId={selectedPropertyId}
                onSelectProperty={handlePropertyFilterChange}
              />
            )}
          </div>

          <TabsContent value="current" className="space-y-6">
            <CurrentBillsTabLessor
              billsByMonth={currentBillsHook.billsByMonth}
              isLoading={currentBillsHook.isLoading}
              isPayingBill={currentBillsHook.isPayingBill}
              // payBill={currentBillsHook.payBill}
              refetch={currentBillsHook.refetch}
              lessorProperties={lessorProperties}
              properties={currentBillsHook.properties}
              selectedPropertyId={selectedPropertyId}
              allBills={currentBillsHook.bills} // Pass all bills to the component
            />
          </TabsContent>

          <TabsContent value="history" className="space-y-6">
            <HistoryBillsTabLessor
              billsByMonth={historyBillsHook.billsByMonth}
              allBills={historyBillsHook.bills} // Add this line to pass all bills
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={historyBillsHook.isLoading}
              isPayingBill={historyBillsHook.isPayingBill}
              // payBill={historyBillsHook.payBill}
              refetch={historyBillsHook.refetch}
              lessorProperties={lessorProperties}
              properties={historyBillsHook.properties}
              selectedPropertyId={selectedPropertyId}
              handleDateRangeChange={handleDateRangeChange}
            />
          </TabsContent>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default LessorBills;