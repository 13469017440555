import React from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Info } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";
import { SheetFooter } from "@/components/ui/sheet";
import { Property, MaintenanceRequestType, RepairPriority, Repair } from '@/types';
import { maintenanceTypesNames, maintenancePriority } from '@/consts';
import { useTranslation } from 'react-i18next';

interface MaintenanceRequestFormProps {
  isNew: boolean;
  formData: {
    propertyId?: string;
    title?: string;
    description?: string;
    type?: MaintenanceRequestType;
    priority?: RepairPriority;
  } | Repair;
  properties: Property[];
  isLoading?: boolean;
  onFormChange: (field: string, value: any) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export const MaintenanceRequestForm: React.FC<MaintenanceRequestFormProps> = ({
  isNew,
  formData,
  properties,
  isLoading = false,
  onFormChange,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation();

  // Helper to get the right field name based on whether this is a new form or edit form
  const getFieldName = (field: string) => {
    if (isNew) {
      return field;
    }

    // Map to correct field names for Repair type
    const fieldMapping: Record<string, string> = {
      'propertyId': 'property_id',
      'title': 'title',
      'description': 'description',
      'type': 'type',
      'priority': 'priority'
    };

    return fieldMapping[field] || field;
  };

  // Get the value from the form data using the correct field name
  const getValue = (field: string) => {
    const key = getFieldName(field);
    return formData[key as keyof typeof formData];
  };

  // Handle changes and map to the correct field
  const handleChange = (field: string, value: any) => {
    onFormChange(getFieldName(field), value);
  };

  return (
    <div className="flex flex-col h-[calc(100%-3rem)]">
      <ScrollArea className="flex-1 pr-4 -mr-4">
        <div className="py-4 space-y-5">
          <div className="flex items-center p-3 bg-blue-50 rounded-lg">
            <Info className="h-4 w-4 text-blue-500 flex-shrink-0 mr-2" />
            <p className="text-xs text-blue-700">
              {t('maintenance.form_description')}
            </p>
          </div>

          <div className="space-y-3">
            <Label htmlFor="property" className="text-sm font-medium">
              {t('maintenance.property')} <span className="text-red-500">*</span>
            </Label>
            <Select
              value={getValue('propertyId') as string}
              onValueChange={(value) => handleChange('propertyId', value)}
              disabled={!isNew}
            >
              <SelectTrigger id="property" className="h-9">
                <SelectValue placeholder={t('maintenance.select_property')} />
              </SelectTrigger>
              <SelectContent>
                {properties.map(property => (
                  <SelectItem key={property.id} value={property.id}>
                    {property.title || `${property.street} ${property.building}, Apt ${property.apartment}`}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-3">
            <Label htmlFor="title" className="text-sm font-medium">
              {t('maintenance.issue_title')} <span className="text-red-500">*</span>
            </Label>
            <Input
              id="title"
              value={getValue('title') as string}
              onChange={(e) => handleChange('title', e.target.value)}
              placeholder={t('maintenance.issue_title_placeholder')}
              className="h-9"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-3">
              <Label htmlFor="type" className="text-sm font-medium">
                {t('maintenance.type')} <span className="text-red-500">*</span>
              </Label>
              <Select
                value={getValue('type') as string}
                onValueChange={(value) => handleChange('type', value)}
              >
                <SelectTrigger id="type" className="justify-between h-9 truncate">
                  <SelectValue placeholder={t('maintenance.select_type')} />
                </SelectTrigger>
                <SelectContent>
                  {maintenanceTypesNames.map(repairType => (
                    <SelectItem key={repairType.value} value={repairType.value}>
                      {t(`maintenance.types.${repairType.value}`)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-3">
              <Label htmlFor="priority" className="text-sm font-medium">
                {t('maintenance.priority')} <span className="text-red-500">*</span>
              </Label>
              <Select
                value={getValue('priority') as string}
                onValueChange={(value) => handleChange('priority', value)}
              >
                <SelectTrigger id="priority" className="h-9">
                  <SelectValue placeholder={t('maintenance.select_priority')} />
                </SelectTrigger>
                <SelectContent>
                  {maintenancePriority.map(prior => (
                    <SelectItem key={prior.value} value={prior.value}>
                      {t(`maintenance.priorities.${prior.value}`)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-3">
            <Label htmlFor="description" className="text-sm font-medium">{t('maintenance.description')}</Label>
            <Textarea
              id="description"
              value={getValue('description') as string}
              onChange={(e) => handleChange('description', e.target.value)}
              placeholder={t('maintenance.description_placeholder')}
              className="min-h-[120px] resize-none"
            />
          </div>
        </div>
      </ScrollArea>

      <SheetFooter className="flex justify-end gap-2 pt-6 pb-2 border-t mt-4">
        <Button variant="outline" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} disabled={isLoading}>
          {isLoading ? (
            <>
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {t('maintenance.submitting')}
            </>
          ) : (
            isNew ? t('maintenance.submit_request_form') : t('maintenance.update_request_form')
          )}
        </Button>
      </SheetFooter>
    </div>
  );
};