import { UserProfile } from '@/types';
import { NormalizedData } from '@/types/normalized-data-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserProfile } from '@/lib/actions/update-user-profile';
import { queryKeys } from '../query-factory';

// React Query Mutation Hook
interface UseProfileUpdateOptions {
    onSuccess?: (userProfile: UserProfile) => void;
    onError?: (error: Error) => void;
}

// Define type for mutation context
interface MutationContext {
    previousData: NormalizedData | undefined;
    previousUserProfileData: UserProfile | undefined;
}

export function useUpdateUserProfile(options: UseProfileUpdateOptions) {
    console.log('Update User Profile Hook triggered');
    const queryClient = useQueryClient();
    if (!options) {
        throw new Error('options is required');
    }

    const mutation = useMutation<UserProfile, Error, UserProfile, MutationContext>({
        mutationFn: (data) => {
            const { created_at, ...restData } = data;
            return updateUserProfile(restData);
        },

        // Add optimistic update
        onMutate: async (input) => {
            if (!input || !input.id) {
                throw new Error('user ID is required');
            }

            // Define the query keys
            const userDataQueryKey = queryKeys.userData(input.id);
            const userProfileQueryKey = queryKeys.userProfile(input.email, input.id);

            // Cancel any outgoing refetches to avoid race conditions
            await queryClient.cancelQueries({ queryKey: userDataQueryKey });
            await queryClient.cancelQueries({ queryKey: userProfileQueryKey });

            // Snapshot the previous values for both queries
            const previousData = queryClient.getQueryData<NormalizedData>(userDataQueryKey);
            const previousUserProfileData = queryClient.getQueryData<UserProfile>(userProfileQueryKey);

            // Optimistically update userData query
            if (previousData) {
                queryClient.setQueryData<NormalizedData>(userDataQueryKey, (old) => {
                    if (!old) return old;

                    const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;
                    return {
                        ...newData,
                        users: {
                            ...newData.users,
                            [input.id]: { ...input }
                        }
                    };
                });
            }

            // Optimistically update userProfile query
            if (previousUserProfileData) {
                queryClient.setQueryData<UserProfile>(userProfileQueryKey, {
                    ...previousUserProfileData,
                    ...input
                });
            }

            return { previousData, previousUserProfileData };
        },

        onError: (error, newData, context) => {
            // Roll back to the previous values if there's an error
            if (context?.previousData) {
                const userDataQueryKey = queryKeys.userData(newData.id);
                queryClient.setQueryData<NormalizedData>(userDataQueryKey, context.previousData);
            }

            if (context?.previousUserProfileData) {
                const userProfileQueryKey = queryKeys.userProfile(newData.email, newData.id);
                queryClient.setQueryData<UserProfile>(userProfileQueryKey, context.previousUserProfileData);
            }

            console.error('Error updating User Profile', error);
            options?.onError?.(error);
        },

        onSuccess: (updatedUser) => {
            // Update both queries with the final server data
            const userDataQueryKey = queryKeys.userData(updatedUser.id);
            const userProfileQueryKey = queryKeys.userProfile(updatedUser.email, updatedUser.id);

            // Update the userProfile query data with the real response
            queryClient.setQueryData<UserProfile>(userProfileQueryKey, updatedUser);

            // Also update the user in userData if it exists
            queryClient.setQueryData<NormalizedData>(userDataQueryKey, (old) => {
                if (!old) return old;

                const newData = JSON.parse(JSON.stringify(old)) as NormalizedData;
                return {
                    ...newData,
                    users: {
                        ...newData.users,
                        [updatedUser.id]: updatedUser
                    }
                };
            });

            options?.onSuccess?.(updatedUser);
        },
        retry: false
    });

    return {
        updateUserProfile: mutation.mutate,
        updateUserProfileAsync: mutation.mutateAsync,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        error: mutation.error,
        isSuccess: mutation.isSuccess,
        data: mutation.data,
    };
}