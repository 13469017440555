import { useInfiniteQuery, InfiniteData } from "@tanstack/react-query";
import { fetchArchivedRents } from "../fetch-functions/fetch-archived-rents";
import { queryKeys } from "./query-factory";
import { Rent, UserRole } from "@/types";
import { ArchivedRentsResponse } from "@/types/normalized-data-types";
import { useCallback, useMemo } from "react";

interface UsePaginatedArchivedRentsOptions {
  userId: string;
  role: UserRole;
  enabled?: boolean;
  pageSize?: number;
}

export function usePaginatedArchivedRents({
  userId,
  role,
  enabled = true,
  pageSize = 20
}: UsePaginatedArchivedRentsOptions) {
  const queryKey = queryKeys.archivedRents(userId, role);

  const query = useInfiniteQuery<ArchivedRentsResponse>({
    queryKey,
    queryFn: ({ pageParam }) => fetchArchivedRents({
      user_id: userId,
      role,
      first: pageSize,
      after: pageParam as string | null
    }),
    initialPageParam: null as string | null,
    getNextPageParam: (lastPage) =>
      lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined,
    enabled: !!userId && !!role && enabled
  });

  // Combine all pages into a single normalized data structure
  const combinedData = useMemo(() => {
    if (!query.data?.pages) {
      return {
        rentsById: {} as Record<string, Rent>,
        allRents: [] as string[]
      };
    }

    const infiniteData = query.data as InfiniteData<ArchivedRentsResponse>;

    return infiniteData.pages.reduce<{
      rentsById: Record<string, Rent>;
      allRents: string[];
    }>((acc, page) => {
      return {
        rentsById: { ...acc.rentsById, ...page.rentsById },
        allRents: [...acc.allRents, ...page.allRents]
      };
    }, { rentsById: {}, allRents: [] });
  }, [query.data]);

  // Fetch more handler
  const fetchNextPage = useCallback(async () => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query.hasNextPage, query.isFetchingNextPage, query.fetchNextPage]);

  return {
    ...query,
    combinedData,
    fetchNextPage,
    hasMore: query.hasNextPage,
    isLoadingMore: query.isFetchingNextPage
  };
}