import { UserRole, Base, UserProfile, Property, Rent, RentBill, Checklist, InventoryItem, Repair } from "@/types";
import { NormalizedData } from "@/types/normalized-data-types";
import { RawUserDataResponse } from "@/types/raw-responses";

export function normalizeUserData(rawData: RawUserDataResponse): NormalizedData {
    // Initialize the normalized data structure
    const normalizedData: NormalizedData = {
        users: {},
        properties: {},
        rents: {},
        rentBills: {},
        checklists: {},
        inventoryItems: {},
        repairs: {},

        // Relationship maps
        propertyRents: {},
        userRents: {
            asLessor: {},
            asTenant: {},
        },
        userProperties: {
            asLessor: {},
            asTenant: {},
        },
        rentBillsByRent: {},
        checklistsByProperty: {},
        inventoryItemsByChecklist: {},
        repairsByProperty: {},
        repairsByTenant: {},
    };

    // Normalize user profile
    if (rawData.profilesCollection?.edges?.length > 0) {
        const userProfile = rawData.profilesCollection.edges[0].node;
        normalizedData.users[userProfile.id] = userProfile;
    }

    // Process properties owned by the user (as Lessor)
    if (rawData.propertiesCollection?.edges) {
        rawData.propertiesCollection.edges.forEach(edge => {
            const property = edge.node;
            normalizedData.properties[property.id] = property;

            // Initialize property relationship arrays
            normalizedData.propertyRents[property.id] = [];
            normalizedData.checklistsByProperty[property.id] = [];
            normalizedData.repairsByProperty[property.id] = [];

            // Add to userProperties relationship
            if (property.lessor_id) {
                if (!normalizedData.userProperties.asLessor[property.lessor_id]) {
                    normalizedData.userProperties.asLessor[property.lessor_id] = [];
                }
                normalizedData.userProperties.asLessor[property.lessor_id].push(property.id);
            }

            // Process checklists for this property
            if (property.checklistsCollection?.edges) {
                property.checklistsCollection.edges.forEach(checklistEdge => {
                    const checklist = checklistEdge.node;
                    normalizedData.checklists[checklist.id] = checklist;
                    normalizedData.checklistsByProperty[property.id].push(checklist.id);

                    // Initialize checklist relationships
                    normalizedData.inventoryItemsByChecklist[checklist.id] = [];

                    // Process inventory items for this checklist
                    if (checklist.inventory_itemsCollection?.edges) {
                        checklist.inventory_itemsCollection.edges.forEach(itemEdge => {
                            const item = itemEdge.node;
                            normalizedData.inventoryItems[item.id] = item;
                            normalizedData.inventoryItemsByChecklist[checklist.id].push(item.id);
                        });
                    }
                });
            }

            // Process rents for this property
            if (property.rentsCollection?.edges) {
                property.rentsCollection.edges.forEach(rentEdge => {
                    const rent = rentEdge.node;
                    normalizedData.rents[rent.id] = rent;
                    normalizedData.propertyRents[property.id].push(rent.id);

                    // Add to userRents relationships
                    if (rent.lessor_id) {
                        if (!normalizedData.userRents.asLessor[rent.lessor_id]) {
                            normalizedData.userRents.asLessor[rent.lessor_id] = [];
                        }
                        normalizedData.userRents.asLessor[rent.lessor_id].push(rent.id);
                    }

                    if (rent.tenant_id && rent.tenant) {
                        if (!normalizedData.userRents.asTenant[rent.tenant_id]) {
                            normalizedData.userRents.asTenant[rent.tenant_id] = [];
                        }

                        normalizedData.userRents.asTenant[rent.tenant_id].push(rent.id);

                        if (!normalizedData.users[rent.tenant_id]) {
                            normalizedData.users[rent.tenant_id] = rent.tenant?.edges[0]?.node
                        }

                        // Add property to tenant's properties
                        if (!normalizedData.userProperties.asTenant[rent.tenant_id]) {
                            normalizedData.userProperties.asTenant[rent.tenant_id] = [];
                        }
                        if (!normalizedData.userProperties.asTenant[rent.tenant_id].includes(property.id)) {
                            normalizedData.userProperties.asTenant[rent.tenant_id].push(property.id);
                        }
                    }

                    // Initialize rent bills relationship
                    normalizedData.rentBillsByRent[rent.id] = [];

                    // Process rent bills
                    if (rent.rents_billsCollection?.edges) {
                        rent.rents_billsCollection.edges.forEach(billEdge => {
                            const bill = billEdge.node;

                            // Add all required fields to ensure it matches the RentBill interface
                            const fullBill: RentBill = {
                                ...bill,
                                lessor_id: rent.lessor_id,
                                tenant_id: rent.tenant_id,
                                property_id: property.id,
                                rent_id: rent.id
                            };

                            normalizedData.rentBills[bill.id] = fullBill;
                            normalizedData.rentBillsByRent[rent.id].push(bill.id);
                        });
                    }
                });
            }

            // Process repairs for this property
            if (property.repairsCollection?.edges) {
                property.repairsCollection.edges.forEach(repairEdge => {
                    const repair = repairEdge.node;
                    normalizedData.repairs[repair.id] = repair;
                    normalizedData.repairsByProperty[property.id].push(repair.id);

                    // Add to repairsByTenant relationship
                    if (repair.tenant_id) {
                        if (!normalizedData.repairsByTenant[repair.tenant_id]) {
                            normalizedData.repairsByTenant[repair.tenant_id] = [];
                        }
                        normalizedData.repairsByTenant[repair.tenant_id].push(repair.id);
                    }
                });
            }
        });
    }

    // Process rents where the user is a tenant
    if (rawData.rentsCollection?.edges) {
        rawData.rentsCollection.edges.forEach(edge => {
            const rent = edge.node;
            // Only process if this rent isn't already in our data (to avoid duplicates)
            if (!normalizedData.rents[rent.id]) {
                normalizedData.rents[rent.id] = rent;

                // Add property from nested property data
                if (rent.properties) {
                    const property = rent.properties;
                    if (!normalizedData.properties[property.id]) {
                        normalizedData.properties[property.id] = property;

                        // Initialize property relationship arrays
                        normalizedData.propertyRents[property.id] = [];
                        normalizedData.checklistsByProperty[property.id] = [];
                        normalizedData.repairsByProperty[property.id] = [];
                    }

                    // Add rent to propertyRents
                    if (!normalizedData.propertyRents[property.id]) {
                        normalizedData.propertyRents[property.id] = [];
                    }
                    normalizedData.propertyRents[property.id].push(rent.id);

                    // Add property to tenant's properties (user is the tenant)
                    if (rent.tenant_id) {
                        if (!normalizedData.userProperties.asTenant[rent.tenant_id]) {
                            normalizedData.userProperties.asTenant[rent.tenant_id] = [];
                        }
                        if (!normalizedData.userProperties.asTenant[rent.tenant_id].includes(property.id)) {
                            normalizedData.userProperties.asTenant[rent.tenant_id].push(property.id);
                        }
                    }
                }

                // Add to userRents relationships
                if (rent.lessor_id) {
                    if (!normalizedData.userRents.asLessor[rent.lessor_id]) {
                        normalizedData.userRents.asLessor[rent.lessor_id] = [];
                    }
                    normalizedData.userRents.asLessor[rent.lessor_id].push(rent.id);
                }

                if (rent.tenant_id) {
                    if (!normalizedData.userRents.asTenant[rent.tenant_id]) {
                        normalizedData.userRents.asTenant[rent.tenant_id] = [];
                    }
                    normalizedData.userRents.asTenant[rent.tenant_id].push(rent.id);
                }

                // Initialize rent bills relationship
                normalizedData.rentBillsByRent[rent.id] = [];

                // Process rent bills
                if (rent.rents_billsCollection?.edges) {
                    rent.rents_billsCollection.edges.forEach(billEdge => {
                        const bill = billEdge.node;

                        // Make sure we have the full RentBill data
                        const fullBill: RentBill = {
                            ...bill,
                            // If these fields are missing, set them from the parent rent
                            lessor_id: bill.lessor_id || rent.lessor_id,
                            tenant_id: bill.tenant_id || rent.tenant_id,
                            property_id: bill.property_id || rent.property_id,
                            rent_id: bill.rent_id || rent.id
                        };

                        normalizedData.rentBills[bill.id] = fullBill;
                        normalizedData.rentBillsByRent[rent.id].push(bill.id);
                    });
                }
            }
        });
    }

    // Process repairs where the user is a tenant
    if (rawData.repairsCollection?.edges) {
        rawData.repairsCollection.edges.forEach(edge => {
            const repair = edge.node;
            // Only process if this repair isn't already in our data
            if (!normalizedData.repairs[repair.id]) {
                normalizedData.repairs[repair.id] = repair;

                // Add to property relationship
                if (repair.property_id) {
                    if (!normalizedData.repairsByProperty[repair.property_id]) {
                        normalizedData.repairsByProperty[repair.property_id] = [];
                    }
                    normalizedData.repairsByProperty[repair.property_id].push(repair.id);
                }

                // Add to tenant relationship
                if (repair.tenant_id) {
                    if (!normalizedData.repairsByTenant[repair.tenant_id]) {
                        normalizedData.repairsByTenant[repair.tenant_id] = [];
                    }
                    normalizedData.repairsByTenant[repair.tenant_id].push(repair.id);
                }
            }
        });
    }

    return normalizedData;
}