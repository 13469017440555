import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { CreditCard, Clock, Receipt, Home, Calendar } from 'lucide-react';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useUserProperties } from '@/lib/query-hooks/get-hooks';
import { useAuth } from '@/contexts/AuthContext';
import { useAllBills } from '@/lib/query-hooks/useAllBills';
import { toast } from '@/hooks/use-toast';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

// Import our custom components
import {
  DashboardSkeleton,
  DashboardErrorState,
  DashboardMetricCard,
  DashboardProperties,
  DashboardBills
} from '@/components/tenant-dashboard';

// Import our new NoRentalsCard component
import NoRentalsCard from '@/components/tenant-dashboard/NoRentalsCard';

export const TenantDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user, profile } = useAuth();
  const userId = user?.id || '';
  const { t, i18n } = useTranslation();

  // Use authenticated user data
  const {
    data: userData,
    isLoading: isLoadingUserData,
    isError: isErrorUserData,
    refetch: refetchAllUserData
  } = useUserData(userId);

  // Get properties
  const myRentals = useUserProperties(userId, 'tenant');

  // Get bills data (both rent and utility)
  const {
    bills: allBills,
    isLoading: isLoadingBills,
    payBill,
    isPayingBill
  } = useAllBills({
    userId,
    role: profile?.role || 'tenant',
    monthsToFetch: 3
  });

  // Filter for just unpaid bills
  const unpaidBills = allBills.filter(bill => !bill.isPaid);

  // Sort unpaid bills by due date
  const upcomingBills = [...unpaidBills]
    .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());

  // Next bill due
  const nextDueDate = upcomingBills.length > 0 ? upcomingBills[0].dueDate : null;

  // Calculate total monthly rent (tenant's expenses)
  const totalRent = myRentals.reduce((sum, property) => {
    return sum + property.price;
  }, 0);

  // Handle paying a bill
  const handlePayBill = async (billId: string) => {
    try {
      await payBill(billId);
      toast({
        title: t('bills.payment_successful'),
        description: t('bills.payment_successful_description'),
      });
    } catch (error) {
      toast({
        title: t('bills.payment_failed'),
        description: t('bills.payment_failed_description'),
        variant: "destructive"
      });
    }
  };

  // Navigate to rental properties
  const handleViewProperties = () => {
    navigate('/tenant/properties');
  };

  // Handle loading and error states
  if (isLoadingUserData || isLoadingBills) {
    return <DashboardSkeleton />;
  }

  if (isErrorUserData) {
    return <DashboardErrorState onRetry={refetchAllUserData} />;
  }

  return (
    <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{t('dashboard.tenant_title')}</h1>
      </div>

      {/* Metric Cards - Updated for tenant perspective */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <DashboardMetricCard
          title={t('dashboard.active_rentals')}
          value={myRentals.length}
          icon={<Home />}
          description={t('dashboard.rented_properties')}
        />

        <DashboardMetricCard
          title={t('dashboard.monthly_expenses')}
          value={`$${totalRent.toFixed(2)}`}
          icon={<CreditCard />}
          description={t('dashboard.rent_payments')}
        />

        <DashboardMetricCard
          title={t('dashboard.pending_payments')}
          value={unpaidBills.length}
          icon={<Receipt />}
          description={t('dashboard.bills_to_pay')}
        />

        <DashboardMetricCard
          title={t('dashboard.next_payment')}
          value={nextDueDate ? formatDate(nextDueDate, i18n.language, 'MMM dd') : t('common.unknown')}
          icon={<Calendar />}
          description={t('dashboard.upcoming_due_date')}

        />
      </div>

      {/* Properties Section */}
      <div>
        <h2 className="text-xl font-semibold mb-4">{t('tenant.my_rentals')}</h2>

        {myRentals.length > 0 ? (
          <DashboardProperties
            properties={myRentals}
            userData={userData}
          />
        ) : (
          <NoRentalsCard onViewProperties={handleViewProperties} />
        )}
      </div>

      {/* Bills Section */}
      <div>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
          <h2 className="text-xl font-semibold">{t('bills.recent_bills_for_property')}</h2>
          <Button
            className='self-end'
            onClick={() => navigate('/bills')}>
            {t('bills.pay_all')}
          </Button>
        </div>

        <DashboardBills
          bills={unpaidBills}
          onPayBill={handlePayBill}
          isPayingBill={isPayingBill}
        />
      </div>
    </div>
  );
};

export default TenantDashboard;