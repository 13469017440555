import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { User } from 'lucide-react';
import { Property } from '@/types';
import { useTranslation } from 'react-i18next';
import { formatDate, formatCurrency } from '@/utils/i18n-utils';
import { getPropertyAddress } from '@/lib/helpers';

interface PropertyCardProps {
    property: Property;
    tenantName: string;
    leaseStartDate: string | null;
    onViewDetails: (propertyId: string) => void;
    onInviteTenant: (propertyId: string) => void;
}

export const DashboardPropertyCard: React.FC<PropertyCardProps> = ({
    property,
    tenantName,
    leaseStartDate,
    onViewDetails,
    onInviteTenant,
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Card className="hover:shadow-md transition-shadow">
            <CardHeader>
                <CardTitle className="text-lg">{property?.title || getPropertyAddress(property)}</CardTitle>
                <CardDescription>
                    {property.status === "occupied"
                        ? `${t('dashboard.tenant')}: ${tenantName}`
                        : t('properties.status.vacant')}
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between">
                        <span className="text-muted-foreground">{t('dashboard.rent')}:</span>
                        <span className="font-medium">
                            {formatCurrency(property.price, i18n.language, property.currency)}/
                            {t(`properties.terms.${property.unit_term}`)}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-muted-foreground">{t('dashboard.status')}:</span>
                        <span className={`font-medium ${property.status === 'occupied' ? 'text-green-600' :
                            property.status === 'vacant' ? 'text-amber-600' :
                                'text-gray-600'
                            }`}>
                            {t(`properties.status.${property.status}`)}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-muted-foreground">{t('dashboard.lease_starts')}:</span>
                        <span className="font-medium">
                            {leaseStartDate
                                ? formatDate(leaseStartDate, i18n.language)
                                : "N/A"}
                        </span>
                    </div>
                </div>
                <div className='flex flex-row space-x-2 items-end'>
                    <Button
                        variant="outline"
                        className="w-full mt-4"
                        onClick={() => onViewDetails(property.id)}
                    >
                        {t('properties.view_details')}
                    </Button>
                    <Button
                        className="w-full"
                        onClick={() => onInviteTenant(property.id)}
                    >
                        <User className="size-4 shrink-0 mr-2" /> {t('tenant.invite')}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};