"use server"

import { graphqlClient } from "@/lib/graphql-client";
import { Repair } from "@/types";
import { UpdateRepairInput } from "@/types/mutation/mutation-input-types";
import { UpdateRepairResponse } from "@/types/mutation/mutation-responses-types";
import { UPDATE_REPAIR } from "@/queries-graphql/mutation/update-repair-mutation";

// Function to update Repair request
export const updateRepairRequest = async (
    input: UpdateRepairInput
): Promise<Repair> => {
    const client = graphqlClient;
    try {
        const variables = { ...input };

        const data = await client.request<UpdateRepairResponse>(
            UPDATE_REPAIR,
            variables
        );
        const response = data.updaterepairsCollection.records[0];
        //console.log(response);
        return response;
    } catch (error) {
        console.error('Error updating Repair:', error);
        throw error;
    }
};