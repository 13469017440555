import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Property, Rent } from '@/types';
import { getPropertyAddress } from '@/lib/helpers';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from '@/utils/i18n-utils';

interface DashboardPropertyCardProps {
    property: Property;
    rent?: Rent | null;
}

/**
 * Card component for displaying a rental property on the dashboard
 */
const DashboardPropertyCard: React.FC<DashboardPropertyCardProps> = ({ property, rent }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // Format the date with i18n support
    const formatDateWithI18n = (dateString: string) => {
        return formatDate(dateString, i18n.language);
    };

    return (
        <Card className="hover:shadow-md transition-shadow">
            <CardHeader>
                <CardTitle className="text-lg">{property.title || `${property.street} ${property.building}`}</CardTitle>
                <CardDescription>
                    {getPropertyAddress(property)}
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between">
                        <span className="text-muted-foreground">{t('dashboard.rent')}:</span>
                        <span className="font-medium">
                            {formatCurrency(property.price, i18n.language, property.currency)}/
                            {t(`properties.terms.${property.unit_term}`)}
                        </span>
                    </div>
                    {rent && (
                        <>
                            <div className="flex justify-between">
                                <span className="text-muted-foreground">{t('dashboard.lease_starts')}:</span>
                                <span className="font-medium">
                                    {formatDateWithI18n(rent.date_start)}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span className="text-muted-foreground">{t('properties.lease_terms')}:</span>
                                <span className="font-medium">
                                    {rent.contract_term || '-'} {t(`properties.terms.${rent.unit}${rent.contract_term !== 1 ? '_plural' : ''}`)}
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <Button
                    variant="outline"
                    className="w-full mt-4"
                    onClick={() => navigate(`/tenant/properties/${property.id}`)}
                >
                    {t('properties.view_details')}
                </Button>
            </CardContent>
        </Card>
    );
};

export default DashboardPropertyCard;