import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { toast } from '@/hooks/use-toast';
import { CombinedBill } from '@/lib/query-hooks/useAllBills';
import { Property } from '@/types';
import { parseISO, isThisMonth, format } from 'date-fns';
import { BillsLoadingState } from '../shared/BillsLoadingState';
import { BillsEmptyState } from '../shared/BillsEmptyState';
import { LessorPropertyBillsGroup } from './LessorPropertyBillsGroup';
import { LessorMonthBillsGroup } from './LessorMonthBillsGroup';
import { useTranslation } from 'react-i18next';

interface CurrentBillsTabLessorProps {
  billsByMonth: Array<{
    monthKey: string;
    displayName: string;
    bills: CombinedBill[];
    totalAmount: number;
    unpaidAmount: number;
    allPaid: boolean;
  }>;
  allBills?: CombinedBill[];
  isLoading: boolean;
  isPayingBill: boolean;
  // payBill?: (billId: string) => Promise<void>;
  refetch: () => void;
  lessorProperties: Record<string, Property>;
  properties: Record<string, Property>;
  selectedPropertyId: string | null;
}

const CurrentBillsTabLessor: React.FC<CurrentBillsTabLessorProps> = ({
  billsByMonth,
  allBills = [],
  isLoading,
  isPayingBill,
  // payBill,
  refetch,
  lessorProperties,
  properties,
  selectedPropertyId
}) => {
  const { t } = useTranslation();
  const [openMonthKeys, setOpenMonthKeys] = useState<string[]>([]);

  // Group all bills by month
  const allBillsByMonth = useMemo(() => {
    // First, collect all bills from both sources
    // Ensure we have arrays to work with
    const allBillsArray = Array.isArray(allBills) ? allBills : [];
    const billsFromMonths = billsByMonth.flatMap(month => month.bills);

    // Combine bills from both sources
    const combinedBills = [...allBillsArray, ...billsFromMonths];

    // Group bills by month
    const months: Record<string, {
      monthKey: string;
      displayName: string;
      bills: CombinedBill[];
    }> = {};

    // Group bills by month
    combinedBills.forEach(bill => {
      try {
        const date = parseISO(bill.dueDate);
        if (isNaN(date.getTime())) {
          console.warn('Invalid date found in bill:', bill);
          return; // Skip bills with invalid dates
        }

        const monthKey = format(date, 'yyyy-MM');
        const displayName = format(date, 'MMMM yyyy');

        if (!months[monthKey]) {
          months[monthKey] = {
            monthKey,
            displayName,
            bills: []
          };
        }

        // Check if this bill is already in the array to avoid duplicates
        if (!months[monthKey].bills.some(existingBill => existingBill.id === bill.id)) {
          months[monthKey].bills.push(bill);
        }
      } catch (error) {
        console.error('Error processing bill:', error, bill);
      }
    });

    // Convert to array and add total calculations
    return Object.values(months).map(month => {
      const unpaidBills = month.bills.filter(bill => !bill.isPaid);
      return {
        ...month,
        totalAmount: month.bills.reduce((sum, bill) => sum + bill.amount, 0),
        unpaidAmount: unpaidBills.reduce((sum, bill) => sum + bill.amount, 0),
        allPaid: unpaidBills.length === 0
      };
    });
  }, [allBills, billsByMonth]);

  // Process months to display - current month and months with unpaid bills
  const relevantMonths = useMemo(() => {
    return allBillsByMonth
      .filter(month => {
        // Include if it's the current month
        const isCurrentMonthGroup = month.bills.some(bill => isThisMonth(parseISO(bill.dueDate)));
        if (isCurrentMonthGroup) return true;

        // Include if it has unpaid bills
        const hasUnpaidBills = month.bills.some(bill => !bill.isPaid);
        return hasUnpaidBills;
      })
      .sort((a, b) => b.monthKey.localeCompare(a.monthKey)); // Sort with most recent months first
  }, [allBillsByMonth]);

  // Group and process the bills by month and property
  const processedMonths = useMemo(() => {
    return relevantMonths.map(month => {
      // Filter bills if a property is selected
      const filteredBills = selectedPropertyId
        ? month.bills.filter(bill => bill.property_id === selectedPropertyId)
        : month.bills;

      // Skip if no bills after filtering
      if (filteredBills.length === 0) {
        return null;
      }

      // Get all property IDs that have bills in this month
      const propertyIdsWithBills = [...new Set(filteredBills.map(bill => bill.property_id))];

      // Map all properties with their bills
      const propertiesWithBills = propertyIdsWithBills.map(propId => {
        // Get bills for this property in this month
        const propertyBills = filteredBills.filter(bill => bill.property_id === propId);

        // Skip if no bills for this property in this month
        if (propertyBills.length === 0) {
          return null;
        }

        const property = lessorProperties[propId] || properties[propId];

        // Sort bills by unpaid first, then by due date
        const sortedBills = [...propertyBills].sort((a, b) => {
          // First, sort by paid status (unpaid first)
          if (a.isPaid !== b.isPaid) {
            return a.isPaid ? 1 : -1;
          }

          // For unpaid bills, sort by due date (earliest first)
          if (!a.isPaid) {
            return parseISO(a.dueDate).getTime() - parseISO(b.dueDate).getTime();
          }

          // For paid bills, sort by due date (most recent first)
          return parseISO(b.dueDate).getTime() - parseISO(a.dueDate).getTime();
        });

        const unpaidBills = sortedBills.filter(bill => !bill.isPaid);

        return {
          propertyId: propId,
          propertyAddress: property
            ? `${property.street} ${property.building}`
            : propertyBills[0]?.propertyAddress || t('properties.unknown_property'),
          bills: sortedBills,
          unpaidBillsCount: unpaidBills.length,
          totalAmount: sortedBills.reduce((sum, bill) => sum + bill.amount, 0),
          unpaidAmount: unpaidBills.reduce((sum, bill) => sum + bill.amount, 0),
          allPaid: unpaidBills.length === 0
        };
      }).filter(Boolean); // Remove nulls for properties with no bills

      // Sort properties with unpaid bills first
      const sortedProperties = propertiesWithBills.sort((a, b) => {
        if (a.unpaidBillsCount > 0 && b.unpaidBillsCount === 0) return -1;
        if (a.unpaidBillsCount === 0 && b.unpaidBillsCount > 0) return 1;
        return 0;
      });

      // Skip months with no properties after filtering
      if (sortedProperties.length === 0) {
        return null;
      }

      // Calculate totals for this month (only counting properties with bills)
      return {
        monthKey: month.monthKey,
        displayName: month.displayName,
        isCurrentMonth: month.bills.some(bill => isThisMonth(parseISO(bill.dueDate))),
        properties: sortedProperties,
        totalBills: sortedProperties.reduce((sum, prop) => sum + prop.bills.length, 0),
        totalPaidBills: sortedProperties.reduce(
          (sum, prop) => sum + (prop.bills.length - prop.unpaidBillsCount), 0
        ),
        totalUnpaidBills: sortedProperties.reduce(
          (sum, prop) => sum + prop.unpaidBillsCount, 0
        ),
        totalAmount: sortedProperties.reduce((sum, prop) => sum + prop.totalAmount, 0),
        unpaidAmount: sortedProperties.reduce((sum, prop) => sum + prop.unpaidAmount, 0)
      };
    }).filter(Boolean); // Remove null entries (months with no matching bills after filtering)
  }, [relevantMonths, lessorProperties, properties, selectedPropertyId, t]);

  // Update open months when filter changes or when component loads
  useEffect(() => {
    // Auto-expand current month and months with unpaid bills
    const monthsToExpand = processedMonths
      .filter(month =>
        month.totalUnpaidBills > 0 || // Has unpaid bills
        month.isCurrentMonth // Is current month
      )
      .map(month => month.monthKey);

    setOpenMonthKeys(monthsToExpand);
  }, [processedMonths]);

  const toggleMonth = (monthKey: string) => {
    setOpenMonthKeys(prev =>
      prev.includes(monthKey)
        ? prev.filter(key => key !== monthKey)
        : [...prev, monthKey]
    );
  };

  const handlePayBill = async (billId: string) => {
    try {
      // await payBill(billId);
      toast({
        title: t('bills.payment_successful'),
        description: t('bills.payment_successful_description'),
      });
      refetch();
    } catch (error) {
      toast({
        title: t('bills.payment_failed'),
        description: t('bills.payment_failed_description'),
        variant: "destructive"
      });
    }
  };

  const handlePayAllBills = (monthKey: string, propertyId?: string) => {
    const month = relevantMonths.find(m => m.monthKey === monthKey);
    if (!month) return;

    let unpaidBills: CombinedBill[] = [];

    if (propertyId) {
      // Pay all unpaid bills for this property in this month
      const propertyBills = month.bills.filter(bill =>
        bill.property_id === propertyId && !bill.isPaid
      );

      if (propertyBills.length === 0) return;
      unpaidBills = propertyBills;

      const property = lessorProperties[propertyId] || properties[propertyId];
      const propertyAddress = property
        ? `${property.street} ${property.building}`
        : t('properties.unknown_property');

      toast({
        title: t('bills.processing_payments'),
        description: t('bills.processing_property_payments', {
          count: unpaidBills.length,
          property: propertyAddress
        }),
      });
    } else {
      // Pay all unpaid bills for this month across all properties (applying property filter if needed)
      unpaidBills = month.bills.filter(bill =>
        !bill.isPaid &&
        (!selectedPropertyId || bill.property_id === selectedPropertyId)
      );

      if (unpaidBills.length === 0) return;

      toast({
        title: t('bills.processing_payments'),
        description: t('bills.processing_month_payments', {
          count: unpaidBills.length,
          month: month.displayName
        }),
      });
    }

    // Process payments
    Promise.all(unpaidBills.map(bill => {
      // Mock payment since payBill is commented out
      return Promise.resolve();
    }))
      .then(() => {
        toast({
          title: t('bills.all_bills_paid'),
          description: t('bills.all_payments_processed'),
        });
        refetch();
      })
      .catch(() => {
        toast({
          title: t('common.error'),
          description: t('bills.payment_error'),
          variant: "destructive"
        });
      });
  };

  if (isLoading) {
    return <BillsLoadingState />;
  }

  if (processedMonths.length === 0) {
    return (
      <BillsEmptyState
        title={t('bills.no_bills')}
        description={t('bills.no_current_bills_lessor')}
        isFilterActive={!!selectedPropertyId}
      />
    );
  }

  return (
    <div className="space-y-6">
      {processedMonths.map(month => {
        const hasUnpaidBills = month.totalUnpaidBills > 0;
        const isOpen = openMonthKeys.includes(month.monthKey);

        return (
          <LessorMonthBillsGroup
            key={month.monthKey}
            monthKey={month.monthKey}
            displayName={month.displayName}
            isCurrentMonth={month.isCurrentMonth}
            totalBills={month.totalBills}
            totalPaidBills={month.totalPaidBills}
            totalUnpaidBills={month.totalUnpaidBills}
            unpaidAmount={month.unpaidAmount}
            isOpen={isOpen}
            isPayingBill={isPayingBill}
            onToggle={() => toggleMonth(month.monthKey)}
            onPayAllBills={() => handlePayAllBills(month.monthKey)}
          >
            {month.properties.length === 0 ? (
              <div className="text-center py-4 text-muted-foreground">
                {t('bills.no_properties_with_bills')}
              </div>
            ) : (
              month.properties.map(property => (
                <LessorPropertyBillsGroup
                  key={`${month.monthKey}-${property.propertyId}`}
                  propertyId={property.propertyId}
                  propertyAddress={property.propertyAddress}
                  bills={property.bills}
                  unpaidBillsCount={property.unpaidBillsCount}
                  unpaidAmount={property.unpaidAmount}
                  isPayingBill={isPayingBill}
                  onPayBill={handlePayBill}
                  onPayAllBills={() => handlePayAllBills(month.monthKey, property.propertyId)}
                />
              ))
            )}
          </LessorMonthBillsGroup>
        );
      })}
    </div>
  );
};

export default CurrentBillsTabLessor;