import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '@/components/layout/MainLayout';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Plus, Search, Building } from 'lucide-react';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useUserProperties } from '@/lib/query-hooks/get-hooks';
import { useAuth } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import PropertyCard from '@/components/properties/PropertyCard';

const Properties: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const userId = user?.id || '';

  const {
    data: userData,
    isLoading: isLoadingUserData,
    isError: isErrorUserData
  } = useUserData(userId);

  const userProperties = useUserProperties(userId, "lessor");
  const [searchTerm, setSearchTerm] = useState('');

  // Extract necessary data for rendering
  const propertyCards = userProperties.map(property => {
    // Get property data from userData instead of calling hooks inside map
    const propertyRents = userData?.propertyRents?.[property.id] || [];
    const rents = propertyRents.map(rentId => userData?.rents[rentId]).filter(Boolean);

    // Find tenant data for the property
    const activeRent = rents.find(rent => rent.isAccepted || rent.status === "pending");
    const tenantId = activeRent?.tenant_id;
    const tenant = tenantId ? userData?.users[tenantId] : null;

    return {
      property,
      rents,
      activeRent,
      tenant
    };
  });

  // Filter properties based on search term
  const filteredProperties = searchTerm
    ? propertyCards.filter(({ property }) =>
      property.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.street?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.title?.toLowerCase().includes(searchTerm.toLowerCase()))
    : propertyCards;

  return (
    <MainLayout>
      <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
        <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{t('properties.title')}</h1>
            <p className="text-muted-foreground mt-1">
              {t('properties.manage_properties')}
            </p>
          </div>
          <Button onClick={() => navigate('/properties/add')}>
            <Plus className="mr-2 h-4 w-4" /> {t('properties.add_property')}
          </Button>
        </div>

        {/* <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder={t('properties.search_properties')}
            className="pl-9"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div> */}

        {isLoadingUserData ? (
          <div className="text-center py-12">
            <p>{t('common.loading')}</p>
          </div>
        ) : isErrorUserData ? (
          <div className="text-center py-12">
            <p>{t('common.error')}</p>
          </div>
        ) : filteredProperties.length === 0 ? (
          <div className="text-center py-12">
            <Building className="h-12 w-12 mx-auto text-muted-foreground" />
            <h3 className="mt-4 text-lg font-medium">{t('properties.no_properties_found')}</h3>
            <p className="mt-1 text-muted-foreground">
              {searchTerm ? t('properties.try_different_search') : t('properties.add_first_property')}
            </p>
            {!searchTerm && (
              <Button
                className="mt-4"
                onClick={() => navigate('/properties/add')}
              >
                <Plus className="mr-2 h-4 w-4" /> {t('properties.add_property')}
              </Button>
            )}
          </div>
        ) : (
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {filteredProperties.map(({ property, rents, tenant }) => (
              <PropertyCard
                key={property.id}
                property={property}
                rents={rents}
                tenant={tenant}
              />
            ))}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Properties;