import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { LucideIcon } from 'lucide-react';

interface DashboardMetricCardProps {
    title: string;
    value: string | number;
    description: string;
    icon: LucideIcon;
}

export const DashboardMetricCard: React.FC<DashboardMetricCardProps> = ({
    title,
    value,
    description,
    icon: Icon,
}) => {
    return (
        <Card>
            <CardHeader className="pb-2">
                <CardDescription>{title}</CardDescription>
                <CardTitle className="text-2xl sm:text-3xl">
                    {value}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex items-center">
                    <Icon className="h-4 w-4 text-muted-foreground mr-1" />
                    <span className="text-sm text-muted-foreground">{description}</span>
                </div>
            </CardContent>
        </Card>
    );
};