import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Checklist, InventoryItem } from '@/types';
import { ClipboardList, Edit, Play, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface ChecklistCardProps {
  checklist: Checklist;
  items: InventoryItem[];
  propertyId: string;
  onEdit?: () => void;
  onRun?: () => void;
}

const ChecklistCard: React.FC<ChecklistCardProps> = ({
  checklist,
  items,
  propertyId,
  onEdit,
  onRun
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Calculate completion statistics
  const totalItems = items.length;
  const completedItems = items.filter(item => item.status === 'completed').length;
  const issueItems = items.filter(item => item.status === 'issue').length;
  const pendingItems = items.filter(item => item.status === 'pending').length;

  const completionPercentage = totalItems > 0
    ? Math.round((completedItems / totalItems) * 100)
    : 0;

  const handleViewDetails = () => {
    if (propertyId && checklist.id) {
      navigate(`/properties/${propertyId}/checklists/${checklist.id}`);
    } else {
      console.error("Missing propertyId or checklistId for navigation");
      // Optionally show an error toast
    }
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader>
        <div className="flex flex-col items-start">
          <div className='flex justify-between w-full'>
            <CardTitle className="flex items-center text-base sm:text-lg">
              <ClipboardList className="mr-2 h-4 w-4 sm:h-5 sm:w-5 text-primary" />
              {checklist.title}
            </CardTitle>
            <div className="flex items-center space-x-1 sm:space-x-2">
              {onEdit && (
                <Button variant="ghost" size="icon" onClick={onEdit} className="h-7 w-7 sm:h-8 sm:w-8">
                  <Edit className="h-3 w-3 sm:h-4 sm:w-4" />
                </Button>
              )}
              {onRun && (
                <Button variant="ghost" size="icon" onClick={onRun} className="h-7 w-7 sm:h-8 sm:w-8">
                  <Play className="h-3 w-3 sm:h-4 sm:w-4" />
                </Button>
              )}
            </div>
          </div>
          <div className='pl-6 sm:pl-7'>
            <CardDescription className="text-xs sm:text-sm">
              {checklist.description || t('common.no_description')}
            </CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-grow px-4 sm:px-6">
        <div className="text-xs sm:text-sm text-muted-foreground">
          {checklist.last_run_date && (
            <p>
              {t('checklists.last_check')}: {formatDate(checklist.last_run_date, i18n.language)}
            </p>
          )}
        </div>

        <div className="space-y-2 sm:space-y-3 mt-2 sm:mt-3">
          <div className="flex items-center justify-between text-xs sm:text-sm">
            <span>{t('checklists.completion')}</span>
            <span className="font-medium">{completionPercentage}%</span>
          </div>
          <div className="flex h-1.5 sm:h-2 w-full overflow-hidden rounded-full bg-secondary">
            <div
              className="bg-green-500 h-full"
              style={{ width: `${totalItems > 0 ? (completedItems / totalItems) * 100 : 0}%` }}
            />
            <div
              className="bg-red-500 h-full"
              style={{ width: `${totalItems > 0 ? (issueItems / totalItems) * 100 : 0}%` }}
            />
          </div>
          <div className="grid grid-cols-3 gap-1 sm:gap-2 text-center text-xs">
            <div className="rounded-md bg-green-100 p-1 text-green-700">
              <div className="font-medium">{completedItems}</div>
              <div className="text-[10px] sm:text-xs">{t('checklists.completed')}</div>
            </div>
            <div className="rounded-md bg-red-100 p-1 text-red-700">
              <div className="font-medium">{issueItems}</div>
              <div className="text-[10px] sm:text-xs">{t('checklists.issues')}</div>
            </div>
            <div className="rounded-md bg-gray-100 p-1 text-gray-700">
              <div className="font-medium">{pendingItems}</div>
              <div className="text-[10px] sm:text-xs">{t('checklists.pending')}</div>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="pt-2 pb-4 px-4 sm:px-6">
        <Button className="w-full text-xs sm:text-sm" variant="outline" onClick={handleViewDetails}>
          {t('common.view_details')} <ArrowRight className="ml-1 sm:ml-2 h-3 w-3 sm:h-4 sm:w-4" />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ChecklistCard;