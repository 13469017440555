import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactQueryProvider } from "./providers/reactQueryProvider";
import React, { useEffect } from "react";
import { AppShell } from "@/components/layout/AppShell";

// Import i18n configuration
import "./i18n";

// Auth Provider
import { AuthProvider } from "@/contexts/AuthContext";

// Route Protection
import ProtectedRoute from "./pages/auth/ProtectedRoute";

// Pages
import Dashboard from "./pages/Dashboard";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import CompleteProfile from "./pages/auth/CompleteProfile";
import Properties from "./pages/Properties";
import AddProperty from "./pages/AddProperty";
import PropertyDetails from "./pages/PropertyDetails";
import InviteTenant from "./pages/InviteTenant";
import Bills from "./pages/Bills";
import LessorBills from "./pages/LessorBills";
import TenantBills from "./pages/TenantBills";
import NotFound from "./pages/NotFound";
import TenantProperties from "./pages/TenantProperties";
import MaintenanceRequests from "./pages/MaintenanceRequests";
// Checklist pages
import PropertyChecklists from "./pages/PropertyChecklists";
import ChecklistCreate from "./pages/ChecklistCreate";
import ChecklistDetail from "./pages/ChecklistDetail";
import ChecklistRun from "./pages/ChecklistRun";
import ChecklistEdit from "./pages/ChecklistEdit";
import Bookings from "./pages/Bookings";
import LandingPage from "./pages/LandingPage";
import EmailConfirmation from "./pages/auth/EmailConfirmation";
import RegistrationConfirmation from "./pages/auth/RegistrationConfirmation";
import PasswordResetConfirmation from "./pages/auth/PasswordResetConfirmation";
import EmailChangeConfirmation from "./pages/auth/EmailChangeConfirmation";
import UserProfilePage from "./pages/UserProfilePage";

const App = () => (
  <React.StrictMode>
    <ReactQueryProvider>
      <BrowserRouter basename="/">
        <AuthProvider>
          <TooltipProvider>
            <Toaster />
            <Sonner />
            <Routes>
              {/* Landing Page as Root */}
              <Route path="/" element={<LandingPage />} />

              {/* Public Auth Routes */}
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/register" element={<Register />} />
              <Route path="/auth/forgot-password" element={<ForgotPassword />} />
              <Route path="/auth/reset-password" element={<ResetPassword />} />
              <Route path="/auth/complete-profile" element={<CompleteProfile />} />
              <Route path="/auth/registration-confirmation" element={<RegistrationConfirmation />} />
              <Route path="/auth/email-confirmation" element={<EmailConfirmation />} />
              <Route path="/auth/reset-confirmation" element={<PasswordResetConfirmation />} />
              <Route path="/auth/email-change-confirmation" element={<EmailChangeConfirmation />} />

              <Route element={<AppShell />}>
                {/* Dashboard with role-based redirection */}
                <Route path="/dashboard" element={<Dashboard />} />

                {/* Protected Routes - Any authenticated user */}
                <Route element={<ProtectedRoute />}>
                  <Route path="/profile" element={<UserProfilePage />} />
                  <Route path="/maintenance" element={<MaintenanceRequests />} />

                  {/* Smart router for bills based on role */}
                  <Route path="/bills" element={<Bills />} />
                </Route>

                {/* Lessor/Owner Routes */}
                <Route element={<ProtectedRoute allowedRoles={['lessor']} />}>
                  <Route path="/properties" element={<Properties />} />
                  <Route path="/properties/add" element={<AddProperty />} />
                  <Route path="/properties/:id" element={<PropertyDetails />} />
                  <Route path="/properties/:id/invite" element={<InviteTenant />} />
                  <Route path="/properties/:id/checklists" element={<PropertyChecklists />} />
                  <Route path="/properties/:id/checklists/new" element={<ChecklistCreate />} />
                  <Route path="/properties/:id/checklists/:checklistId" element={<ChecklistDetail />} />
                  <Route path="/properties/:id/checklists/:checklistId/run" element={<ChecklistRun />} />
                  <Route path="/properties/:id/checklists/:checklistId/edit" element={<ChecklistEdit />} />
                  <Route path="/lessor/bills" element={<LessorBills />} />
                  <Route path="/bookings" element={<Bookings />} />
                </Route>

                {/* Tenant Routes */}
                <Route element={<ProtectedRoute allowedRoles={['tenant']} />}>
                  <Route path="/tenant/properties" element={<TenantProperties />} />
                  <Route path="/tenant/bills" element={<TenantBills />} />
                  {/* Add more tenant-specific routes as needed */}
                </Route>
              </Route>

              {/* 404 Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </TooltipProvider>
        </AuthProvider>
      </BrowserRouter>
    </ReactQueryProvider>
  </React.StrictMode>
);

export default App;