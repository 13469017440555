import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Wrench, Plus } from 'lucide-react';
import { UserRole } from '@/types';
import { useTranslation } from 'react-i18next';

interface EmptyMaintenanceStateProps {
  userRole: UserRole;
  onCreateRequest: () => void;
}

export const EmptyMaintenanceState: React.FC<EmptyMaintenanceStateProps> = ({
  userRole,
  onCreateRequest
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent className="p-8 text-center">
        <div className="mx-auto w-16 h-16 rounded-full bg-muted flex items-center justify-center mb-4">
          <Wrench className="h-8 w-8 text-muted-foreground" />
        </div>
        <h3 className="text-xl font-medium mb-2">{t('maintenance.no_requests')}</h3>
        <p className="text-muted-foreground mb-6 max-w-md mx-auto">
          {userRole === 'tenant'
            ? t('maintenance.no_request_tenant')
            : t('maintenance.no_request_lessor')}
        </p>
        {userRole === 'tenant' && (
          <Button
            onClick={onCreateRequest}
            size="lg"
          >
            <Plus className="mr-2 h-4 w-4" /> {t('maintenance.new_request')}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};