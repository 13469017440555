import { subMonths, format, addDays, parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { Property, UserRole } from '@/types';

// Simplified utility bill structure matching what useAllBills expects
export interface MockUtilityBill {
  id: string;
  property_id: string;
  propertyAddress?: string;
  tenant_id: string;
  lessor_id: string;
  type: 'utility';
  utilityType: string;
  description: string;
  amount: number;
  currency: string;
  dueDate: string;
  isPaid: boolean;
  paidDate?: string;
}

// Utility types
const UTILITY_TYPES = ['water', 'electricity', 'gas', 'internet'];

// Base amounts for utility types
const BASE_AMOUNTS = {
  water: 45,
  electricity: 85,
  gas: 60,
  internet: 50
};

/**
 * Generate mock utility bills for all properties of a user
 * 
 * @param userId The user ID (either lessor or tenant)
 * @param properties Properties for which to generate bills
 * @param role Role of the user ('lessor' or 'tenant')
 * @param monthsRange Number of months to generate bills for
 * @returns Array of mock utility bills
 */
export function generateMockUtilityBills(
  userId: string,
  properties: Record<string, Property>,
  role: UserRole,
  monthsRange: number = 6
): MockUtilityBill[] {
  // Array to hold all generated bills
  const bills: MockUtilityBill[] = [];

  // The properties we care about
  const propertiesToUse = Object.values(properties);

  // Skip if no properties
  if (propertiesToUse.length === 0) return [];

  // Generate bills for each month in range
  for (let i = 0; i < monthsRange; i++) {
    const date = subMonths(new Date(), i);
    const monthName = format(date, 'MMMM yyyy');
    const monthValue = format(date, 'yyyy-MM');

    // Generate bills for each property
    propertiesToUse.forEach(property => {
      // Determine lessor and tenant IDs
      const lessorId = property.lessor_id;
      // For tenant role, the userId is the tenant
      // For lessor role, we mock a tenant ID based on property
      const tenantId = role === 'tenant' ? userId : `tenant-${property.id.substring(0, 8)}`;

      // Skip if this property doesn't match the user's role
      if ((role === 'lessor' && lessorId !== userId) ||
        (role === 'tenant' && tenantId !== userId)) {
        return;
      }

      // Create a readable property address
      const propertyAddress = `${property.street} ${property.building}`;

      // Generate 1-3 different utility bills for this property/month
      const utilityCount = Math.floor(Math.random() * 3) + 1;
      const selectedUtilities = [...UTILITY_TYPES]
        .sort(() => Math.random() - 0.5)
        .slice(0, utilityCount);

      selectedUtilities.forEach(utilityType => {
        // Due date (10th-25th of month)
        const dueDay = Math.floor(Math.random() * 15) + 10;
        const dueDate = new Date(date.getFullYear(), date.getMonth(), dueDay);

        // For current month or last month, make some bills unpaid
        // The more recent, the more likely to be unpaid
        const isCurrentOrLastMonth = i <= 1;
        const isPaid = isCurrentOrLastMonth
          ? Math.random() > 0.7 // 30% chance to be unpaid for current/last month
          : Math.random() > 0.2; // 80% chance to be paid for older months

        // If paid, generate a payment date (before or on due date)
        const paidDate = isPaid
          ? format(addDays(dueDate, -Math.floor(Math.random() * 5)), 'yyyy-MM-dd')
          : undefined;

        // Base amount for this utility type
        let amount = BASE_AMOUNTS[utilityType as keyof typeof BASE_AMOUNTS];

        // Add some random variation (±20%)
        const variation = 0.8 + Math.random() * 0.4; // 0.8 to 1.2
        amount = Math.round(amount * variation);

        // Create the mock bill
        const mockBill: MockUtilityBill = {
          id: `utility-${uuidv4()}`,
          property_id: property.id,
          propertyAddress,
          tenant_id: tenantId,
          lessor_id: lessorId,
          type: 'utility',
          utilityType,
          description: `${utilityType.charAt(0).toUpperCase() + utilityType.slice(1)} bill for ${monthName}`,
          amount,
          currency: property.currency || 'usd',
          dueDate: format(dueDate, 'yyyy-MM-dd'),
          isPaid,
          paidDate
        };

        bills.push(mockBill);
      });
    });
  }

  return bills;
}

/**
 * Helper function to filter utility bills based on date range and property
 * 
 * @param bills All utility bills
 * @param startDate Start date of the range
 * @param endDate End date of the range
 * @param propertyId Optional property ID to filter by
 * @returns Filtered bills
 */
export function filterUtilityBills(
  bills: MockUtilityBill[],
  startDate: Date,
  endDate: Date,
  propertyId?: string | null
): MockUtilityBill[] {
  return bills.filter(bill => {
    // Filter by property if specified
    if (propertyId && bill.property_id !== propertyId) {
      return false;
    }

    // Filter by date range
    const billDate = parseISO(bill.dueDate);
    return billDate >= startDate && billDate <= endDate;
  });
}