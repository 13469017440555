import { gql } from "graphql-request";

export const CREATE_USER_PROFILE = gql`
  mutation CreateUserProfile(
  $id: UUID!
  $email: String!
  $role: String!
  $first_name: String!
  $last_name: String
  ) {
    insertIntoprofilesCollection(
      objects: { 
      id: $id
      email: $email
      role: $role
      first_name: $first_name
      last_name: $last_name
       }
    ) {
      records {
        id
        role
        first_name
        last_name
        email
        phone
        image_url
        updated_at
        created_at
      }
    }
  }
`;
