import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Calendar } from 'lucide-react';
import { DatePicker } from "@/components/ui/custom-ui/date-picker";
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/i18n-utils';

interface DateRangePickerProps {
    startDate: Date;
    setStartDate: (date: Date) => void;
    endDate: Date;
    setEndDate: (date: Date) => void;
    onApply: () => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onApply
}) => {
    const { t, i18n } = useTranslation();

    return (
        <Card>
            <CardHeader>
                <CardTitle>{t('bills.select_period')}</CardTitle>
                <CardDescription>
                    {t('bills.date_range_description')}
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col items-stretch justify-items-stretch sm:flex-row gap-4 sm:justify-items-end sm:items-end ">
                    <div className="space-y-2 flex-1">
                        <div className="text-sm font-medium">{t('bills.start_date')}</div>
                        <DatePicker
                            date={startDate}
                            setDate={setStartDate}
                            locale={i18n.language}
                        />
                    </div>
                    <div className="space-y-2 flex-1">
                        <div className="text-sm font-medium">{t('bills.end_date')}</div>
                        <DatePicker
                            date={endDate}
                            setDate={setEndDate}
                            locale={i18n.language}
                        />
                    </div>
                    <Button
                        onClick={onApply}
                        className="flex-shrink-0"
                        variant='outline'
                    >
                        <Calendar className="mr-2 h-4 w-4" />
                        {t('bills.update_results')}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};