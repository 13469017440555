import { gql } from "graphql-request";

export const CREATE_CHECKLIST = gql`
  mutation CreateChecklist(
    $property_id: String!,
    $title: String!,
    $description: String,
    $last_run_date: String
  ) {
    insertIntochecklistsCollection(
      objects: {
        property_id: $property_id,
        title: $title,
        description: $description,
        last_run_date: $last_run_date
      }
    ) {
      records {
        id
        property_id
        title
        description
        last_run_date
        created_at
        updated_at
      }
    }
  }
`;
