import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useProfileWithInvitation } from '@/lib/auth-hooks/useProfileWithInvitation';

const CompleteProfile: React.FC = () => {
  const navigate = useNavigate();

  // Use our custom hook to handle the profile creation with invitation flow
  const {
    formData,
    error,
    isInvitationFlow,
    isLoading,
    isProcessing,
    updateFormField,
    handleSubmit,
    setRole
  } = useProfileWithInvitation();

  // Show loading state while checking auth or validating invitation
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center gap-2">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent"></div>
          <p className="text-sm text-muted-foreground">
            {isInvitationFlow ? 'Validating invitation...' : 'Loading...'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center">
            {isInvitationFlow ? 'Complete Your Tenant Profile' : 'Complete Your Profile'}
          </CardTitle>
          <CardDescription className="text-center">
            {isInvitationFlow
              ? 'Please provide your details to complete the rental process'
              : 'Please provide a few more details to complete your account setup'}
          </CardDescription>
        </CardHeader>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
          <CardContent className="space-y-4">
            {/* Show error message if any */}
            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Special message for invitation flow */}
            {isInvitationFlow && (
              <Alert>
                <AlertDescription>
                  You're accepting an invitation to rent a property.
                  <p>Your role will be set as Tenant.</p>
                  <p>You can switch roles in the app.</p>
                </AlertDescription>
              </Alert>
            )}

            {/* First Name */}
            <div className="space-y-2">
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                value={formData.firstName}
                onChange={(e) => updateFormField('firstName', e.target.value)}
                placeholder="Enter your first name"
                required
              />
            </div>

            {/* Last Name */}
            <div className="space-y-2">
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                value={formData.lastName}
                onChange={(e) => updateFormField('lastName', e.target.value)}
                placeholder="Enter your last name"
                required
              />
            </div>

            {/* Phone Number */}
            <div className="space-y-2">
              <Label htmlFor="phone">Phone Number (optional)</Label>
              <Input
                id="phone"
                type="tel"
                value={formData.phone}
                onChange={(e) => updateFormField('phone', e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>

            {/* Role Selection - Only show in non-invitation flow */}
            {!isInvitationFlow && (
              <div className="space-y-2">
                <Label>I am a:</Label>
                <p className='text-sm text-muted-foreground'>
                  The role can be switched in the app
                </p>
                <RadioGroup
                  value={formData.role}
                  onValueChange={(value) => setRole(value as any)}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="tenant" id="tenant" />
                    <Label htmlFor="tenant">Tenant (I rent property)</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="lessor" id="lessor" />
                    <Label htmlFor="lessor">Lessor (I own property)</Label>
                  </div>
                </RadioGroup>
              </div>
            )}
          </CardContent>
          <CardFooter>
            <Button
              type="submit"
              className="w-full"
              disabled={isProcessing}
            >
              {isProcessing ? (
                <>
                  <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-transparent"></span>
                  {isInvitationFlow ? 'Accepting Invitation...' : 'Creating Profile...'}
                </>
              ) : (
                isInvitationFlow ? 'Accept & Complete Profile' : 'Complete Profile'
              )}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default CompleteProfile;