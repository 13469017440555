import React from 'react';
import { MainLayout } from '@/components/layout/MainLayout';
import { Card, CardContent } from '@/components/ui/card';
import { Home } from 'lucide-react';
import { useUserData } from '@/lib/query-hooks/useUserData';
import { useUserProperties } from '@/lib/query-hooks/get-hooks';
import { useAuth } from '@/contexts/AuthContext';
import TenantPropertyCard from '@/components/properties/tenant/TenantPropertyCard';
import TenantInvitationCard from '@/components/properties/tenant/TenantInvitationCard';
import PropertyCardSkeleton from '@/components/properties/tenant/TenantPropertyCardSkeleton';
import { Property, Rent } from '@/types';
import { isWithinInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';

const TenantProperties: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const userId = user?.id || '';

  // Fetch user data
  const {
    data: userData,
    isLoading,
    isError,
    refetch
  } = useUserData(userId);

  // Get all properties for the current user as tenant
  const userProperties = useUserProperties(userId, 'tenant');

  // First, categorize properties into invitation and active rentals to avoid duplication
  const categorizedProperties = React.useMemo(() => {
    if (!userData) return { invitations: [], activeRentals: [] };

    const invitations: { property: Property, rent: Rent }[] = [];
    const activeRentals: {
      property: Property,
      activeRent: Rent | null,
      lessor: any,
      showAccessInfo: boolean
    }[] = [];

    // Track properties that have already been processed
    const processedPropertyIds = new Set<string>();

    // Process properties with priority:
    // 1. First, find properties with active, accepted rents
    // 2. Then, consider properties with pending rents if not already included

    // First pass: Find all properties with active rents
    userProperties.forEach(property => {
      const propertyRents = userData.propertyRents?.[property.id] || [];

      // Look for active, accepted, non-archived rent
      const activeRent = propertyRents
        .map(rentId => userData.rents[rentId])
        .find(rent =>
          rent.isAccepted &&
          !rent.isArchived &&
          rent.tenant_id === userId &&
          rent.status === 'active'
        );

      if (activeRent) {
        // Found an active rent for this property
        processedPropertyIds.add(property.id);

        // Get lessor data if available
        const lessorId = activeRent.lessor_id;
        const lessor = lessorId ? userData.users[lessorId] : null;

        // Check if access info should be shown
        const isCurrentRental = isWithinInterval(
          new Date(),
          {
            start: new Date(activeRent.date_start),
            end: new Date(activeRent.date_end)
          }
        );

        activeRentals.push({
          property,
          activeRent,
          lessor,
          showAccessInfo: isCurrentRental
        });
      }
    });

    // Second pass: Find properties with pending invitations that aren't already active
    userProperties.forEach(property => {
      // Skip if this property already has an active rent
      if (processedPropertyIds.has(property.id)) return;

      const propertyRents = userData.propertyRents?.[property.id] || [];

      // Find the most recent pending invitation
      const pendingRents = propertyRents
        .map(rentId => userData.rents[rentId])
        .filter(rent =>
          rent.status === "pending" &&
          !rent.isArchived &&
          !rent.isAccepted &&
          rent.tenant_id === userId
        )
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

      if (pendingRents.length > 0) {
        // Use the most recent pending invitation
        invitations.push({ property, rent: pendingRents[0] });
        processedPropertyIds.add(property.id);
      } else {
        // No active or pending rents, but the user can access the property
        // This could be a property with an accepted but not active rent
        const otherRent = propertyRents
          .map(rentId => userData.rents[rentId])
          .find(rent =>
            rent.tenant_id === userId &&
            !rent.isArchived &&
            rent.status !== 'canceled' // Exclude canceled rents
          );

        if (otherRent) {
          // Get lessor data if available
          const lessorId = otherRent.lessor_id;
          const lessor = lessorId ? userData.users[lessorId] : null;

          activeRentals.push({
            property,
            activeRent: otherRent,
            lessor,
            showAccessInfo: false // Not active, so don't show access info
          });
          processedPropertyIds.add(property.id);
        }
      }
    });

    return { invitations, activeRentals };
  }, [userData, userProperties, userId]);

  // Extract the results for easier use in rendering
  const pendingInvitations = categorizedProperties.invitations;
  const activeRentals = categorizedProperties.activeRentals;

  // Function to handle invitation acceptance success
  const handleInvitationAccepted = () => {
    refetch();
  };

  // Function to handle invitation rejection
  const handleInvitationRejected = () => {
    refetch();
  };

  return (
    <MainLayout>
      <div className="space-y-6 pt-4 mb-8 lg:py-4 lg:mb-0">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">{t('tenant.my_rentals')}</h1>
          <p className="text-muted-foreground mt-1">
            {t('tenant.rentals_description')}
          </p>
        </div>

        {isLoading ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {[1, 2, 3, 4].map(i => (
              <PropertyCardSkeleton key={i} />
            ))}
          </div>
        ) : isError ? (
          <div className="text-center py-12">
            <p>{t('common.error_loading')}</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Pending Invitations */}
            {pendingInvitations.map(({ property, rent }) => (
              <TenantInvitationCard
                key={rent.id}
                property={property}
                rent={rent}
                onAccepted={handleInvitationAccepted}
                onRejected={handleInvitationRejected}
                userId={userId}
              />
            ))}

            {/* Active Rentals */}
            {activeRentals.map(({ property, activeRent, lessor, showAccessInfo }) => (
              <TenantPropertyCard
                key={property.id}
                property={property}
                activeRent={activeRent}
                lessor={lessor}
                showAccessInfo={showAccessInfo}
              />
            ))}

            {/* No Rentals Message */}
            {pendingInvitations.length === 0 && activeRentals.length === 0 && (
              <Card className="lg:col-span-2">
                <CardContent className="p-8 text-center">
                  <Home className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                  <h3 className="text-lg font-medium mb-2">{t('tenant.no_rentals')}</h3>
                  <p className="text-muted-foreground">{t('tenant.no_active_agreements')}</p>
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default TenantProperties;