import { Property, UserProfile } from "@/types";
import CryptoJS from 'crypto-js';

// Function to get address string
export const getPropertyAddress = (property: Property): string => {
    return `${property.city}, ${property.street} ${property.building}, ${property.apartment}`;
};

export const getUserName = (user: UserProfile): string => {
    return `${user.first_name} ${user?.last_name}`
}

// Encryption/decryption utilities
export const encryptData = (data: string): string => {
    if (!data) return '';
    const encryptionKey = import.meta.env.VITE_SALT_ENCRIPTION!;
    return CryptoJS.AES.encrypt(data, encryptionKey).toString();
};

export const decryptData = (encryptedData: string): string => {
    if (!encryptedData) return '';
    try {
        const encryptionKey = import.meta.env.VITE_SALT_ENCRIPTION!;
        const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Failed to decrypt data:', error);
        return '';
    }
};