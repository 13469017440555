import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Property, Currency, UnitTerm } from '@/types';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Mail, Send, Calendar, Info, AlertTriangle } from 'lucide-react';
import { format, parseISO, isAfter, isBefore, addMonths } from 'date-fns';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useTranslation } from 'react-i18next';
import { formatDate, formatCurrency } from '@/utils/i18n-utils';

// Define the available date range type
interface DateRange {
    start: string;
    end: string | null;
}

interface InvitationFormProps {
    property: Property;
    isLoading: boolean;
    onSubmit: (values: any) => Promise<void>;
    availableDateRanges: DateRange[];
    checkDateOverlap: (startDate: string, endDate: string) => boolean;
}

export const InvitationForm: React.FC<InvitationFormProps> = ({
    property,
    isLoading,
    onSubmit,
    availableDateRanges,
    checkDateOverlap
}) => {
    const { t, i18n } = useTranslation();
    const [dateError, setDateError] = useState<string | null>(null);

    // Create form schema with translations for error messages
    const formSchema = z.object({
        email: z.string().email({ message: t('invitation.form.email_validation') }),
        date_start: z.string().min(1, { message: t('invitation.form.start_date_required') }),
        date_end: z.string().min(1, { message: t('invitation.form.end_date_required') }),
        price_rent: z.number().min(1, { message: t('invitation.form.price_required') }),
        currency: z.enum(['usd', 'uah'] as const),
        unit: z.enum(['day', 'week', 'month', 'year'] as const),
    });

    type FormValues = z.infer<typeof formSchema>;

    // Initialize the form with default values
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: '',
            date_start: format(new Date(), 'yyyy-MM-dd'),
            date_end: format(addMonths(new Date(), 6), 'yyyy-MM-dd'),
            price_rent: property?.price || 0,
            currency: property?.currency || 'usd',
            unit: property?.unit_term || 'month',
        },
    });

    // Watch for date changes
    const startDate = form.watch('date_start');
    const endDate = form.watch('date_end');

    // Validate dates whenever they change
    useEffect(() => {
        if (!startDate || !endDate) return;

        const start = parseISO(startDate);
        const end = parseISO(endDate);

        // Check if end date is after start date
        if (!isAfter(end, start)) {
            setDateError(t('invitation.form.end_date_after_start'));
            return;
        }

        // Check for overlaps with existing rentals
        if (checkDateOverlap(startDate, endDate)) {
            setDateError(t('invitation.toast.date_overlap'));
            return;
        }

        // Clear errors if valid
        setDateError(null);
    }, [startDate, endDate, checkDateOverlap, t]);

    // Handle form submission
    const handleSubmit = async (values: FormValues) => {
        if (dateError) return;
        await onSubmit(values);
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center">
                    <Mail className="mr-2 h-5 w-5" />
                    {t('invitation.title')}
                </CardTitle>
                <CardDescription>
                    {t('invitation.form.description')}
                </CardDescription>
            </CardHeader>
            <CardContent>
                {/* Available Date Ranges Info */}
                <div className="mb-6">
                    <div className="bg-blue-50 border border-blue-200 rounded-md p-4">
                        <h3 className="font-medium flex items-center text-blue-800 mb-2">
                            <Info className="h-4 w-4 mr-2" />
                            {t('invitation.form.available_dates')}
                        </h3>
                        <div className="space-y-2">
                            {availableDateRanges.length === 0 ? (
                                <p className="text-sm text-blue-600">{t('invitation.form.no_available_dates')}</p>
                            ) : (
                                availableDateRanges.map((range, index) => (
                                    <div key={index} className="flex items-center text-sm text-blue-600">
                                        <Calendar className="h-3 w-3 mr-2" />
                                        {formatDate(range.start, i18n.language)} {range.end
                                            ? `${t('invitation.form.to')} ${formatDate(range.end, i18n.language)}`
                                            : t('invitation.form.onwards')}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
                        {/* Tenant Email */}
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{t('invitation.form.tenant_email')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="email"
                                            type="email"
                                            placeholder={t('invitation.form.email_placeholder')}
                                            {...field}
                                            disabled={isLoading}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* Start Date */}
                            <FormField
                                control={form.control}
                                name="date_start"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('invitation.form.start_date')}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="date"
                                                {...field}
                                                disabled={isLoading}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {/* End Date */}
                            <FormField
                                control={form.control}
                                name="date_end"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('invitation.form.end_date')}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="date"
                                                {...field}
                                                disabled={isLoading}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        {/* Date validation error */}
                        {dateError && (
                            <Alert variant="destructive" className="mt-2">
                                <div className='flex flex-row items-center space-x-2'>
                                    <AlertTriangle className="h-4 w-4" />
                                    <AlertDescription>{dateError}</AlertDescription>
                                </div>
                            </Alert>
                        )}

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {/* Price */}
                            <FormField
                                control={form.control}
                                name="price_rent"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('properties.price')}</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="number"
                                                placeholder="0"
                                                {...field}
                                                onChange={(e) => field.onChange(parseFloat(e.target.value) || 0)}
                                                disabled={isLoading}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {/* Currency */}
                            <FormField
                                control={form.control}
                                name="currency"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('properties.currency')}</FormLabel>
                                        <Select
                                            onValueChange={field.onChange}
                                            defaultValue={field.value}
                                            disabled={isLoading}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={t('invitation.form.select_currency')} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value="usd">{t('currencies.usd')}</SelectItem>
                                                <SelectItem value="uah">{t('currencies.uah')}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {/* Unit */}
                            <FormField
                                control={form.control}
                                name="unit"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('invitation.form.unit')}</FormLabel>
                                        <Select
                                            onValueChange={field.onChange}
                                            defaultValue={field.value}
                                            disabled={isLoading}
                                        >
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder={t('invitation.form.select_unit')} />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value="day">{t('properties.terms.day')}</SelectItem>
                                                <SelectItem value="week">{t('properties.terms.week')}</SelectItem>
                                                <SelectItem value="month">{t('properties.terms.month')}</SelectItem>
                                                <SelectItem value="year">{t('properties.terms.year')}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className="pt-4">
                            <Button
                                type="submit"
                                disabled={isLoading || !!dateError}
                                className="w-full md:w-auto"
                            >
                                {isLoading ? (
                                    <span className="flex items-center">{t('invitation.form.processing')}</span>
                                ) : (
                                    <span className="flex items-center">
                                        <Send className="mr-2 h-4 w-4" /> {t('invitation.form.send_invitation')}
                                    </span>
                                )}
                            </Button>
                        </div>
                    </form>
                </Form>
            </CardContent>
        </Card>
    );
};