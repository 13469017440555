import React from 'react';
import { Badge } from '@/components/ui/badge';
import { RentStatus } from '@/types';
import { CircleCheckBig, Clock2, Ban } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface BookingStatusBadgeProps {
  status: RentStatus;
  size?: 'sm' | 'default';
  showIcon?: boolean;
}

export const BookingStatusBadge: React.FC<BookingStatusBadgeProps> = ({
  status,
  size = 'default',
  showIcon = true
}) => {
  const { t } = useTranslation();
  // Pick colors based on status
  const getStatusStyle = () => {
    switch (status) {
      case 'active':
        return {
          variant: 'default',
          icon: showIcon ? <CircleCheckBig className="size-4 mr-1" /> : null,
          className: ''
        };
      case 'pending':
        return {
          variant: 'outline',
          icon: showIcon ? <Clock2 className="size-4 mr-1" /> : null,
          className: 'bg-amber-50 text-amber-700 hover:bg-amber-100 border-amber-200'
        };
      case 'canceled':
        return {
          variant: 'destructive',
          icon: showIcon ? <Ban className="size-4 mr-1" /> : null,
          className: ''
        };
      default:
        return {
          variant: 'outline',
          icon: null,
          className: ''
        };
    }
  };

  const { variant, icon, className } = getStatusStyle();

  return (
    <Badge
      variant={variant as any}
      className={`flex capitalize justify-center items-center ${size === 'sm' ? 'text-xs py-0 px-2 h-5' : ''} ${className}`}
    >
      {icon}
      <span>{t(`bookings.status_badge.${status}`)}</span>
    </Badge>
  );
};
