import { gql } from 'graphql-request';

export const GET_ARCHIVED_RENTS = gql`
  query GetArchivedRents($user_id: String!, $first: Int!, $after: String, $lessor_id: String!) {
    rentsCollection(
      first: $first,
      after: $after,
      filter: {isArchived: { eq: true },
        lessor_id: { eq: $lessor_id },
        
        }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          lessor_id
          tenant_id
          manager_id
          property_id
          unit
          date_start
          date_end
          status
          contract_url
          contract_term
          currency
          price_rent
          isAccepted
          isArchived
          updated_at
          created_at
        }
      }
    }
  }
`;