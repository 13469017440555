import { Checklist, InventoryItem, Property, Repair, UserRole } from "@/types";
import { useUserData } from "./useUserData";
import { useMemo } from "react";

// Hook to get a specific user
export function useUser(userId: string) {
    const { data } = useUserData(userId);
    return useMemo(() => data?.users[userId], [data, userId]);
}

// Hook to get a specific property
export function useProperty(propertyId: string, userId: string) {
    const { data } = useUserData(userId);
    return useMemo(() => data?.properties[propertyId], [data, propertyId]);
}

// Hook to get all properties for a user by role
export function useUserProperties(userId: string, role: UserRole) {
    const { data } = useUserData(userId);

    return useMemo(() => {
        if (!data) return [];

        const propertyIds = role === 'lessor'
            ? data.userProperties?.asLessor?.[userId] || []
            : data.userProperties?.asTenant?.[userId] || [];

        return propertyIds
            .map(id => data.properties[id])
            .filter(Boolean);
    }, [data, userId, role]);
}

// Hook to get all rents for a property
export function usePropertyRents(propertyId: string, userId: string) {
    const { data } = useUserData(userId);

    return useMemo(() => {
        if (!data) return [];

        const rentIds = data.propertyRents?.[propertyId] || [];
        return rentIds.map(id => data.rents[id]).filter(Boolean);
    }, [data, propertyId, userId]);
}

// Hook to get all bills for a rent
export function useRentBills(rentId: string, userId: string) {
    const { data } = useUserData(userId);

    return useMemo(() => {
        if (!data) return [];

        const billIds = data.rentBillsByRent?.[rentId] || [];
        return billIds.map(id => data.rentBills[id]).filter(Boolean);
    }, [data, rentId, userId]);
}

// Hook to get all repairs for a property
export function usePropertyRepairs(propertyId: string, userId: string) {
    const { data } = useUserData(userId);

    return useMemo(() => {
        if (!data) return [];

        const repairIds = data.repairsByProperty?.[propertyId] || [];
        return repairIds.map(id => data.repairs[id]).filter(Boolean);
    }, [data, propertyId, userId]);
}

// Custom hook to get all repairs for a user grouped by property
export function useUserRepairs(userId: string, role: UserRole) {
    const { data, isLoading, error } = useUserData(userId);

    return useMemo(() => {
        if (!data) return { repairsByProperty: {}, properties: [], isLoading, error };

        let propertyIds: string[] = [];

        // Get relevant property IDs based on user role
        if (role === 'lessor') {
            propertyIds = data.userProperties?.asLessor?.[userId] || [];
        } else if (role === 'tenant') {
            propertyIds = data.userProperties?.asTenant?.[userId] || [];
        }

        // Get all properties user has access to
        const properties = propertyIds
            .map(id => data.properties[id])
            .filter(Boolean) as Property[];

        // Group repairs by property
        const repairsByProperty: Record<string, Repair[]> = {};

        // For each property, get its repairs
        propertyIds.forEach(propertyId => {
            const repairIds = data.repairsByProperty?.[propertyId] || [];
            const repairs = repairIds
                .map(id => data.repairs[id])
                .filter(Boolean) as Repair[];

            if (repairs.length > 0) {
                repairsByProperty[propertyId] = repairs;
            }
        });

        return { repairsByProperty, properties, isLoading, error };
    }, [data, userId, role, isLoading, error]);
}

// Hook to get all checklists for a property
export function usePropertyChecklists(propertyId: string, userId: string) {
    const { data, isLoading, isError, refetch } = useUserData(userId);

    const result = useMemo(() => {
        if (!data || !propertyId) {
            return {
                checklists: [],
                totalChecklists: 0,
                activeChecklists: 0,
                completedChecklists: 0,
                lastRunDate: null,
                inventoryItems: {},
                itemStatusCounts: {
                    completed: 0,
                    issue: 0,
                    pending: 0
                }
            };
        }

        // Get checklist IDs for this property
        const checklistIds = data.checklistsByProperty?.[propertyId] || [];

        // Get actual checklist objects
        const checklists = checklistIds
            .map(id => data.checklists?.[id])
            .filter(Boolean) as Checklist[];

        // Calculate statistics
        const totalChecklists = checklists.length;

        // Since Checklist doesn't have a status field in your type definition,
        // we'll determine completion based on inventory items or last_run_date
        const hasBeenRun = checklists.filter(c => c.last_run_date).length;
        const activeChecklists = totalChecklists - hasBeenRun;
        const completedChecklists = hasBeenRun;

        // Find the most recently run checklist
        let lastRunDate = null;
        if (checklists.length > 0) {
            const sortedChecklists = [...checklists].sort((a, b) => {
                const dateA = a.last_run_date ? new Date(a.last_run_date).getTime() : 0;
                const dateB = b.last_run_date ? new Date(b.last_run_date).getTime() : 0;
                return dateB - dateA;
            });

            if (sortedChecklists[0]?.last_run_date) {
                lastRunDate = sortedChecklists[0].last_run_date;
            }
        }

        // Get inventory items for all checklists
        const inventoryItems: Record<string, InventoryItem[]> = {};
        const itemStatusCounts = {
            completed: 0,
            issue: 0,
            pending: 0
        };

        checklists.forEach(checklist => {
            const itemIds = data.inventoryItemsByChecklist?.[checklist.id] || [];
            const items = itemIds
                .map(id => data.inventoryItems?.[id])
                .filter(Boolean) as InventoryItem[];

            inventoryItems[checklist.id] = items;

            // Count item statuses
            items.forEach(item => {
                if (item.status === 'completed') {
                    itemStatusCounts.completed += 1;
                } else if (item.status === 'issue') {
                    itemStatusCounts.issue += 1;
                } else if (item.status === 'pending') {
                    itemStatusCounts.pending += 1;
                }
            });
        });

        return {
            checklists,
            totalChecklists,
            activeChecklists,
            completedChecklists,
            lastRunDate,
            inventoryItems,
            itemStatusCounts
        };
    }, [data, propertyId, userId]);

    return {
        ...result,
        isLoading,
        isError,
        refetch
    };
}