import { gql } from 'graphql-request';

export const GET_RENT_BILLS_BY_DATE = gql`
  query FetchRentBillsByDate(
    $user_id: UUID!,
    $role: String!,
    $start_date: Date!,
    $end_date: Date!,
    $property_id: String
  ) {
    # Get rent bills between start and end dates
    rents_billsCollection(
      filter: {
        and: [
          { 
            or: [
              { tenant_id: { eq: $user_id } },
              { lessor_id: { eq: $user_id } }
            ]
          },
          { 
            or: [
              # Match bills with date_from within our range
              { date_from: { gte: $start_date, lte: $end_date } },
              # Match bills with due_date within our range
              { due_date: { gte: $start_date, lte: $end_date } }
            ]
          }
        ]
      },
      first: 100 # Increased limit to ensure we get enough bills
    ) {
      edges {
        node {
          id
          lessor_id
          tenant_id
          property_id
          rent_id
          bill_debt
          isPaid
          date_from
          due_date
          paid_at
          number_of_days
          comment
          currency
          price_rent
          invoice_url
          receipts_urls
          updated_at
          created_at
        }
      }
    }
    
    # Get properties for these bills
    propertiesCollection {
      edges {
        node {
          id
          lessor_id
          status
          title
          city
          street
          building
          block
          apartment
          price
          currency
          unit_term
          image_url
        }
      }
    }
  }
`;