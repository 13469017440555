import { parseISO, format as dateFormat, isValid } from 'date-fns';
import { uk, enUS } from 'date-fns/locale';

/**
 * Format a date string using i18n
 * @param dateString Date string to format
 * @param locale Current locale (e.g., 'en', 'uk')
 * @param formatStr Optional custom format string
 * @returns Formatted date string
 */
export const formatDate = (dateString: string, locale: string, formatStr?: string): string => {
  try {
    if (!dateString) return '';
    
    const date = parseISO(dateString);
    
    if (!isValid(date)) {
      return locale === 'uk' ? 'Недійсна дата' : 'Invalid date';
    }
    
    // Select locale object based on current language
    const localeObj = locale === 'uk' ? uk : enUS;
    
    // Use default format or custom format if provided
    const defaultFormat = 'PP'; // 'Apr 29, 2023' in English or locale equivalent
    
    return dateFormat(date, formatStr || defaultFormat, { locale: localeObj });
  } catch (error) {
    console.error('Error formatting date:', error);
    return locale === 'uk' ? 'Помилка формату дати' : 'Date format error';
  }
};

/**
 * Format a currency amount using i18n
 * @param amount Amount to format
 * @param locale Current locale (e.g., 'en', 'uk')
 * @param currency Currency code (default: 'USD')
 * @returns Formatted currency string
 */
export const formatCurrency = (
  amount: number, 
  locale: string, 
  currency: string = 'USD'
): string => {
  try {
    return new Intl.NumberFormat(locale === 'uk' ? 'uk-UA' : 'en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  } catch (error) {
    console.error('Error formatting currency:', error);
    return `${amount}`;
  }
};

/**
 * Format a number using i18n
 * @param value Number to format
 * @param locale Current locale (e.g., 'en', 'uk')
 * @param options NumberFormat options
 * @returns Formatted number string
 */
export const formatNumber = (
  value: number, 
  locale: string,
  options?: Intl.NumberFormatOptions
): string => {
  try {
    return new Intl.NumberFormat(
      locale === 'uk' ? 'uk-UA' : 'en-US', 
      options
    ).format(value);
  } catch (error) {
    console.error('Error formatting number:', error);
    return `${value}`;
  }
};

/**
 * Get localized unit text based on unit type and locale
 * @param unitType Unit type identifier (e.g., 'sq_meter', 'sq_foot')
 * @param locale Current locale
 * @returns Localized unit text
 */
export const getLocalizedUnit = (
  unitType: string, 
  locale: string
): string => {
  const units: Record<string, Record<string, string>> = {
    'sq_meter': {
      'en': 'sq m',
      'uk': 'кв. м'
    },
    'sq_foot': {
      'en': 'sq ft',
      'uk': 'кв. фт'
    },
    'day': {
      'en': 'day',
      'uk': 'день'
    },
    'week': {
      'en': 'week',
      'uk': 'тиждень'
    },
    'month': {
      'en': 'month',
      'uk': 'місяць'
    },
    'year': {
      'en': 'year',
      'uk': 'рік'
    }
  };

  return units[unitType]?.[locale] || unitType;
};

/**
 * Use this hook to get formatted date for use with components
 * This wrapper ensures consistency across the application
 * @param dateString Date string to format
 * @param i18n The i18next instance from useTranslation()
 * @returns Formatted date string
 */
export const useFormattedDate = (
  dateString: string,
  i18n: { language: string }
): string => {
  return formatDate(dateString, i18n.language);
};

/**
 * Use this hook to get formatted currency for use with components
 * This wrapper ensures consistency across the application
 * @param amount Amount to format
 * @param i18n The i18next instance from useTranslation()
 * @param currency Currency code
 * @returns Formatted currency string
 */
export const useFormattedCurrency = (
  amount: number,
  i18n: { language: string },
  currency: string = 'USD'
): string => {
  return formatCurrency(amount, i18n.language, currency);
};

export default {
  formatDate,
  formatCurrency,
  formatNumber,
  getLocalizedUnit,
  useFormattedDate,
  useFormattedCurrency
};